/* eslint-disable react/prop-types */
import React, {useState, useEffect} from 'react';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import FullWidthTab from './TabsItem';
import Divider from '@material-ui/core/Divider';
import {useMediaQuery} from 'react-responsive';
import DeleteIcon from '@material-ui/icons/Delete';
import DialogContentText from '@material-ui/core/DialogContentText';
import {useSelector} from 'react-redux';
import {CircularProgress} from '@material-ui/core';
import {getAuth} from 'firebase/auth';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)(props => {
  const {children, classes, onClose, ...other} = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function AddItemDialogs({
  bopen,
  handleClose,
  handleNotiStack,
  updateCatalogo,
  isClone,
}) {
  const auth = getAuth();
  const isSmall = useMediaQuery({query: '(max-width: 767.98px)'});
  const isVerySmall = useMediaQuery({query: '(max-width: 367.98px)'});
  const idstore = useSelector(state => state.setrestaurante.idstore);
  const idcat = useSelector(state => state.setcats.id);
  const cats = useSelector(state => state.setcatalogo.catalogo);
  const [open, setOpen] = React.useState(false);
  const [toggleBackdrop, setToggleBackdrop] = useState(false);
  const [titleBackdrop, setTitleBackdrop] = useState('');
  const [descriptionBackdrop, setDescriptionBackdrop] = useState('');
  const [codeBackdrop, setCodeBackdrop] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [isSending, setIsSending] = useState(false);
  const handleBackDrop = (title, description, code, open) => {
    setToggleBackdrop(open);
    setTitleBackdrop(title);
    setDescriptionBackdrop(description);
    setCodeBackdrop(code);
  };
  const handleClickOpen2 = () => {
    setOpen(true);
  };
  const handleClose2 = () => {
    setValue(0);
    setOpen(false);
  };

  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChangeIndex = index => {
    setValue(index);
  };

  function close() {
    setValue(0);
    handleClose();
  }

  function closewithDialog() {
    handleClose();
    handleClose2();
  }

  const [fDetails, setFdetails] = useState(true);
  const [fComp, setFComp] = useState(true);
  const [fHorario, setFHorario] = useState(true);
  const [fPromo, setFPromo] = useState(true);

  //detalhes
  const [cNome, setCnome] = useState('');
  const [cDesc, setcDesc] = useState('');
  const [cPdv, setcPdv] = useState('');
  const [cat, setCat] = React.useState('');
  const [cvlr, setCvlr] = useState();
  const [ccpt, setCcpt] = useState();
  const [isAvaliable, setAvailable] = useState(true);
  const [files, setFiles] = useState([]);
  const [cIsPeso, setCbpeso] = useState(false);
  const [cIntPeso, setCintPeso] = useState(0);
  const [isImgReal, setIsImgReal] = useState(false);

  //disponibilidade
  const [bDom, setbDom] = useState(true);
  const [bSeg, setbSeg] = useState(true);
  const [bTer, setbTer] = useState(true);
  const [bQua, setbQua] = useState(true);
  const [bQui, setbQui] = useState(true);
  const [bSex, setbSex] = useState(true);
  const [bSab, setbSab] = useState(true);

  //promo
  const [isPromo, setbpromo] = useState(false);
  const [cvlrpromo, setCvlrpromo] = useState();

  const [ITENS, setITENS] = useState([]);
  const item = useSelector(state => state.setitem);
  const aratdmii = useSelector(state => state.setrestaurante.aratdmii);
  const aratdm = useSelector(state => state.setrestaurante.aratdm);

  useEffect(() => {
    let obj = cats.find(o => o._id === idcat);
    if (isClone) {
      //detalhes
      setCnome(item.nome);
      setcDesc(item.descricao);
      setcPdv('');
      setCat(obj);
      setCvlr(item.vlr);
      setAvailable(item.bavailable);
      setFiles([]);
      setCbpeso(item.bpeso);
      setCintPeso(item.intpeso);
      setIsImgReal(item.bimg);

      //disponibilidade
      setbDom(item.bodom);
      setbSeg(item.boseg);
      setbTer(item.boter);
      setbQua(item.boqua);
      setbQui(item.boqui);
      setbSex(item.bosex);
      setbSab(item.bosab);

      setbpromo(item.bpromo);
      setCvlrpromo(item.vlrpromo);
      setITENS(item.itens);
    } else {
      setFdetails(true);
      setFComp(true);
      setFHorario(true);
      setFPromo(true);

      //detalhes
      setCnome('');
      setcDesc('');
      setcPdv('');
      setCat(obj);
      setCvlr(0);
      setAvailable(true);
      setFiles([]);
      setCbpeso(false);
      setCintPeso(0);
      setIsImgReal(false);

      //disponibilidade
      setbDom(true);
      setbSeg(true);
      setbTer(true);
      setbQua(true);
      setbQui(true);
      setbSex(true);
      setbSab(true);

      setbpromo(false);
      setCvlrpromo();
      setITENS([]);
    }
  }, [bopen]);

  function saveImage(iditem) {
    setIsSending(true);
    if (files.length !== 0) {
      var metadata = new FormData();
      metadata.append('file', files[0]);
      auth.currentUser.getIdToken().then(token => {
        const imgrequest = new Request(
          'https://kops.apiireit.com/gateway/s3/upload' +
            '?docid=' +
            iditem +
            '&userid=' +
            idstore +
            '&svc=store&index=0',
          {
            method: 'POST',
            headers: {
              'My-Custom-Header': 'Custom-Header-Value',
              Authorization: `Bearer ${token}`,
            },
            mode: 'cors',
            body: metadata,
          },
        );

        fetch(imgrequest)
          .then(response => {
            if (response.status === 200) {
              handleBackDrop('', '', 200, false);
              setTimeout(() => {
                setFdetails(true);
              }, 1000);
            } else if (response.status === 503) {
              setIsSending(false);
              setTimeout(() => {
                handleNotiStack('Não foi possível salvar a imagem', 'error');
              }, 1000);
              handleBackDrop(
                'Serviço indisponível no momento',
                'O serviços para as lojas Irê it não estão disponíveis no momento. Por favor, tente novamente mais tarde',
                503,
                true,
              );
              throw new Error('Something went wrong on API server!');
            } else if (response.status === 404) {
              setIsSending(false);
              setTimeout(() => {
                handleNotiStack(
                  'Não foi possível salvar as informações',
                  'error',
                );
              }, 1000);
              handleBackDrop(
                'Serviço não encontrado.',
                'Por favor, entre em contato com o nosso suporte e relate o problema.',
                404,
                true,
              );
            } else if (response.status === 401) {
              setIsSending(false);
              setTimeout(() => {
                handleNotiStack(
                  'Não foi possível salvar as informações',
                  'error',
                );
              }, 1000);
              handleBackDrop(
                'Você não possui autorização ou suas credenciais expirarão.',
                'Por favor, atualize a baixa ou refaça o processo dee login.',
                401,
                true,
              );
            } else if (response.status === 400) {
              setIsSending(false);
              setTimeout(() => {
                handleNotiStack(
                  'Não foi possível salvar as informações',
                  'error',
                );
              }, 1000);
              handleBackDrop(
                'Requisição inválida.',
                'Confira se todos os campos estão preenchidos corretamente.',
                400,
                true,
              );
            } else if (response.status === 500) {
              setIsSending(false);
              setTimeout(() => {
                handleNotiStack(
                  'Não foi possível salvar as informações',
                  'error',
                );
              }, 1000);
              handleBackDrop(
                'Nosso servidor está passando por irregularidades.',
                'Estamos trabalhado para resolver o mais rápido possível.',
                500,
                true,
              );
            }
          })
          .catch(error => {
            setIsSending(false);
            setTimeout(() => {
              handleNotiStack(
                'Não foi possível salvar as informações',
                'error',
              );
            }, 1000);
            handleBackDrop(
              'Nosso servidor está passando por irregularidades.',
              'Estamos trabalhado para resolver o mais rápido possível.',
              500,
              true,
            );
            console.error(error);
          });
      });
    }
  }

  function inserItem() {
    setIsSending(true);
    let newimg = '';
    if (files.length !== 0) {
      newimg = 'https://ireifiles.s3.amazonaws.com/' + idstore + '/store/';
    }
    auth.currentUser.getIdToken().then(token => {
      const request = new Request(
        'https://kops.apiireit.com/gateway/produtos/itens/insert',
        {
          method: 'POST',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            cpt: ccpt,
            cqtd: 0,
            descricao: cDesc,
            idstore: idstore,
            idcat: cat._id,
            cat: cat.title,
            bavailable: isAvaliable,
            img: newimg,
            bimg: isImgReal,
            bmonted: ITENS.length > 0,
            bpeso: cIsPeso,
            bpromo: isPromo,
            bqtd: false,
            itens: ITENS,
            nome: cNome,
            obs: '',
            qtd: 0,
            intpeso: cIntPeso,
            vlr: cvlr,
            vlrpromo: cvlrpromo,
            pdv: cPdv,
            bodom: bDom,
            boseg: bSeg,
            boter: bTer,
            boqua: bQua,
            boqui: bQui,
            bosex: bSex,
            bosab: bSab,
            aratdm: aratdm,
            aratdmii: aratdmii,
          }),
        },
      );

      fetch(request)
        .then(response => {
          if (response.status === 200) {
            handleBackDrop('', '', 200, false);
            response
              .json()
              .then(data => {
                if (data.map !== undefined) {
                  if (data.map.code == 401) {
                    setIsSending(false);
                    handleNotiStack(
                      'Você já possui um produto com esse nome',
                      'error',
                    );
                  }
                } else {
                  setTimeout(() => {
                    saveImage(data._id);
                    setValue(0);
                    handleClose();
                    setIsSending(false);
                    handleNotiStack(
                      'Produto adicionado com sucesso',
                      'success',
                    );
                  }, 1000);
                  updateCatalogo();
                }
              })
              .catch(error => {
                console.log(error);
              });
          } else if (response.status === 503) {
            setIsSending(false);
            setTimeout(() => {
              handleNotiStack(
                'Não foi possível salvar as informações',
                'error',
              );
            }, 1000);
            handleBackDrop(
              'Serviço indisponível no momento',
              'O serviços para as lojas Irê it não estão disponíveis no momento. Por favor, tente novamente mais tarde',
              503,
              true,
            );
            throw new Error('Something went wrong on API server!');
          } else if (response.status === 404) {
            setIsSending(false);
            setTimeout(() => {
              handleNotiStack(
                'Não foi possível salvar as informações',
                'error',
              );
            }, 1000);
            handleBackDrop(
              'Serviço não encontrado.',
              'Por favor, entre em contato com o nosso suporte e relate o problema.',
              404,
              true,
            );
          } else if (response.status === 401) {
            setIsSending(false);
            setTimeout(() => {
              handleNotiStack(
                'Não foi possível salvar as informações',
                'error',
              );
            }, 1000);
            handleBackDrop(
              'Você não possui autorização ou suas credenciais expirarão.',
              'Por favor, atualize a baixa ou refaça o processo dee login.',
              401,
              true,
            );
          } else if (response.status === 400) {
            setIsSending(false);
            setTimeout(() => {
              handleNotiStack(
                'Não foi possível salvar as informações',
                'error',
              );
            }, 1000);
            handleBackDrop(
              'Requisição inválida.',
              'Confira se todos os campos estão preenchidos corretamente.',
              400,
              true,
            );
          } else if (response.status === 500) {
            setIsSending(false);
            setTimeout(() => {
              handleNotiStack(
                'Não foi possível salvar as informações',
                'error',
              );
            }, 1000);
            handleBackDrop(
              'Nosso servidor está passando por irregularidades.',
              'Estamos trabalhado para resolver o mais rápido possível.',
              500,
              true,
            );
          }
        })
        .catch(error => {
          setIsSending(false);
          setTimeout(() => {
            handleNotiStack('Não foi possível salvar as informações', 'error');
          }, 1000);
          handleBackDrop(
            'Nosso servidor está passando por irregularidades.',
            'Estamos trabalhado para resolver o mais rápido possível.',
            500,
            true,
          );
          console.error(error);
        });
    });
  }

  function onSave() {
    if (cNome == '') {
      handleNotiStack('Adicione o nome do produto', 'warning');
    } else if (cDesc == '') {
      handleNotiStack('Adicione a descrição do produto', 'warning');
    } else if (cvlr == 0) {
      handleNotiStack('Adicione o valor do produto', 'warning');
    } else {
      inserItem();
    }
  }

  return (
    <div>
      <Dialog
        onClose={() => close()}
        style={{
          marginLeft: isVerySmall ? -20 : 0,
          marginRight: isVerySmall ? -20 : 0,
          paddingLeft: 0,
        }}
        aria-labelledby="customized-dialog-title"
        open={bopen}
      >
        <FullWidthTab
          value={value}
          handleChange={handleChange}
          handleChangeIndex={handleChangeIndex}
          cNome={cNome}
          setCnome={setCnome}
          cDesc={cDesc}
          setcDesc={setcDesc}
          cPdv={cPdv}
          setcPdv={setcPdv}
          cat={cat}
          setCat={setCat}
          cvlr={cvlr}
          setCvlr={setCvlr}
          ccpt={ccpt}
          setCcpt={setCcpt}
          isAvaliable={isAvaliable}
          setAvailable={setAvailable}
          files={files}
          setFiles={setFiles}
          bdom={bDom}
          setbdom={setbDom}
          bseg={bSeg}
          setbseg={setbSeg}
          bter={bTer}
          setbter={setbTer}
          bqua={bQua}
          setbqua={setbQua}
          bqui={bQui}
          setbqui={setbQui}
          bsex={bSex}
          setbsex={setbSex}
          bsab={bSab}
          setbsab={setbSab}
          isPromo={isPromo}
          setbpromo={setbpromo}
          cvlrpromo={cvlrpromo}
          setCvlrpromo={setCvlrpromo}
          ITENS={ITENS}
          setITENS={setITENS}
          cbpeso={cIsPeso}
          setCbpeso={setCbpeso}
          cIntPeso={cIntPeso}
          setCintPeso={setCintPeso}
          fDetails={fDetails}
          setFdetails={setFdetails}
          fComp={fComp}
          setFComp={setFComp}
          fHorario={fHorario}
          setFHorario={setFHorario}
          fPromo={fPromo}
          setFPromo={setFPromo}
          isImgReal={isImgReal}
          setIsImgReal={setIsImgReal}
        />
        <Divider variant="middle" />

        <DialogActions style={{position: 'relative', bottom: 0}}>
          {isVerySmall ? (
            <div
              style={{
                alignItems: 'center',
              }}
            >
              <IconButton
                onClick={handleClickOpen2}
                aria-label="delete"
                color="secondary"
              >
                <DeleteIcon />
              </IconButton>
            </div>
          ) : isSmall ? (
            <div
              style={{
                display: 'flex',
              }}
            >
              <Button
                onClick={handleClickOpen2}
                style={{marginTop: 0}}
                size={'small'}
                color="secondary"
                variant="outlined"
              >
                Excluir item
              </Button>
            </div>
          ) : (
            <div
              style={{
                position: 'absolute',
                bottom: 0,
                marginBottom: 10,
                left: 15,
              }}
            >
              <Button
                onClick={handleClickOpen2}
                style={{marginTop: 0}}
                size={'small'}
                color="secondary"
                variant="outlined"
              >
                Excluir item
              </Button>
            </div>
          )}
          {isVerySmall ? (
            <Button size={'small'} onClick={() => close()}>
              Cancelar
            </Button>
          ) : (
            <Button onClick={() => close()}>Cancelar</Button>
          )}
          {isSmall ? (
            value === 3 ? (
              <Button size={'small'} onClick={() => onSave()} color="primary">
                Adicionar
              </Button>
            ) : (
              <Button
                size={'small'}
                onClick={() => setValue(value + 1)}
                color="primary"
              >
                Próximo
              </Button>
            )
          ) : value === 3 ? (
            <Button onClick={() => onSave()} color="primary">
              Adicionar
            </Button>
          ) : (
            <Button onClick={() => setValue(value + 1)} color="primary">
              Próximo
            </Button>
          )}
        </DialogActions>
      </Dialog>

      <Dialog
        open={open}
        onClose={() => close()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Excluir item?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Deseja deletar o item?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose2} color="primary">
            Não
          </Button>
          <Button onClick={() => closewithDialog()} color="primary">
            Sim
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={toggleBackdrop} onClick={() => setToggleBackdrop(false)}>
        <DialogTitle id="alert-dialog-title">
          Erro: {codeBackdrop} - {titleBackdrop}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {descriptionBackdrop}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose2} color="primary">
            Entendi
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={isSending} onClick={() => setIsSending(false)}>
        <DialogContent>
          <div
            style={{
              display: 'flex',
              flex: 1,
              alignItems: 'center',
              justifyContent: 'center',
              height: 100,
              marginLeft: 20,
              marginRight: 20,
            }}
          >
            <CircularProgress />
            <p1 style={{marginLeft: 20}}>Salvando...</p1>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
