/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Detalhes from './detalhes';
import {useMediaQuery} from 'react-responsive';
import LoyaltyIcon from '@material-ui/icons/Loyalty';
import InfoIcon from '@material-ui/icons/Info';
import AddIcon from '@material-ui/icons/Add';
import ScheduleIcon from '@material-ui/icons/Schedule';
import Promocao from './promocao';
import Disponibilidade from './disponibilidade';
import Complementos from './complementos';

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 500,
  },
  tabs: {
    fontSize: 14,
  },
  labelContainer: {
    padding: 0,
  },
  tab: {
    fontSize: 7,
    minWidth: '25%',
    width: '25%',
    minHeight: 0,
    height: 50,
    fontWeight: 'bold',
  },
}));

export default function FullWidthTabs({
  value,
  handleChange,
  handleChangeIndex,
  cNome,
  setCnome,
  cDesc,
  setcDesc,
  cPdv,
  setcPdv,
  cat,
  setCat,
  cvlr,
  setCvlr,
  ccpt,
  setCcpt,
  isAvaliable,
  setAvailable,
  files,
  setFiles,
  bdom,
  setbdom,
  bseg,
  setbseg,
  bter,
  setbter,
  bqua,
  setbqua,
  bqui,
  setbqui,
  bsex,
  setbsex,
  bsab,
  setbsab,
  bpromo,
  setbpromo,
  cvlrpromo,
  setCvlrpromo,
  ITENS,
  setITENS,
  cbpeso,
  setCbpeso,
  cIntPeso,
  setCintPeso,
  fDetails,
  setFdetails,
  fComp,
  setFComp,
  fHorario,
  setFHorario,
  fPromo,
  setFPromo,
  bimgReal,
  setbimgReal,
}) {
  const classes = useStyles();
  const theme = useTheme();

  const isSmall = useMediaQuery({query: '(max-width: 767.98px)'});
  const isVerySmall = useMediaQuery({query: '(max-width:450.00px)'});

  return (
    <div style={{margintop: 0, paddingTop: 0}} className={classes.root}>
      <AppBar
        position={isVerySmall ? 'fixed' : isSmall ? 'fixed' : 'static'}
        color="default"
      >
        <Tabs
          className={classes.tabs}
          labelContainer={classes.labelContainer}
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="full width tabs example"
        >
          <Tab
            labelContainer={classes.labelContainer}
            className={classes.tab}
            icon={<InfoIcon />}
            label="Detalhes"
            {...a11yProps(0)}
          />
          <Tab
            className={classes.tab}
            icon={<AddIcon />}
            label="Complementos"
            {...a11yProps(1)}
          />
          <Tab
            className={classes.tab}
            icon={<ScheduleIcon />}
            label="Disponibilidade"
            {...a11yProps(2)}
          />
          <Tab
            className={classes.tab}
            icon={<LoyaltyIcon />}
            label="Promoção"
            {...a11yProps(3)}
          />
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel
          style={{overflowX: 'hidden'}}
          value={value}
          index={0}
          dir={theme.direction}
        >
          <Detalhes
            cNome={cNome}
            setCnome={setCnome}
            cDesc={cDesc}
            setcDesc={setcDesc}
            cPdv={cPdv}
            setcPdv={setcPdv}
            cat={cat}
            setCat={setCat}
            cvlr={cvlr}
            setCvlr={setCvlr}
            ccpt={ccpt}
            setCcpt={setCcpt}
            isAvaliable={isAvaliable}
            setAvailable={setAvailable}
            files={files}
            setFiles={setFiles}
            cbpeso={cbpeso}
            setCbpeso={setCbpeso}
            cIntPeso={cIntPeso}
            setCintPeso={setCintPeso}
            fDetails={fDetails}
            setFdetails={setFdetails}
            bimgReal={bimgReal}
            setbimgReal={setbimgReal}
          />
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <Complementos
            ITENS={ITENS}
            setITENS={setITENS}
            fComp={fComp}
            setFComp={setFComp}
          />
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          <Disponibilidade
            bdom={bdom}
            setbdom={setbdom}
            bseg={bseg}
            setbseg={setbseg}
            bter={bter}
            setbter={setbter}
            bqua={bqua}
            setbqua={setbqua}
            bqui={bqui}
            setbqui={setbqui}
            bsex={bsex}
            setbsex={setbsex}
            bsab={bsab}
            setbsab={setbsab}
            fHorario={fHorario}
            setFHorario={setFHorario}
          />
        </TabPanel>
        <TabPanel value={value} index={3} dir={theme.direction}>
          <Promocao
            bpromo={bpromo}
            setbpromo={setbpromo}
            cvlr={cvlr}
            setCvlr={setCvlr}
            cvlrpromo={cvlrpromo}
            setCvlrpromo={setCvlrpromo}
            files={files}
            fPromo={fPromo}
            setFPromo={setFPromo}
          />
        </TabPanel>
      </SwipeableViews>
    </div>
  );
}
