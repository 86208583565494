/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, {useLayoutEffect, useState, useEffect} from 'react';
import {useMediaQuery} from 'react-responsive';
import {makeStyles} from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import {FaBars} from 'react-icons/fa';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import AlertSending from '../../components/AlertSeding';
import {useSelector} from 'react-redux';
import {useDispatch} from 'react-redux';
import * as ActionStore from '../../store/actions/setRestaurante';
import {getAuth} from 'firebase/auth';

const useStyles = makeStyles(theme => ({
  formControl: {
    marginLeft: 10,
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const DadosBancario = ({
  handleToggleSidebar,
  handleNotiStack,
  handleBackDrop,
}) => {
  const auth = getAuth();
  const isSmall = useMediaQuery({query: '(max-width: 750.98px)'});
  const isVerySmall = useMediaQuery({query: '(max-width:450.00px)'});
  const [isLoading, setLoading] = useState(true);
  const classes = useStyles();
  const [requestbopen, setRequestOpen] = useState(false);
  const dispatch = useDispatch();
  const bbank = useSelector(state => state.setrestaurante.bbank);
  const btcnta = useSelector(state => state.setrestaurante.btcnta);
  const bagcia = useSelector(state => state.setrestaurante.bagcia);
  const bcnta = useSelector(state => state.setrestaurante.bcnta);
  const bdig = useSelector(state => state.setrestaurante.bdig);
  const cpix = useSelector(state => state.setrestaurante.cpix);
  const npix = useSelector(state => state.setrestaurante.npix);
  const docid = useSelector(state => state.setrestaurante.id);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  const [agencia, setAgencia] = useState('');
  const [conta, setConta] = useState('');
  const [digito, setDigito] = useState('');
  const [banco, setBanco] = useState('');
  const [bancos, setBancos] = useState([
    {
      title: 'Banco do Brasil',
    },
    {
      title: 'Banco do nordeste',
    },
    {
      title: 'Banco Bradesco',
    },
    {
      title: 'Banco Itaú',
    },
    {
      title: 'Caixa',
    },
  ]);

  const [tipoConta, setTipoConta] = useState('');
  const [tipos, setTipos] = useState([
    {
      title: 'Conta corrente',
    },
    {
      title: 'Conta poupança',
    },
  ]);

  const handleChange = event => {
    setBanco(event.target.value);
  };

  const handleChange2 = event => {
    setTipoConta(event.target.value);
  };

  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    // Button is displayed after scrolling for 500 pixels
    const toggleVisibility = () => {
      if (window.pageYOffset > 50) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', toggleVisibility);
    return () => window.removeEventListener('scroll', toggleVisibility);
  }, []);

  useEffect(() => {
    setAgencia(bagcia);
    setConta(bcnta);
    setDigito(bdig);
    setTipoConta(btcnta);
    setBanco(bbank);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  }
  const [width, height] = useWindowSize();

  const [isSending, setIsSending] = useState(false);
  function onSave() {
    setIsSending(true);
    if (banco === '') {
      setTimeout(() => {
        setIsSending(false);
        handleNotiStack('Selecione o banco', 'error');
      }, 2000);
    } else if (agencia === 0 || agencia === '') {
      setTimeout(() => {
        setIsSending(false);
        handleNotiStack('Preencha a agência', 'error');
      }, 2000);
    } else if (conta === 0 || conta === '') {
      setTimeout(() => {
        setIsSending(false);
        handleNotiStack('Preencha a conta', 'error');
      }, 2000);
    } else if (digito === '') {
      setTimeout(() => {
        setIsSending(false);
        handleNotiStack('Preencha o digito da conta', 'error');
      }, 2000);
    } else if (tipoConta === '') {
      setTimeout(() => {
        setIsSending(false);
        handleNotiStack('Tipo de conta incorreto', 'error');
      }, 2000);
    } else {
      setTimeout(() => {
        auth.currentUser.getIdToken().then(token => {
          const request = new Request(
            'https://kops.apiireit.com/gateway/store/update/bankinfo/' +
              docid +
              '?banco=' +
              banco +
              '&tconta=' +
              tipoConta +
              '&agencia=' +
              agencia +
              '&nconta=' +
              conta +
              '&dgt=' +
              digito +
              '&cpix=' +
              '' +
              '&npix=' +
              '',
            {
              method: 'PUT',
              mode: 'cors',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
              },
              body: JSON.stringify({}),
            },
          );

          fetch(request)
            .then(response => {
              if (response.status === 200) {
                handleBackDrop('', '', 200, false);
                response
                  .json()
                  .then(data => {
                    setIsSending(false);
                    setTimeout(() => {
                      handleNotiStack(
                        'Informações atualizadas com sucesso',
                        'success',
                      );
                    }, 1000);
                    dispatch(
                      ActionStore.setrestaurante(
                        data._id,
                        data.nome,
                        data.dsc,
                        data.nota,
                        data.nNotas,
                        data.cat,
                        data.entrg,
                        data.rtrda,
                        data.agdnt,
                        data.espra,
                        data.dsct,
                        data.frete,
                        data.nAv,
                        data.pdmin,
                        data.entgrts,
                        data.location,
                        data.img,
                        data.stt,
                        data.catprice,
                        data.iient,
                        data.chck,
                        data.merc,
                        data.cpm,
                        data.promoent,
                        data.bapp,
                        data.pix,
                        data.bame,
                        data.picpay,
                        data.bmp,
                        data.bpaypal,
                        data.bpre,
                        data.bvir,
                        data.bvref,
                        data.bd,
                        data.bvd,
                        data.bmcd,
                        data.belod,
                        data.bhipd,
                        data.bdind,
                        data.baled,
                        data.bsood,
                        data.bvrd,
                        data.bbend,
                        data.bdisd,
                        data.btrid,
                        data.bbnsd,
                        data.bbrsd,
                        data.bbrd,
                        data.bvc,
                        data.bmcc,
                        data.beloc,
                        data.bhipc,
                        data.bdinc,
                        data.balec,
                        data.bsodc,
                        data.bvrc,
                        data.bbenc,
                        data.bdisc,
                        data.btric,
                        data.bbnsc,
                        data.bbrsc,
                        data.bbrc,
                        data.end,
                        data.num,
                        data.bairro,
                        data.cid,
                        data.est,
                        data.cnpj,
                        data.bodom,
                        data.hdom,
                        data.boseg,
                        data.hseg,
                        data.boter,
                        data.hter,
                        data.boqua,
                        data.hqua,
                        data.boqui,
                        data.hqui,
                        data.bosex,
                        data.hsex,
                        data.bosab,
                        data.hsab,
                        data.aratdm,
                        data.raio,
                        data.dstc,
                        data.essm,
                        data.esfds,
                        data.inalta,
                        data.npd,
                        data.znid,
                        data.zntx,
                        data.iitx,
                        data.iietx,
                        data.freetx,
                        data.freetill,
                        data.lastSeenAt,
                        data.presence,
                        data.openchat,
                        data.agd,
                        data.fagdays,
                        data.dtsm,
                        data.dtfds,
                        data.ctto,
                        data.aratdmii,
                        data.mxprmetrg,
                        data.bbank,
                        data.btcnta,
                        data.bagcia,
                        data.bcnta,
                        data.bdig,
                        data.cpix,
                        data.npix,
                        data.nimg,
                      ),
                    );
                  })
                  .catch(error => {
                    console.log(error);
                  });
              } else if (response.status === 503) {
                setIsSending(false);
                setTimeout(() => {
                  handleNotiStack(
                    'Não foi possível salvar as informações',
                    'error',
                  );
                }, 1000);
                handleBackDrop(
                  'Serviço indisponível no momento',
                  'O serviços para as lojas Irê it não estão disponíveis no momento. Por favor, tente novamente mais tarde',
                  503,
                  true,
                );
                throw new Error('Something went wrong on API server!');
              } else if (response.status === 404) {
                setIsSending(false);
                setTimeout(() => {
                  handleNotiStack(
                    'Não foi possível salvar as informações',
                    'error',
                  );
                }, 1000);
                handleBackDrop(
                  'Serviço não encontrado.',
                  'Por favor, entre em contato com o nosso suporte e relate o problema.',
                  404,
                  true,
                );
              } else if (response.status === 401) {
                setIsSending(false);
                setTimeout(() => {
                  handleNotiStack(
                    'Não foi possível salvar as informações',
                    'error',
                  );
                }, 1000);
                handleBackDrop(
                  'Você não possui autorização ou suas credenciais expirarão.',
                  'Por favor, atualize a baixa ou refaça o processo dee login.',
                  401,
                  true,
                );
              } else if (response.status === 400) {
                setIsSending(false);
                setTimeout(() => {
                  handleNotiStack(
                    'Não foi possível salvar as informações',
                    'error',
                  );
                }, 1000);
                handleBackDrop(
                  'Requisição inválida.',
                  'Confira se todos os campos estão preenchidos corretamente.',
                  400,
                  true,
                );
              } else if (response.status === 500) {
                setIsSending(false);
                setTimeout(() => {
                  handleNotiStack(
                    'Não foi possível salvar as informações',
                    'error',
                  );
                }, 1000);
                handleBackDrop(
                  'Nosso servidor está passando por irregularidades.',
                  'Estamos trabalhado para resolver o mais rápido possível.',
                  500,
                  true,
                );
              }
            })
            .catch(error => {
              setIsSending(false);
              setTimeout(() => {
                handleNotiStack(
                  'Não foi possível salvar as informações',
                  'error',
                );
              }, 1000);
              handleBackDrop(
                'Nosso servidor está passando por irregularidades.',
                'Estamos trabalhado para resolver o mais rápido possível.',
                500,
                true,
              );
              console.error(error);
            });
        });
      }, 2000);
    }
  }

  return (
    <div>
      {isLoading ? (
        <div
          style={{
            display: 'flex',
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            height: height,
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <div>
          {isVisible ? (
            <Fab
              onClick={() => scrollToTop()}
              style={{position: 'fixed', bottom: 20, right: 20, zIndex: 2}}
              size={'small'}
              color="primary"
              aria-label="add"
            >
              <ArrowUpwardIcon />
            </Fab>
          ) : null}
          <div
            style={{marginLeft: 10, marginTop: 10}}
            className="btn-toggle"
            onClick={() => handleToggleSidebar(true)}
          >
            <FaBars />
          </div>
          <h1
            style={{
              fontSize: isVerySmall ? 20 : 30,
              marginLeft: 10,
              marginBottom: 5,
              color: '#393939',
            }}
          >
            Dados bancários
          </h1>
          <div
            style={{
              marginLeft: 10,
              marginRight: 10,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <FormControl
              style={{
                marginTop: 10,
                marginLeft: 0,
                width: isVerySmall ? '100%' : isSmall ? '100%' : 200,
              }}
              className={classes.formControl}
            >
              <InputLabel style={{fontSize: 14}} id="demo-simple-select-label">
                Banco
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={banco}
                onChange={handleChange}
                style={{marginTop: 14}}
              >
                {(Array.isArray(bancos)
                  ? bancos.map(({title}) => [title])
                  : Object.entries(bancos)
                ).map(([title]) => {
                  return (
                    <MenuItem style={{fontSize: 14}} key={title} value={title}>
                      {title}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl
              style={{
                marginTop: 10,
                marginLeft: 0,
                width: isVerySmall ? '100%' : isSmall ? '100%' : 200,
              }}
              className={classes.formControl}
            >
              <InputLabel style={{fontSize: 14}} id="demo-simple-select-label">
                Tipo de conta
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={tipoConta}
                onChange={handleChange2}
                style={{marginTop: 14}}
              >
                {(Array.isArray(tipos)
                  ? tipos.map(({title}) => [title])
                  : Object.entries(tipos)
                ).map(([title]) => {
                  return (
                    <MenuItem style={{fontSize: 14}} key={title} value={title}>
                      {title}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <TextField
              style={{
                width: isVerySmall ? '100%' : isSmall ? '100%' : 200,
                marginTop: 10,
              }}
              inputProps={{
                style: {fontSize: 14},
                maxLength: 150,
              }}
              InputLabelProps={{style: {fontSize: 14}}}
              id="agencia"
              label="Agência"
              defaultValue={agencia}
              value={agencia}
              onChange={event => {
                setAgencia(event.target.value);
              }}
            />
            <div>
              <TextField
                style={{
                  width: isVerySmall ? '60%' : isSmall ? '60%' : 110,
                  marginTop: 10,
                }}
                inputProps={{
                  style: {fontSize: 14},
                  maxLength: 150,
                }}
                InputLabelProps={{style: {fontSize: 14}}}
                id="conta"
                label="Conta"
                defaultValue={conta}
                value={conta}
                onChange={event => {
                  setConta(event.target.value);
                }}
              />
              <TextField
                style={{
                  width: isVerySmall ? '35%' : isSmall ? '35%' : 80,
                  marginTop: 10,
                  marginLeft: 10,
                }}
                inputProps={{
                  style: {fontSize: 14},
                  maxLength: 150,
                }}
                InputLabelProps={{style: {fontSize: 14}}}
                id="digito"
                label="Dígito"
                defaultValue={digito}
                value={digito}
                onChange={event => {
                  setDigito(event.target.value);
                }}
              />
            </div>
          </div>
          {requestbopen ? (
            <div>
              <p style={{marginLeft: 10, color: '#393939'}}>
                • Você possui uma solicitação em aberta. Em breve a Irê it irá
                entrar em contato para confirmar algumas informações
              </p>
            </div>
          ) : (
            <Button
              onClick={() => onSave()}
              style={{marginLeft: 10, marginTop: 40}}
              variant="contained"
              size={'medium'}
              color="primary"
            >
              Salvar
            </Button>
          )}
        </div>
      )}
      <AlertSending
        bopen={isSending}
        handleClose={() => setIsSending(false)}
        handleNotiStack
        style={{
          top: 0,
          position: 'absolute',
          zIndex: 3,
        }}
      />
    </div>
  );
};

export default DadosBancario;
