export function setcats(cats) {
  return {
    type: 'SET_CATEGORIAS',
    cats,
  };
}

export function setcurrentcat(id, cat, itens) {
  return {
    type: 'SET_CAT',
    id,
    cat,
    itens,
  };
}
