/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, {useState, useLayoutEffect, useEffect} from 'react';
import {useMediaQuery} from 'react-responsive';
import {withStyles} from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import TextField from '@material-ui/core/TextField';
import {format} from '../../../configuration/format';
import Checkbox from '@material-ui/core/Checkbox';
import {useDispatch, useSelector} from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as ActionItem from '../../../store/actions/setItem';

const PurpleSwitch = withStyles({
  switchBase: {
    color: '#d4d4d4',
    '&$checked': {
      color: '#52d869',
    },
    '&$checked + $track': {
      backgroundColor: '#52d869',
    },
  },
  checked: {},
  track: {},
})(Switch);

const GreenCheckbox = withStyles({
  root: {
    color: '#C4C4C4',
    '&$checked': {
      color: '#52d869',
    },
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />);

// eslint-disable-next-line react/prop-types
const Promocao = ({
  bpromo,
  setbpromo,
  cvlr,
  setCvlr,
  cvlrpromo,
  setCvlrpromo,
  files,
}) => {
  const isSmall = useMediaQuery({query: '(max-width: 750.98px)'});
  const isVerySmall = useMediaQuery({query: '(max-width:450.00px)'});
  const [width, height] = useWindowSize();
  const [isLoading, setLoading] = useState(true);
  const dispatch = useDispatch();
  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  }

  function handlyvalue(value) {
    setCvlrpromo(value);
  }

  const [isMinPromo, setMinPromo] = React.useState(false);
  const [bimg, setbimg] = React.useState(true);

  const handleChange = event => {
    setbpromo(event.target.checked);
  };

  const isCPromo = useSelector(state => state.setitem.bpromo);
  const vlr = useSelector(state => state.setitem.vlr);
  const vlrpromo = useSelector(state => state.setitem.vlrpromo);
  const item = useSelector(state => state.setitem);
  useEffect(() => {
    dispatch(ActionItem.setFPromo(true));
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (item.fPromo) {
        dispatch(ActionItem.setFPromo(false));
        setbpromo(isCPromo);
        if (isCPromo) {
          setCvlr(vlr);
          setCvlrpromo(vlrpromo);
        } else {
          setCvlrpromo(vlr);
        }
      }
    }, 1000);
  }, []);

  useEffect(() => {
    if (1 - cvlrpromo / cvlr <= 0.1 || cvlr < 2) {
      setMinPromo(false);
    } else {
      setMinPromo(true);
    }
  }, [cvlr, cvlrpromo]);

  useEffect(() => {
    if (files === []) {
      setbimg(false);
    } else {
      setbimg(true);
    }
  }, [cvlr, cvlrpromo]);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  return (
    <div>
      {isLoading ? (
        <div
          style={{
            display: 'flex',
            width: isVerySmall ? '45%' : '100%',
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <div>
          {isVerySmall ? (
            <div style={{}}>
              <div>
                <p
                  style={{
                    fontSize: 16,
                    width: width * 0.73,
                    color: '#393939',
                    marginBottom: 0,
                  }}
                >
                  Ativar promoçâo:
                </p>
              </div>
              <div style={{marginTop: 0}}>
                <FormControlLabel
                  style={{}}
                  control={
                    <PurpleSwitch
                      checked={bpromo}
                      onChange={handleChange}
                      name="checked"
                    />
                  }
                />
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    style={{marginTop: 20, width: 100, marginBottom: 10}}
                    inputProps={{
                      style: {fontSize: 14, padding: 5, color: '#797979'},
                      maxLength: 20,
                    }}
                    InputLabelProps={{style: {fontSize: 14, color: '#797979'}}}
                    id="vlr"
                    variant="outlined"
                    label="Valor original"
                    defaultValue={'R$ ' + format(cvlr)}
                  />
                  <CurrencyTextField
                    style={{marginRight: 10, width: 140, marginTop: 10}}
                    inputProps={{style: {fontSize: 14, padding: 10}}}
                    InputLabelProps={{style: {fontSize: 14}}}
                    label="Valor promocional"
                    variant="outlined"
                    value={cvlrpromo}
                    currencySymbol="R$"
                    minimumValue="0"
                    outputFormat="string"
                    decimalCharacter=","
                    digitGroupSeparator="."
                    onChange={(event, value) => handlyvalue(value)}
                  />
                  <div style={{marginTop: 10, width: width * 0.65}}>
                    <FormControlLabel
                      disabled
                      control={
                        <GreenCheckbox checked={isMinPromo} name="minPromo" />
                      }
                      style={{color: '#C4C4C4'}}
                      label={
                        <p
                          style={{
                            fontSize: 12,
                            color: '#c4c4c4',
                          }}
                        >
                          Oferta mínima de 10% e maior que R$ 2,00
                        </p>
                      }
                    />
                    <FormControlLabel
                      disabled
                      control={<GreenCheckbox checked={bimg} name="bimg" />}
                      style={{color: '#C4C4C4'}}
                      label={
                        <p
                          style={{
                            fontSize: 12,
                            color: '#c4c4c4',
                            width: width * 0.65,
                          }}
                        >
                          Produto com foto
                        </p>
                      }
                    />
                  </div>
                  <p
                    style={{
                      fontSize: 12,
                      color: '#999999',
                      width: width * 0.65,
                    }}
                  >
                    Se os itens acima estiverem marcados. Significa que a Irê it
                    irá mostrar na pagina inicial do delivery sua(s) oferta(s)
                    para os possíveis clientes em nossa plataforma.
                  </p>
                </div>
              </div>
            </div>
          ) : isSmall ? (
            <div style={{width: width * 0.675}}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  backgroundColor: '#F4F4F4',
                  paddingBottom: 10,
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  paddingRight: 20,
                  paddingLeft: 20,
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <div>
                    <div>
                      <p
                        style={{
                          fontSize: 14,
                          width: width * 0.25,
                          color: '#393939',
                          marginBottom: 0,
                        }}
                      >
                        Ativar promoçâo:
                      </p>
                    </div>
                    <FormControlLabel
                      control={
                        <PurpleSwitch
                          checked={bpromo}
                          onChange={handleChange}
                          name="checked"
                        />
                      }
                    />
                  </div>
                </div>

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    style={{marginTop: 10, width: 100, marginBottom: 10}}
                    inputProps={{
                      style: {fontSize: 14, padding: 5, color: '#797979'},
                      maxLength: 20,
                    }}
                    InputLabelProps={{style: {fontSize: 14, color: '#797979'}}}
                    id="vlr"
                    variant="outlined"
                    label="Valor original"
                    defaultValue={'R$ ' + format(cvlr)}
                  />
                  <CurrencyTextField
                    style={{marginRight: 10, width: 140}}
                    inputProps={{style: {fontSize: 14, padding: 10}}}
                    InputLabelProps={{style: {fontSize: 14}}}
                    label="Valor promocional"
                    variant="outlined"
                    value={cvlrpromo}
                    currencySymbol="R$"
                    minimumValue="0"
                    outputFormat="string"
                    decimalCharacter=","
                    digitGroupSeparator="."
                    onChange={(event, value) => handlyvalue(value)}
                  />
                </div>
              </div>

              <div style={{marginTop: 10}}>
                <FormControlLabel
                  disabled
                  control={
                    <GreenCheckbox checked={isMinPromo} name="minPromo" />
                  }
                  style={{color: '#C4C4C4'}}
                  label={
                    <p style={{fontSize: 12, color: '#c4c4c4'}}>
                      Oferta mínima de 10% e maior que R$ 2,00
                    </p>
                  }
                />
                <FormControlLabel
                  disabled
                  control={<GreenCheckbox checked={bimg} name="bimg" />}
                  style={{color: '#C4C4C4'}}
                  label={
                    <p style={{fontSize: 12, color: '#c4c4c4'}}>
                      Produto com foto
                    </p>
                  }
                />
              </div>
              <p style={{fontSize: 12, color: '#999999', width: width * 0.65}}>
                Se os itens acima estiverem marcados. Significa que a Irê it irá
                mostrar na pagina inicial do delivery sua(s) oferta(s) para os
                possíveis clientes em nossa plataforma.
              </p>
            </div>
          ) : (
            <div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  backgroundColor: '#F4F4F4',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  paddingRight: 20,
                  paddingLeft: 20,
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <div>
                    <FormControlLabel
                      control={
                        <PurpleSwitch
                          checked={bpromo}
                          onChange={handleChange}
                          name="checked"
                        />
                      }
                    />
                  </div>
                  <div>
                    <p style={{fontSize: 12, width: 100, color: '#999999'}}>
                      Clique no botão ao lado para ativar e desativar a promoçâo
                    </p>
                  </div>
                </div>

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    style={{marginTop: 10, width: 100, marginBottom: 10}}
                    inputProps={{
                      style: {fontSize: 14, padding: 5, color: '#797979'},
                      maxLength: 20,
                    }}
                    InputLabelProps={{style: {fontSize: 14, color: '#797979'}}}
                    id="vlr"
                    variant="outlined"
                    label="Valor original"
                    defaultValue={'R$ ' + format(cvlr)}
                  />
                  <CurrencyTextField
                    style={{marginRight: 10, width: 140}}
                    inputProps={{style: {fontSize: 14, padding: 10}}}
                    InputLabelProps={{style: {fontSize: 14}}}
                    label="Valor promocional"
                    variant="outlined"
                    value={cvlrpromo}
                    currencySymbol="R$"
                    minimumValue="0"
                    outputFormat="string"
                    decimalCharacter=","
                    digitGroupSeparator="."
                    onChange={(event, value) => handlyvalue(value)}
                  />
                </div>
              </div>

              <div style={{marginTop: 10}}>
                <FormControlLabel
                  disabled
                  control={
                    <GreenCheckbox checked={isMinPromo} name="minPromo" />
                  }
                  style={{color: '#C4C4C4'}}
                  label="Oferta mínima de 10% e maior que R$ 2,00"
                />
                <FormControlLabel
                  disabled
                  control={<GreenCheckbox checked={bimg} name="bimg" />}
                  style={{color: '#C4C4C4'}}
                  label="Produto com foto"
                />
              </div>
              <p style={{fontSize: 12, color: '#999999'}}>
                Se os itens acima estiverem marcados. Significa que a Irê it irá
                mostrar na pagina inicial do delivery sua(s) oferta(s) para os
                possíveis clientes em nossa plataforma.
              </p>
            </div>
          )}
          <div></div>
        </div>
      )}
    </div>
  );
};

export default Promocao;
