const INITIAL_STATE = {
  cats: [],
  id: '',
  cat: '',
  itens: [],
};

export default function setcats(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'SET_CATEGORIAS':
      return {
        ...state,
        cats: action.cats,
      };
    case 'SET_CAT':
      return {
        ...state,
        id: action.id,
        cat: action.cat,
        itens: action.itens,
      };
    default:
      return state;
  }
}
