/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, {useState, useEffect} from 'react';
import useGeolocation from './useLocation';
import {Marker} from '@react-google-maps/api';
import {GoogleMap, useJsApiLoader} from '@react-google-maps/api';
import {useSelector} from 'react-redux';

const containerStyle = {
  width: '100%',
  height: '500px',
};

const ContentMap = ({width, isVerySmall, isSmall, marker, setMarker}) => {
  const {isLoaded} = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyADIHr5UwS75lsAsHpdTHG2u5i9y2nBpO4',
  });

  const [zoom, setZoom] = useState(17.35);
  const [center, setCenter] = useState({lat: 0, lng: 0});

  const clat = useSelector(
    state => state.setrestaurante.location.coordinates[1],
  );
  const clng = useSelector(
    state => state.setrestaurante.location.coordinates[0],
  );

  const estado = useSelector(state => state.setrestaurante);

  const location = useGeolocation();

  useEffect(() => {
    setCenter({lat: location.latitude, lng: location.longitude});
  }, []);

  useEffect(() => {
    if (clat !== 0 && clng !== 0) {
      setMarker({lat: clat, lng: clng});
      setCenter({lat: clat, lng: clng});
    }
  }, [estado]);

  const [map, setMap] = React.useState(null);

  const onLoad = React.useCallback(function callback(map) {
    //const bounds = new window.google.maps.LatLngBounds();
    //map.fitBounds(bounds);
    setMap(map);
    setZoom(17.35);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  function handleClick(e) {
    setMarker({lat: e.latLng.lat(), lng: e.latLng.lng()});
    setCenter({lat: e.latLng.lat(), lng: e.latLng.lng()});
  }

  return isLoaded ? (
    <div
      style={{
        display: 'flex',
        width: 'auto',
        marginLeft: 10,
        marginRight: 10,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={{lat: center.lat, lng: center.lng}}
        zoom={zoom}
        onLoad={onLoad}
        onUnmount={onUnmount}
        onClick={e => handleClick(e)}
      >
        <div
          style={{
            position: 'absolute',
            display: 'flex',
            top: isVerySmall || isSmall ? 50 : 50,
            left: 0,
            zIndex: 2,
            marginLeft: isVerySmall ? 0 : 10,
            marginRight: isVerySmall ? 0 : '0%',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        ></div>

        <Marker
          icon={{
            // eslint-disable-next-line no-undef
            path: google.maps.SymbolPath.CIRCLE,
            scale: 8,
          }}
          position={{lat: marker.lat, lng: marker.lng}}
        />

        <></>
      </GoogleMap>
    </div>
  ) : (
    <div style={{width: '100%', height: '500px'}}></div>
  );
};

export default ContentMap;
