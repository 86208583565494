const INITIAL_STATE = {
  estado: 'Inicio',
};

export default function setstatescreen(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'SET_CHANGE_SCREEN':
      return {
        ...state,
        estado: action.estado,
      };
    default:
      return state;
  }
}
