const INITIAL_STATE = {
  id: '',
  idstore: '',
  nome: '',
  descricao: '',
  cat: '',
  idcat: '',
  pdv: '',
  bqtd: false,
  qtd: 0,
  cqtd: 0,
  vlr: 0,
  bimg: false,
  img: '',
  vlrpromo: 0,
  bpromo: false,
  bmonted: false,
  bpeso: false,
  intpeso: 0,
  bavailable: false,
  itens: [],
  cpt: 0,
  obs: '',
  bodom: true,
  boseg: true,
  boter: true,
  boqua: true,
  boqui: true,
  bosex: false,
  bosab: true,
  index: 0,
  fDetails: true,
  fComp: true,
  fHorario: true,
  fPromo: true,
};

export default function setitem(state = INITIAL_STATE, action) {
  if (action.type === 'SET_ITEM') {
    return {
      ...state,
      id: action.id,
      idstore: action.idstore,
      nome: action.nome,
      descricao: action.descricao,
      cat: action.cat,
      idcat: action.idcat,
      pdv: action.pdv,
      bqtd: action.bqtd,
      qtd: action.qtd,
      cqtd: action.cqtd,
      vlr: action.vlr,
      bimg: action.bimg,
      img: action.img,
      vlrpromo: action.vlrpromo,
      bpromo: action.bpromo,
      bmonted: action.bmonted,
      bpeso: action.bpeso,
      intpeso: action.intpeso,
      bavailable: action.bavailable,
      itens: action.itens,
      cpt: action.cpt,
      obs: action.obs,
      bodom: action.bodom,
      boseg: action.boseg,
      boter: action.boter,
      boqua: action.boqua,
      boqui: action.boqui,
      bosex: action.bosex,
      bosab: action.bosab,
      index: action.index,
    };
  } else if (action.type === 'SET_CQTD') {
    return {
      ...state,
      cqtd: action.cqtd,
    };
  } else if (action.type === 'SET_INDEX') {
    return {
      ...state,
      index: action.index,
    };
  } else if (action.type === 'SET_ITENS') {
    return {
      ...state,
      itens: action.itens,
    };
  } else if (action.type === 'SET_FDETAILS') {
    return {
      ...state,
      fDetails: action.fDetails,
    };
  } else if (action.type === 'SET_FCOMP') {
    return {
      ...state,
      fComp: action.fComp,
    };
  } else if (action.type === 'SET_FHORARIO') {
    return {
      ...state,
      fHorario: action.fHorario,
    };
  } else if (action.type === 'SET_FPROMO') {
    return {
      ...state,
      fPromo: action.fPromo,
    };
  } else if (action.type === 'SET_CLEAR_F') {
    return {
      ...state,
      fDetails: action.fDetails,
      fComp: action.fComp,
      fHorario: action.fHorario,
      fPromo: action.fPromo,
    };
  }
  return state;
}
