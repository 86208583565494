import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Divider from '@material-ui/core/Divider';
import {useMediaQuery} from 'react-responsive';

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

// eslint-disable-next-line react/prop-types
const AlertNotSuported = ({bopen, handleClose}) => {
  const isSmall = useMediaQuery({query: '(max-width: 750.98px)'});
  const isVerySmall = useMediaQuery({query: '(max-width:450.00px)'});
  function close() {
    handleClose();
  }

  function onSave() {
    handleClose();
  }

  return (
    <div>
      <Dialog
        onClose={() => close()}
        style={{
          marginLeft: isVerySmall ? -20 : 0,
          marginRight: isVerySmall ? -20 : 0,
          paddingLeft: 0,
        }}
        aria-labelledby="customized-dialog-title"
        open={bopen}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            paddingLeft: 10,
            paddingRight: 10,
            maxWidth: 500,
          }}
        >
          <p style={{fontWeight: 'bold', color: '#393939'}}>
            O seu navegador não é compátivel com o sistema de notificações da
            Irê it
          </p>
          <p style={{color: '#393939'}}>
            O sistema de notificações não funcionará no seu navegador atual.
            Isso significa que alertas como o recebimento de pedidos podem não
            aparecer.
          </p>
          <p style={{color: '#393939'}}>
            Este erro é esperado em navegadores abertos em sistemas IOS (iPhone,
            iPad) Tanto chrome quanto o próprio safari (navegador padrão). E no
            macbook apenas no navegador safari.
          </p>
          <p style={{color: '#393939', fontWeight: 'bold'}}>
            Algumas sugestões oferecidas pela Irê it inicialmente:
          </p>
          <p style={{color: '#393939'}}>
            Caso você tenha um macbook: Utilize o google chrome como navegador.
          </p>
          <p style={{color: '#393939'}}>
            Caso iPhone: Iremos em breve criar um gestor de pedidos para o IOS.
          </p>
        </div>

        <Divider variant="middle" />

        <DialogActions style={{position: 'relative', bottom: 0}}>
          {isSmall ? (
            <Button size={'small'} onClick={() => onSave()} color="primary">
              Estou ciente
            </Button>
          ) : (
            <Button onClick={() => onSave()} color="primary">
              Estou ciente
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AlertNotSuported;
