/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, {useLayoutEffect, useState, useEffect} from 'react';
import {useMediaQuery} from 'react-responsive';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import TextField from '@material-ui/core/TextField';
import DeleteIcon from '@material-ui/icons/Delete';
import {makeStyles} from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import StopIcon from '@material-ui/icons/Stop';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import {useSelector} from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogReoderComp from './dialogeditcomp';
import DialogReoderComps from './dialogeditcomps';
import DialogAddCatComp from './DialogComp/dialogAddCatComp';
import {produce} from 'immer';

const ColorButton = withStyles(theme => ({
  root: {
    boxShadow: 2,
    textTransform: 'none',
    fontSize: 16,
    padding: '6px 12px',
    border: '1px solid',
    lineHeight: 1.5,
    backgroundColor: '#06448e',
    borderColor: '#06448e',
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      backgroundColor: '#06448e',
      borderColor: '#06448e',
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#06448e',
      borderColor: '#06448e',
    },
  },
}))(Button);

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)(props => {
  const {children, classes, onClose, ...other} = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const WhiteButton = withStyles(theme => ({
  root: {
    boxShadow: 2,
    textTransform: 'none',
    fontSize: 16,
    padding: '6px 12px',
    border: '1px solid',
    lineHeight: 1.5,
    backgroundColor: '#e4e4e4',
    borderColor: '#e4e4e4',
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      backgroundColor: '#D4D4D4',
      borderColor: '#D4D4D4',
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#D4D4D4',
      borderColor: '#D4D4D4',
    },
  },
}))(Button);

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
    marginBottom: 10,
  },
}));

const GreenCheckbox = withStyles({
  root: {
    color: '#C4C4C4',
    '&$checked': {
      color: '#06448E',
    },
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />);

const Listdata = ({
  list,
  ind,
  handlyvalue,
  showDeleteComp,
  stopComp,
  setITENS,
}) => {
  const classes = useStyles();
  let data = list.map((item, index) => {
    return (
      <div key={index}>
        <Box
          boxShadow={2}
          style={{
            backgroundColor: '#FFFFFF',
            marginTop: 10,
            marginBottom: 10,
            padding: 3,
            borderRadius: 5,
            marginLeft: -10,
            marginRight: -10,
          }}
        >
          <TextField
            style={{
              marginTop: 20,
              width: '22%',
              marginBottom: 10,
            }}
            inputProps={{
              style: {fontSize: 14, padding: 6, color: '#797979'},
              maxLength: 100,
            }}
            InputLabelProps={{
              style: {fontSize: 14, marginTop: -8, color: '#797979'},
            }}
            variant="outlined"
            label="Produto"
            defaultValue={item.nome}
            value={item.nome}
            onChange={e => {
              const nome = e.target.value;
              setITENS(currentItem =>
                produce(currentItem, v => {
                  v[ind].data[index].nome = nome;
                }),
              );
            }}
          />
          <TextField
            style={{
              marginTop: 20,
              width: '22%',
              marginBottom: 10,
              marginLeft: 10,
            }}
            inputProps={{
              style: {fontSize: 14, padding: 6, color: '#797979'},
              maxLength: 20,
            }}
            InputLabelProps={{
              style: {fontSize: 14, marginTop: -8, color: '#797979'},
            }}
            variant="outlined"
            label="PDV"
            defaultValue={item.pdv}
            value={item.pdv}
            onChange={e => {
              const pdv = e.target.value;
              setITENS(currentItem =>
                produce(currentItem, v => {
                  v[ind].data[index].pdv = pdv;
                }),
              );
            }}
          />
          <TextField
            style={{
              marginTop: 20,
              width: '22%',
              marginBottom: 10,
              marginLeft: 10,
            }}
            inputProps={{
              style: {fontSize: 14, padding: 6, color: '#797979'},
              maxLength: 200,
            }}
            InputLabelProps={{
              style: {fontSize: 14, marginTop: -8, color: '#797979'},
            }}
            variant="outlined"
            label="Descrição"
            defaultValue={item.descricao}
            value={item.descricao}
            onChange={e => {
              const descricao = e.target.value;
              setITENS(currentItem =>
                produce(currentItem, v => {
                  v[ind].data[index].descricao = descricao;
                }),
              );
            }}
          />
          <CurrencyTextField
            style={{
              marginRight: 10,
              width: '22%',
              marginTop: 20,
              marginLeft: 10,
            }}
            inputProps={{
              style: {
                fontSize: 14,
                padding: 6,
                color: '#797979',
                paddingLeft: 0,
              },
              maxLength: 10,
            }}
            InputLabelProps={{
              style: {fontSize: 14, marginTop: -8, color: '#797979'},
            }}
            label="Valor"
            variant="outlined"
            value={item.vlr}
            currencySymbol="R$"
            minimumValue="0"
            outputFormat="string"
            decimalCharacter=","
            digitGroupSeparator="."
            onChange={(e, v) => {
              const vlr = v;
              setITENS(currentItem =>
                produce(currentItem, v => {
                  v[ind].data[index].vlr = vlr;
                }),
              );
            }}
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginLeft: -5,
              marginRight: 10,
            }}
          >
            <Button
              onClick={() => showDeleteComp(ind, index)}
              style={{
                paddingTop: 0,
                paddingBottom: 0,
                height: 30,
                width: '20%',
              }}
              size={'small'}
              variant="outlined"
              color="secondary"
              className={classes.button}
              startIcon={<DeleteIcon fontSize="small" />}
            >
              Excluir
            </Button>
            {item.bavailable ? (
              <Button
                onClick={() => stopComp(index, ind, false)}
                size={'small'}
                variant="outlined"
                color="primary"
                style={{
                  paddingTop: 0,
                  paddingBottom: 0,
                  height: 30,
                  width: '22%',
                  marginTop: 7.5,
                }}
              >
                <StopIcon fontSize="small" />
                Pausar
              </Button>
            ) : (
              <Button
                onClick={() => stopComp(index, ind, true)}
                size={'small'}
                variant="outlined"
                color="secondary"
                style={{
                  paddingTop: 0,
                  paddingBottom: 0,
                  height: 30,
                  width: '22%',
                  marginTop: 7.5,
                }}
              >
                <PlayArrowIcon fontSize="small" />
                Pausado
              </Button>
            )}
          </div>
        </Box>
      </div>
    );
  });

  return data;
};

const ListdataVerySmall = ({
  list,
  ind,
  handlyvalue,
  showDeleteComp,
  stopComp,
  setITENS,
}) => {
  const classes = useStyles();
  const [width, height] = useWindowSize();
  let data = list.map((item, index) => {
    return (
      <div key={index}>
        <Box
          boxShadow={2}
          style={{marginRight: 10, marginTop: 20, backgroundColor: '#FFFFFF'}}
        >
          <TextField
            style={{
              marginTop: 20,
              width: '90%',
              marginBottom: 10,
              paddingLeft: 10,
              paddingRight: 10,
            }}
            inputProps={{
              style: {fontSize: 14, padding: 6, color: '#797979'},
              maxLength: 100,
            }}
            InputLabelProps={{
              style: {fontSize: 14, marginTop: -8, color: '#797979'},
            }}
            variant="outlined"
            label="Produto"
            defaultValue={item.nome}
            value={item.nome}
            onChange={e => {
              const nome = e.target.value;
              setITENS(currentItem =>
                produce(currentItem, v => {
                  v[ind].data[index].nome = nome;
                }),
              );
            }}
          />
          <TextField
            style={{
              marginTop: 10,
              width: '90%',
              marginBottom: 10,
              marginLeft: 10,
            }}
            inputProps={{
              style: {fontSize: 14, padding: 6, color: '#797979'},
              maxLength: 20,
            }}
            InputLabelProps={{
              style: {fontSize: 14, marginTop: -8, color: '#797979'},
            }}
            variant="outlined"
            label="PDV"
            defaultValue={item.pdv}
            value={item.pdv}
            onChange={e => {
              const pdv = e.target.value;
              setITENS(currentItem =>
                produce(currentItem, v => {
                  v[ind].data[index].pdv = pdv;
                }),
              );
            }}
          />
          <TextField
            style={{
              marginTop: 10,
              width: '90%',
              marginBottom: 10,
              marginLeft: 10,
            }}
            inputProps={{
              style: {fontSize: 14, padding: 6, color: '#797979'},
              maxLength: 200,
            }}
            InputLabelProps={{
              style: {fontSize: 14, marginTop: -8, color: '#797979'},
            }}
            variant="outlined"
            label="Descrição"
            defaultValue={item.descricao}
            value={item.descricao}
            onChange={e => {
              const descricao = e.target.value;
              setITENS(currentItem =>
                produce(currentItem, v => {
                  v[ind].data[index].descricao = descricao;
                }),
              );
            }}
          />
          <CurrencyTextField
            style={{
              marginRight: 10,
              width: '90%',
              marginTop: 10,
              marginLeft: 10,
              marginBottom: 10,
            }}
            inputProps={{
              style: {
                fontSize: 14,
                padding: 6,
                color: '#797979',
                paddingLeft: 0,
              },
              maxLength: 10,
            }}
            InputLabelProps={{
              style: {fontSize: 14, marginTop: -8, color: '#797979'},
            }}
            label="Valor"
            variant="outlined"
            value={item.vlr}
            currencySymbol="R$"
            minimumValue="0"
            outputFormat="string"
            decimalCharacter=","
            digitGroupSeparator="."
            onChange={(e, v) => {
              const vlr = v;
              setITENS(currentItem =>
                produce(currentItem, v => {
                  v[ind].data[index].vlr = vlr;
                }),
              );
            }}
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginLeft: -5,
              marginRight: 10,
            }}
          >
            <Button
              onClick={() => showDeleteComp(ind, index)}
              style={{
                paddingTop: 0,
                paddingBottom: 0,
                height: 30,
                width: '90%',
              }}
              size={'small'}
              variant="outlined"
              color="secondary"
              className={classes.button}
              startIcon={<DeleteIcon fontSize="small" />}
            >
              Excluir
            </Button>
            {item.bavailable ? (
              <Button
                onClick={() => stopComp(index, ind, false)}
                size={'small'}
                variant="outlined"
                color="primary"
                style={{
                  paddingTop: 0,
                  paddingBottom: 0,
                  height: 30,
                  width: '90%',
                  marginTop: 7.5,
                }}
              >
                <StopIcon fontSize="small" />
                Pausar
              </Button>
            ) : (
              <Button
                onClick={() => stopComp(index, ind, true)}
                size={'small'}
                variant="outlined"
                color="secondary"
                style={{
                  paddingTop: 0,
                  paddingBottom: 0,
                  height: 30,
                  width: '90%',
                  marginTop: 7.5,
                }}
              >
                <PlayArrowIcon fontSize="small" />
                Pausado
              </Button>
            )}
          </div>
        </Box>
      </div>
    );
  });

  return data;
};

const ListdataSmall = ({
  list,
  ind,
  handlyvalue,
  showDeleteComp,
  stopComp,
  setITENS,
}) => {
  const classes = useStyles();
  const [width, height] = useWindowSize();
  let data = list.map((item, index) => {
    return (
      <div key={index}>
        <Box
          boxShadow={2}
          style={{marginRight: 10, marginTop: 20, backgroundColor: '#FFFFFF'}}
        >
          <TextField
            style={{
              marginTop: 20,
              width: '90%',
              marginBottom: 10,
              paddingLeft: 10,
              paddingRight: 10,
            }}
            inputProps={{
              style: {fontSize: 14, padding: 6, color: '#797979'},
              maxLength: 100,
            }}
            InputLabelProps={{
              style: {fontSize: 14, marginTop: -8, color: '#797979'},
            }}
            variant="outlined"
            label="Produto"
            defaultValue={item.nome}
            value={item.nome}
            onChange={e => {
              const nome = e.target.value;
              setITENS(currentItem =>
                produce(currentItem, v => {
                  v[ind].data[index].nome = nome;
                }),
              );
            }}
          />
          <TextField
            style={{
              marginTop: 10,
              width: '90%',
              marginBottom: 10,
              marginLeft: 10,
            }}
            inputProps={{
              style: {fontSize: 14, padding: 6, color: '#797979'},
              maxLength: 20,
            }}
            InputLabelProps={{
              style: {fontSize: 14, marginTop: -8, color: '#797979'},
            }}
            variant="outlined"
            label="PDV"
            defaultValue={item.pdv}
            value={item.pdv}
            onChange={e => {
              const pdv = e.target.value;
              setITENS(currentItem =>
                produce(currentItem, v => {
                  v[ind].data[index].pdv = pdv;
                }),
              );
            }}
          />
          <TextField
            style={{
              marginTop: 10,
              width: '90%',
              marginBottom: 10,
              marginLeft: 10,
            }}
            inputProps={{
              style: {fontSize: 14, padding: 6, color: '#797979'},
              maxLength: 200,
            }}
            InputLabelProps={{
              style: {fontSize: 14, marginTop: -8, color: '#797979'},
            }}
            variant="outlined"
            label="Descrição"
            defaultValue={item.descricao}
            value={item.descricao}
            onChange={e => {
              const descricao = e.target.value;
              setITENS(currentItem =>
                produce(currentItem, v => {
                  v[ind].data[index].descricao = descricao;
                }),
              );
            }}
          />
          <CurrencyTextField
            style={{
              marginRight: 10,
              width: '90%',
              marginTop: 10,
              marginLeft: 10,
              marginBottom: 10,
            }}
            inputProps={{
              style: {
                fontSize: 14,
                padding: 6,
                color: '#797979',
                paddingLeft: 0,
              },
              maxLength: 10,
            }}
            InputLabelProps={{
              style: {fontSize: 14, marginTop: -8, color: '#797979'},
            }}
            label="Valor"
            variant="outlined"
            value={item.vlr}
            currencySymbol="R$"
            minimumValue="0"
            outputFormat="string"
            decimalCharacter=","
            digitGroupSeparator="."
            onChange={(e, v) => {
              const vlr = v;
              setITENS(currentItem =>
                produce(currentItem, v => {
                  v[ind].data[index].vlr = vlr;
                }),
              );
            }}
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginLeft: -5,
              marginRight: 10,
            }}
          >
            <Button
              onClick={() => showDeleteComp(ind, index)}
              style={{
                paddingTop: 0,
                paddingBottom: 0,
                height: 30,
                width: '90%',
              }}
              size={'small'}
              variant="outlined"
              color="secondary"
              className={classes.button}
              startIcon={<DeleteIcon fontSize="small" />}
            >
              Excluir
            </Button>
            {item.bavailable ? (
              <Button
                onClick={() => stopComp(index, ind, false)}
                size={'small'}
                variant="outlined"
                color="primary"
                style={{
                  paddingTop: 0,
                  paddingBottom: 0,
                  height: 30,
                  width: '90%',
                  marginTop: 7.5,
                }}
              >
                <StopIcon fontSize="small" />
                Pausar
              </Button>
            ) : (
              <Button
                onClick={() => stopComp(index, ind, true)}
                size={'small'}
                variant="outlined"
                color="secondary"
                style={{
                  paddingTop: 0,
                  paddingBottom: 0,
                  height: 30,
                  width: '90%',
                  marginTop: 7.5,
                }}
              >
                <PlayArrowIcon fontSize="small" />
                Pausado
              </Button>
            )}
          </div>
        </Box>
      </div>
    );
  });

  return data;
};

const Listcat = ({
  ITENS,
  setITENS,
  currentIndex,
  currentIndexComp,
  open,
  openReoderComps,
  openComp,
  addNewComp,
  deleteCat,
  deleteComp,
  deleteline,
  handleClose2,
  handleClose3,
  handlyvalue,
  showDeleteComp,
  stopComp,
}) => {
  const classes = useStyles();
  return ITENS.map((item, index) => {
    return (
      <div key={'cat' + index}>
        <Box
          style={{
            width: '97%',
            backgroundColor: '#F4F4F4',
            marginTop: 30,
            display: 'flex',
            flexDirection: 'column',
          }}
          boxShadow={3}
        >
          <div
            style={{
              backgroundColor: '#F4F4F4',
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              paddingLeft: 10,
              paddingRight: 10,
              borderRadius: 5,
              paddingBottom: 10,
            }}
          >
            <div
              style={{
                display: 'flex',
                width: '100%',
                alignItems: 'flex-end',
              }}
            >
              <TextField
                key={'title' + index}
                style={{marginTop: 20, width: '80%', marginBottom: 10}}
                inputProps={{
                  style: {fontSize: 14, padding: 10, color: '#797979'},
                  maxLength: 50,
                }}
                InputLabelProps={{
                  style: {fontSize: 14, marginTop: -8, color: '#797979'},
                }}
                variant="outlined"
                label="Categoria"
                defaultValue={item.title}
                value={item.title}
                onChange={e => {
                  const title = e.target.value;
                  setITENS(currentItem =>
                    produce(currentItem, v => {
                      v[index].title = title;
                    }),
                  );
                }}
              />
              <Button
                onClick={() => deleteCat(index)}
                style={{paddingTop: 0, paddingBottom: 0, height: 30}}
                size={'small'}
                variant="outlined"
                color="secondary"
                className={classes.button}
                startIcon={<DeleteIcon fontSize="small" />}
              >
                Excluir
              </Button>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-around',
              }}
            >
              <div style={{display: 'flex'}}>
                <TextField
                  type={'number'}
                  style={{marginTop: 20, width: '20%', marginBottom: 10}}
                  inputProps={{
                    style: {fontSize: 14, padding: 6, color: '#797979'},
                    maxLength: 10,
                  }}
                  InputLabelProps={{
                    style: {
                      fontSize: 14,
                      marginTop: -8,
                      color: '#797979',
                    },
                  }}
                  variant="outlined"
                  label="Min."
                  defaultValue={item.min}
                  value={item.min}
                  onChange={e => {
                    const min = e.target.value;
                    setITENS(currentItem =>
                      produce(currentItem, v => {
                        v[index].min = min;
                      }),
                    );
                  }}
                />
                <TextField
                  type={'number'}
                  style={{
                    marginTop: 20,
                    width: '20%',
                    marginBottom: 10,
                    marginLeft: 10,
                  }}
                  inputProps={{
                    style: {fontSize: 14, padding: 6, color: '#797979'},
                    maxLength: 10,
                  }}
                  InputLabelProps={{
                    style: {
                      fontSize: 14,
                      marginTop: -8,
                      color: '#797979',
                    },
                  }}
                  variant="outlined"
                  label="Max."
                  defaultValue={item.max}
                  value={item.max}
                  onChange={e => {
                    const max = e.target.value;
                    setITENS(currentItem =>
                      produce(currentItem, v => {
                        v[index].max = max;
                      }),
                    );
                  }}
                />
              </div>
              <div style={{marginTop: 8}}>
                <FormControlLabel
                  control={
                    <GreenCheckbox
                      onChange={e => {
                        const bavailable = e.target.value;
                        setITENS(currentItem =>
                          produce(currentItem, v => {
                            v[index].bobg = !v[index].bobg;
                          }),
                        );
                      }}
                      checked={item.bobg}
                      name="chck"
                    />
                  }
                  style={{color: '#393939'}}
                  label={
                    <p
                      style={{
                        fontSize: 14,
                        color: '#393939',
                      }}
                    >
                      Obrigatório
                    </p>
                  }
                />
              </div>
            </div>

            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <Chip
                style={{marginTop: 0, marginBottom: 0}}
                variant="outlined"
                size="small"
                color={'primary'}
                icon={<AddIcon />}
                label="Adicionar complemento"
                onClick={() => addNewComp(index)}
              />
              <Chip
                onClick={() => openReoderComps(index)}
                style={{marginTop: 0, marginBottom: 0}}
                variant="outlined"
                size="small"
                color={'primary'}
                icon={<ImportExportIcon />}
                label="Reordenar"
              />
            </div>
            <div>
              <Listdata
                list={item.data}
                ind={index}
                handlyvalue={handlyvalue}
                showDeleteComp={showDeleteComp}
                stopComp={stopComp}
                setITENS={setITENS}
              />
            </div>
          </div>
        </Box>
        <Dialog
          open={open}
          onClose={() => close()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {'Deletar categoria?'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Deseja deletar a categoria com todos os complementos?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose2} color="primary">
              Não
            </Button>
            <Button onClick={() => deleteline(currentIndex)} color="primary">
              Sim
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openComp}
          onClose={() => close()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {'Deletar complemento?'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Deseja deletar este complemento?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose3} color="primary">
              Não
            </Button>
            <Button
              onClick={() => deleteComp(currentIndex, currentIndexComp)}
              color="primary"
            >
              Sim
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  });
};

const ListcatVerySmall = ({
  ITENS,
  setITENS,
  currentIndex,
  currentIndexComp,
  open,
  openReoderComps,
  openComp,
  addNewComp,
  deleteCat,
  deleteComp,
  deleteline,
  handleClose2,
  handleClose3,
  handlyvalue,
  showDeleteComp,
  stopComp,
}) => {
  const classes = useStyles();
  const [width, height] = useWindowSize();
  let list = ITENS.map((item, index) => {
    return (
      <div key={index}>
        <div
          style={{
            width: 'auto',
            marginLeft: -15,
            marginRight: 0,
          }}
        >
          <Box boxShadow={3}>
            <ListItem
              style={{
                backgroundColor: '#F0F0F0',
                marginTop: 30,
                display: 'flex',
                flexDirection: 'column',
              }}
              key={index}
            >
              <div
                style={{
                  backgroundColor: '#F0F0F0',
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                  borderRadius: 5,
                  paddingLeft: 10,
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                  }}
                >
                  <TextField
                    style={{marginTop: 20, width: '95%', marginBottom: 10}}
                    inputProps={{
                      style: {fontSize: 14, padding: 10, color: '#797979'},
                      maxLength: 50,
                    }}
                    InputLabelProps={{
                      style: {
                        fontSize: 14,
                        marginTop: -8,
                        color: '#797979',
                      },
                    }}
                    variant="outlined"
                    label="Categoria"
                    defaultValue={item.title}
                    value={item.title}
                    onChange={e => {
                      const title = e.target.value;
                      setITENS(currentItem =>
                        produce(currentItem, v => {
                          v[index].title = title;
                        }),
                      );
                    }}
                  />
                  <IconButton
                    onClick={() => deleteCat(index)}
                    color="secondary"
                    aria-label="upload picture"
                    component="span"
                    size={'small'}
                    style={{marginTop: 8}}
                  >
                    <DeleteIcon />
                  </IconButton>
                </div>
                <div style={{display: 'flex'}}>
                  <TextField
                    type={'number'}
                    style={{marginTop: 20, width: '20%', marginBottom: 10}}
                    inputProps={{
                      style: {fontSize: 14, padding: 6, color: '#797979'},
                      maxLength: 10,
                    }}
                    InputLabelProps={{
                      style: {
                        fontSize: 14,
                        marginTop: -8,
                        color: '#797979',
                      },
                    }}
                    variant="outlined"
                    label="Min."
                    defaultValue={item.min}
                    value={item.min}
                    onChange={e => {
                      const min = e.target.value;
                      setITENS(currentItem =>
                        produce(currentItem, v => {
                          v[index].min = min;
                        }),
                      );
                    }}
                  />
                  <TextField
                    type={'number'}
                    style={{
                      marginTop: 20,
                      width: '20%',
                      marginBottom: 10,
                      marginLeft: width * 0.18,
                    }}
                    inputProps={{
                      style: {fontSize: 14, padding: 6, color: '#797979'},
                      maxLength: 10,
                    }}
                    InputLabelProps={{
                      style: {
                        fontSize: 14,
                        marginTop: -8,
                        color: '#797979',
                      },
                    }}
                    variant="outlined"
                    label="Max."
                    defaultValue={item.max}
                    onChange={e => {
                      const max = e.target.value;
                      setITENS(currentItem =>
                        produce(currentItem, v => {
                          v[index].max = max;
                        }),
                      );
                    }}
                  />
                </div>
                <div style={{marginTop: 8}}>
                  <FormControlLabel
                    control={
                      <GreenCheckbox
                        onChange={e => {
                          const bavailable = e.target.value;
                          setITENS(currentItem =>
                            produce(currentItem, v => {
                              v[index].bobg = !v[index].bobg;
                            }),
                          );
                        }}
                        checked={item.bobg}
                        name="chck"
                      />
                    }
                    style={{color: '#393939'}}
                    label={
                      <p
                        style={{
                          fontSize: 14,
                          color: '#393939',
                        }}
                      >
                        Obrigatório
                      </p>
                    }
                  />
                </div>
                <Chip
                  onClick={() => addNewComp(index)}
                  style={{marginTop: 0, marginBottom: 0}}
                  variant="outlined"
                  size="small"
                  color={'primary'}
                  icon={<AddIcon />}
                  label="Adicionar complemento"
                />
                <Chip
                  onClick={() => openReoderComps(index)}
                  style={{marginTop: 10, marginBottom: 0}}
                  variant="outlined"
                  size="small"
                  color={'primary'}
                  icon={<ImportExportIcon />}
                  label="Reordenar"
                />
                <div>
                  <ListdataVerySmall
                    list={item.data}
                    ind={index}
                    handlyvalue={handlyvalue}
                    showDeleteComp={showDeleteComp}
                    stopComp={stopComp}
                    setITENS={setITENS}
                  />
                </div>
              </div>
            </ListItem>
          </Box>
          <Dialog
            open={open}
            onClose={() => close()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {'Deletar categoria?'}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Deseja deletar a categoria com todos os complementos?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose2} color="primary">
                Não
              </Button>
              <Button onClick={() => deleteline(currentIndex)} color="primary">
                Sim
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={openComp}
            onClose={() => close()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {'Deletar complemento?'}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Deseja deletar este complemento?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose3} color="primary">
                Não
              </Button>
              <Button
                onClick={() => deleteComp(currentIndex, currentIndexComp)}
                color="primary"
              >
                Sim
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    );
  });
  return list;
};

const ListcatSmall = ({
  ITENS,
  setITENS,
  currentIndex,
  currentIndexComp,
  open,
  openReoderComps,
  openComp,
  addNewComp,
  deleteCat,
  deleteComp,
  deleteline,
  handleClose2,
  handleClose3,
  handlyvalue,
  showDeleteComp,
  stopComp,
}) => {
  const classes = useStyles();
  const [width, height] = useWindowSize();
  let list = ITENS.map((item, index) => {
    return (
      <div style={{width: 0.7 * width}} key={index}>
        <div
          style={{
            marginLeft: -25,
          }}
        >
          <Box boxShadow={3}>
            <ListItem
              style={{
                backgroundColor: '#F0F0F0',
                marginTop: 30,
                display: 'flex',
                flexDirection: 'column',
              }}
              key={index}
            >
              <div
                style={{
                  backgroundColor: '#F0F0F0',
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                  borderRadius: 5,
                  paddingLeft: 10,
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                  }}
                >
                  <TextField
                    style={{marginTop: 20, width: '87%', marginBottom: 10}}
                    inputProps={{
                      style: {fontSize: 14, padding: 10, color: '#797979'},
                      maxLength: 50,
                    }}
                    InputLabelProps={{
                      style: {
                        fontSize: 14,
                        marginTop: -8,
                        color: '#797979',
                      },
                    }}
                    variant="outlined"
                    label="Categoria"
                    defaultValue={item.title}
                    value={item.title}
                    onChange={e => {
                      const title = e.target.value;
                      setITENS(currentItem =>
                        produce(currentItem, v => {
                          v[index].title = title;
                        }),
                      );
                    }}
                  />
                  <IconButton
                    onClick={() => deleteCat(index)}
                    color="secondary"
                    aria-label="upload picture"
                    component="span"
                    size={'small'}
                    style={{marginTop: 8}}
                  >
                    <DeleteIcon />
                  </IconButton>
                </div>
                <div style={{display: 'flex'}}>
                  <TextField
                    type={'number'}
                    style={{marginTop: 20, width: '20%', marginBottom: 10}}
                    inputProps={{
                      style: {fontSize: 14, padding: 6, color: '#797979'},
                      maxLength: 10,
                    }}
                    InputLabelProps={{
                      style: {
                        fontSize: 14,
                        marginTop: -8,
                        color: '#797979',
                      },
                    }}
                    variant="outlined"
                    label="Min."
                    defaultValue={item.min}
                    value={item.min}
                    onChange={e => {
                      const min = e.target.value;
                      setITENS(currentItem =>
                        produce(currentItem, v => {
                          v[index].min = min;
                        }),
                      );
                    }}
                  />
                  <TextField
                    type={'number'}
                    style={{
                      marginTop: 20,
                      width: '20%',
                      marginBottom: 10,
                      marginLeft: width * 0.18,
                    }}
                    inputProps={{
                      style: {fontSize: 14, padding: 6, color: '#797979'},
                      maxLength: 10,
                    }}
                    InputLabelProps={{
                      style: {
                        fontSize: 14,
                        marginTop: -8,
                        color: '#797979',
                      },
                    }}
                    variant="outlined"
                    label="Max."
                    defaultValue={item.max}
                    onChange={e => {
                      const max = e.target.value;
                      setITENS(currentItem =>
                        produce(currentItem, v => {
                          v[index].max = max;
                        }),
                      );
                    }}
                  />
                </div>
                <div style={{marginTop: 8}}>
                  <FormControlLabel
                    control={
                      <GreenCheckbox
                        onChange={e => {
                          setITENS(currentItem =>
                            produce(currentItem, v => {
                              v[index].bobg = !v[index].bobg;
                            }),
                          );
                        }}
                        checked={item.bobg}
                        name="chck"
                      />
                    }
                    style={{color: '#393939'}}
                    label={
                      <p
                        style={{
                          fontSize: 14,
                          color: '#393939',
                        }}
                      >
                        Obrigatório
                      </p>
                    }
                  />
                </div>
                <Chip
                  style={{marginTop: 0, marginBottom: 0}}
                  variant="outlined"
                  size="small"
                  color={'primary'}
                  icon={<AddIcon />}
                  label="Adicionar complemento"
                  onClick={() => addNewComp(index)}
                />
                <Chip
                  onClick={() => openReoderComps(index)}
                  style={{marginTop: 10, marginBottom: 0}}
                  variant="outlined"
                  size="small"
                  color={'primary'}
                  icon={<ImportExportIcon />}
                  label="Reordenar"
                />
                <div>
                  <ListdataSmall
                    list={item.data}
                    ind={index}
                    handlyvalue={handlyvalue}
                    showDeleteComp={showDeleteComp}
                    stopComp={stopComp}
                    setITENS={setITENS}
                  />
                </div>
              </div>
            </ListItem>
          </Box>
          <Dialog
            open={open}
            onClose={() => close()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {'Deletar categoria?'}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Deseja deletar a categoria com todos os complementos?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose2} color="primary">
                Não
              </Button>
              <Button onClick={() => deleteline(currentIndex)} color="primary">
                Sim
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={openComp}
            onClose={() => close()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {'Deletar complemento?'}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Deseja deletar este complemento?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose3} color="primary">
                Não
              </Button>
              <Button
                onClick={() => deleteComp(currentIndex, currentIndexComp)}
                color="primary"
              >
                Sim
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    );
  });
  return list;
};

const useWindowSize = () => {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
};

const Complementos = ({ITENS, setITENS, fComp, setFComp}) => {
  const classes = useStyles();
  const isSmall = useMediaQuery({query: '(max-width: 750.98px)'});
  const isVerySmall = useMediaQuery({query: '(max-width:450.00px)'});
  const [isLoading, setLoading] = useState(true);

  const [open, setOpen] = React.useState(false);
  const handleClose2 = () => {
    setOpen(false);
  };

  const [openComp, setOpenComp] = React.useState(false);
  const handleClose3 = () => {
    setOpenComp(false);
  };

  const [openAddCat, setOpenAddCat] = useState(false);
  const handleClose6 = () => {
    setOpenAddCat(false);
  };

  const [openAddComp, setOpenAddComp] = useState(false);
  const handleClose7 = () => {
    setOpenAddComp(false);
  };

  const [reorderComp, setReorderComp] = useState(false);
  const handleClose4 = () => {
    setReorderComp(false);
  };

  const [reorderComps, setReorderComps] = useState(false);
  const handleClose5 = () => {
    setReorderComps(false);
  };

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (fComp) {
        setITENS([]);
        setFComp(false);
      }
    }, 500);
  }, []);

  function handlyvalue(value) {
    return null;
  }

  function openReoderComps(index) {
    setCurrentIndex(index);
    setReorderComps(true);
  }

  function addNewItem() {
    let newItem = {
      id: 'bd7ascbea-c1b1-46c2-aed5-adj00b2zd2sax38sda',
      title: '',
      min: 0,
      max: 1,
      qtd: 0,
      vlr: 0,
      bobg: false,
      bcheck: false,
      data: [],
    };

    let list = ITENS.map((item, index) => {
      return item;
    });

    list.splice(0, 0, newItem);
    setITENS(list);
    //setOpenAddCat(true);
  }

  function addNewComp(ind) {
    let newItem = {
      id: 'bd7ascbas-c1b1-46c2-aed5-3ads100bkb28ba',
      idStore: 'idrestaurante',
      nome: '',
      descricao: '',
      vlr: 0.0,
      qtd: 0,
      bavailable: true,
      pdv: '',
    };

    let list = ITENS.map((item, index) => {
      if (ind === index) {
        let listdata = ITENS[index].data.map((item2, index) => {
          return item2;
        });
        listdata.splice(0, 0, newItem);
        return {...item, data: listdata};
      } else {
        return item;
      }
    });

    setITENS(list);
  }

  function showDeleteComp(ind, indmain) {
    setCurrentIndex(ind);
    setCurrentIndexComp(indmain);
    setOpenComp(true);
  }

  function deleteComp(ind, indmain) {
    let list = ITENS.map((item, index) => {
      if (ind === index) {
        let listdata = ITENS[index].data.map((item2, index) => {
          return item2;
        });
        listdata.splice(indmain, 1);
        return {...item, data: listdata};
      } else {
        return item;
      }
    });
    setOpenComp(false);
    setITENS(list);
  }

  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentIndexComp, setCurrentIndexComp] = useState(0);
  const [width, height] = useWindowSize();
  function deleteCat(index) {
    setCurrentIndex(index);
    setOpen(true);
  }

  function deleteline(index) {
    let list = ITENS.map((item, index) => {
      return item;
    });
    list.splice(index, 1);

    setTimeout(() => {
      handleClose2();
      setITENS(list);
    }, 100);
  }

  function stopComp(ind, indmain, value) {
    let list = ITENS.map((item, index) => {
      if (index === indmain) {
        let datalist = item.data.map((item2, index2) => {
          if (ind === index2) {
            return {...item2, bavailable: value};
          } else {
            return item2;
          }
        });
        return {...item, data: datalist};
      } else {
        return item;
      }
    });

    setTimeout(() => {
      setITENS(list);
    }, 100);
  }

  return (
    <div>
      {isLoading ? (
        <div
          style={{
            display: 'flex',
            width: isVerySmall ? '45%' : '100%',
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          className={classes.root}
        >
          <CircularProgress />
        </div>
      ) : (
        <div>
          {isVerySmall ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginTop: 20,
                width: width * 0.8,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <ColorButton
                  onClick={() => addNewItem()}
                  startIcon={<AddIcon />}
                  size={'small'}
                  style={{color: '#ffffff', marginTop: 0, maxWidth: 150}}
                >
                  Categoria
                </ColorButton>
                <WhiteButton
                  onClick={() => setReorderComp(true)}
                  startIcon={<ImportExportIcon />}
                  size={'small'}
                  style={{
                    color: '#393939',
                    marginTop: 10,
                    maxWidth: 150,
                  }}
                >
                  Reordenar
                </WhiteButton>
              </div>

              <div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginLeft: 10,
                    marginRight: 10,
                    flex: 1,
                    width: 'auto',
                  }}
                >
                  <ListcatVerySmall
                    ITENS={ITENS}
                    setITENS={setITENS}
                    currentIndex={currentIndex}
                    currentIndexComp={currentIndexComp}
                    open={open}
                    openReoderComps={openReoderComps}
                    openComp={openComp}
                    addNewComp={addNewComp}
                    deleteCat={deleteCat}
                    deleteComp={deleteComp}
                    deleteline={deleteline}
                    handleClose2={handleClose2}
                    handleClose3={handleClose3}
                    handlyvalue={handlyvalue}
                    showDeleteComp={showDeleteComp}
                    stopComp={stopComp}
                  />
                </div>
              </div>
            </div>
          ) : isSmall ? (
            <div style={{display: 'flex', flexDirection: 'column'}}>
              <div style={{display: 'flex', flexDirection: 'row'}}>
                <ColorButton
                  onClick={() => addNewItem()}
                  startIcon={<AddIcon />}
                  size={'small'}
                  style={{
                    color: '#ffffff',
                    marginTop: 10,
                    maxWidth: 200,
                  }}
                >
                  Categoria
                </ColorButton>
                <WhiteButton
                  onClick={() => setReorderComp(true)}
                  startIcon={<ImportExportIcon />}
                  size={'small'}
                  style={{
                    color: '#393939',
                    marginTop: 10,
                    maxWidth: 200,
                    marginLeft: 15,
                  }}
                >
                  Reordenar
                </WhiteButton>
              </div>

              <div>
                <List component="nav" aria-labelledby="nested-list-subheader">
                  <ListcatSmall
                    ITENS={ITENS}
                    setITENS={setITENS}
                    currentIndex={currentIndex}
                    currentIndexComp={currentIndexComp}
                    open={open}
                    openReoderComps={openReoderComps}
                    openComp={openComp}
                    addNewComp={addNewComp}
                    deleteCat={deleteCat}
                    deleteComp={deleteComp}
                    deleteline={deleteline}
                    handleClose2={handleClose2}
                    handleClose3={handleClose3}
                    handlyvalue={handlyvalue}
                    showDeleteComp={showDeleteComp}
                    stopComp={stopComp}
                  />
                </List>
              </div>
            </div>
          ) : (
            <div>
              <div style={{display: 'flex'}}>
                <ColorButton
                  onClick={() => addNewItem()}
                  startIcon={<AddIcon />}
                  size={'small'}
                  style={{color: '#ffffff', marginTop: 0, maxWidth: 200}}
                >
                  Categoria
                </ColorButton>

                <WhiteButton
                  onClick={() => setReorderComp(true)}
                  startIcon={<ImportExportIcon />}
                  size={'small'}
                  style={{
                    color: '#393939',
                    marginTop: 0,
                    marginLeft: 10,
                    maxWidth: 200,
                  }}
                >
                  Reordenar
                </WhiteButton>
              </div>
              <div>
                <Listcat
                  ITENS={ITENS}
                  setITENS={setITENS}
                  currentIndex={currentIndex}
                  currentIndexComp={currentIndexComp}
                  open={open}
                  openReoderComps={openReoderComps}
                  openComp={openComp}
                  addNewComp={addNewComp}
                  deleteCat={deleteCat}
                  deleteComp={deleteComp}
                  deleteline={deleteline}
                  handleClose2={handleClose2}
                  handleClose3={handleClose3}
                  handlyvalue={handlyvalue}
                  showDeleteComp={showDeleteComp}
                  stopComp={stopComp}
                />
              </div>
            </div>
          )}{' '}
          <DialogReoderComp
            list={ITENS}
            setList={setITENS}
            bopen={reorderComp}
            handleClose={() => setReorderComp(false)}
            style={{
              top: 0,
              position: 'absolute',
              zIndex: 3,
            }}
          />
          <DialogReoderComps
            list={ITENS}
            currentIndex={currentIndex}
            setList={setITENS}
            bopen={reorderComps}
            handleClose={() => setReorderComps(false)}
            style={{
              top: 0,
              position: 'absolute',
              zIndex: 3,
            }}
          />
          <DialogAddCatComp
            list={ITENS}
            setList={setITENS}
            bopen={openAddCat}
            handleClose={() => setOpenAddCat(false)}
            style={{
              top: 0,
              position: 'absolute',
              zIndex: 3,
            }}
          />
        </div>
      )}
    </div>
  );
};

export default Complementos;
