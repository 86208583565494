/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */

import React, {useState, useEffect, useRef} from 'react';
import Aside from './aside';
import Catalogo from '../Catalogo';
import {useSelector} from 'react-redux';
import './App.scss';
import Informacoes from '../Informacoes';
import Frm from '../Frm';
import Location from '../Location';
import Inicio from '../Inicio';
import Avaliacoes from '../Avaliacoes';
import DadosBancario from '../DadosBancarios';
import Clientes from '../Clientes';
import Vendas from '../Vendas';
import Horarios from '../Horarios';
import Espera from '../Espera';
import Faturas from '../Faturas';
import Atendimento from '../AreadeAtendimento';
import AtendimentoIreit from '../AtendimentoIreit';
import {useSnackbar} from 'notistack';
import PedidoReceive from '../../components/Snackbar/PedidoReceiveSnackbar';
import {
  Badge,
  Box,
  Button,
  Card,
  CircularProgress,
  Divider,
} from '@material-ui/core';
import Pedidos from '../Pedidos';
import Gestor from '../Gestor';
import {useMediaQuery} from 'react-responsive';
import {withStyles} from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import IconButton from '@material-ui/core/IconButton';
import NotificationsIcon from '@material-ui/icons/Notifications';
import SettingsIcon from '@material-ui/icons/Settings';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import InfoIcon from '@material-ui/icons/Info';
import ReceiptIcon from '@material-ui/icons/Receipt';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import AlarmIcon from '@material-ui/icons/Alarm';
import BlockIcon from '@material-ui/icons/Block';
import {useDispatch} from 'react-redux';
import * as ActionCatalogo from '../../store/actions/setCatalogo';
import * as ActionScreen from '../../store/actions/setStateScreen';
import * as ActionCounters from '../../store/actions/setCounters';
import * as ActionStore from '../../store/actions/setRestaurante';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import {getAuth, signOut} from 'firebase/auth';
import Promocoes from '../Promocoes';
import AlertNewOrder from '../../components/AlertNewOrder';
import StarIcon from '@material-ui/icons/Star';
import Backdrop from '@material-ui/core/Backdrop';
import {makeStyles} from '@material-ui/core/styles';
import pedidos_ringtone from '../../assets/pedidos_ringtone.mp3';
import {useStompClient, useSubscription} from 'react-stomp-hooks';
import Ajuda from '../Ajuda';

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 5,
    color: '#fff',
  },
}));

const PurpleSwitch = withStyles({
  switchBase: {
    color: '#d4d4d4',
    '&$checked': {
      color: '#52d869',
    },
    '&$checked + $track': {
      backgroundColor: '#52d869',
    },
  },
  checked: {},
  track: {},
})(Switch);

function Home({
  setLocale,
  setNewPedido,
  newPedido,
  notifications,
  setNotifications,
  maxPageNot,
  setMaxPageNot,
}) {
  const auth = getAuth();
  const [rtl, setRtl] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const [image, setImage] = useState(true);
  const [toggled, setToggled] = useState(false);
  const estado = useSelector(state => state.setstatescreen.estado);
  const isSmall = useMediaQuery({query: '(max-width: 750.98px)'});
  const isVerySmall = useMediaQuery({query: '(max-width:450.00px)'});
  const dispatch = useDispatch();
  const idstore = useSelector(state => state.setrestaurante.idstore);
  const cat = useSelector(state => state.setrestaurante.cat);
  const location = useSelector(state => state.setrestaurante.location);
  const dtsm = useSelector(state => state.setrestaurante.dtsm);
  const dtfds = useSelector(state => state.setrestaurante.dtfds);
  const nome = useSelector(state => state.setrestaurante.nome);
  const status = useSelector(state => state.setrestaurante.status);
  const imge = useSelector(state => state.setrestaurante.img);
  const docid = useSelector(state => state.setrestaurante.id);
  const user = useSelector(state => state.setuser.email);
  const nNotifications = useSelector(state => state.setcounters.notifications);
  const [currentPageNot, setCurrentPageNot] = useState(0);
  const [currentNotifications, setCurrentNotifications] = useState([]);
  const handleCollapsedChange = checked => {
    setCollapsed(checked);
  };
  const [wsPedidoMessage, setWSPedidomessage] = useState();
  const [toggleInfo, setToggleInfo] = useState(true);
  const [toggleEnd, setToggleEnd] = useState(true);
  const [togglePay, setTogglePay] = useState(true);
  const [toggleAratdm, setToggleAratdm] = useState(true);
  const [toggleEspera, setToggleEspera] = useState(true);
  const [toggleHorario, setToggleHorario] = useState(true);
  const [toggleBackdrop, setToggleBackdrop] = useState(false);
  const [titleBackdrop, setTitleBackdrop] = useState('');
  const [descriptionBackdrop, setDescriptionBackdrop] = useState('');
  const [codeBackdrop, setCodeBackdrop] = useState(0);
  const [pedidos, setPedidos] = useState([]);
  const [play, setPlay] = useState(false);
  const audioEl = useRef(new Audio(pedidos_ringtone));
  const [isSendingStatus, setSendingStatus] = useState(true);
  const [isSendingEntregaIreit, setSendingEntregaIreit] = useState(false);
  const classes = useStyles();
  useSubscription('/topic/' + idstore, message =>
    setWSPedidomessage(message.body),
  );
  const handleBackDrop = (title, description, code, open) => {
    setToggleBackdrop(open);
    setTitleBackdrop(title);
    setDescriptionBackdrop(description);
    setCodeBackdrop(code);
  };
  const handleRtlChange = checked => {
    setRtl(checked);
    setLocale('en');
  };
  const handleImageChange = checked => {
    setImage(checked);
  };
  const handleToggleSidebar = value => {
    setToggled(value);
  };
  const [ITENS, setITENS] = useState([]);
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const handleNotiStack = (txt, variant) => {
    enqueueSnackbar(txt, {variant});
  };
  const [isOpen, setIsOpen] = useState(false);
  const handleChange = event => {
    setSendingStatus(true);
    if (
      toggleEnd &&
      toggleInfo &&
      togglePay &&
      !isSendingStatus &&
      toggleAratdm &&
      toggleHorario &&
      toggleEspera
    ) {
      let newstate = event.target.checked;
      let letStatus = 2;
      if (newstate) {
        letStatus = 4;
      } else {
        letStatus = 2;
      }
      auth.currentUser.getIdToken().then(token => {
        const request = new Request(
          'https://kops.apiireit.com/gateway/store/update/status/' +
            docid +
            '?status=' +
            letStatus,
          {
            method: 'PUT',
            mode: 'cors',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({}),
          },
        );

        fetch(request)
          .then(response => {
            if (response.status === 200) {
              handleBackDrop('', '', 200, false);
              response
                .json()
                .then(data => {
                  setTimeout(() => {
                    setSendingStatus(false);
                    setIsOpen(newstate);
                    if (newstate) {
                      handleNotiStack('Estabelecimento aberto', 'success');
                      startmonitoring();
                    } else {
                      handleNotiStack('Estabelecimento fechado', 'warning');
                      endmonitoring();
                    }
                  }, 1000);
                })
                .catch(error => {
                  console.log(error);
                });
            } else if (response.status === 503) {
              setSendingStatus(false);
              setTimeout(() => {
                handleNotiStack(
                  'Não foi possível salvar as informações',
                  'error',
                );
              }, 1000);
              handleBackDrop(
                'Serviço indisponível no momento',
                'O serviços para as lojas Irê it não estão disponíveis no momento. Por favor, tente novamente mais tarde',
                503,
                true,
              );
              throw new Error('Something went wrong on API server!');
            } else if (response.status === 404) {
              setSendingStatus(false);
              setTimeout(() => {
                handleNotiStack(
                  'Não foi possível salvar as informações',
                  'error',
                );
              }, 1000);
              handleBackDrop(
                'Serviço não encontrado.',
                'Por favor, entre em contato com o nosso suporte e relate o problema.',
                404,
                true,
              );
            } else if (response.status === 401) {
              setSendingStatus(false);
              setTimeout(() => {
                handleNotiStack(
                  'Não foi possível salvar as informações',
                  'error',
                );
              }, 1000);
              handleBackDrop(
                'Você não possui autorização ou suas credenciais expirarão.',
                'Por favor, atualize a baixa ou refaça o processo dee login.',
                401,
                true,
              );
            } else if (response.status === 400) {
              setSendingStatus(false);
              setTimeout(() => {
                handleNotiStack(
                  'Não foi possível salvar as informações',
                  'error',
                );
              }, 1000);
              handleBackDrop(
                'Requisição inválida.',
                'Confira se todos os campos estão preenchidos corretamente.',
                400,
                true,
              );
            } else if (response.status === 500) {
              setSendingStatus(false);
              setTimeout(() => {
                handleNotiStack(
                  'Não foi possível salvar as informações',
                  'error',
                );
              }, 1000);
              handleBackDrop(
                'Nosso servidor está passando por irregularidades.',
                'Estamos trabalhado para resolver o mais rápido possível.',
                500,
                true,
              );
            }
          })
          .catch(error => {
            setSendingStatus(false);
            setTimeout(() => {
              handleNotiStack(
                'Não foi possível salvar as informações',
                'error',
              );
            }, 1000);
            handleBackDrop(
              'Nosso servidor está passando por irregularidades.',
              'Estamos trabalhado para resolver o mais rápido possível.',
              500,
              true,
            );
            console.error(error);
          });
      });
    } else {
      setSendingStatus(false);
      if (!toggleEnd) {
        handleNotiStack('Verifique seu endereço', 'error');
      } else if (!toggleInfo) {
        handleNotiStack('Verifique as informações do estabelecimento', 'error');
      } else if (!togglePay) {
        handleNotiStack('Verifique as formas de pagamento', 'error');
      } else if (!toggleEspera) {
        handleNotiStack('Verifique o tempo de espera', 'error');
      } else if (!toggleAratdm) {
        handleNotiStack('Verifique a área de atendimento', 'error');
      } else if (!toggleHorario) {
        handleNotiStack('Verifique o horário de funcionamento', 'error');
      }
    }
  };

  function startmonitoring() {
    var now = new Date().toLocaleString('en-US', {
      weekday: 'long',
      timeZone: 'America/Sao_Paulo',
    });

    var days = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ];
    var intday = days.indexOf(now);
    let hr1a = '';
    let hr1f = '';
    let hr2a = '';
    let hr2f = '';
    let b2 = false;
    if (intday == 0) {
      hr1a = dtfds[2].hr1a;
      hr1f = dtfds[2].hr1f;
      hr2a = dtfds[2].hr2a;
      hr2f = dtfds[2].hr2f;
      b2 = dtfds[2].b2t;
    } else if (intday == 1) {
      hr1a = dtsm[0].hr1a;
      hr1f = dtsm[0].hr1f;
      hr2a = dtsm[0].hr2a;
      hr2f = dtsm[0].hr2f;
      b2 = dtsm[0].b2t;
    } else if (intday == 2) {
      hr1a = dtsm[1].hr1a;
      hr1f = dtsm[1].hr1f;
      hr2a = dtsm[1].hr2a;
      hr2f = dtsm[1].hr2f;
      b2 = dtsm[1].b2t;
    } else if (intday == 3) {
      hr1a = dtsm[2].hr1a;
      hr1f = dtsm[2].hr1f;
      hr2a = dtsm[2].hr2a;
      hr2f = dtsm[2].hr2f;
      b2 = dtsm[2].b2t;
    } else if (intday == 4) {
      hr1a = dtsm[3].hr1a;
      hr1f = dtsm[3].hr1f;
      hr2a = dtsm[3].hr2a;
      hr2f = dtsm[3].hr2f;
      b2 = dtsm[3].b2t;
    } else if (intday == 5) {
      hr1a = dtfds[0].hr1a;
      hr1f = dtfds[0].hr1f;
      hr2a = dtfds[0].hr2a;
      hr2f = dtfds[0].hr2f;
      b2 = dtfds[0].b2t;
    } else if (intday == 6) {
      hr1a = dtfds[1].hr1a;
      hr1f = dtfds[1].hr1f;
      hr2a = dtfds[1].hr2a;
      hr2f = dtfds[1].hr2f;
      b2 = dtfds[1].b2t;
    }
    auth.currentUser.getIdToken().then(token => {
      const request = new Request(
        'https://kops.apiireit.com/gateway/monitoring/store/open?estid=' +
          idstore +
          '&hr1a=' +
          hr1a +
          '&hr1f=' +
          hr1f +
          '&hr2a=' +
          hr2a +
          '&hr2f=' +
          hr2f +
          '&t2=' +
          b2 +
          '&lat=' +
          location.coordinates[1] +
          '&lng=' +
          location.coordinates[0] +
          '&cat=' +
          cat,
        {
          method: 'PUT',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({}),
        },
      );

      fetch(request)
        .then(response => {
          if (response.status === 200) {
            handleBackDrop('', '', 200, false);
            response
              .json()
              .then(data => {
                console.debug(data);
              })
              .catch(error => {
                console.log(error);
              });
          } else if (response.status === 503) {
            setSendingStatus(false);
            throw new Error('Something went wrong on API server!');
          } else if (response.status === 404) {
            throw new Error('Not found!');
          } else if (response.status === 401) {
            throw new Error('Unauthorized');
          } else if (response.status === 400) {
            throw new Error('Bad Request');
          } else if (response.status === 500) {
            throw new Error('Server problem');
          }
        })
        .catch(error => {
          console.error(error);
        });
    });
  }

  function endmonitoring() {
    auth.currentUser.getIdToken().then(token => {
      const request = new Request(
        'https://kops.apiireit.com/gateway/monitoring/store/close?estid=' +
          idstore,
        {
          method: 'PUT',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({}),
        },
      );

      fetch(request)
        .then(response => {
          if (response.status === 200) {
            handleBackDrop('', '', 200, false);
            response
              .json()
              .then(data => {
                console.debug(data);
              })
              .catch(error => {
                console.log(error);
              });
          } else if (response.status === 503) {
            setSendingStatus(false);
            throw new Error('Something went wrong on API server!');
          } else if (response.status === 404) {
            throw new Error('Not found!');
          } else if (response.status === 401) {
            throw new Error('Unauthorized');
          } else if (response.status === 400) {
            throw new Error('Bad Request');
          } else if (response.status === 500) {
            throw new Error('Server problem');
          }
        })
        .catch(error => {
          console.error(error);
        });
    });
  }

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [anchorEl2, setAnchorEl2] = React.useState(null);

  const handleClick2 = event => {
    setAnchorEl2(event.currentTarget);
    if (event.currentTarget) {
      dispatch(ActionCounters.setnotifications(0));
    }
  };

  useEffect(() => {
    if (anchorEl2) {
      setLoadingNotifications(true);
      setCurrentNotifications([]);
      setCurrentPageNot(0);
      SearchingNoti();
    } else {
      setLoadingNotifications(false);
    }
  }, [anchorEl2]);

  useEffect(() => {
    let list = [];
    if (currentNotifications.length > 0) {
      list = currentNotifications.map((item, index) => {
        return item;
      });
    }

    try {
      notifications.data.map((item, index) => {
        list.push(item);
      });
    } catch (error) {
      console.log(error);
    }

    setCurrentNotifications(list);
  }, [notifications]);

  function updateStore(data) {
    dispatch(
      ActionStore.setrestaurante(
        data._id,
        data.nome,
        data.dsc,
        data.nota,
        data.nNotas,
        data.cat,
        data.entrg,
        data.rtrda,
        data.agdnt,
        data.espra,
        data.dsct,
        data.frete,
        data.nAv,
        data.pdmin,
        data.entgrts,
        data.location,
        data.img,
        data.stt,
        data.catprice,
        data.iient,
        data.chck,
        data.merc,
        data.cpm,
        data.promoent,
        data.bapp,
        data.bpix,
        data.bame,
        data.bpicpay,
        data.bmp,
        data.bpaypal,
        data.bpre,
        data.bvir,
        data.bvref,
        data.bd,
        data.bvd,
        data.bmcd,
        data.belod,
        data.bhipd,
        data.bdind,
        data.baled,
        data.bsood,
        data.bvrd,
        data.bbend,
        data.bdisd,
        data.btrid,
        data.bbnsd,
        data.bbrsd,
        data.bbrd,
        data.bvc,
        data.bmcc,
        data.beloc,
        data.bhipc,
        data.bdinc,
        data.balec,
        data.bsodc,
        data.bvrc,
        data.bbenc,
        data.bdisc,
        data.btric,
        data.bbnsc,
        data.bbrsc,
        data.bbrc,
        data.end,
        data.num,
        data.bairro,
        data.cid,
        data.est,
        data.cnpj,
        data.bodom,
        data.hdom,
        data.boseg,
        data.hseg,
        data.boter,
        data.hter,
        data.boqua,
        data.hqua,
        data.boqui,
        data.hqui,
        data.bosex,
        data.hsex,
        data.bosab,
        data.hsab,
        data.aratdm,
        data.raio,
        data.bdstc,
        data.essm,
        data.esfds,
        data.inalta,
        data.npd,
        data.znid,
        data.zntx,
        data.iitx,
        data.iietx,
        data.freetx,
        data.freetill,
        data.lastSeenAt,
        data.presence,
        data.openchat,
        data.agd,
        data.fagdays,
        data.dtsm,
        data.dtfds,
        data.ctto,
        data.aratdmii,
        data.mxprmetrg,
        data.bbank,
        data.btcnta,
        data.bagcia,
        data.bcnta,
        data.bdig,
        data.cpix,
        data.npix,
        data.nimg,
      ),
    );
  }

  useEffect(() => {
    if (idstore != '') {
      auth.currentUser.getIdToken().then(token => {
        const request = new Request(
          'https://kops.apiireit.com/gateway/store/store?idstore=' + idstore,
          {
            method: 'GET',
            mode: 'cors',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          },
        );

        setTimeout(() => {
          fetch(request)
            .then(response => {
              if (response.status === 200) {
                handleBackDrop('', '', 200, false);
                response
                  .json()
                  .then(data => {
                    if (data.stt == 2) {
                      setIsOpen(false);
                    } else if (data.stt == 4) {
                      setIsOpen(true);
                    } else {
                      setIsOpen(false);
                    }
                    dispatch(
                      ActionStore.setrestaurante(
                        data._id,
                        data.nome,
                        data.dsc,
                        data.nota,
                        data.nNotas,
                        data.cat,
                        data.entrg,
                        data.rtrda,
                        data.agdnt,
                        data.espra,
                        data.dsct,
                        data.frete,
                        data.nAv,
                        data.pdmin,
                        data.entgrts,
                        data.location,
                        data.img,
                        data.stt,
                        data.catprice,
                        data.iient,
                        data.chck,
                        data.merc,
                        data.cpm,
                        data.promoent,
                        data.bapp,
                        data.bpix,
                        data.bame,
                        data.bpicpay,
                        data.bmp,
                        data.bpaypal,
                        data.bpre,
                        data.bvir,
                        data.bvref,
                        data.bd,
                        data.bvd,
                        data.bmcd,
                        data.belod,
                        data.bhipd,
                        data.bdind,
                        data.baled,
                        data.bsood,
                        data.bvrd,
                        data.bbend,
                        data.bdisd,
                        data.btrid,
                        data.bbnsd,
                        data.bbrsd,
                        data.bbrd,
                        data.bvc,
                        data.bmcc,
                        data.beloc,
                        data.bhipc,
                        data.bdinc,
                        data.balec,
                        data.bsodc,
                        data.bvrc,
                        data.bbenc,
                        data.bdisc,
                        data.btric,
                        data.bbnsc,
                        data.bbrsc,
                        data.bbrc,
                        data.end,
                        data.num,
                        data.bairro,
                        data.cid,
                        data.est,
                        data.cnpj,
                        data.bodom,
                        data.hdom,
                        data.boseg,
                        data.hseg,
                        data.boter,
                        data.hter,
                        data.boqua,
                        data.hqua,
                        data.boqui,
                        data.hqui,
                        data.bosex,
                        data.hsex,
                        data.bosab,
                        data.hsab,
                        data.aratdm,
                        data.raio,
                        data.bdstc,
                        data.essm,
                        data.esfds,
                        data.inalta,
                        data.npd,
                        data.znid,
                        data.zntx,
                        data.iitx,
                        data.iietx,
                        data.freetx,
                        data.freetill,
                        data.lastSeenAt,
                        data.presence,
                        data.openchat,
                        data.agd,
                        data.fagdays,
                        data.dtsm,
                        data.dtfds,
                        data.ctto,
                        data.aratdmii,
                        data.mxprmetrg,
                        data.bbank,
                        data.btcnta,
                        data.bagcia,
                        data.bcnta,
                        data.bdig,
                        data.cpix,
                        data.npix,
                        data.nimg,
                      ),
                    );
                    setTimeout(() => {
                      setSendingStatus(false);
                    }, 1000);
                  })
                  .catch(error => {
                    console.log(error);
                  });
              } else if (response.status === 503) {
                setTimeout(() => {
                  setSendingStatus(false);
                }, 1000);
                handleBackDrop(
                  'Serviço indisponível no momento',
                  'O serviços para as lojas Irê it não estão disponíveis no momento. Por favor, tente novamente mais tarde',
                  503,
                  true,
                );
                throw new Error('Something went wrong on API server!');
              } else if (response.status === 404) {
                setTimeout(() => {
                  setSendingStatus(false);
                }, 1000);
                handleBackDrop(
                  'Serviço não encontrado.',
                  'Por favor, entre em contato com o nosso suporte e relate o problema.',
                  404,
                  true,
                );
              } else if (response.status === 401) {
                setTimeout(() => {
                  setSendingStatus(false);
                }, 1000);
                handleBackDrop(
                  'Você não possui autorização ou suas credenciais expirarão.',
                  'Por favor, atualize a baixa ou refaça o processo dee login.',
                  401,
                  true,
                );
              } else if (response.status === 400) {
                setTimeout(() => {
                  setSendingStatus(false);
                }, 1000);
                handleBackDrop(
                  'Requisição inválida.',
                  'Confira se todos os campos estão preenchidos corretamente.',
                  400,
                  true,
                );
              } else if (response.status === 500) {
                setTimeout(() => {
                  setSendingStatus(false);
                }, 1000);
                handleBackDrop(
                  'Nosso servidor está passando por irregularidades.',
                  'Estamos trabalhado para resolver o mais rápido possível.',
                  500,
                  true,
                );
              }
            })
            .catch(error => {
              setTimeout(() => {
                setSendingStatus(false);
              }, 1000);
              handleBackDrop(
                'Nosso servidor está passando por irregularidades.',
                'Estamos trabalhado para resolver o mais rápido possível.',
                500,
                true,
              );
              console.error(error);
            });
        });
        UpdateCatalogo();
        updatePedidos();
      }, 1000);
    }
  }, [idstore]);

  function makeid(length) {
    var result = '';
    var characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  const stompClient = useStompClient();
  const sendMessage = (title, tag, data, users) => {
    if (stompClient) {
      //Send Message
      console.log('Message is sending -> ' + '/topic/' + idstore);
      stompClient.publish({
        destination: '/topic/' + idstore,
        body: JSON.stringify({
          key: makeid(5),
          title: title,
          tag: tag,
          data: data,
          to: users,
        }),
      });
    } else {
      console.log('MESSAGE IS NOT SENDING');
      //Handle error
    }
  };

  useEffect(() => {
    if (wsPedidoMessage !== undefined) {
      const json = wsPedidoMessage;
      const obj = JSON.parse(json);
      console.log('Obj tag: ', obj.tag);
      if (obj.tag === 'new_pedido') {
        updatePedidos();
        setPlay(true);
        if (estado !== 'Gestor') {
          enqueueSnackbar(wsPedidoMessage, {
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
            // eslint-disable-next-line react/display-name
            content: (key, message) => (
              <PedidoReceive
                id={key}
                closeSnackbar={closeSnackbar}
                message={'Você possui um novo pedido'}
                submessage={
                  'Clique no botão abaixo para poder ver seus pedidos'
                }
                txtbutton={'Ir para pedidos'}
                ActionScreen={ActionScreen}
                route={'Gestor'}
              />
            ),
            persist: false,
          });
        }
      } else if (obj.tag === 'update_entrega') {
        updatePedidos();
        setPlay(true);
        if (estado !== 'Gestor') {
          enqueueSnackbar(wsPedidoMessage, {
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
            // eslint-disable-next-line react/display-name
            content: (key, message) => (
              <PedidoReceive
                id={key}
                closeSnackbar={closeSnackbar}
                message={'Entrega atualizada'}
                submessage={
                  'Vá ao menu de pedidos para ver o status da entrega'
                }
                txtbutton={'Ir para pedidos'}
                ActionScreen={ActionScreen}
                route={'Gestor'}
              />
            ),
            persist: false,
          });
        }
      }
    } else {
      console.log('WS Pedidos is undefined');
    }
  }, [wsPedidoMessage]);

  useEffect(() => {
    const audio = audioEl.current;
    audio.load();
    const onPlay = () => audio.play();
    if (play) {
      if (audio && play) {
        audio.addEventListener('canplaythrough', onPlay);
        setTimeout(() => {
          setPlay(false);
        }, 3000);
      } else {
        console.log('Audio is not ready');
      }
    }
    return () => {
      if (audio) {
        audio.pause();
        audio.removeEventListener('canplaythrough', onPlay);
      }
    };
  }, [play]);

  const handleClose2 = () => {
    setAnchorEl2(null);
    setCurrentPageNot(0);
    setCurrentNotifications([]);
  };

  const preventDefault = event => event.preventDefault();

  const [loadingNotification, setLoadingNotification] = useState(false);
  const [isloadingNotifications, setLoadingNotifications] = useState(false);

  function UpdateCatalogo() {
    auth.currentUser.getIdToken().then(token => {
      const catalogorequest = new Request(
        'https://kops.apiireit.com/gateway/produtos/allcatalogo/' + idstore,
        {
          method: 'GET',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        },
      );

      fetch(catalogorequest)
        .then(response => {
          if (response.status === 200) {
            handleBackDrop('', '', 200, false);
            response
              .json()
              .then(data => {
                dispatch(ActionCatalogo.setcatalogo(data.catalogo));
              })
              .catch(error => {
                console.log(error);
              });
          } else if (response.status === 503) {
            handleBackDrop(
              'Serviço indisponível no momento',
              'O serviços para as lojas Irê it não estão disponíveis no momento. Por favor, tente novamente mais tarde',
              503,
              true,
            );
            throw new Error('Something went wrong on API server!');
          } else if (response.status === 404) {
            handleBackDrop(
              'Serviço não encontrado.',
              'Por favor, entre em contato com o nosso suporte e relate o problema.',
              404,
              true,
            );
          } else if (response.status === 401) {
            handleBackDrop(
              'Você não possui autorização ou suas credenciais expirarão.',
              'Por favor, atualize a baixa ou refaça o processo dee login.',
              401,
              true,
            );
          } else if (response.status === 400) {
            handleBackDrop(
              'Requisição inválida.',
              'Confira se todos os campos estão preenchidos corretamente.',
              400,
              true,
            );
          } else if (response.status === 500) {
            handleBackDrop(
              'Nosso servidor está passando por irregularidades.',
              'Estamos trabalhado para resolver o mais rápido possível.',
              500,
              true,
            );
          }
        })
        .catch(error => {
          handleBackDrop(
            'Nosso servidor está passando por irregularidades.',
            'Estamos trabalhado para resolver o mais rápido possível.',
            500,
            true,
          );
          console.error(error);
        });
    });
  }

  function updatePedidos() {
    auth.currentUser.getIdToken().then(token => {
      const pedidosrequest = new Request(
        'https://kops.apiireit.com/gateway/pedidos/get/current?estid=' +
          idstore,
        {
          method: 'GET',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        },
      );

      fetch(pedidosrequest)
        .then(response => {
          if (response.status === 200) {
            handleBackDrop('', '', 200, false);
            response
              .json()
              .then(data => {
                setPedidos(data);
                if (pedidos.some(e => e.stt === 0)) {
                  setPlay(true);
                }
              })
              .catch(error => {
                console.log(error);
              });
          } else if (response.status === 503) {
            handleBackDrop(
              'Serviço indisponível no momento',
              'O serviços para as lojas Irê it não estão disponíveis no momento. Por favor, tente novamente mais tarde',
              503,
              true,
            );
            throw new Error('Something went wrong on API server!');
          } else if (response.status === 404) {
            handleBackDrop(
              'Serviço não encontrado.',
              'Por favor, entre em contato com o nosso suporte e relate o problema.',
              404,
              true,
            );
          } else if (response.status === 401) {
            handleBackDrop(
              'Você não possui autorização ou suas credenciais expirarão.',
              'Por favor, atualize a baixa ou refaça o processo dee login.',
              401,
              true,
            );
          } else if (response.status === 400) {
            handleBackDrop(
              'Requisição inválida.',
              'Confira se todos os campos estão preenchidos corretamente.',
              400,
              true,
            );
          } else if (response.status === 500) {
            handleBackDrop(
              'Nosso servidor está passando por irregularidades.',
              'Estamos trabalhado para resolver o mais rápido possível.',
              500,
              true,
            );
          }
        })
        .catch(error => {
          handleBackDrop(
            'Nosso servidor está passando por irregularidades.',
            'Estamos trabalhado para resolver o mais rápido possível.',
            500,
            true,
          );
          console.error(error);
        });
    });
  }

  function SearchingNoti() {
    setLoadingNotification(true);
    auth.currentUser.getIdToken().then(token => {
      const request = new Request(
        'https://kops.apiireit.com/gateway/notifications/page' +
          '?pageNo=' +
          currentPageNot +
          '&pageSize=' +
          15 +
          '&sortyby=' +
          '_id' +
          '&userid=' +
          idstore,
        {
          method: 'GET',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        },
      );

      fetch(request)
        .then(response => {
          if (response.status === 200) {
            response
              .json()
              .then(data => {
                setTimeout(() => {
                  setCurrentPageNot(currentPageNot + 1);
                  setNotifications(data);
                  setMaxPageNot(data['Numero de Paginas: ']);
                  setTimeout(() => {
                    setLoadingNotification(false);
                    setLoadingNotifications(false);
                  }, 1000);
                }, 1000);
              })
              .catch(error => {
                setTimeout(() => {
                  setLoadingNotification(false);
                  setLoadingNotifications(false);
                }, 1000);
                console.log(error);
              });
          } else if (response.status === 503) {
            setTimeout(() => {
              setLoadingNotification(false);
              setLoadingNotifications(false);
            }, 1000);
            console.debug('Error 503');
            throw new Error('Something went wrong on API server!');
          } else if (response.status === 404) {
            setTimeout(() => {
              setLoadingNotification(false);
              setLoadingNotifications(false);
            }, 1000);
            console.debug('Error 404');
          } else if (response.status === 401) {
            setTimeout(() => {
              setLoadingNotification(false);
              setLoadingNotifications(false);
            }, 1000);
            console.debug('Error 401');
          } else if (response.status === 400) {
            setTimeout(() => {
              setLoadingNotification(false);
              setLoadingNotifications(false);
            }, 1000);
            console.debug('Error 400');
          } else if (response.status === 500) {
            setTimeout(() => {
              setLoadingNotification(false);
              setLoadingNotifications(false);
            }, 1000);
            console.debug('Error 500');
          }
        })
        .catch(error => {
          setTimeout(() => {
            setLoadingNotification(false);
            setLoadingNotifications(false);
          }, 1000);
          console.debug(error);
        });
    });
  }

  function signout() {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        // Sign-out successful.
      })
      .catch(error => {
        // An error happened.
      });
  }

  function handleNotification(topic, docid) {
    auth.currentUser.getIdToken().then(token => {
      const request = new Request(
        'https://kops.apiireit.com/gateway/notifications/update/read/' + docid,
        {
          method: 'PUT',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({}),
        },
      );

      fetch(request)
        .then(response => {
          if (response.status === 200) {
            handleBackDrop('', '', 200, false);
            response
              .json()
              .then(data => {
                console.debug(data);
              })
              .catch(error => {
                console.log(error);
              });
          } else if (response.status === 503) {
            console.debug('Error 503');
            throw new Error('Something went wrong on API server!');
          } else if (response.status === 404) {
            console.debug('Error 404');
          } else if (response.status === 401) {
            console.debug('Error 401');
          } else if (response.status === 400) {
            console.debug('Error 400');
          } else if (response.status === 500) {
            console.debug('Error 500');
          }
        })
        .catch(error => {
          console.error(error);
        });
    });

    handleClose2();
    if (topic === 'pedido') {
      dispatch(ActionScreen.setChangeScreen('Gestor'));
    } else if (topic === 'news') {
      dispatch(ActionScreen.setChangeScreen('Inicio'));
    } else if (topic === 'alert') {
      dispatch(ActionScreen.setChangeScreen('Inicio'));
    } else if (topic === 'atraso') {
      dispatch(ActionScreen.setChangeScreen('Gestor'));
    } else if (topic === 'block') {
      dispatch(ActionScreen.setChangeScreen('Faturas'));
    } else if (topic === 'avaliation') {
      dispatch(ActionScreen.setChangeScreen('Avaliacoes'));
    } else {
      return null;
    }
  }

  const Listnotification = ({list}) => {
    let listnot = currentNotifications.map((item, index) => {
      return (
        <div key={index}>
          <Button
            style={{
              marginRight: 0,
              padding: 0,
              textTranform: 'none',
              width: '100%',
              justifyContent: 'flex-start',
            }}
            onClick={() => handleNotification(item.topic, item._id)}
          >
            <div
              style={{
                position: 'absolute',
                top: isVerySmall ? '40%' : '40%',
                right: 5,
              }}
            >
              {item.read ? (
                <CheckCircleIcon style={{color: '#52d869', fontSize: 14}} />
              ) : null}
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                marginLeft: 10,
                marginRight: 20,
                width: '100%',
              }}
            >
              <div>
                {item.topic === 'pedido' ? (
                  <ReceiptIcon style={{color: '#06448E'}} />
                ) : item.topic === 'news' ? (
                  <AnnouncementIcon style={{color: '#06448E'}} />
                ) : item.topic === 'alert' ? (
                  <ReportProblemIcon style={{color: '#FFF000'}} />
                ) : item.topic === 'atraso' ? (
                  <AlarmIcon style={{color: '#FF3333'}} />
                ) : item.topic === 'block' ? (
                  <BlockIcon style={{color: '#FF3333'}} />
                ) : item.topic === 'avaliation' ? (
                  <StarIcon style={{color: '#FFF000'}} />
                ) : (
                  <NotificationsIcon style={{color: '#06448E'}} />
                )}
              </div>
              <div style={{marginLeft: 10, maxWidth: 300}}>
                <p
                  style={{
                    marginTop: 10,
                    marginBottom: 0,
                    fontSize: 12,
                    fontWeight: 'bold',
                    textAlign: 'left',
                    textTransform: 'none',
                  }}
                >
                  {item.title}
                </p>
                <p
                  style={{
                    marginTop: 0,
                    fontSize: 12,
                    textAlign: 'left',
                    textTransform: 'none',
                  }}
                >
                  {item.message}
                </p>
              </div>
            </div>
          </Button>
          <Divider variant="middle" />
        </div>
      );
    });

    return listnot;
  };

  return (
    <div>
      {status === 5 ? (
        <div style={{width: '100%'}}>
          <Box
            boxShadow={3}
            style={{
              backgroundColor: '#ff2222',
              flex: 1,
              position: 'sticky',
              top: 0,
              zIndex: 2,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              paddingLeft: 10,
              paddingRight: 10,
            }}
          >
            <p style={{color: '#FFFFFF'}}>
              Este estabelecimento está bloqueado. Entre em contato com a Irê it
              pelo email suporte@ireit.com.br ou através do whatsapp (74) para
              poder fazer o processo de desbloqueio da conta. 99999-9999
            </p>
          </Box>
        </div>
      ) : null}

      <div className={`app ${rtl ? 'rtl' : ''} ${toggled ? 'toggled' : ''}`}>
        <div style={{position: 'relative', backgroundColor: '#06448e'}}>
          <Aside
            image={image}
            setCollapsed={setCollapsed}
            collapsed={collapsed}
            rtl={rtl}
            toggled={toggled}
            handleToggleSidebar={handleToggleSidebar}
            toggleInfo={toggleInfo}
            setToggleInfo={setToggleInfo}
            toggleAratdm={toggleAratdm}
            setToggleAratdm={setToggleAratdm}
            toggleEnd={toggleEnd}
            setToggleEnd={setToggleEnd}
            togglePay={togglePay}
            setTogglePay={setTogglePay}
            toggleEspera={toggleEspera}
            setToggleEspera={setToggleEspera}
            toggleHorario={toggleHorario}
            setToggleHorario={setToggleHorario}
            handleBackDrop={handleBackDrop}
            handleNotiStack={handleNotiStack}
            isSendingEntregaIreit={isSendingEntregaIreit}
            setSendingEntregaIreit={setSendingEntregaIreit}
          />
        </div>
        <div style={{width: '100%'}}>
          <Box
            boxShadow={3}
            style={{
              backgroundColor: '#ffffff',
              flex: 1,
              height: 60,
              position: 'sticky',
              top: 0,
              zIndex: 5,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              paddingLeft: 10,
              paddingRight: 10,
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                flex: 1,
              }}
            >
              {status === 5 ? (
                <div
                  style={{
                    backgroundColor: '#FFDDDD',
                    paddingLeft: 10,
                    paddingRight: 10,
                    paddingTop: 0,
                    paddingBottom: 0,
                    display: 'flex',
                    alignItems: 'center',
                    borderRadius: 30,
                    marginLeft: isVerySmall ? -5 : 0,
                  }}
                >
                  {isVerySmall ? null : (
                    <RadioButtonCheckedIcon
                      style={{color: '#ff0000', fontSize: 14}}
                    />
                  )}

                  <p style={{marginTop: 0, marginBottom: 0, marginLeft: 0}}>
                    Bloqueado
                  </p>
                </div>
              ) : (
                <div style={{marginTop: 0, display: 'flex'}}>
                  <FormControlLabel
                    style={{marginRight: 0}}
                    control={
                      <PurpleSwitch
                        checked={isOpen}
                        onChange={handleChange}
                        name="checked"
                      />
                    }
                  />
                  {isOpen ? (
                    isSendingStatus ? (
                      <div
                        style={{
                          alignItems: 'center',
                          justifyContent: 'center',
                          marginTop: 10,
                        }}
                      >
                        <CircularProgress
                          style={{color: '#06448e'}}
                          size={15}
                        />
                      </div>
                    ) : (
                      <div
                        style={{
                          backgroundColor: '#DDFFDD',
                          paddingLeft: 10,
                          paddingRight: 10,
                          paddingTop: 0,
                          paddingBottom: 0,
                          display: 'flex',
                          alignItems: 'center',
                          borderRadius: 30,
                          marginLeft: isVerySmall ? -5 : 0,
                        }}
                      >
                        {isVerySmall ? null : (
                          <RadioButtonCheckedIcon
                            style={{color: '#52d869', fontSize: 14}}
                          />
                        )}

                        <p
                          style={{marginTop: 0, marginBottom: 0, marginLeft: 0}}
                        >
                          Aberto
                        </p>
                      </div>
                    )
                  ) : isSendingStatus ? (
                    <div
                      style={{
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginTop: 10,
                      }}
                    >
                      <CircularProgress style={{color: '#06448e'}} size={18} />
                    </div>
                  ) : (
                    <div
                      style={{
                        backgroundColor: '#FFDDDD',
                        paddingLeft: 10,
                        paddingRight: 10,
                        paddingTop: 0,
                        paddingBottom: 0,
                        display: 'flex',
                        alignItems: 'center',
                        borderRadius: 30,
                        marginLeft: isVerySmall ? -5 : 0,
                      }}
                    >
                      {isVerySmall ? null : (
                        <RadioButtonCheckedIcon
                          style={{color: '#ff0000', fontSize: 14}}
                        />
                      )}

                      <p style={{marginTop: 0, marginBottom: 0, marginLeft: 0}}>
                        Fechado
                      </p>
                    </div>
                  )}
                </div>
              )}

              <div style={{alignItems: 'center', justifyContent: 'flex-end'}}>
                <IconButton
                  onClick={handleClick2}
                  style={{
                    backgroundColor: '#f4f4f4',
                  }}
                  aria-label="close"
                >
                  <Badge
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    badgeContent={nNotifications}
                    color="primary"
                  >
                    <NotificationsIcon
                      style={{fontSize: isVerySmall ? 14 : 20}}
                    />
                  </Badge>
                </IconButton>
                <IconButton
                  onClick={handleClick}
                  style={{
                    backgroundColor: '#f4f4f4',
                    marginLeft: 10,
                  }}
                  aria-label="close"
                >
                  <SettingsIcon style={{fontSize: isVerySmall ? 14 : 20}} />
                </IconButton>

                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <div
                    style={{
                      width: isVerySmall ? 180 : 300,
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    {imge === '' ? (
                      <div style={{width: 60, height: 60}} />
                    ) : (
                      <img
                        src={imge + idstore + '?t=' + new Date().getTime()}
                        style={{width: 60, height: 60, borderRadius: 30}}
                      />
                    )}
                    <p
                      style={{
                        maxWidth: 200,
                        textAlign: 'center',
                        fontWeight: 'bold',
                      }}
                    >
                      {nome}
                    </p>
                    <div
                      style={{
                        marginTop: 0,
                        marginLeft: 10,
                        marginRight: 10,
                        width: '90%',
                        alignItems: 'center',
                        justifyContent: 'center',
                        overflowWrap: 'break-word',
                      }}
                    >
                      <p style={{textAlign: 'center', marginTop: 0}}>
                        {user.email}
                      </p>
                    </div>
                    <div style={{width: '100%'}}>
                      <Divider variant="fullWidth" />
                    </div>
                    <div>
                      <Button
                        onClick={() => signout()}
                        style={{
                          marginLeft: 10,
                          marginTop: 10,
                          marginBottom: 10,
                        }}
                        variant="outlined"
                        size={'medium'}
                        color="primary"
                      >
                        Sair
                      </Button>
                    </div>
                    <div style={{width: '100%'}}>
                      <Divider variant="fullWidth" />
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: isVerySmall ? 'column' : 'row',
                        justifyContent: 'space-around',
                        alignItems: 'center',
                        width: '100%',
                      }}
                    >
                      <Button
                        style={{
                          marginLeft: 10,
                          marginTop: 10,
                          marginBottom: 10,
                          fontSize: 10,
                        }}
                        variant="text"
                        size={'small'}
                        color="primary"
                        href={
                          'https://www.ireit.com.br/politica_de_privacidade.html'
                        }
                      >
                        Política de privacidade
                      </Button>
                      <p
                        style={{
                          color: '#393939',
                          fontSize: 12,
                          fontWeight: 'bold',
                          marginTop: isVerySmall ? 0 : 10,
                        }}
                      >
                        ·
                      </p>
                      <Button
                        style={{
                          marginLeft: 10,
                          marginTop: isVerySmall ? 0 : 10,
                          marginBottom: 10,
                          fontSize: 10,
                        }}
                        variant="text"
                        size={'small'}
                        color="primary"
                        href={'https://www.ireit.com.br/termos_de_uso.html'}
                      >
                        Termos de uso
                      </Button>
                    </div>
                  </div>
                </Menu>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl2}
                  keepMounted
                  open={Boolean(anchorEl2)}
                  onClose={handleClose2}
                >
                  {currentNotifications.length === 0 &&
                  !isloadingNotifications ? (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                        paddingLeft: 10,
                        paddingRight: 10,
                      }}
                    >
                      <p
                        style={{
                          fontWeight: 'bold',
                          fontSize: 20,
                          marginBottom: 10,
                          marginTop: 0,
                          color: '#999999',
                        }}
                      >
                        Notificações
                      </p>
                      <InfoIcon style={{color: '#c4c4c4'}} />
                      <p
                        style={{
                          color: '#c4c4c4',
                          maxWidth: 200,
                          textAlign: 'center',
                        }}
                      >
                        Você ainda não possui notificações
                      </p>
                    </div>
                  ) : (
                    <div style={{width: isVerySmall ? 200 : 300}}>
                      <p
                        style={{
                          marginLeft: 10,
                          fontWeight: 'bold',
                          fontSize: 20,
                          marginBottom: 0,
                          marginTop: 0,
                        }}
                      >
                        Notificações
                      </p>
                      {isloadingNotifications ? (
                        <div
                          style={{
                            display: 'flex',
                            flex: 1,
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: 30,
                            marginBottom: 5,
                            marginTop: 20,
                          }}
                        >
                          <CircularProgress size={20} />
                        </div>
                      ) : (
                        <Listnotification list={notifications} />
                      )}

                      {loadingNotification && !isloadingNotifications ? (
                        <div
                          style={{
                            display: 'flex',
                            flex: 1,
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: 30,
                            marginBottom: 5,
                          }}
                        >
                          <CircularProgress size={20} />
                        </div>
                      ) : currentPageNot < maxPageNot &&
                        !isloadingNotifications ? (
                        <Button
                          onClick={() => SearchingNoti()}
                          style={{width: '100%', alignItems: 'left'}}
                          variant={'text'}
                          color={'primary'}
                        >
                          <p style={{textTransform: 'none', fontSize: 12}}>
                            Carregar mais notificações
                          </p>
                          <ArrowDropDownIcon style={{marginTop: -3}} />
                        </Button>
                      ) : null}
                    </div>
                  )}
                </Menu>
              </div>
            </div>
          </Box>
          {estado === 'Inicio' ? (
            <Inicio
              image={image}
              toggled={toggled}
              collapsed={collapsed}
              rtl={rtl}
              handleToggleSidebar={handleToggleSidebar}
              handleCollapsedChange={handleCollapsedChange}
              handleRtlChange={handleRtlChange}
              handleImageChange={handleImageChange}
              handleNotiStack={handleNotiStack}
              handleBackDrop={handleBackDrop}
            />
          ) : estado === 'Catalogo' ? (
            <Catalogo
              updateCatalogo={UpdateCatalogo}
              style={{backgroundColor: '#FFFFFF', background: '#FFFFFF'}}
              handleToggleSidebar={handleToggleSidebar}
              handleNotiStack={handleNotiStack}
              handleBackDrop={handleBackDrop}
            />
          ) : estado === 'Historico' ? (
            <Pedidos
              handleToggleSidebar={handleToggleSidebar}
              handleNotiStack={handleNotiStack}
              handleBackDrop={handleBackDrop}
            />
          ) : estado === 'Promocoes' ? (
            <Promocoes
              handleToggleSidebar={handleToggleSidebar}
              handleNotiStack={handleNotiStack}
              handleBackDrop={handleBackDrop}
              updateStore={updateStore}
            />
          ) : estado === 'Gestor' ? (
            <Gestor
              handleToggleSidebar={handleToggleSidebar}
              handleNotiStack={handleNotiStack}
              handleBackDrop={handleBackDrop}
              pedidos={pedidos}
              setPedidos={setPedidos}
              sendMessage={sendMessage}
              updatePedidos={updatePedidos}
            />
          ) : estado === 'Informacoes' ? (
            <Informacoes
              handleToggleSidebar={handleToggleSidebar}
              handleNotiStack={handleNotiStack}
              handleBackDrop={handleBackDrop}
            />
          ) : estado === 'Frm' ? (
            <Frm
              handleToggleSidebar={handleToggleSidebar}
              handleNotiStack={handleNotiStack}
              handleBackDrop={handleBackDrop}
            />
          ) : estado === 'Location' ? (
            <Location
              handleToggleSidebar={handleToggleSidebar}
              handleNotiStack={handleNotiStack}
              handleBackDrop={handleBackDrop}
            />
          ) : estado === 'Avaliacoes' ? (
            <Avaliacoes
              handleToggleSidebar={handleToggleSidebar}
              handleNotiStack={handleNotiStack}
              handleBackDrop={handleBackDrop}
            />
          ) : estado === 'DadosBancarios' ? (
            <DadosBancario
              handleToggleSidebar={handleToggleSidebar}
              handleNotiStack={handleNotiStack}
              handleBackDrop={handleBackDrop}
            />
          ) : estado === 'Clientes' ? (
            <Clientes
              handleToggleSidebar={handleToggleSidebar}
              handleNotiStack={handleNotiStack}
              handleBackDrop={handleBackDrop}
            />
          ) : estado === 'Vendas' ? (
            <Vendas
              handleToggleSidebar={handleToggleSidebar}
              handleNotiStack={handleNotiStack}
              handleBackDrop={handleBackDrop}
            />
          ) : estado === 'Horarios' ? (
            <Horarios
              handleToggleSidebar={handleToggleSidebar}
              handleNotiStack={handleNotiStack}
              handleBackDrop={handleBackDrop}
            />
          ) : estado === 'Espera' ? (
            <Espera
              handleToggleSidebar={handleToggleSidebar}
              handleNotiStack={handleNotiStack}
              handleBackDrop={handleBackDrop}
            />
          ) : estado === 'Faturas' ? (
            <Faturas
              handleToggleSidebar={handleToggleSidebar}
              handleNotiStack={handleNotiStack}
              handleBackDrop={handleBackDrop}
            />
          ) : estado === 'Atendimento' ? (
            <Atendimento
              handleToggleSidebar={handleToggleSidebar}
              handleNotiStack={handleNotiStack}
              handleBackDrop={handleBackDrop}
              isSendingEntregaIreit={isSendingEntregaIreit}
              setSendingEntregaIreit={setSendingEntregaIreit}
            />
          ) : estado === 'AtendimentoIreit' ? (
            <AtendimentoIreit
              handleToggleSidebar={handleToggleSidebar}
              handleNotiStack={handleNotiStack}
              handleBackDrop={handleBackDrop}
            />
          ) : estado === 'Ajuda' ? (
            <Ajuda
              handleToggleSidebar={handleToggleSidebar}
              handleNotiStack={handleNotiStack}
              handleBackDrop={handleBackDrop}
            />
          ) : null}
        </div>
      </div>
      <div>
        <AlertNewOrder
          isOpen={newPedido}
          handleClose={() => setNewPedido(false)}
          handleNotiStack
          style={{
            top: 0,
            position: 'absolute',
            zIndex: 3,
          }}
        />
      </div>
      <Backdrop
        className={classes.backdrop}
        open={toggleBackdrop}
        onClick={() => setToggleBackdrop(false)}
      >
        <Card style={{maxWidth: '70%'}}>
          <div style={{paddingLeft: 20, paddingRight: 20}}>
            <div
              style={{
                background: '#FFDDDD',
                paddingLeft: 0,
                paddingRight: 0,
              }}
            >
              <p style={{color: '#ff0000'}}>Erro: {codeBackdrop}</p>
            </div>
            <div>
              <p style={{fontWeight: 'bold'}}>
                Erro {codeBackdrop} - {titleBackdrop}
              </p>
            </div>
            <div
              style={{
                background: '#F3F3F3',
                paddingLeft: 5,
                paddingRight: 5,
                paddingTop: 1,
                paddingBottom: 1,
                marginTop: 0,
              }}
            >
              <p style={{fontSize: 14, marginTop: 5}}>{descriptionBackdrop}</p>
            </div>
            <div
              style={{
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: 20,
                marginBottom: 10,
                width: '100%',
                display: 'flex',
              }}
            >
              <Button style={{background: '#06448e', color: '#FFFFFF'}}>
                Entendi.
              </Button>
            </div>
          </div>
        </Card>
      </Backdrop>
    </div>
  );
}

export default Home;
