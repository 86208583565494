/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, {useState, useEffect, useRef, useCallback} from 'react';
import {GoogleMap, Polygon, useJsApiLoader} from '@react-google-maps/api';
import useGeolocation from './useLocation';
import {Circle} from '@react-google-maps/api';
import Button from '@material-ui/core/Button';
import {Slider} from '@material-ui/core';
import {Marker} from '@react-google-maps/api';
import {useSelector} from 'react-redux';

const containerStyle = {
  width: '100%',
  height: '500px',
};

const ContentMap = ({
  width,
  isDistance,
  setIsDistance,
  raio,
  setRaio,
  handleChange,
  path,
  setPath,
  circlePath,
  setCirclePath,
  isVerySmall,
  isSmall,
}) => {
  const {isLoaded} = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyADIHr5UwS75lsAsHpdTHG2u5i9y2nBpO4',
  });

  const [firstTime, setIsFirstTime] = useState(true);
  const [zoom, setZoom] = useState(12.35);
  const [center, setCenter] = useState({lat: 0, lng: 0});
  const cRaio = useSelector(state => state.setrestaurante.raio);
  const cIsDistance = useSelector(state => state.setrestaurante.bDstc);
  const cPath = useSelector(state => state.setrestaurante.aratdm.coordinates);
  const estado = useSelector(state => state.setrestaurante);
  const clat = useSelector(
    state => state.setrestaurante.location.coordinates[1],
  );
  const clng = useSelector(
    state => state.setrestaurante.location.coordinates[0],
  );

  const marks = [
    {
      value: 1,
      label: '1km',
    },
    {
      value: 5,
      label: '5km',
    },
    {
      value: 10,
      label: '10km',
    },
    {
      value: 15,
      label: '15km',
    },
    {
      value: 20,
      label: '20km',
    },
    {
      value: 25,
      label: '25km',
    },
    {
      value: 30,
      label: '30km',
    },
  ];

  const littlemarks = [
    {
      value: 1,
      label: '1km',
    },

    {
      value: 30,
      label: '30km',
    },
  ];

  const location = useGeolocation();

  useEffect(() => {
    if (cIsDistance) {
      if (firstTime) {
        setIsFirstTime(false);
        resetPath();
      }
    } else {
      let newpath = [];
      if (firstTime) {
        cPath[0].forEach(s => newpath.push({lat: s[1], lng: s[0]}));
      }
      setPath(newpath);
    }

    setCenter({lat: clat, lng: clng});
    setTimeout(() => {
      setIsDistance(cIsDistance);
      setRaio(cRaio);
      setZoom(12.35);
    }, 1000);
  }, []);

  // Define refs for Polygon instance and listeners
  const polygonRef = useRef(null);
  const listenersRef = useRef([]);

  // Call setPath with new edited path
  const onEdit = useCallback(() => {
    if (polygonRef.current) {
      const nextPath = polygonRef.current
        .getPath()
        .getArray()
        .map(latLng => {
          return {lat: latLng.lat(), lng: latLng.lng()};
        });
      setPath(nextPath);
    }
  }, [setPath]);

  // Call setPath with new edited path
  const onEdit2 = useCallback(() => {
    if (polygonRef.current) {
      const nextPath = polygonRef.current
        .getPath()
        .getArray()
        .map(latLng => {
          return {lat: latLng.lat(), lng: latLng.lng()};
        });
      setCirclePath(nextPath);
    }
  }, [setCirclePath]);

  function resetPath() {
    setPath([
      {lat: clat - 0.05, lng: clng - 0.05},
      {lat: clat - 0.05, lng: clng + 0.05},
      {lat: clat + 0.05, lng: clng + 0.05},
      {lat: clat + 0.05, lng: clng - 0.05},
    ]);
  }

  const [map, setMap] = React.useState(null);
  const mapRef = useRef(GoogleMap);

  const onLoad = React.useCallback(function callback(map) {
    //const bounds = new window.google.maps.LatLngBounds();
    //map.fitBounds(bounds);
    setMap(map);
    setZoom(12.35);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  const onLoad2 = useCallback(
    polygon => {
      polygonRef.current = polygon;
      const path = polygon.getPath();
      listenersRef.current.push(
        path.addListener('set_at', onEdit),
        path.addListener('insert_at', onEdit),
        path.addListener('remove_at', onEdit),
      );
    },
    [onEdit],
  );

  // Clean up refs
  const onUnmount2 = useCallback(() => {
    listenersRef.current.forEach(lis => lis.remove());
    polygonRef.current = null;
  }, []);

  return isLoaded ? (
    <div
      style={{
        display: 'flex',
        width: 'auto',
        marginLeft: 10,
        marginRight: 10,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={{lat: clat, lng: clng}}
        zoom={zoom}
        onLoad={onLoad}
        onUnmount={onUnmount}
        ref={mapRef}
      >
        <div
          style={{
            position: 'absolute',
            display: 'flex',
            top: isVerySmall || isSmall ? 50 : 50,
            left: 0,
            zIndex: 2,
            marginLeft: isVerySmall ? 0 : 10,
            marginRight: isVerySmall ? 0 : '0%',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              width: isVerySmall || isSmall ? 'auto' : 250,
              justifyContent: 'space-between',
              alignItems: 'center',
              backgroundColor: '#FFFFFF',
              marginTop: 10,
              paddingTop: 10,
              paddingBottom: 10,
              paddingLeft: 10,
              paddingRight: 10,
              borderRadius: 10,
            }}
          >
            <Button
              style={{color: '#FFFFFF'}}
              onClick={() => setIsDistance(true)}
              variant="contained"
              size={'small'}
              color={isDistance ? 'primary' : 'secondary'}
            >
              Distância
            </Button>
            <Button
              style={{color: '#FFFFFF'}}
              onClick={() => setIsDistance(false)}
              variant="contained"
              size={'small'}
              color={!isDistance ? 'primary' : 'secondary'}
            >
              Personalido
            </Button>
          </div>
        </div>
        {isDistance ? (
          <div
            style={{
              position: 'absolute',
              display: 'flex',
              bottom: 0,
              left: 0,
              right: 0,
              zIndex: 2,
              marginLeft: '20%',
              marginRight: '20%',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <div
              style={{
                display: 'flex',
                width: '80%',
                justifyContent: 'space-between',
                alignItems: 'center',
                backgroundColor: '#FFFFFF',
                marginBottom: 10,
                paddingTop: 10,
                paddingBottom: 10,
                paddingLeft: 10,
                paddingRight: 10,
                borderRadius: 10,
              }}
            >
              <Slider
                style={{marginLeft: 10, marginRight: 10}}
                size={'small'}
                aria-label="Small steps"
                defaultValue={1}
                step={1}
                min={1}
                max={30}
                valueLabelDisplay="auto"
                value={raio}
                onChange={handleChange}
                marks={isVerySmall ? littlemarks : marks}
              />
            </div>
          </div>
        ) : (
          <div
            style={{
              position: 'absolute',
              display: 'flex',
              bottom: 0,
              left: 0,
              right: 0,
              zIndex: 2,
              marginLeft: '20%',
              marginRight: '20%',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: 10,
                paddingTop: 10,
                paddingBottom: 10,
                paddingLeft: 10,
                paddingRight: 10,
                borderRadius: 10,
              }}
            >
              <Button
                style={{color: '#FFFFFF'}}
                onClick={() => resetPath()}
                variant="contained"
                size={'small'}
                color={'primary'}
              >
                Resetar área
              </Button>
            </div>
          </div>
        )}
        <Marker
          icon={{
            // eslint-disable-next-line no-undef
            path: google.maps.SymbolPath.CIRCLE,
            scale: 5,
          }}
          position={{lat: clat, lng: clng}}
        />
        <Polygon
          // Make the Polygon editable / draggable
          editable={isDistance ? false : true}
          draggable={false}
          options={{
            strokeOpacity: 1,
            strokeColor: '#06448e',
            fillColor: '#06447e',
          }}
          path={isDistance ? circlePath : path}
          // Event used when manipulating and adding points
          onMouseUp={onEdit}
          // Event used when dragging the whole Polygon
          onDragEnd={onEdit}
          onLoad={onLoad2}
          onUnmount={onUnmount2}
        />

        <></>
      </GoogleMap>
    </div>
  ) : (
    <div style={{width: '100%', height: '500px'}}></div>
  );
};

export default ContentMap;
