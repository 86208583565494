/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, {useState, useLayoutEffect, useEffect} from 'react';
import {useMediaQuery} from 'react-responsive';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import {makeStyles} from '@material-ui/core/styles';

import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import StopIcon from '@material-ui/icons/Stop';
import {useDropzone} from 'react-dropzone';
import ImageIcon from '@material-ui/icons/Image';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';
import {useSelector} from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {withStyles} from '@material-ui/core/styles';
import {format} from '../../../configuration/format';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles(theme => ({
  formControl: {
    marginLeft: 10,
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const GreenCheckbox = withStyles({
  root: {
    color: '#C4C4C4',
    '&$checked': {
      color: '#06448E',
    },
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />);

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 0,
};

const thumb = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 90,
  height: 90,
  padding: 0,
  boxSizing: 'border-box',
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden',
};

const img = {
  display: 'block',
  width: 'auto',
  height: 90,
};

// eslint-disable-next-line react/prop-types
const Detalhes = ({
  cNome,
  setCnome,
  cDesc,
  setcDesc,
  cPdv,
  setcPdv,
  cat,
  setCat,
  cvlr,
  setCvlr,
  ccpt,
  setCcpt,
  isAvaliable,
  setAvailable,
  files,
  setFiles,
  cbpeso,
  setCbpeso,
  cIntPeso,
  setCintPeso,
  fDetails,
  setFdetails,
  bimgReal,
  setbimgReal,
  setIsSeding,
}) => {
  const isSmall = useMediaQuery({query: '(max-width: 750.98px)'});
  const isVerySmall = useMediaQuery({query: '(max-width:450.00px)'});
  const [isLoading, setLoading] = useState(true);
  const classes = useStyles();
  const [categorias, setCategorias] = useState([]);

  const handleChange = event => {
    setCat(event.target.value);
  };
  function handlyvalue(value) {
    setCvlr(value);
  }

  function handleSwitchChange() {
    setCbpeso(!cbpeso);
  }

  function handleImgRealChange() {
    setbimgReal(!bimgReal);
  }

  function handlyintPeso(value) {
    setCintPeso(value);
  }

  const cats = useSelector(state => state.setcatalogo.catalogo);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
      setCategorias(cats);
    }, 1000);
  }, []);

  const {getRootProps, getInputProps} = useDropzone({
    maxFiles: 1,
    accept: 'image/*',
    onDrop: acceptedFiles => {
      setFiles(
        acceptedFiles.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ),
      );
    },
  });

  const thumbs = files.map(file => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        {files.length > 0 ? (
          <img src={file.preview} style={img} />
        ) : (
          <img src={file.preview + '?t=' + new Date().getTime()} style={img} />
        )}
      </div>
    </div>
  ));
  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach(file => URL.revokeObjectURL(file.preview));
    },
    [files],
  );

  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  }

  const [width, height] = useWindowSize();

  return (
    <main>
      {isLoading ? (
        <div
          style={{
            display: 'flex',
            width: isVerySmall ? '45%' : '100%',
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          className={classes.root}
        >
          <CircularProgress />
        </div>
      ) : (
        <div style={{}}>
          {isSmall ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: isVerySmall
                  ? width * 0.73
                  : isSmall
                  ? width * 0.675
                  : width,
                justifyContent: 'center',
                alignItems: 'center',
                marginLeft: 0,
                marginTop: 10,
              }}
            >
              <div
                style={{
                  width: 90,
                  height: 90,
                  backgroundColor: '#F4F4F4',
                  alignItems: 'center',
                  justifyContent: 'center',
                  display: 'flex',
                  borderWidth: files.length === 0 ? 2 : 0,
                  borderStyle: 'dashed',
                  borderColor: '#d4d4d4',
                }}
              >
                <section
                  style={{
                    width: '100%',
                    height: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'inline-block',
                    position: 'relative',
                  }}
                >
                  <div>
                    <IconButton
                      onClick={() => setFiles([])}
                      color="primary"
                      aria-label="upload picture"
                      component="span"
                      size={'small'}
                      style={{
                        position: 'absolute',
                        top: -15,
                        right: -15,
                        zIndex: 4,
                      }}
                    >
                      <CancelIcon />
                    </IconButton>
                  </div>
                  <div {...getRootProps({className: 'dropzone'})}>
                    <input {...getInputProps()} />
                    {files.length === 0 ? (
                      <div
                        style={{
                          alignItems: 'center',
                          justifyContent: 'center',
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <ImageIcon
                          style={{color: '#c4c4c4', paddingTop: 13}}
                          size={'small'}
                        />
                        <p
                          style={{
                            fontSize: 10,
                            textAlign: 'center',
                            padding: 5,
                            paddingTop: 0,
                            paddingBottom: 0,
                            color: '#c4c4c4',
                          }}
                        >
                          Arraste uma imagem aqui
                        </p>
                      </div>
                    ) : (
                      <aside style={thumbsContainer}>{thumbs}</aside>
                    )}
                  </div>
                </section>
              </div>
              <div>
                <Button
                  {...getRootProps()}
                  disableTypography={true}
                  style={{fontSize: 10, textAlign: 'center', marginTop: 10}}
                  variant="contained"
                  color="primary"
                  component="span"
                  size={'small'}
                >
                  Escolher foto
                </Button>
                <div
                  style={{
                    marginTop: 15,
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                  }}
                >
                  {isAvaliable ? (
                    <Button
                      onClick={() => setAvailable(false)}
                      size={'small'}
                      variant="outlined"
                      color="primary"
                      style={{width: 100, fontSize: 12}}
                    >
                      <StopIcon fontSize="small" />
                      Pausar vendas
                    </Button>
                  ) : (
                    <Button
                      onClick={() => setAvailable(true)}
                      size={'small'}
                      variant="outlined"
                      color="secondary"
                      style={{width: 100, fontSize: 12}}
                    >
                      <PlayArrowIcon fontSize="small" />
                      Vendas Pausadas
                    </Button>
                  )}
                </div>
              </div>
              <div style={{marginLeft: 0, width: '90%'}}>
                <div>
                  <TextField
                    style={{width: '100%', marginTop: 10}}
                    inputProps={{
                      style: {fontSize: 14},
                      maxLength: 150,
                    }}
                    InputLabelProps={{style: {fontSize: 14}}}
                    id="nome"
                    label="Nome"
                    defaultValue={cNome}
                    value={cNome}
                    onChange={event => {
                      setCnome(event.target.value);
                    }}
                  />
                  <FormControl
                    style={{marginTop: 10, marginLeft: 0, width: '100%'}}
                    className={classes.formControl}
                  >
                    <InputLabel
                      style={{fontSize: 14}}
                      id="demo-simple-select-label"
                    >
                      Categoria
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={cat}
                      onChange={handleChange}
                      style={{marginTop: 14, width: '100%'}}
                    >
                      {(Array.isArray(categorias)
                        ? categorias.map(item => [item])
                        : Object.entries(categorias)
                      ).map(item => {
                        return (
                          <MenuItem
                            style={{fontSize: 14}}
                            key={item[0]._id}
                            value={item[0]}
                          >
                            {item[0].title}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </div>
                <TextField
                  multiline={true}
                  size={'small'}
                  style={{width: '100%', marginTop: 10}}
                  id="nome"
                  label="Descrição"
                  inputProps={{style: {fontSize: 14}, maxLength: 1000}}
                  InputLabelProps={{style: {fontSize: 14}}}
                  defaultValue={cDesc}
                  value={cDesc}
                  onChange={event => {
                    setcDesc(event.target.value);
                  }}
                />
                <div style={{marginTop: 20}}>
                  <CurrencyTextField
                    style={{marginRight: 10, width: '100%'}}
                    inputProps={{style: {fontSize: 14}}}
                    InputLabelProps={{style: {fontSize: 14}}}
                    label="Preço"
                    variant="standard"
                    value={cvlr}
                    currencySymbol="R$"
                    minimumValue="0"
                    outputFormat="string"
                    decimalCharacter=","
                    digitGroupSeparator="."
                    onChange={(event, value) => handlyvalue(value)}
                  />
                  <TextField
                    type={'number'}
                    style={{width: '100%', marginTop: 10}}
                    inputProps={{
                      style: {fontSize: 14},
                      maxLength: 150,
                    }}
                    InputLabelProps={{style: {fontSize: 14}}}
                    id="cpt"
                    label="Serve quantas pessoas?"
                    defaultValue={ccpt}
                    value={ccpt}
                    onChange={event => {
                      setCcpt(event.target.value);
                    }}
                  />
                  <TextField
                    style={{marginTop: 10, width: '100%'}}
                    inputProps={{style: {fontSize: 14}, maxLength: 20}}
                    InputLabelProps={{style: {fontSize: 14}}}
                    id="pdv"
                    label="Código PDV"
                    defaultValue={cPdv}
                    value={cPdv}
                    onChange={event => {
                      setcPdv(event.target.value);
                    }}
                  />
                  <div style={{marginTop: 10}}>
                    {files.length === 0 ? null : (
                      <FormControlLabel
                        control={
                          <GreenCheckbox
                            onChange={handleImgRealChange}
                            checked={bimgReal}
                            name="minPromo"
                          />
                        }
                        style={{color: '#393939'}}
                        label={
                          <p
                            style={{
                              fontSize: 14,
                              color: '#393939',
                            }}
                          >
                            Imagem real
                          </p>
                        }
                      />
                    )}

                    <FormControlLabel
                      control={
                        <GreenCheckbox
                          onChange={handleSwitchChange}
                          checked={cbpeso}
                          name="minPromo"
                        />
                      }
                      style={{color: '#393939'}}
                      label={
                        <p
                          style={{
                            fontSize: 14,
                            color: '#393939',
                          }}
                        >
                          Item por peso
                        </p>
                      }
                    />
                    {cbpeso ? (
                      <div>
                        <CurrencyTextField
                          style={{marginRight: 10, width: 100}}
                          inputProps={{style: {fontSize: 14}}}
                          InputLabelProps={{style: {fontSize: 14}}}
                          decimalPlaces={0}
                          label="Gramas"
                          variant="standard"
                          value={cIntPeso}
                          currencySymbol="g"
                          minimumValue="0"
                          outputFormat="string"
                          decimalCharacter=","
                          digitGroupSeparator="."
                          onChange={(event, value) => handlyintPeso(value)}
                        />
                        <p style={{fontSize: 12, color: '#999999'}}>
                          *O cliente adicionará em {cIntPeso} em {cIntPeso}{' '}
                          gramas na loja. O preço de {cIntPeso} gramas será de
                          {' R$ ' + format(cvlr)}
                        </p>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div style={{display: 'flex', flexDirection: 'row'}}>
              <div>
                <div
                  style={{
                    width: 90,
                    height: 90,
                    backgroundColor: '#F4F4F4',
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                    borderWidth: files.length === 0 ? 2 : 0,
                    borderStyle: 'dashed',
                    borderColor: '#d4d4d4',
                  }}
                >
                  <section
                    style={{
                      width: '100%',
                      height: '100%',
                      alignItems: 'center',
                      justifyContent: 'center',
                      display: 'inline-block',
                      position: 'relative',
                    }}
                  >
                    <div>
                      <IconButton
                        onClick={() => setFiles([])}
                        color="primary"
                        aria-label="upload picture"
                        component="span"
                        size={'small'}
                        style={{
                          position: 'absolute',
                          top: -15,
                          right: -15,
                          zIndex: 4,
                        }}
                      >
                        <CancelIcon />
                      </IconButton>
                    </div>
                    <div {...getRootProps({className: 'dropzone'})}>
                      <input {...getInputProps()} />
                      {files.length === 0 ? (
                        <div
                          style={{
                            alignItems: 'center',
                            justifyContent: 'center',
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                        >
                          <ImageIcon
                            style={{color: '#c4c4c4', paddingTop: 13}}
                            size={'small'}
                          />
                          <p
                            style={{
                              fontSize: 10,
                              textAlign: 'center',
                              padding: 5,
                              paddingTop: 0,
                              paddingBottom: 0,
                              color: '#c4c4c4',
                            }}
                          >
                            Arraste uma imagem aqui
                          </p>
                        </div>
                      ) : (
                        <aside style={thumbsContainer}>{thumbs}</aside>
                      )}
                    </div>
                  </section>
                </div>
                <Button
                  {...getRootProps()}
                  disableTypography={true}
                  style={{fontSize: 10, textAlign: 'center', marginTop: 10}}
                  variant="contained"
                  color="primary"
                  component="span"
                  size={'small'}
                >
                  Escolher foto
                </Button>
                <div
                  style={{
                    marginTop: 15,
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                  }}
                >
                  {isAvaliable ? (
                    <Button
                      onClick={() => setAvailable(false)}
                      size={'small'}
                      variant="outlined"
                      color="primary"
                      style={{width: 100, fontSize: 12}}
                    >
                      <StopIcon fontSize="small" />
                      Pausar vendas
                    </Button>
                  ) : (
                    <Button
                      onClick={() => setAvailable(true)}
                      size={'small'}
                      variant="outlined"
                      color="secondary"
                      style={{width: 100, fontSize: 12}}
                    >
                      <PlayArrowIcon fontSize="small" />
                      Vendas Pausadas
                    </Button>
                  )}
                </div>
              </div>
              <div style={{marginLeft: 20}}>
                <div>
                  <TextField
                    inputProps={{style: {fontSize: 14}, maxLength: 150}}
                    InputLabelProps={{style: {fontSize: 14}}}
                    id="nome"
                    label="Nome"
                    defaultValue={cNome}
                    value={cNome}
                    onChange={event => {
                      setCnome(event.target.value);
                    }}
                  />
                  <FormControl className={classes.formControl}>
                    <InputLabel
                      style={{fontSize: 14}}
                      id="demo-simple-select-label"
                    >
                      Categoria
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={cat}
                      onChange={handleChange}
                      style={{marginTop: 14, maxWidth: 130}}
                    >
                      {(Array.isArray(categorias)
                        ? categorias.map(item => [item])
                        : Object.entries(categorias)
                      ).map(item => {
                        return (
                          <MenuItem
                            style={{fontSize: 14}}
                            key={item[0]._id}
                            value={item[0]}
                          >
                            {item[0].title}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </div>
                <TextField
                  multiline={true}
                  size={'small'}
                  style={{width: '100%', marginTop: 10}}
                  id="nome"
                  label="Descrição"
                  inputProps={{style: {fontSize: 14}, maxLength: 1000}}
                  InputLabelProps={{style: {fontSize: 14}}}
                  defaultValue={cDesc}
                  value={cDesc}
                  onChange={event => {
                    setcDesc(event.target.value);
                  }}
                />
                <div style={{marginTop: 10}}>
                  <CurrencyTextField
                    style={{marginRight: 10, width: 100}}
                    inputProps={{style: {fontSize: 14}}}
                    InputLabelProps={{style: {fontSize: 14}}}
                    label="Preço"
                    variant="standard"
                    value={cvlr}
                    currencySymbol="R$"
                    minimumValue="0"
                    outputFormat="string"
                    decimalCharacter=","
                    digitGroupSeparator="."
                    onChange={(event, value) => handlyvalue(value)}
                  />
                  <TextField
                    type={'number'}
                    style={{width: '60%', marginTop: 0}}
                    inputProps={{
                      style: {fontSize: 14},
                      maxLength: 150,
                    }}
                    InputLabelProps={{style: {fontSize: 14}}}
                    id="cpt"
                    label="Serve quantas pessoas?"
                    defaultValue={ccpt}
                    value={ccpt}
                    onChange={event => {
                      setCcpt(event.target.value);
                    }}
                  />
                  <div>
                    <TextField
                      inputProps={{style: {fontSize: 14}, maxLength: 20}}
                      InputLabelProps={{style: {fontSize: 14}}}
                      id="pdv"
                      label="Código PDV"
                      defaultValue={cPdv}
                      value={cPdv}
                      onChange={event => {
                        setcPdv(event.target.value);
                      }}
                    />
                  </div>

                  <div style={{marginTop: 10}}>
                    {files.length === 0 ? null : (
                      <FormControlLabel
                        control={
                          <GreenCheckbox
                            onChange={handleImgRealChange}
                            checked={bimgReal}
                            name="minPromo"
                          />
                        }
                        style={{color: '#393939'}}
                        label={
                          <p
                            style={{
                              fontSize: 14,
                              color: '#393939',
                            }}
                          >
                            Imagem real
                          </p>
                        }
                      />
                    )}

                    <FormControlLabel
                      control={
                        <GreenCheckbox
                          onChange={handleSwitchChange}
                          checked={cbpeso}
                          name="minPromo"
                        />
                      }
                      style={{color: '#393939'}}
                      label={
                        <p
                          style={{
                            fontSize: 14,
                            color: '#393939',
                          }}
                        >
                          Item por peso
                        </p>
                      }
                    />
                    {cbpeso ? (
                      <div>
                        <CurrencyTextField
                          style={{marginRight: 10, width: 100}}
                          inputProps={{style: {fontSize: 14}}}
                          InputLabelProps={{style: {fontSize: 14}}}
                          decimalPlaces={0}
                          label="Gramas"
                          variant="standard"
                          value={cIntPeso}
                          currencySymbol="g"
                          minimumValue="0"
                          outputFormat="string"
                          decimalCharacter=","
                          digitGroupSeparator="."
                          onChange={(event, value) => handlyintPeso(value)}
                        />
                        <p style={{fontSize: 12, color: '#999999'}}>
                          *O cliente adicionará em {cIntPeso} em {cIntPeso}{' '}
                          gramas na loja. O preço de {cIntPeso} gramas será de
                          {' R$ ' + format(cvlr)}
                        </p>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </main>
  );
};

export default Detalhes;
