/* eslint-disable no-unused-vars */
import React, {useLayoutEffect, useState, useEffect} from 'react';
import {useMediaQuery} from 'react-responsive';
import {makeStyles} from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import CircularProgress from '@material-ui/core/CircularProgress';
import logo from '../../assets/logo.png';
import {Box, Button, TextField} from '@material-ui/core';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import AlertEnter from '../../components/AlertEnter';
import {useSnackbar} from 'notistack';
import {getAuth, signInWithEmailAndPassword} from 'firebase/auth';

const useStyles = makeStyles(theme => ({
  formControl: {
    marginLeft: 10,
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

// eslint-disable-next-line react/prop-types
const Login = ({handleToggleSidebar, setCurrentUser, user}) => {
  const isSmall = useMediaQuery({query: '(max-width: 750.98px)'});
  const isVerySmall = useMediaQuery({query: '(max-width:450.00px)'});
  const [isLoading, setLoading] = useState(true);
  const classes = useStyles();

  const [conta, setConta] = useState('');
  const [password, setPassword] = useState('');

  const [value, setValue] = useState();
  function handlyvalue(value) {
    setValue(value);
  }

  const [isSending, setIsSending] = useState(false);

  function enterButton() {
    if (conta === '') {
      handleNotiStack('Preencha o campo conta', 'error');
    } else if (password === '') {
      handleNotiStack('Preencha o campo senha', 'error');
    } else if (password.length < 6) {
      handleNotiStack('Senha deve ter no minímo 6 caracteres', 'error');
    } else {
      setIsSending(true);
      const auth = getAuth();
      signInWithEmailAndPassword(auth, conta, password)
        .then(userCredential => {
          // Signed in
          const user = userCredential.user;
          setCurrentUser(user);
          setIsSending(false);
          // ...
        })
        .catch(error => {
          setIsSending(false);
          const errorCode = error.code;
          const errorMessage = error.message;
          if (errorMessage === 'Firebase: Error (auth/wrong-password).') {
            handleNotiStack('Senha ou conta incorreto', 'error');
          }
          handleNotiStack(
            'Algo deu errado, tente novamente mais tarde',
            'error',
          );
          console.log('USER: ' + errorMessage);
        });
    }
  }

  useEffect(() => {
    setTimeout(() => {
      if (user) {
        setLoading(false);
      }
    }, 1000);
  }, [user]);

  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    // Button is displayed after scrolling for 500 pixels
    const toggleVisibility = () => {
      if (window.pageYOffset > 50) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', toggleVisibility);

    return () => window.removeEventListener('scroll', toggleVisibility);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  }
  const [width, height] = useWindowSize();

  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const handleNotiStack = (txt, variant) => {
    enqueueSnackbar(txt, {variant});
  };

  return (
    <div>
      {isLoading ? (
        <div
          style={{
            width: width,
            height: height,
            display: 'flex',
            background: '#06448e',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            flex: 1,
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <img style={{width: 'auto', height: 80}} src={logo} alt="Logo" />
            <CircularProgress style={{color: '#FFFFFF', marginTop: 20}} />
          </div>
        </div>
      ) : (
        <div>
          {isVisible ? (
            <Fab
              onClick={() => scrollToTop()}
              style={{position: 'fixed', bottom: 20, right: 20, zIndex: 2}}
              size={'small'}
              color="primary"
              aria-label="add"
            >
              <ArrowUpwardIcon />
            </Fab>
          ) : null}
        </div>
      )}
      {isLoading ? null : (
        <div
          style={{
            backgroundColor: '#06448e',
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            width: width,
            height: height,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <img
            style={{
              width: 'auto',
              height: 80,
              borderWidth: 3,
              borderColor: '#FFFFFF',
              marginBottom: 10,
            }}
            src={logo}
            alt="Logo"
          />
          <Box
            style={{
              backgroundColor: '#FFFFFF',
              borderRadius: 5,
              width: isVerySmall || isVerySmall ? '90%' : '30%',
            }}
            boxShadow={3}
          >
            <div
              style={{
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <p style={{fontSize: 20}}>Painel parceiros Irê it</p>
              <TextField
                style={{
                  marginTop: 10,
                  width: '80%',
                  marginBottom: 10,
                  paddingLeft: 10,
                  paddingRight: 10,
                }}
                inputProps={{
                  style: {fontSize: 14, padding: 6, color: '#393939'},
                  maxLength: 100,
                }}
                InputLabelProps={{
                  style: {fontSize: 14, marginTop: -8, color: '#797979'},
                }}
                id="email"
                variant="outlined"
                label="Conta"
                defaultValue={conta}
                value={conta}
                onChange={event => {
                  setConta(event.target.value);
                }}
              />
              <TextField
                type={'password'}
                style={{
                  marginTop: 10,
                  width: '80%',
                  marginBottom: 10,
                  paddingLeft: 10,
                  paddingRight: 10,
                }}
                inputProps={{
                  style: {fontSize: 14, padding: 6, color: '#393939'},
                  maxLength: 100,
                }}
                InputLabelProps={{
                  style: {fontSize: 14, marginTop: -8, color: '#797979'},
                }}
                id="email"
                variant="outlined"
                label="Senha"
                defaultValue={password}
                value={password}
                onChange={event => {
                  setPassword(event.target.value);
                }}
              />
              <Button
                onClick={() => enterButton()}
                style={{marginLeft: 10, marginTop: 10}}
                variant="contained"
                size={'medium'}
                color="primary"
              >
                Entrar
              </Button>
              <p
                style={{
                  fontSize: 12,
                  color: '#999999',
                  marginTop: 20,
                  marginBottom: 0,
                }}
              >
                Precisa de ajuda? Entre em contato
              </p>
              <Button
                variant={'text'}
                href={
                  'https://wa.me/557499999859?text=Estou%20com%20problema%20para%20acessar%20minha%20conta'
                }
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <WhatsAppIcon
                  style={{
                    color: '#ff6600',
                    marginTop: -14,
                    padding: 0,
                    fontSize: 20,
                    marginRight: 2,
                  }}
                />
                <p style={{color: '#FF6600', fontWeight: 'bold', marginTop: 0}}>
                  (74) 99999-9999
                </p>
              </Button>
            </div>
          </Box>
        </div>
      )}

      <AlertEnter
        bopen={isSending}
        handleClose={() => setIsSending(false)}
        handleNotiStack
        style={{
          top: 0,
          position: 'absolute',
          zIndex: 3,
        }}
      />
    </div>
  );
};

export default Login;
