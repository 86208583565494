const INITIAL_STATE = {
  resume: {},
};

export default function setresume(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'SET_RESUME':
      return {
        ...state,
        resume: action.resume,
      };
    default:
      return state;
  }
}
