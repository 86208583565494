/* eslint-disable no-unused-vars */
import React, {useLayoutEffect, useState, useEffect} from 'react';
import {useMediaQuery} from 'react-responsive';
import {makeStyles} from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import {FaBars} from 'react-icons/fa';
import CircularProgress from '@material-ui/core/CircularProgress';
import {Box} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import {withStyles} from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import DateFnsUtils from '@date-io/date-fns';
import {useSelector} from 'react-redux';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import 'date-fns';
import deLocale from 'date-fns/locale/pt';
import {produce} from 'immer';
import TextField from '@material-ui/core/TextField';
import AlertSending from '../../components/AlertSeding';
import * as ActionStore from '../../store/actions/setRestaurante';
import {useDispatch} from 'react-redux';
import {getAuth} from 'firebase/auth';

const useStyles = makeStyles(theme => ({
  formControl: {
    marginLeft: 10,
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const GreenCheckbox = withStyles({
  root: {
    color: '#C4C4C4',
    '&$checked': {
      color: '#06448E',
    },
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />);

const ListWeek = ({
  DATA,
  isVerySmall,
  updateStatus,
  update2t,
  handleDateChange,
  handleDateChange2,
  handleDateChange3,
  handleDateChange4,
}) => {
  const classes = useStyles();
  let list = DATA.essm.map((item, index) => {
    return (
      <div
        key={'listweek' + index.toString()}
        style={{
          width: isVerySmall ? '100%' : 210,
          marginLeft: 10,
          marginRight: 10,
          marginTop: 20,
        }}
      >
        <Box
          boxShadow={3}
          style={{
            paddingLeft: 10,
            paddingRight: 10,
            borderRadius: 10,
            paddingBottom: 5,
            paddingTop: 5,
            backgroundColor: item.bopen ? '#ffffff' : '#ffffff',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <p style={{fontWeight: 'bold'}}>{item.day}</p>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <GreenCheckbox
                onChange={() =>
                  updateStatus(index, !item.bopen, DATA.essm, 'sm')
                }
                checked={item.bopen}
                name={item.name}
              />
              <p style={{marginLeft: 0}}>Aberto</p>
            </div>
          </div>
          {item.bopen ? (
            <div style={{}}>
              <p style={{marginBottom: 0, marginTop: 0, fontWeight: 'bold'}}>
                Espera padrão
              </p>
              <div
                style={{
                  backgroundColor: '#f4f4f4',
                  marginBottom: 10,
                  borderRadius: 3,
                  padding: 5,
                }}
              >
                <MuiPickersUtilsProvider locale={deLocale} utils={DateFnsUtils}>
                  <div style={{display: 'flex'}}>
                    <TextField
                      type={'number'}
                      style={{marginTop: 20, width: '50%', marginBottom: 10}}
                      inputProps={{
                        style: {fontSize: 14, padding: 6, color: '#797979'},
                        maxLength: 10,
                      }}
                      InputLabelProps={{
                        style: {
                          fontSize: 14,
                          marginTop: -8,
                          color: '#797979',
                        },
                      }}
                      variant="outlined"
                      label="Min."
                      defaultValue={item.hr1a}
                      value={item.hr1a}
                      onChange={e =>
                        handleDateChange(e.target.value, index, 'sm')
                      }
                    />
                    <TextField
                      type={'number'}
                      style={{
                        marginTop: 20,
                        width: '50%',
                        marginBottom: 10,
                        marginLeft: 10,
                      }}
                      inputProps={{
                        style: {fontSize: 14, padding: 6, color: '#797979'},
                        maxLength: 10,
                      }}
                      InputLabelProps={{
                        style: {
                          fontSize: 14,
                          marginTop: -8,
                          color: '#797979',
                        },
                      }}
                      variant="outlined"
                      label="Max."
                      defaultValue={item.hr1f}
                      value={item.hr1f}
                      onChange={e =>
                        handleDateChange2(e.target.value, index, 'sm')
                      }
                    />
                  </div>
                </MuiPickersUtilsProvider>
                <p style={{marginBottom: 0, marginTop: 0, fontWeight: 'bold'}}>
                  * Em minutos
                </p>
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: 0,
                  marginTop: 0,
                }}
              >
                <p style={{marginBottom: 0, marginTop: 0, fontWeight: 'bold'}}>
                  Em alta demanda
                </p>
                <GreenCheckbox
                  onChange={() => update2t(index, !item.b2t, DATA.essm, 'sm')}
                  checked={item.b2t}
                  name={item.name}
                />
              </div>
              {item.b2t ? (
                <div
                  style={{
                    backgroundColor: '#f4f4f4',
                    marginBottom: 10,
                    borderRadius: 3,
                    padding: 5,
                  }}
                >
                  <MuiPickersUtilsProvider
                    locale={deLocale}
                    utils={DateFnsUtils}
                  >
                    <div style={{display: 'flex'}}>
                      <TextField
                        type={'number'}
                        style={{marginTop: 20, width: '50%', marginBottom: 10}}
                        inputProps={{
                          style: {fontSize: 14, padding: 6, color: '#797979'},
                          maxLength: 10,
                        }}
                        InputLabelProps={{
                          style: {
                            fontSize: 14,
                            marginTop: -8,
                            color: '#797979',
                          },
                        }}
                        variant="outlined"
                        label="Min."
                        defaultValue={item.hr2a}
                        value={item.hr2a}
                        onChange={e =>
                          handleDateChange3(e.target.value, index, 'sm')
                        }
                      />
                      <TextField
                        type={'number'}
                        style={{
                          marginTop: 20,
                          width: '50%',
                          marginBottom: 10,
                          marginLeft: 10,
                        }}
                        inputProps={{
                          style: {fontSize: 14, padding: 6, color: '#797979'},
                          maxLength: 10,
                        }}
                        InputLabelProps={{
                          style: {
                            fontSize: 14,
                            marginTop: -8,
                            color: '#797979',
                          },
                        }}
                        variant="outlined"
                        label="Max."
                        defaultValue={item.hr2f}
                        value={item.hr2f}
                        onChange={e =>
                          handleDateChange4(e.target.value, index, 'sm')
                        }
                      />
                    </div>
                  </MuiPickersUtilsProvider>
                  <p
                    style={{marginBottom: 0, marginTop: 0, fontWeight: 'bold'}}
                  >
                    * Em minutos
                  </p>
                </div>
              ) : null}
            </div>
          ) : (
            <div
              style={{
                backgroundColor: '#FFE7E7',
                paddingLeft: 5,
                paddingTop: 1,
                paddingBottom: 1,
                borderRadius: 3,
              }}
            >
              <p style={{color: '#FF0000', textAlign: 'center'}}>Fechado</p>
            </div>
          )}
        </Box>
      </div>
    );
  });

  return list;
};

const ListWeekend = ({
  DATA,
  isVerySmall,
  updateStatus,
  update2t,
  handleDateChange,
  handleDateChange2,
  handleDateChange3,
  handleDateChange4,
}) => {
  const classes = useStyles();
  let list = DATA.esfds.map((item, index) => {
    return (
      <div
        key={'listweekend' + index.toString()}
        style={{
          width: isVerySmall ? '100%' : 270,
          marginLeft: 15,
          marginRight: 15,
          marginTop: 20,
        }}
      >
        <Box
          boxShadow={3}
          style={{
            paddingLeft: 10,
            paddingRight: 10,
            borderRadius: 10,
            paddingBottom: 5,
            paddingTop: 5,
            backgroundColor: item.bopen ? '#ffffff' : '#FFFFFF',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <p style={{fontWeight: 'bold'}}>{item.day}</p>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <GreenCheckbox
                onChange={() =>
                  updateStatus(index, !item.bopen, DATA.esfds, 'fds')
                }
                checked={item.bopen}
                name={item.name}
              />
              <p style={{marginLeft: 0}}>Aberto</p>
            </div>
          </div>
          {item.bopen ? (
            <div style={{}}>
              <p style={{marginBottom: 0, marginTop: 0, fontWeight: 'bold'}}>
                Espera padrão
              </p>
              <div
                style={{
                  backgroundColor: '#f4f4f4',
                  marginBottom: 10,
                  borderRadius: 3,
                  padding: 5,
                }}
              >
                <MuiPickersUtilsProvider locale={deLocale} utils={DateFnsUtils}>
                  <div style={{display: 'flex'}}>
                    <TextField
                      type={'number'}
                      style={{marginTop: 20, width: '50%', marginBottom: 10}}
                      inputProps={{
                        style: {fontSize: 14, padding: 6, color: '#797979'},
                        maxLength: 10,
                      }}
                      InputLabelProps={{
                        style: {
                          fontSize: 14,
                          marginTop: -8,
                          color: '#797979',
                        },
                      }}
                      variant="outlined"
                      label="Min."
                      defaultValue={item.hr1a}
                      value={item.hr1a}
                      onChange={e =>
                        handleDateChange(e.target.value, index, 'fds')
                      }
                    />
                    <TextField
                      type={'number'}
                      style={{
                        marginTop: 20,
                        width: '50%',
                        marginBottom: 10,
                        marginLeft: 10,
                      }}
                      inputProps={{
                        style: {fontSize: 14, padding: 6, color: '#797979'},
                        maxLength: 10,
                      }}
                      InputLabelProps={{
                        style: {
                          fontSize: 14,
                          marginTop: -8,
                          color: '#797979',
                        },
                      }}
                      variant="outlined"
                      label="Max."
                      defaultValue={item.hr1f}
                      value={item.hr1f}
                      onChange={e =>
                        handleDateChange2(e.target.value, index, 'fds')
                      }
                    />
                  </div>
                </MuiPickersUtilsProvider>
                <p style={{marginBottom: 0, marginTop: 0, fontWeight: 'bold'}}>
                  * Em minutos
                </p>
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: 0,
                  marginTop: 0,
                }}
              >
                <p style={{marginBottom: 0, marginTop: 0, fontWeight: 'bold'}}>
                  Em alta demanda
                </p>
                <GreenCheckbox
                  onChange={() => update2t(index, !item.b2t, DATA.esfds, 'fds')}
                  checked={item.b2t}
                  name={item.name}
                />
              </div>
              {item.b2t ? (
                <div
                  style={{
                    backgroundColor: '#f4f4f4',
                    marginBottom: 10,
                    borderRadius: 3,
                    padding: 5,
                  }}
                >
                  <MuiPickersUtilsProvider
                    locale={deLocale}
                    utils={DateFnsUtils}
                  >
                    <div style={{display: 'flex'}}>
                      <TextField
                        type={'number'}
                        style={{marginTop: 20, width: '50%', marginBottom: 10}}
                        inputProps={{
                          style: {fontSize: 14, padding: 6, color: '#797979'},
                          maxLength: 10,
                        }}
                        InputLabelProps={{
                          style: {
                            fontSize: 14,
                            marginTop: -8,
                            color: '#797979',
                          },
                        }}
                        variant="outlined"
                        label="Min."
                        defaultValue={item.hr2a}
                        value={item.hr2a}
                        onChange={e =>
                          handleDateChange3(e.target.value, index, 'fds')
                        }
                      />
                      <TextField
                        type={'number'}
                        style={{
                          marginTop: 20,
                          width: '50%',
                          marginBottom: 10,
                          marginLeft: 10,
                        }}
                        inputProps={{
                          style: {fontSize: 14, padding: 6, color: '#797979'},
                          maxLength: 10,
                        }}
                        InputLabelProps={{
                          style: {
                            fontSize: 14,
                            marginTop: -8,
                            color: '#797979',
                          },
                        }}
                        variant="outlined"
                        label="Max."
                        defaultValue={item.hr2f}
                        value={item.hr2f}
                        onChange={e =>
                          handleDateChange4(e.target.value, index, 'fds')
                        }
                      />
                    </div>
                  </MuiPickersUtilsProvider>
                  <p
                    style={{marginBottom: 0, marginTop: 0, fontWeight: 'bold'}}
                  >
                    * Em minutos
                  </p>
                </div>
              ) : null}
            </div>
          ) : (
            <div
              style={{
                backgroundColor: '#FFE7E7',
                paddingLeft: 5,
                paddingTop: 1,
                paddingBottom: 1,
                borderRadius: 3,
              }}
            >
              <p style={{color: '#FF0000', textAlign: 'center'}}>Fechado</p>
            </div>
          )}
        </Box>
      </div>
    );
  });

  return list;
};

// eslint-disable-next-line react/prop-types
const Espera = ({handleToggleSidebar, handleNotiStack, handleBackDrop}) => {
  const auth = getAuth();
  const isSmall = useMediaQuery({query: '(max-width: 750.98px)'});
  const isVerySmall = useMediaQuery({query: '(max-width:450.00px)'});
  const [isLoading, setLoading] = useState(true);
  const docid = useSelector(state => state.setrestaurante.id);
  const estado = useSelector(state => state.setrestaurante);
  const dispatch = useDispatch();
  // The first commit of Material-UI
  const [selectedDate, setSelectedDate] = React.useState(new Date());

  function handleDateChange(date, index, list) {
    if (list === 'fds') {
      setDATA(currentItem =>
        produce(currentItem, v => {
          v.esfds[index].hr1a = date;
        }),
      );
    } else {
      setDATA(currentItem =>
        produce(currentItem, v => {
          v.essm[index].hr1a = date;
        }),
      );
    }
  }

  function handleDateChange2(date, index, list) {
    if (list === 'fds') {
      setDATA(currentItem =>
        produce(currentItem, v => {
          v.esfds[index].hr1f = date.toString();
        }),
      );
    } else {
      setDATA(currentItem =>
        produce(currentItem, v => {
          v.essm[index].hr1f = date.toString();
        }),
      );
    }
  }

  function handleDateChange3(date, index, list) {
    if (list === 'fds') {
      setDATA(currentItem =>
        produce(currentItem, v => {
          v.esfds[index].hr2a = date.toString();
        }),
      );
    } else {
      setDATA(currentItem =>
        produce(currentItem, v => {
          v.essm[index].hr2a = date.toString();
        }),
      );
    }
  }

  function handleDateChange4(date, index, list) {
    if (list === 'fds') {
      setDATA(currentItem =>
        produce(currentItem, v => {
          v.esfds[index].hr2f = date.toString();
        }),
      );
    } else {
      setDATA(currentItem =>
        produce(currentItem, v => {
          v.essm[index].hr2f = date.toString();
        }),
      );
    }
  }

  const [value, setValue] = useState();
  function handlyvalue(value) {
    setValue(value);
  }

  const sm = useSelector(state => state.setrestaurante.essm);
  const fds = useSelector(state => state.setrestaurante.esfds);

  useEffect(() => {
    setDATA({...DATA, esfds: fds, essm: sm});
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [estado]);

  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    // Button is displayed after scrolling for 500 pixels
    const toggleVisibility = () => {
      if (window.pageYOffset > 50) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', toggleVisibility);

    return () => window.removeEventListener('scroll', toggleVisibility);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  }
  const [width, height] = useWindowSize();

  const [DATA, setDATA] = useState({
    essm: [
      {
        day: 'Segundas',
        bopen: true,
        hr1a: 0,
        hr1f: 0,
        b2t: true,
        hr2a: 0,
        hr2f: 0,
      },
      {
        day: 'Terças',
        bopen: false,
        hr1a: 0,
        hr1f: 0,
        b2t: true,
        hr2a: 0,
        hr2f: 0,
      },
      {
        day: 'Quartas',
        bopen: true,
        hr1a: 0,
        hr1f: 0,
        b2t: true,
        hr2a: 0,
        hr2f: 0,
      },
      {
        day: 'Quintas',
        bopen: true,
        hr1a: 0,
        hr1f: 0,
        b2t: true,
        hr2a: 0,
        hr2f: 0,
      },
    ],
    esfds: [
      {
        day: 'Sextas',
        bopen: true,
        hr1a: 0,
        hr1f: 0,
        b2t: true,
        hr2a: 0,
        hr2f: 0,
      },
      {
        day: 'Sábados',
        bopen: false,
        hr1a: 0,
        hr1f: 0,
        b2t: true,
        hr2a: 0,
        hr2f: 0,
      },
      {
        day: 'Domingos',
        bopen: true,
        hr1a: 0,
        hr1f: 0,
        b2t: true,
        hr2a: 0,
        hr2f: 0,
      },
    ],
  });

  function updateStatus(ind, value, list, sec) {
    let data = list.map((item, index) => {
      if (ind === index) {
        return {...item, bopen: value};
      } else {
        return item;
      }
    });

    if (sec === 'fds') {
      setDATA({...DATA, esfds: data});
    } else {
      setDATA({...DATA, essm: data});
    }
  }

  function update2t(ind, value, list, sec) {
    let data = list.map((item, index) => {
      if (ind === index) {
        return {...item, b2t: value};
      } else {
        return item;
      }
    });

    if (sec === 'fds') {
      setDATA({...DATA, esfds: data});
    } else {
      setDATA({...DATA, essm: data});
    }
  }

  const [isSending, setIsSending] = useState(false);
  function onSave() {
    setIsSending(true);
    let chckfds = DATA.esfds.map((item, index) => {
      if (item.bopen) {
        if (item.hr1a > item.hr1f || item.hr2a > item.hr2f) {
          if (index === 0) {
            handleNotiStack(
              'Horário minímo da Sexta maior que o máximo',
              'error',
            );
          } else if (index === 1) {
            handleNotiStack(
              'Horário minímo do Sábado maior que o máximo',
              'error',
            );
          } else if (index === 2) {
            handleNotiStack(
              'Horário minímo do Domingo maior que o máximo',
              'error',
            );
          }
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    });

    let chcksm = DATA.essm.map((item, index) => {
      if (item.bopen) {
        if (item.hr1a > item.hr1f || item.hr2a > item.hr2f) {
          if (index === 0) {
            handleNotiStack(
              'Horário minímo da Segunda maior que o máximo',
              'error',
            );
          } else if (index === 1) {
            handleNotiStack(
              'Horário minímo da Terça maior que o máximo',
              'error',
            );
          } else if (index === 2) {
            handleNotiStack(
              'Horário minímo da Quarta maior que o máximo',
              'error',
            );
          } else if (index === 3) {
            handleNotiStack(
              'Horário minímo da Quinta maior que o máximo',
              'error',
            );
          }
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    });

    let checker = arr => arr.every(v => v === true);

    if (checker(chckfds) && checker(chcksm)) {
      auth.currentUser.getIdToken().then(token => {
        const request = new Request(
          'https://kops.apiireit.com/gateway/store/update/waittime/' + docid,
          {
            method: 'PUT',
            mode: 'cors',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify([DATA.esfds, DATA.essm]),
          },
        );

        fetch(request)
          .then(response => {
            if (response.status === 200) {
              handleBackDrop('', '', 200, false);
              response
                .json()
                .then(data => {
                  setTimeout(() => {
                    setIsSending(false);
                    handleNotiStack(
                      'Informações atualizadas com sucesso',
                      'success',
                    );
                  }, 1000);
                  dispatch(
                    ActionStore.setrestaurante(
                      data._id,
                      data.nome,
                      data.dsc,
                      data.nota,
                      data.nNotas,
                      data.cat,
                      data.entrg,
                      data.rtrda,
                      data.agdnt,
                      data.espra,
                      data.dsct,
                      data.frete,
                      data.nAv,
                      data.pdmin,
                      data.entgrts,
                      data.location,
                      data.img,
                      data.stt,
                      data.catprice,
                      data.iient,
                      data.chck,
                      data.merc,
                      data.cpm,
                      data.promoent,
                      data.bapp,
                      data.bpix,
                      data.bame,
                      data.bpicpay,
                      data.bmp,
                      data.bpaypal,
                      data.bpre,
                      data.bvir,
                      data.bvref,
                      data.bd,
                      data.bvd,
                      data.bmcd,
                      data.belod,
                      data.bhipd,
                      data.bdind,
                      data.baled,
                      data.bsood,
                      data.bvrd,
                      data.bbend,
                      data.bdisd,
                      data.btrid,
                      data.bbnsd,
                      data.bbrsd,
                      data.bbrd,
                      data.bvc,
                      data.bmcc,
                      data.beloc,
                      data.bhipc,
                      data.bdinc,
                      data.balec,
                      data.bsodc,
                      data.bvrc,
                      data.bbenc,
                      data.bdisc,
                      data.btric,
                      data.bbnsc,
                      data.bbrsc,
                      data.bbrc,
                      data.end,
                      data.num,
                      data.bairro,
                      data.cid,
                      data.est,
                      data.cnpj,
                      data.bodom,
                      data.hdom,
                      data.boseg,
                      data.hseg,
                      data.boter,
                      data.hter,
                      data.boqua,
                      data.hqua,
                      data.boqui,
                      data.hqui,
                      data.bosex,
                      data.hsex,
                      data.bosab,
                      data.hsab,
                      data.aratdm,
                      data.raio,
                      data.bdstc,
                      data.essm,
                      data.esfds,
                      data.inalta,
                      data.npd,
                      data.znid,
                      data.zntx,
                      data.iitx,
                      data.iietx,
                      data.freetx,
                      data.freetill,
                      data.lastSeenAt,
                      data.presence,
                      data.openchat,
                      data.agd,
                      data.fagdays,
                      data.dtsm,
                      data.dtfds,
                      data.ctto,
                      data.aratdmii,
                      data.mxprmetrg,
                      data.bbank,
                      data.btcnta,
                      data.bagcia,
                      data.bcnta,
                      data.bdig,
                      data.cpix,
                      data.npix,
                      data.nimg,
                    ),
                  );
                })
                .catch(error => {
                  console.log(error);
                });
            } else if (response.status === 503) {
              setIsSending(false);
              setTimeout(() => {
                handleNotiStack(
                  'Não foi possível salvar as informações',
                  'error',
                );
              }, 1000);
              handleBackDrop(
                'Serviço indisponível no momento',
                'O serviços para as lojas Irê it não estão disponíveis no momento. Por favor, tente novamente mais tarde',
                503,
                true,
              );
              throw new Error('Something went wrong on API server!');
            } else if (response.status === 404) {
              setIsSending(false);
              setTimeout(() => {
                handleNotiStack(
                  'Não foi possível salvar as informações',
                  'error',
                );
              }, 1000);
              handleBackDrop(
                'Serviço não encontrado.',
                'Por favor, entre em contato com o nosso suporte e relate o problema.',
                404,
                true,
              );
            } else if (response.status === 401) {
              setIsSending(false);
              setTimeout(() => {
                handleNotiStack(
                  'Não foi possível salvar as informações',
                  'error',
                );
              }, 1000);
              handleBackDrop(
                'Você não possui autorização ou suas credenciais expirarão.',
                'Por favor, atualize a baixa ou refaça o processo dee login.',
                401,
                true,
              );
            } else if (response.status === 400) {
              setIsSending(false);
              setTimeout(() => {
                handleNotiStack(
                  'Não foi possível salvar as informações',
                  'error',
                );
              }, 1000);
              handleBackDrop(
                'Requisição inválida.',
                'Confira se todos os campos estão preenchidos corretamente.',
                400,
                true,
              );
            } else if (response.status === 500) {
              setIsSending(false);
              setTimeout(() => {
                handleNotiStack(
                  'Não foi possível salvar as informações',
                  'error',
                );
              }, 1000);
              handleBackDrop(
                'Nosso servidor está passando por irregularidades.',
                'Estamos trabalhado para resolver o mais rápido possível.',
                500,
                true,
              );
            }
          })
          .catch(error => {
            setIsSending(false);
            setTimeout(() => {
              handleNotiStack(
                'Não foi possível salvar as informações',
                'error',
              );
            }, 1000);
            handleBackDrop(
              'Nosso servidor está passando por irregularidades.',
              'Estamos trabalhado para resolver o mais rápido possível.',
              500,
              true,
            );
            console.error(error);
          });
      });
    } else {
      setIsSending(false);
      handleNotiStack(
        'Verifique se tem pelo menos um dia aberto e se alguma tempo de espera mínimo está maior que o máximo',
        'error',
      );
    }
  }

  return (
    <div>
      {isLoading ? (
        <div
          style={{
            display: 'flex',
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            height: height,
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <div>
          {isVisible ? (
            <Fab
              onClick={() => scrollToTop()}
              style={{position: 'fixed', bottom: 20, right: 20, zIndex: 2}}
              size={'small'}
              color="primary"
              aria-label="add"
            >
              <ArrowUpwardIcon />
            </Fab>
          ) : null}
          <div
            style={{marginLeft: 10, marginTop: 10}}
            className="btn-toggle"
            onClick={() => handleToggleSidebar(true)}
          >
            <FaBars />
          </div>
          {isVerySmall || isSmall ? (
            <div style={{marginLeft: 10, marginRight: 10}}>
              <h1>Tempo de espera</h1>
              <div
                style={{flexWrap: 'wrap', display: 'flex', marginBottom: 10}}
              >
                <ListWeekend
                  DATA={DATA}
                  isVerySmall={isVerySmall}
                  updateStatus={updateStatus}
                  update2t={update2t}
                  handleDateChange={handleDateChange}
                  handleDateChange2={handleDateChange2}
                  handleDateChange3={handleDateChange3}
                  handleDateChange4={handleDateChange4}
                />
              </div>
              <div
                style={{flexWrap: 'wrap', display: 'flex', marginBottom: 30}}
              >
                <ListWeek
                  DATA={DATA}
                  isVerySmall={isVerySmall}
                  updateStatus={updateStatus}
                  update2t={update2t}
                  handleDateChange={handleDateChange}
                  handleDateChange2={handleDateChange2}
                  handleDateChange3={handleDateChange3}
                  handleDateChange4={handleDateChange4}
                />
              </div>
              <Button
                onClick={() => onSave()}
                style={{marginLeft: 10, marginTop: 10, marginBottom: 40}}
                variant="contained"
                size={'medium'}
                color="primary"
              >
                Salvar
              </Button>
            </div>
          ) : (
            <div style={{marginLeft: 10, marginRight: 10}}>
              <h1>Tempo de espera</h1>
              <div
                style={{flexWrap: 'wrap', display: 'flex', marginBottom: 10}}
              >
                <ListWeekend
                  DATA={DATA}
                  isVerySmall={isVerySmall}
                  updateStatus={updateStatus}
                  update2t={update2t}
                  handleDateChange={handleDateChange}
                  handleDateChange2={handleDateChange2}
                  handleDateChange3={handleDateChange3}
                  handleDateChange4={handleDateChange4}
                />
              </div>
              <div
                style={{flexWrap: 'wrap', display: 'flex', marginBottom: 30}}
              >
                <ListWeek
                  DATA={DATA}
                  isVerySmall={isVerySmall}
                  updateStatus={updateStatus}
                  update2t={update2t}
                  handleDateChange={handleDateChange}
                  handleDateChange2={handleDateChange2}
                  handleDateChange3={handleDateChange3}
                  handleDateChange4={handleDateChange4}
                />
              </div>
              <Button
                onClick={() => onSave()}
                style={{marginLeft: 10, marginTop: 10, marginBottom: 40}}
                variant="contained"
                size={'medium'}
                color="primary"
              >
                Salvar
              </Button>
            </div>
          )}
        </div>
      )}
      <AlertSending
        bopen={isSending}
        handleClose={() => setIsSending(false)}
        handleNotiStack
        style={{
          top: 0,
          position: 'absolute',
          zIndex: 4,
        }}
      />
    </div>
  );
};

export default Espera;
