/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, {useLayoutEffect, useState, useEffect} from 'react';
import {useMediaQuery} from 'react-responsive';
import Fab from '@material-ui/core/Fab';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import {FaBars} from 'react-icons/fa';
import ContentMap from './ContentMap';
import {useSelector} from 'react-redux';
import {Box, CircularProgress, Divider} from '@material-ui/core';
import {realFormat} from '../../configuration/realFormat';
import {makeStyles} from '@material-ui/core/styles';
import {withStyles} from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {useDispatch} from 'react-redux';
import * as ActionRestaurante from '../../store/actions/setRestaurante';
import AlertSending from '../../components/AlertSeding';
import {getAuth} from 'firebase/auth';

const useStyles = makeStyles(theme => ({
  formControl: {
    marginLeft: 10,
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const PurpleSwitch = withStyles({
  switchBase: {
    color: '#d4d4d4',
    '&$checked': {
      color: '#52d869',
    },
    '&$checked + $track': {
      backgroundColor: '#52d869',
    },
  },
  checked: {},
  track: {},
})(Switch);

const AtendimentoIreit = ({
  handleToggleSidebar,
  handleBackDrop,
  handleNotiStack,
}) => {
  const auth = getAuth();
  const isSmall = useMediaQuery({query: '(max-width: 750.98px)'});
  const isVerySmall = useMediaQuery({query: '(max-width:450.00px)'});
  const [isLoading, setLoading] = useState(true);
  const docid = useSelector(state => state.setrestaurante.id);
  const [raio, setRaio] = useState(1);
  const dispatch = useDispatch();
  const [isSending, setIsSending] = useState(false);
  const [foundArea, setFoundArea] = useState(false);
  const [currentzone, setcurrentzon] = useState([]);
  const idstore = useSelector(state => state.setrestaurante.idstore);
  const handleChange = (event, newValue) => {
    setRaio(newValue);
  };
  const [entrgii, setEntrii] = useState(false);
  const handleChangeEntrgii = event => {
    setIsSending(true);
    let newstate = event.target.checked;
    var coordinates = [];
    if (!newstate) {
      coordinates.push([Number(0.0), Number(0.0)]);
      coordinates.push([Number(0.0001), Number(0.0001)]);
      coordinates.push([Number(0.0002), Number(0.00002)]);
      coordinates.push([Number(0.0), Number(0.0)]);
    } else {
      path.forEach(s => coordinates.push([Number(s.lng), Number(s.lat)]));
      coordinates.push(coordinates[0]);
    }

    auth.currentUser.getIdToken().then(token => {
      const requestprod = new Request(
        'https://kops.apiireit.com/gateway/produtos/updateall/aratdmii' +
          '?estid=' +
          idstore,
        {
          method: 'PUT',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify([coordinates]),
        },
      );

      const request = new Request(
        'https://kops.apiireit.com/gateway/store/update/useiient/' +
          docid +
          '?iient=' +
          event.target.checked,
        {
          method: 'PUT',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify([coordinates]),
        },
      );

      fetch(request)
        .then(response => {
          if (response.status === 200) {
            handleBackDrop('', '', 200, false);
            response
              .json()
              .then(data => {
                setTimeout(() => {
                  setEntrii(newstate);
                  dispatch(ActionRestaurante.setIreitEntrega(newstate));
                  setIsSending(false);
                  if (newstate) {
                    handleNotiStack('Entrega Irê it habilidata', 'success');
                  } else {
                    handleNotiStack('Entrega Irê it desabilitada', 'warning');
                  }
                }, 1000);
              })
              .catch(error => {
                console.log(error);
              });
          } else if (response.status === 503) {
            setIsSending(false);
            setTimeout(() => {
              handleNotiStack(
                'Não foi possível salvar as informações',
                'error',
              );
            }, 1000);
            handleBackDrop(
              'Serviço indisponível no momento',
              'O serviços para as lojas Irê it não estão disponíveis no momento. Por favor, tente novamente mais tarde',
              503,
              true,
            );
            throw new Error('Something went wrong on API server!');
          } else if (response.status === 404) {
            setIsSending(false);
            setTimeout(() => {
              handleNotiStack(
                'Não foi possível salvar as informações',
                'error',
              );
            }, 1000);
            handleBackDrop(
              'Serviço não encontrado.',
              'Por favor, entre em contato com o nosso suporte e relate o problema.',
              404,
              true,
            );
          } else if (response.status === 401) {
            setIsSending(false);
            setTimeout(() => {
              handleNotiStack(
                'Não foi possível salvar as informações',
                'error',
              );
            }, 1000);
            handleBackDrop(
              'Você não possui autorização ou suas credenciais expirarão.',
              'Por favor, atualize a baixa ou refaça o processo dee login.',
              401,
              true,
            );
          } else if (response.status === 400) {
            setIsSending(false);
            setTimeout(() => {
              handleNotiStack(
                'Não foi possível salvar as informações',
                'error',
              );
            }, 1000);
            handleBackDrop(
              'Requisição inválida.',
              'Confira se todos os campos estão preenchidos corretamente.',
              400,
              true,
            );
          } else if (response.status === 500) {
            setIsSending(false);
            setTimeout(() => {
              handleNotiStack(
                'Não foi possível salvar as informações',
                'error',
              );
            }, 1000);
            handleBackDrop(
              'Nosso servidor está passando por irregularidades.',
              'Estamos trabalhado para resolver o mais rápido possível.',
              500,
              true,
            );
          }
        })
        .finally(() => {
          fetch(requestprod)
            .then(response => {
              console.log(response.status);
              if (response.status === 200) {
                response.json(data => {
                  console.log(data);
                });
              }
            })
            .catch(error => {
              console.debug(error);
            });
        })
        .catch(error => {
          setIsSending(false);
          setTimeout(() => {
            handleNotiStack('Não foi possível salvar as informações', 'error');
          }, 1000);
          handleBackDrop(
            'Nosso servidor está passando por irregularidades.',
            'Estamos trabalhado para resolver o mais rápido possível.',
            500,
            true,
          );
          console.error(error);
        });
    });
  };

  const [path, setPath] = useState([]);
  const [DATA, setDATA] = useState({});

  const location = useSelector(state => state.setrestaurante.location);
  const bentrii = useSelector(state => state.setrestaurante.ireitentrega);

  useEffect(() => {
    setEntrii(bentrii);
    findCloseArea();
  }, []);

  function findCloseArea() {
    auth.currentUser.getIdToken().then(token => {
      const request = new Request(
        'https://kops.apiireit.com/gateway/preco/cat/get/entregaii/location?lat=' +
          location.coordinates[1] +
          '&lng=' +
          location.coordinates[0] +
          '&svc=' +
          false +
          '&entrgii=' +
          true,

        {
          method: 'GET',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        },
      );

      fetch(request)
        .then(response => {
          if (response.status === 200) {
            handleBackDrop('', '', 200, false);
            response
              .json()
              .then(data => {
                console.log(data[0]);
                setPath(data[0].aratdm.coordinates);
                setcurrentzon(data[0].aratdm.coordinates);
                setDATA(data[0]);
                setFoundArea(true);
                setTimeout(() => {
                  setLoading(false);
                }, 1000);
              })
              .catch(error => {
                setFoundArea(false);
                setPath([
                  [0.0, 0.0],
                  [0.0, 0.0],
                  [0.0, 0.0],
                  [0.0, 0.0],
                ]);
                setTimeout(() => {
                  setLoading(false);
                }, 1000);
                console.log(error);
              });
          } else if (response.status === 503) {
            setTimeout(() => {
              setLoading(false);
            }, 1000);
            handleBackDrop(
              'Serviço indisponível no momento',
              'O serviços para as lojas Irê it não estão disponíveis no momento. Por favor, tente novamente mais tarde',
              503,
              true,
            );
            throw new Error('Something went wrong on API server!');
          } else if (response.status === 404) {
            setTimeout(() => {
              setIsSending(false);
              setLoading(false);
            }, 1000);
            handleBackDrop(
              'Serviço não encontrado.',
              'Por favor, entre em contato com o nosso suporte e relate o problema.',
              404,
              true,
            );
          } else if (response.status === 401) {
            setTimeout(() => {
              setIsSending(false);
              setLoading(false);
            }, 1000);
            handleBackDrop(
              'Você não possui autorização ou suas credenciais expirarão.',
              'Por favor, atualize a baixa ou refaça o processo dee login.',
              401,
              true,
            );
          } else if (response.status === 400) {
            setTimeout(() => {
              setIsSending(false);
              setLoading(false);
            }, 1000);
            handleBackDrop(
              'Requisição inválida.',
              'Confira se todos os campos estão preenchidos corretamente.',
              400,
              true,
            );
          } else if (response.status === 500) {
            setTimeout(() => {
              setIsSending(false);
              setLoading(false);
            }, 1000);
            handleBackDrop(
              'Nosso servidor está passando por irregularidades.',
              'Estamos trabalhado para resolver o mais rápido possível.',
              500,
              true,
            );
          }
        })
        .catch(error => {
          setTimeout(() => {
            setIsSending(false);
            setLoading(false);
          }, 1000);
          handleBackDrop(
            'Nosso servidor está passando por irregularidades.',
            'Estamos trabalhado para resolver o mais rápido possível.',
            500,
            true,
          );
          console.error(error);
        });
    });
  }

  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    // Button is displayed after scrolling for 500 pixels
    const toggleVisibility = () => {
      if (window.pageYOffset > 50) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', toggleVisibility);

    return () => window.removeEventListener('scroll', toggleVisibility);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  }
  // eslint-disable-next-line no-unused-vars
  const [width, height] = useWindowSize();
  const [isDistance, setIsDistance] = useState(false);
  function isEmpty(obj) {
    return Object.keys(obj).length === 0;
  }

  return (
    <div>
      {isLoading ? (
        <div
          style={{
            display: 'flex',
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            height: height,
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <div>
          {isVisible ? (
            <Fab
              onClick={() => scrollToTop()}
              style={{position: 'fixed', bottom: 20, right: 20, zIndex: 2}}
              size={'small'}
              color="primary"
              aria-label="add"
            >
              <ArrowUpwardIcon />
            </Fab>
          ) : null}
          <div
            style={{marginLeft: 10, marginTop: 10}}
            className="btn-toggle"
            onClick={() => handleToggleSidebar(true)}
          >
            <FaBars />
          </div>
          <div
            style={{
              display: 'flex',
              backgroundColor: '#FFFFFF',
              width: isVerySmall || isSmall ? '98%' : 'auto',
              flex: 1,
              flexDirection: 'column',
              marginBottom: 30,
            }}
          >
            <h1 style={{marginLeft: 10, marginBottom: 0}}>
              Área de entrega Irê it
            </h1>
            {!isEmpty(DATA) ? (
              <Box
                style={{
                  paddingLeft: 10,
                  paddingRight: 10,
                  borderRadius: 5,
                  marginBottom: 20,
                  marginLeft: 10,
                  marginRight: isVerySmall ? 10 : '5%',
                  marginTop: 10,
                  background: foundArea ? '#FFFFFF' : '#FFDDDD',
                }}
                boxShadow={3}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                  }}
                >
                  {isVerySmall ? (
                    <div>
                      {foundArea ? (
                        <div>
                          <div>
                            <FormControlLabel
                              style={{}}
                              label={entrgii ? 'Ativado' : 'Desativado'}
                              control={
                                <PurpleSwitch
                                  checked={entrgii}
                                  onChange={handleChangeEntrgii}
                                  name="checked"
                                />
                              }
                            />
                          </div>
                          <div>
                            <p style={{fontWeight: 'bold', marginBottom: 0}}>
                              {DATA.nome}
                            </p>
                            <p
                              style={{
                                marginTop: 5,
                                fontSize: 12,
                                fontStyle: 'italic',
                                fontWeight: 'bold',
                              }}
                            >
                              Ao habilitar. Os clientes e o estabelecimento
                              poderão escolher ou solicitar uma entrega com a
                              Irê it. Entregas com a Irê it possuem uma taxa de
                              12% em cima do valor pedido.
                            </p>

                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                              }}
                            >
                              <p style={{width: '70%'}}>
                                Valor fixo para entregas de até {DATA.dmin} km:{' '}
                              </p>
                              <p style={{fontWeight: 'bold'}}>
                                {realFormat(DATA.prfx1)}
                              </p>
                            </div>
                            <Divider />
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                              }}
                            >
                              <p style={{width: '70%'}}>
                                Preço pelo KM em entregas acima de {DATA.dmin}{' '}
                                km:{' '}
                              </p>
                              <p style={{fontWeight: 'bold'}}>
                                {realFormat(DATA.bnd1)}
                              </p>
                            </div>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                              }}
                            >
                              <p style={{marginTop: 0, width: '70%'}}>
                                Preço dos minutos com entregas acima de{' '}
                                {DATA.dmin} km:{' '}
                              </p>
                              <p style={{fontWeight: 'bold', marginTop: 0}}>
                                {realFormat(DATA.btm1)}
                              </p>
                            </div>
                            <Divider />
                            <p style={{marginBottom: 3}}>
                              Formas de pagamento:{' '}
                            </p>
                            {DATA.bod ? (
                              <p
                                style={{
                                  marginTop: 0,
                                  marginBottom: 3,
                                  fontSize: 14,
                                  fontWeight: 'bold',
                                  marginLeft: 10,
                                }}
                              >
                                - Dinheiro
                              </p>
                            ) : null}
                            {DATA.bom ? (
                              <p
                                style={{
                                  marginTop: 0,
                                  marginBottom: 3,
                                  fontSize: 14,
                                  fontWeight: 'bold',
                                  marginLeft: 10,
                                }}
                              >
                                - Maquina de cartão de crédito e debito
                              </p>
                            ) : null}
                            {DATA.bopix ? (
                              <p
                                style={{
                                  marginTop: 0,
                                  marginBottom: 3,
                                  fontSize: 14,
                                  fontWeight: 'bold',
                                  marginLeft: 10,
                                }}
                              >
                                - Pix
                              </p>
                            ) : null}
                            {DATA.boc ? (
                              <p
                                style={{
                                  marginTop: 0,
                                  marginBottom: 3,
                                  fontSize: 14,
                                  fontWeight: 'bold',
                                  marginLeft: 10,
                                }}
                              >
                                - Pago na fatura
                              </p>
                            ) : null}
                          </div>
                          <Divider style={{marginTop: 20}} />
                        </div>
                      ) : (
                        <div>
                          <p
                            style={{
                              fontWeight: 'bold',
                              marginBottom: 0,
                              fontSize: 18,
                            }}
                          >
                            Entrega Irê it não encontrada
                          </p>
                          <p
                            style={{
                              marginTop: 5,
                              fontSize: 14,
                            }}
                          >
                            A Entrega Irê it ainda não está disponível em sua
                            região. Estamos trabalhando para que você possa
                            utilizar os sistemas de entrega da Irê it e
                            facilitar seu delivery.
                          </p>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div style={{paddingBottom: 20}}>
                      {foundArea ? (
                        <div>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                            }}
                          >
                            <div>
                              <p style={{fontWeight: 'bold', marginBottom: 0}}>
                                {DATA.nome}
                              </p>
                              <p
                                style={{
                                  marginTop: 5,
                                  fontSize: 12,
                                  fontStyle: 'italic',
                                  fontWeight: 'bold',
                                  width: '70%',
                                }}
                              >
                                Ao habilitar. Os clientes e o estabelecimento
                                poderão escolher ou solicitar uma entrega com a
                                Irê it. Entregas com a Irê it possuem uma taxa
                                de 12% em cima do valor pedido.
                              </p>
                            </div>
                            <FormControlLabel
                              style={{}}
                              label={entrgii ? 'Ativado' : 'Desativado'}
                              control={
                                <PurpleSwitch
                                  checked={entrgii}
                                  onChange={handleChangeEntrgii}
                                  name="checked"
                                />
                              }
                            />
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                            }}
                          >
                            <p style={{width: '70%'}}>
                              Valor fixo para entregas de até {DATA.dmin} km:{' '}
                            </p>
                            <p style={{fontWeight: 'bold'}}>
                              {realFormat(DATA.prfx1)}
                            </p>
                          </div>
                          <Divider />
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                            }}
                          >
                            <p style={{width: '70%'}}>
                              Preço pelo KM em entregas acima de {DATA.dmin} km:{' '}
                            </p>
                            <p style={{fontWeight: 'bold'}}>
                              {realFormat(DATA.bnd1)}
                            </p>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                            }}
                          >
                            <p style={{marginTop: 0, width: '70%'}}>
                              Preço dos minutos com entregas acima de{' '}
                              {DATA.dmin} km:{' '}
                            </p>
                            <p style={{fontWeight: 'bold', marginTop: 0}}>
                              {realFormat(DATA.btm1)}
                            </p>
                          </div>
                          <Divider />
                          <p style={{marginBottom: 3}}>Formas de pagamento: </p>
                          {DATA.bod ? (
                            <p
                              style={{
                                marginTop: 0,
                                marginBottom: 3,
                                fontSize: 14,
                                fontWeight: 'bold',
                                marginLeft: 10,
                              }}
                            >
                              - Dinheiro
                            </p>
                          ) : null}
                          {DATA.bom ? (
                            <p
                              style={{
                                marginTop: 0,
                                marginBottom: 3,
                                fontSize: 14,
                                fontWeight: 'bold',
                                marginLeft: 10,
                              }}
                            >
                              - Maquina de cartão de crédito e debito
                            </p>
                          ) : null}
                          {DATA.bopix ? (
                            <p
                              style={{
                                marginTop: 0,
                                marginBottom: 3,
                                fontSize: 14,
                                fontWeight: 'bold',
                                marginLeft: 10,
                              }}
                            >
                              - Pix
                            </p>
                          ) : null}
                          {DATA.boc ? (
                            <p
                              style={{
                                marginTop: 0,
                                marginBottom: 3,
                                fontSize: 14,
                                fontWeight: 'bold',
                                marginLeft: 10,
                              }}
                            >
                              - Pago na fatura
                            </p>
                          ) : null}
                        </div>
                      ) : (
                        <div
                          style={{
                            paddingLeft: 10,
                            paddingRight: 10,
                            borderRadius: 5,
                            marginBottom: 20,
                            marginLeft: 10,
                            marginRight: 40,
                            marginTop: 10,
                          }}
                        >
                          <p
                            style={{
                              fontWeight: 'bold',
                              marginBottom: 0,
                              fontSize: 18,
                            }}
                          >
                            Entrega Irê it não encontrada
                          </p>
                          <p
                            style={{
                              marginTop: 5,
                              fontSize: 14,
                            }}
                          >
                            A Entrega Irê it ainda não está disponível em sua
                            região. Estamos trabalhando para que você possa
                            utilizar os sistemas de entrega da Irê it e
                            facilitar seu delivery.
                          </p>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </Box>
            ) : null}
            <p
              style={{
                marginLeft: 10,
                marginBottom: 5,
                fontSize: 18,
                fontWeight: 'bold',
                marginTop: 5,
              }}
            >
              Area de entrega:
            </p>
            <div
              style={{
                display: 'flex',
                width: 'auto',
                justifyContent: 'center',
                marginRight: 10,
                marginLeft: 10,
              }}
            >
              <ContentMap
                width={width}
                isDistance={isDistance}
                setIsDistance={setIsDistance}
                style={{height: 500}}
                raio={raio}
                setRaio={setRaio}
                handleChange={handleChange}
                path={path}
                setPath={setPath}
                currentzone={currentzone}
              />
            </div>
          </div>
        </div>
      )}
      <AlertSending
        bopen={isSending}
        handleClose={() => setIsSending(false)}
        handleNotiStack
        style={{
          top: 0,
          position: 'absolute',
          zIndex: 3,
        }}
      />
    </div>
  );
};

export default AtendimentoIreit;

/* 
              <Box
              boxShadow={3}
              style={{
                width: '30%',
                marginTop: 10,
                marginLeft: 10,
                marginBottom: 10,
                borderRadius: 10,
                paddingLeft: 10,
                paddingRight: 10,
                paddingTop: 5,
              }}
            >
              <div style={{display: 'flex'}}>
                <p style={{fontWeight: 'bold', marginBottom: 0}}>
                  Tempo de entrega
                </p>
                <p style={{marginBottom: 0, marginLeft: 5}}> (Em minutos)</p>
              </div>

              <div style={{display: 'flex'}}>
                <TextField
                  type={'number'}
                  value={min}
                  onChange={event => {
                    setMin(event.target.value);
                  }}
                  style={{marginTop: 20, width: '50%', marginBottom: 10}}
                  inputProps={{
                    style: {fontSize: 14, padding: 6, color: '#797979'},
                    maxLength: 10,
                  }}
                  InputLabelProps={{
                    style: {
                      fontSize: 14,
                      marginTop: -8,
                      color: '#797979',
                    },
                  }}
                  variant="outlined"
                  label="Min."
                />
                <TextField
                  type={'number'}
                  value={max}
                  onChange={event => {
                    setMax(event.target.value);
                  }}
                  style={{
                    marginTop: 20,
                    width: '50%',
                    marginBottom: 10,
                    marginLeft: 10,
                  }}
                  inputProps={{
                    style: {fontSize: 14, padding: 6, color: '#797979'},
                    maxLength: 10,
                  }}
                  InputLabelProps={{
                    style: {
                      fontSize: 14,
                      marginTop: -8,
                      color: '#797979',
                    },
                  }}
                  variant="outlined"
                  label="Max."
                />
              </div>
            </Box>
              } */
