/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, {useState, useLayoutEffect, useEffect} from 'react';
import {useMediaQuery} from 'react-responsive';
import {withStyles} from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import Divider from '@material-ui/core/Divider';
import {Button} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import UpdateIcon from '@material-ui/icons/Update';
import {dayformat} from '../../../configuration/dayformat';
import {dateformat} from '../../../configuration/dateformat';
import {realFormat} from '../../../configuration/realFormat';
import QRCode from 'react-qr-code';

const useStyles = makeStyles(() => ({
  root: {
    '@media (min-width:600px)': {
      minWidth: '344px !important',
    },
  },
  checkIcon: {
    fontSize: 36,
    paddingRight: 4,
    marginLeft: 5,
    color: '#39B40D',
  },
  noPayIcon: {
    fontSize: 36,
    paddingRight: 4,
    marginLeft: 5,
    color: '#FF6600',
  },
}));

// eslint-disable-next-line react/prop-types
const PaymentPage = ({
  frm,
  cdg,
  setCdg,
  exppix,
  setExppix,
  expblt,
  setExpblt,
  urlblt,
  setUrlblt,
  downloadBlt,
  setDownloadBlt,
  fatSelected,
  checkIfPaymentIsDone,
  currentBoleto,
  currentPix,
  pixDone,
  pixExp,
  boletoExp,
  generateBoleto,
  generatePix,
  getBoleto,
  getPix,
  isLoadingPagePayment,
  setLoadingPagePayment,
  value,
  handleNotiStack,
}) => {
  const isSmall = useMediaQuery({query: '(max-width: 750.98px)'});
  const isVerySmall = useMediaQuery({query: '(max-width:450.00px)'});
  const [width, height] = useWindowSize();
  const classes = useStyles();
  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  }

  useEffect(() => {
    setLoadingPagePayment(true);
    setTimeout(() => {
      checkIfPaymentIsDone();
    }, 500);
  }, [value]);

  function copyCode() {
    navigator.clipboard.writeText(cdg);
    handleNotiStack('Código copiado', 'success');
  }

  return (
    <div>
      {isLoadingPagePayment ? (
        <div
          style={{
            display: 'flex',
            width: isVerySmall ? '45%' : '100%',
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <div>
          {isVerySmall ? (
            <div style={{paddingTop: 30, width: width * 0.8}}>
              <div>
                {pixDone && frm == 'Pix' ? (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'left',
                      alignItems: 'center',
                    }}
                  >
                    <CheckCircleOutlineIcon
                      color="primary"
                      className={classes.checkIcon}
                    />
                    <FormLabel
                      component="legend"
                      style={{
                        color: '#3d3d3d',
                        fontWeight: 'bold',
                        marginTop: 10,
                        textAlign: 'center',
                      }}
                    >
                      Está fatura já está paga
                    </FormLabel>
                  </div>
                ) : (pixExp && frm == 'Pix') || (cdg == '' && frm == 'Pix') ? (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'left',
                      alignItems: 'center',
                    }}
                  >
                    <UpdateIcon color="primary" className={classes.noPayIcon} />
                    <FormLabel
                      component="legend"
                      style={{
                        color: '#3d3d3d',
                        fontWeight: 'bold',
                        marginTop: 10,
                        textAlign: 'center',
                      }}
                    >
                      Pix Expirado. Clique em gerar um novo para realizar o
                      pagamento
                    </FormLabel>
                    <Button
                      style={{marginTop: 20}}
                      size={'small'}
                      onClick={() => generatePix()}
                      color="primary"
                    >
                      Gerar novo código pix
                    </Button>
                  </div>
                ) : boletoExp && frm === 'Boleto' ? (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'left',
                      alignItems: 'center',
                    }}
                  >
                    <UpdateIcon color="primary" className={classes.noPayIcon} />
                    <FormLabel
                      component="legend"
                      style={{
                        color: '#3d3d3d',
                        fontWeight: 'bold',
                        marginTop: 10,
                        textAlign: 'center',
                      }}
                    >
                      Boleto expirado. Clique em gerar um novo para realizar o
                      pagamento
                    </FormLabel>
                    <Button
                      style={{marginTop: 20}}
                      size={'small'}
                      onClick={() => generateBoleto(true)}
                      color="primary"
                    >
                      Gerar novo boleto
                    </Button>
                  </div>
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      backgroundColor: '#FFFFFF',
                      alignItems: 'center',
                      justifyContent: 'center',
                      paddingRight: 0,
                      paddingLeft: 0,
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'left',
                      }}
                    >
                      <FormLabel
                        component="legend"
                        style={{
                          color: '#3d3d3d',
                          fontWeight: 'bold',
                          fontSize: 18,
                        }}
                      >
                        Copie o código e cole no seu app de pagamento
                      </FormLabel>
                      <div
                        style={{
                          backgroundColor: '#e8ffff',
                          display: 'flex',
                          marginTop: 10,
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'center',
                          padding: 5,
                        }}
                      >
                        <Radio checked={true} color="primary" />
                        <FormLabel
                          component="legend"
                          style={{
                            color: '#3d3d3d',
                            fontWeight: 'normal',
                            fontSize: 14,
                            textAlign: 'center',
                            wordWrap: 'break-word',
                            width: 200,
                          }}
                        >
                          A Irê it leva até 72 horas para atualizar a fatura no
                          sistema
                        </FormLabel>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          marginTop: 10,
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          padding: 5,
                          width: '100%',
                        }}
                      >
                        <div>
                          <FormLabel
                            component="legend"
                            style={{
                              color: '#3d3d3d',
                              fontWeight: 'normal',
                              fontSize: 12,
                              textAlign: 'left',
                            }}
                          >
                            Fatura de {dayformat(fatSelected.sdate)} -{' '}
                            {dayformat(fatSelected.edate)}
                          </FormLabel>
                          <FormLabel
                            component="legend"
                            style={{
                              color: '#3d3d3d',
                              fontWeight: 'normal',
                              fontSize: 12,
                              textAlign: 'left',
                            }}
                          >
                            Vencimento: {dayformat(fatSelected.exp)}
                          </FormLabel>
                        </div>
                        <FormLabel
                          component="legend"
                          style={{
                            color: '#3d3d3d',
                            fontWeight: 'normal',
                            fontSize: 14,
                            textAlign: 'right',
                          }}
                        >
                          {realFormat(fatSelected.total * -1)}
                        </FormLabel>
                      </div>
                      {frm == 'Boleto' ? (
                        <div
                          style={{
                            display: 'flex',
                            marginTop: 10,
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            padding: 5,
                            width: '100%',
                          }}
                        >
                          <div>
                            <FormLabel
                              component="legend"
                              style={{
                                color: '#3d3d3d',
                                fontWeight: 'normal',
                                fontSize: 12,
                                textAlign: 'left',
                              }}
                            >
                              Taxa por boleto gerado
                            </FormLabel>
                          </div>
                          <FormLabel
                            component="legend"
                            style={{
                              color: '#3d3d3d',
                              fontWeight: 'normal',
                              fontSize: 14,
                              textAlign: 'right',
                            }}
                          >
                            {realFormat(fatSelected.txblt)}
                          </FormLabel>
                        </div>
                      ) : null}
                      <div
                        style={{
                          backgroundColor: '#ff6600',
                          height: 1,
                          width: '100%',
                          marginTop: 10,
                        }}
                      />
                      <div
                        style={{
                          display: 'flex',
                          marginTop: 10,
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          padding: 5,
                          width: '100%',
                        }}
                      >
                        <div>
                          <FormLabel
                            component="legend"
                            style={{
                              color: '#0d0d0d',
                              fontWeight: 'bold',
                              fontSize: 18,
                              textAlign: 'left',
                            }}
                          >
                            Total
                          </FormLabel>
                        </div>
                        {frm == 'Pix' ? (
                          <FormLabel
                            component="legend"
                            style={{
                              color: '#0d0d0d',
                              fontWeight: 'bold',
                              fontSize: 18,
                              textAlign: 'right',
                            }}
                          >
                            {realFormat(fatSelected.total * -1)}
                          </FormLabel>
                        ) : (
                          <FormLabel
                            component="legend"
                            style={{
                              color: '#0d0d0d',
                              fontWeight: 'bold',
                              fontSize: 18,
                              textAlign: 'right',
                            }}
                          >
                            {realFormat(
                              fatSelected.total * -1 + fatSelected.txblt,
                            )}
                          </FormLabel>
                        )}
                      </div>
                      <div>
                        <FormLabel
                          component="legend"
                          style={{
                            color: '#3d3d3d',
                            fontWeight: 'normal',
                            fontSize: 14,
                            textAlign: 'left',
                            marginTop: 20,
                          }}
                        >
                          Código:
                        </FormLabel>
                        {frm == 'Boleto' ? (
                          <div style={{width: 200}}>
                            <FormLabel
                              component="legend"
                              style={{
                                color: '#2d2d2d',
                                fontWeight: 'bold',
                                fontSize: 14,
                                textAlign: 'left',
                                marginTop: 5,
                                wordWrap: 'break-word',
                              }}
                            >
                              {currentBoleto.nboleto}
                            </FormLabel>
                          </div>
                        ) : (
                          <FormLabel
                            component="legend"
                            style={{
                              color: '#2d2d2d',
                              fontWeight: 'bold',
                              fontSize: 14,
                              textAlign: 'left',
                              marginTop: 5,
                              wordWrap: 'break-word',
                              width: 200,
                            }}
                          >
                            {cdg}
                          </FormLabel>
                        )}
                      </div>
                      {frm == 'Boleto' ? (
                        <div>
                          <FormLabel
                            component="legend"
                            style={{
                              color: '#3d3d3d',
                              fontWeight: 'normal',
                              fontSize: 14,
                              textAlign: 'left',
                              marginTop: 20,
                            }}
                          >
                            Vencimento boleto:
                          </FormLabel>
                          <FormLabel
                            component="legend"
                            style={{
                              color: '#2d2d2d',
                              fontWeight: 'bold',
                              fontSize: 14,
                              textAlign: 'left',
                              marginTop: 5,
                            }}
                          >
                            {currentBoleto.expday}
                          </FormLabel>
                        </div>
                      ) : frm === 'Pix' ? (
                        <div>
                          <FormLabel
                            component="legend"
                            style={{
                              color: '#3d3d3d',
                              fontWeight: 'normal',
                              fontSize: 14,
                              textAlign: 'left',
                              marginTop: 20,
                            }}
                          >
                            Validade do código:
                          </FormLabel>
                          <FormLabel
                            component="legend"
                            style={{
                              color: '#2d2d2d',
                              fontWeight: 'bold',
                              fontSize: 14,
                              textAlign: 'left',
                              marginTop: 5,
                            }}
                          >
                            {dateformat(currentPix.exp)}
                          </FormLabel>
                        </div>
                      ) : null}
                      {frm == 'Boleto' ? (
                        <div>
                          <Button
                            variant={'contained'}
                            color={'primary'}
                            href={currentBoleto.urlboleto}
                            style={{
                              color: '#ffffff',
                              fontSize: 14,
                              textAlign: 'left',
                              marginTop: 20,
                            }}
                          >
                            Link do boleto
                          </Button>
                        </div>
                      ) : null}
                      {frm == 'Pix' ? (
                        <div
                          style={{
                            height: 'auto',
                            width: 128,
                            marginTop: 10,
                          }}
                        >
                          <QRCode
                            size={128}
                            style={{
                              height: 'auto',
                              maxWidth: 128,
                              width: 128,
                            }}
                            value={cdg}
                            viewBox={`0 0 256 256`}
                          />
                        </div>
                      ) : null}
                      {frm == 'Pix' ? (
                        <Button
                          style={{marginTop: 20}}
                          size={'small'}
                          onClick={() => copyCode()}
                          color="primary"
                        >
                          Copiar código
                        </Button>
                      ) : null}

                      {frm == 'Pix' ? (
                        <Button
                          style={{marginTop: 20, padding: 5}}
                          size={'small'}
                          onClick={() => getPix()}
                        >
                          Verificar pagamento
                        </Button>
                      ) : null}
                      {frm == 'Boleto' ? (
                        <Button
                          style={{marginTop: 20}}
                          size={'small'}
                          href={currentBoleto.urlpdf}
                          color="primary"
                        >
                          PDF Boleto
                        </Button>
                      ) : null}
                    </div>
                  </div>
                )}
              </div>
            </div>
          ) : isSmall ? (
            <div style={{width: width * 0.675, paddingTop: 30}}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  backgroundColor: '#FFFFFF',
                  paddingBottom: 10,
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  paddingRight: 20,
                  paddingLeft: 20,
                }}
              >
                <div>
                  {pixDone && frm == 'Pix' ? (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <CheckCircleOutlineIcon
                        color="primary"
                        className={classes.checkIcon}
                      />
                      <FormLabel
                        component="legend"
                        style={{
                          color: '#3d3d3d',
                          fontWeight: 'bold',
                          marginTop: 10,
                          textAlign: 'center',
                        }}
                      >
                        Está fatura já está paga
                      </FormLabel>
                    </div>
                  ) : (pixExp && frm == 'Pix') ||
                    (cdg == '' && frm == 'Pix') ? (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <UpdateIcon
                        color="primary"
                        className={classes.noPayIcon}
                      />
                      <FormLabel
                        component="legend"
                        style={{
                          color: '#3d3d3d',
                          fontWeight: 'bold',
                          marginTop: 10,
                          textAlign: 'center',
                        }}
                      >
                        Pix Expirado. Clique em gerar um novo para realizar o
                        pagamento
                      </FormLabel>
                      <Button
                        style={{marginTop: 20}}
                        size={'small'}
                        onClick={() => generatePix()}
                        color="primary"
                      >
                        Gerar novo código pix
                      </Button>
                    </div>
                  ) : boletoExp && frm === 'Boleto' ? (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <UpdateIcon
                        color="primary"
                        className={classes.noPayIcon}
                      />
                      <FormLabel
                        component="legend"
                        style={{
                          color: '#3d3d3d',
                          fontWeight: 'bold',
                          marginTop: 10,
                          textAlign: 'center',
                        }}
                      >
                        Boleto expirado. Clique em gerar um novo para realizar o
                        pagamento
                      </FormLabel>
                      <Button
                        style={{marginTop: 20}}
                        size={'small'}
                        onClick={() => generateBoleto(true)}
                        color="primary"
                      >
                        Gerar novo boleto
                      </Button>
                    </div>
                  ) : (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        backgroundColor: '#FFFFFF',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        paddingRight: 20,
                        paddingLeft: 20,
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'left',
                        }}
                      >
                        <FormLabel
                          component="legend"
                          style={{
                            color: '#3d3d3d',
                            fontWeight: 'bold',
                            fontSize: 18,
                          }}
                        >
                          Copie o código e cole no seu app de pagamento
                        </FormLabel>
                        <div
                          style={{
                            backgroundColor: '#e8ffff',
                            display: 'flex',
                            marginTop: 10,
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: 5,
                          }}
                        >
                          <Radio checked={true} color="primary" />
                          <FormLabel
                            component="legend"
                            style={{
                              color: '#3d3d3d',
                              fontWeight: 'normal',
                              fontSize: 14,
                              textAlign: 'center',
                              wordWrap: 'break-word',
                              width: 200,
                            }}
                          >
                            A Irê it leva até 72 horas para atualizar a fatura
                            no sistema
                          </FormLabel>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            marginTop: 10,
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            padding: 5,
                            width: '100%',
                          }}
                        >
                          <div>
                            <FormLabel
                              component="legend"
                              style={{
                                color: '#3d3d3d',
                                fontWeight: 'normal',
                                fontSize: 14,
                                textAlign: 'left',
                              }}
                            >
                              Fatura de {dayformat(fatSelected.sdate)} -{' '}
                              {dayformat(fatSelected.edate)}
                            </FormLabel>
                            <FormLabel
                              component="legend"
                              style={{
                                color: '#3d3d3d',
                                fontWeight: 'normal',
                                fontSize: 14,
                                textAlign: 'left',
                              }}
                            >
                              Vencimento: {dayformat(fatSelected.exp)}
                            </FormLabel>
                          </div>
                          <FormLabel
                            component="legend"
                            style={{
                              color: '#3d3d3d',
                              fontWeight: 'normal',
                              fontSize: 14,
                              textAlign: 'right',
                            }}
                          >
                            {realFormat(fatSelected.total * -1)}
                          </FormLabel>
                        </div>
                        {frm == 'Boleto' ? (
                          <div
                            style={{
                              display: 'flex',
                              marginTop: 10,
                              flexDirection: 'row',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                              padding: 5,
                              width: '100%',
                            }}
                          >
                            <div>
                              <FormLabel
                                component="legend"
                                style={{
                                  color: '#3d3d3d',
                                  fontWeight: 'normal',
                                  fontSize: 14,
                                  textAlign: 'left',
                                }}
                              >
                                Taxa por boleto gerado
                              </FormLabel>
                            </div>
                            <FormLabel
                              component="legend"
                              style={{
                                color: '#3d3d3d',
                                fontWeight: 'normal',
                                fontSize: 14,
                                textAlign: 'right',
                              }}
                            >
                              {realFormat(fatSelected.txblt)}
                            </FormLabel>
                          </div>
                        ) : null}

                        <div
                          style={{
                            backgroundColor: '#ff6600',
                            height: 1,
                            width: '100%',
                            marginTop: 10,
                          }}
                        />
                        <div
                          style={{
                            display: 'flex',
                            marginTop: 10,
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            padding: 5,
                            width: '100%',
                          }}
                        >
                          <div>
                            <FormLabel
                              component="legend"
                              style={{
                                color: '#0d0d0d',
                                fontWeight: 'bold',
                                fontSize: 18,
                                textAlign: 'left',
                              }}
                            >
                              Total
                            </FormLabel>
                          </div>
                          {frm == 'Pix' ? (
                            <FormLabel
                              component="legend"
                              style={{
                                color: '#0d0d0d',
                                fontWeight: 'bold',
                                fontSize: 18,
                                textAlign: 'right',
                              }}
                            >
                              {realFormat(fatSelected.total * -1)}
                            </FormLabel>
                          ) : (
                            <FormLabel
                              component="legend"
                              style={{
                                color: '#0d0d0d',
                                fontWeight: 'bold',
                                fontSize: 18,
                                textAlign: 'right',
                              }}
                            >
                              {realFormat(
                                fatSelected.total * -1 + fatSelected.txblt,
                              )}
                            </FormLabel>
                          )}
                        </div>
                        <div>
                          <FormLabel
                            component="legend"
                            style={{
                              color: '#3d3d3d',
                              fontWeight: 'normal',
                              fontSize: 14,
                              textAlign: 'left',
                              marginTop: 20,
                            }}
                          >
                            Código:
                          </FormLabel>
                          {frm == 'Boleto' ? (
                            <FormLabel
                              component="legend"
                              style={{
                                color: '#2d2d2d',
                                fontWeight: 'bold',
                                fontSize: 14,
                                textAlign: 'left',
                                marginTop: 5,
                                wordWrap: 'break-word',
                              }}
                            >
                              {currentBoleto.nboleto}
                            </FormLabel>
                          ) : (
                            <FormLabel
                              component="legend"
                              style={{
                                color: '#2d2d2d',
                                fontWeight: 'bold',
                                fontSize: 14,
                                textAlign: 'left',
                                marginTop: 5,
                                wordWrap: 'break-word',
                                width: 200,
                              }}
                            >
                              {cdg}
                            </FormLabel>
                          )}
                        </div>
                        {frm == 'Boleto' ? (
                          <div>
                            <FormLabel
                              component="legend"
                              style={{
                                color: '#3d3d3d',
                                fontWeight: 'normal',
                                fontSize: 14,
                                textAlign: 'left',
                                marginTop: 20,
                              }}
                            >
                              Vencimento boleto:
                            </FormLabel>
                            <FormLabel
                              component="legend"
                              style={{
                                color: '#2d2d2d',
                                fontWeight: 'bold',
                                fontSize: 14,
                                textAlign: 'left',
                                marginTop: 5,
                              }}
                            >
                              {currentBoleto.expday}
                            </FormLabel>
                          </div>
                        ) : frm === 'Pix' ? (
                          <div>
                            <FormLabel
                              component="legend"
                              style={{
                                color: '#3d3d3d',
                                fontWeight: 'normal',
                                fontSize: 14,
                                textAlign: 'left',
                                marginTop: 20,
                              }}
                            >
                              Validade do código:
                            </FormLabel>
                            <FormLabel
                              component="legend"
                              style={{
                                color: '#2d2d2d',
                                fontWeight: 'bold',
                                fontSize: 14,
                                textAlign: 'left',
                                marginTop: 5,
                              }}
                            >
                              {dateformat(currentPix.exp)}
                            </FormLabel>
                          </div>
                        ) : null}

                        {frm == 'Boleto' ? (
                          <div>
                            <Button
                              variant={'contained'}
                              color={'primary'}
                              href={currentBoleto.urlboleto}
                              style={{
                                color: '#ffffff',
                                fontSize: 14,
                                textAlign: 'left',
                                marginTop: 20,
                              }}
                            >
                              Link do boleto
                            </Button>
                          </div>
                        ) : null}
                        {frm == 'Pix' ? (
                          <div
                            style={{
                              height: 'auto',
                              width: 128,
                              marginTop: 10,
                            }}
                          >
                            <QRCode
                              size={128}
                              style={{
                                height: 'auto',
                                maxWidth: 128,
                                width: 128,
                              }}
                              value={cdg}
                              viewBox={`0 0 256 256`}
                            />
                          </div>
                        ) : null}
                        {frm == 'Pix' ? (
                          <Button
                            style={{marginTop: 20}}
                            size={'small'}
                            onClick={() => copyCode()}
                            color="primary"
                          >
                            Copiar código
                          </Button>
                        ) : null}
                        {frm == 'Pix' ? (
                          <Button
                            style={{marginTop: 20, padding: 5}}
                            size={'small'}
                            onClick={() => getPix()}
                          >
                            Verificar pagamento
                          </Button>
                        ) : null}
                        {frm == 'Boleto' ? (
                          <Button
                            style={{marginTop: 20}}
                            size={'small'}
                            href={currentBoleto.urlpdf}
                            color="primary"
                          >
                            PDF Boleto
                          </Button>
                        ) : null}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div>
              {pixDone && frm == 'Pix' ? (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <CheckCircleOutlineIcon
                    color="primary"
                    className={classes.checkIcon}
                  />
                  <FormLabel
                    component="legend"
                    style={{
                      color: '#3d3d3d',
                      fontWeight: 'bold',
                      marginTop: 10,
                      textAlign: 'center',
                    }}
                  >
                    Está fatura já está paga
                  </FormLabel>
                </div>
              ) : (pixExp && frm == 'Pix') || (cdg == '' && frm == 'Pix') ? (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <UpdateIcon color="primary" className={classes.noPayIcon} />
                  <FormLabel
                    component="legend"
                    style={{
                      color: '#3d3d3d',
                      fontWeight: 'bold',
                      marginTop: 10,
                      textAlign: 'center',
                    }}
                  >
                    Pix Expirado. Clique em gerar um novo para realizar o
                    pagamento
                  </FormLabel>
                  <Button
                    style={{marginTop: 20}}
                    size={'small'}
                    onClick={() => generatePix()}
                    color="primary"
                  >
                    Gerar novo código pix
                  </Button>
                </div>
              ) : boletoExp && frm === 'Boleto' ? (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <UpdateIcon color="primary" className={classes.noPayIcon} />
                  <FormLabel
                    component="legend"
                    style={{
                      color: '#3d3d3d',
                      fontWeight: 'bold',
                      marginTop: 10,
                      textAlign: 'center',
                    }}
                  >
                    Boleto expirado. Clique em gerar um novo para realizar o
                    pagamento
                  </FormLabel>
                  <Button
                    style={{marginTop: 20}}
                    size={'small'}
                    onClick={() => generateBoleto(true)}
                    color="primary"
                  >
                    Gerar novo boleto
                  </Button>
                </div>
              ) : (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    backgroundColor: '#FFFFFF',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    paddingRight: 20,
                    paddingLeft: 20,
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'left',
                    }}
                  >
                    <FormLabel
                      component="legend"
                      style={{
                        color: '#3d3d3d',
                        fontWeight: 'bold',
                        fontSize: 18,
                      }}
                    >
                      Copie o código e cole no seu app de pagamento
                    </FormLabel>
                    <div
                      style={{
                        backgroundColor: '#e8ffff',
                        display: 'flex',
                        marginTop: 10,
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: 5,
                        width: '75%',
                      }}
                    >
                      <Radio checked={true} color="primary" />
                      <FormLabel
                        component="legend"
                        style={{
                          color: '#3d3d3d',
                          fontWeight: 'normal',
                          fontSize: 14,
                          textAlign: 'center',
                          wordWrap: 'break-word',
                          width: '55%',
                        }}
                      >
                        A Irê it leva até 72 horas para atualizar a fatura no
                        sistema
                      </FormLabel>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        marginTop: 10,
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        padding: 5,
                        width: '100%',
                      }}
                    >
                      <div>
                        <FormLabel
                          component="legend"
                          style={{
                            color: '#3d3d3d',
                            fontWeight: 'normal',
                            fontSize: 14,
                            textAlign: 'left',
                          }}
                        >
                          Fatura de {dayformat(fatSelected.sdate)} -{' '}
                          {dayformat(fatSelected.edate)}
                        </FormLabel>
                        <FormLabel
                          component="legend"
                          style={{
                            color: '#3d3d3d',
                            fontWeight: 'normal',
                            fontSize: 14,
                            textAlign: 'left',
                          }}
                        >
                          Vencimento: {dayformat(fatSelected.exp)}
                        </FormLabel>
                      </div>
                      <FormLabel
                        component="legend"
                        style={{
                          color: '#3d3d3d',
                          fontWeight: 'normal',
                          fontSize: 14,
                          textAlign: 'right',
                        }}
                      >
                        {realFormat(fatSelected.total * -1)}
                      </FormLabel>
                    </div>
                    {frm == 'Boleto' ? (
                      <div
                        style={{
                          display: 'flex',
                          marginTop: 10,
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          padding: 5,
                          width: '100%',
                        }}
                      >
                        <div>
                          <FormLabel
                            component="legend"
                            style={{
                              color: '#3d3d3d',
                              fontWeight: 'normal',
                              fontSize: 14,
                              textAlign: 'left',
                            }}
                          >
                            Taxa por boleto gerado
                          </FormLabel>
                        </div>
                        <FormLabel
                          component="legend"
                          style={{
                            color: '#3d3d3d',
                            fontWeight: 'normal',
                            fontSize: 14,
                            textAlign: 'right',
                          }}
                        >
                          {realFormat(fatSelected.txblt)}
                        </FormLabel>
                      </div>
                    ) : null}

                    <div
                      style={{
                        backgroundColor: '#ff6600',
                        height: 1,
                        width: '100%',
                        marginTop: 10,
                      }}
                    />
                    <div
                      style={{
                        display: 'flex',
                        marginTop: 10,
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        padding: 5,
                        width: '100%',
                      }}
                    >
                      <div>
                        <FormLabel
                          component="legend"
                          style={{
                            color: '#0d0d0d',
                            fontWeight: 'bold',
                            fontSize: 18,
                            textAlign: 'left',
                          }}
                        >
                          Total
                        </FormLabel>
                      </div>
                      {frm == 'Pix' ? (
                        <FormLabel
                          component="legend"
                          style={{
                            color: '#0d0d0d',
                            fontWeight: 'bold',
                            fontSize: 18,
                            textAlign: 'right',
                          }}
                        >
                          {realFormat(fatSelected.total * -1)}
                        </FormLabel>
                      ) : (
                        <FormLabel
                          component="legend"
                          style={{
                            color: '#0d0d0d',
                            fontWeight: 'bold',
                            fontSize: 18,
                            textAlign: 'right',
                          }}
                        >
                          {realFormat(
                            fatSelected.total * -1 + fatSelected.txblt,
                          )}
                        </FormLabel>
                      )}
                    </div>
                    <div>
                      <FormLabel
                        component="legend"
                        style={{
                          color: '#3d3d3d',
                          fontWeight: 'normal',
                          fontSize: 14,
                          textAlign: 'left',
                          marginTop: 20,
                        }}
                      >
                        Código:
                      </FormLabel>
                      {frm == 'Boleto' ? (
                        <FormLabel
                          component="legend"
                          style={{
                            color: '#2d2d2d',
                            fontWeight: 'bold',
                            fontSize: 14,
                            textAlign: 'left',
                            marginTop: 5,
                            wordWrap: 'break-word',
                          }}
                        >
                          {currentBoleto.nboleto}
                        </FormLabel>
                      ) : (
                        <FormLabel
                          component="legend"
                          style={{
                            color: '#2d2d2d',
                            fontWeight: 'bold',
                            fontSize: 14,
                            textAlign: 'left',
                            marginTop: 5,
                            wordWrap: 'break-word',
                            width: '75%',
                          }}
                        >
                          {cdg}
                        </FormLabel>
                      )}
                    </div>
                    {frm == 'Boleto' ? (
                      <div>
                        <FormLabel
                          component="legend"
                          style={{
                            color: '#3d3d3d',
                            fontWeight: 'normal',
                            fontSize: 14,
                            textAlign: 'left',
                            marginTop: 20,
                          }}
                        >
                          Vencimento boleto:
                        </FormLabel>
                        <FormLabel
                          component="legend"
                          style={{
                            color: '#2d2d2d',
                            fontWeight: 'bold',
                            fontSize: 14,
                            textAlign: 'left',
                            marginTop: 5,
                          }}
                        >
                          {currentBoleto.expday}
                        </FormLabel>
                      </div>
                    ) : frm === 'Pix' ? (
                      <div>
                        <FormLabel
                          component="legend"
                          style={{
                            color: '#3d3d3d',
                            fontWeight: 'normal',
                            fontSize: 14,
                            textAlign: 'left',
                            marginTop: 20,
                          }}
                        >
                          Validade do código:
                        </FormLabel>
                        <FormLabel
                          component="legend"
                          style={{
                            color: '#2d2d2d',
                            fontWeight: 'bold',
                            fontSize: 14,
                            textAlign: 'left',
                            marginTop: 5,
                          }}
                        >
                          {dateformat(currentPix.exp)}
                        </FormLabel>
                      </div>
                    ) : null}

                    {frm == 'Boleto' ? (
                      <div>
                        <Button
                          variant={'contained'}
                          color={'primary'}
                          href={currentBoleto.urlboleto}
                          style={{
                            color: '#ffffff',
                            fontSize: 14,
                            textAlign: 'left',
                            marginTop: 20,
                          }}
                        >
                          Link do boleto
                        </Button>
                      </div>
                    ) : null}

                    {frm == 'Pix' ? (
                      <div
                        style={{
                          height: 'auto',
                          width: 128,
                          marginTop: 10,
                        }}
                      >
                        <QRCode
                          size={128}
                          style={{
                            height: 'auto',
                            maxWidth: 128,
                            width: 128,
                          }}
                          value={cdg}
                          viewBox={`0 0 256 256`}
                        />
                      </div>
                    ) : null}
                    {frm == 'Pix' ? (
                      <Button
                        style={{marginTop: 20, padding: 5, width: '75%'}}
                        size={'small'}
                        onClick={() => copyCode()}
                        color="primary"
                      >
                        Copiar código
                      </Button>
                    ) : null}
                    {frm == 'Pix' ? (
                      <Button
                        style={{marginTop: 20, padding: 5}}
                        size={'small'}
                        onClick={() => getPix()}
                      >
                        Verificar pagamento
                      </Button>
                    ) : null}
                    {frm == 'Boleto' ? (
                      <Button
                        style={{marginTop: 20}}
                        size={'small'}
                        href={currentBoleto.urlpdf}
                        color="primary"
                      >
                        PDF Boleto
                      </Button>
                    ) : null}
                  </div>
                </div>
              )}
            </div>
          )}
          <div></div>
        </div>
      )}
    </div>
  );
};

export default PaymentPage;
