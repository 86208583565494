/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, {useState, useLayoutEffect, useEffect} from 'react';
import {useMediaQuery} from 'react-responsive';
import FormLabel from '@material-ui/core/FormLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import MoneyOffIcon from '@material-ui/icons/MoneyOff';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(() => ({
  root: {
    '@media (min-width:600px)': {
      minWidth: '344px !important',
    },
  },
  checkIcon: {
    fontSize: 36,
    paddingRight: 4,
    marginLeft: 5,
    color: '#39B40D',
  },
  noPayIcon: {
    fontSize: 36,
    paddingRight: 4,
    marginLeft: 5,
    color: '#FF6600',
  },
}));

// eslint-disable-next-line react/prop-types
const CheckPayment = ({
  value,
  handleChange,
  handleChangeIndex,
  paymentDone,
  loadingFrmFat,
}) => {
  const isSmall = useMediaQuery({query: '(max-width: 750.98px)'});
  const isVerySmall = useMediaQuery({query: '(max-width:450.00px)'});
  const [width, height] = useWindowSize();
  const [isLoading, setLoading] = useState(true);
  const classes = useStyles();
  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  }

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  useEffect(() => {
    if (value == 0) {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }, [value]);

  return (
    <div style={{}}>
      {isLoading ? (
        <div
          style={{
            display: 'flex',
            width: isVerySmall ? '45%' : '100%',
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <CircularProgress />
          {loadingFrmFat ? (
            <p
              style={{
                fontSize: 16,
                color: '#393939',
              }}
            >
              Verificando formas de pagamento disponíveis
            </p>
          ) : (
            <p
              style={{
                fontSize: 16,
                color: '#393939',
              }}
            >
              Verificando fatura
            </p>
          )}
        </div>
      ) : (
        <div>
          {isVerySmall ? (
            <div style={{}}>
              {paymentDone ? (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'left',
                  }}
                >
                  <CheckCircleOutlineIcon
                    color="primary"
                    className={classes.checkIcon}
                  />
                  <FormLabel
                    component="legend"
                    style={{
                      color: '#3d3d3d',
                      fontWeight: 'bold',
                      marginTop: 10,
                    }}
                  >
                    Está fatura já está paga
                  </FormLabel>
                </div>
              ) : (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'left',
                    width: '50%',
                  }}
                >
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <MoneyOffIcon
                      color="secondary"
                      className={classes.noPayIcon}
                    />
                  </div>

                  <FormLabel
                    component="legend"
                    style={{
                      color: '#3d3d3d',
                      fontWeight: 'bold',
                      marginTop: 20,
                      textAlign: 'left',
                    }}
                  >
                    Está fatura ainda está em aberto. Clique no botão abaixo
                    para prosseguir com o pagamento
                  </FormLabel>
                  <Button
                    style={{
                      fontSize: 10,
                      textAlign: 'center',
                      marginTop: 20,
                    }}
                    variant="contained"
                    color="primary"
                    component="span"
                    size="large"
                    onClick={() => handleChangeIndex(1)}
                  >
                    Realizar pagamento
                  </Button>
                </div>
              )}
            </div>
          ) : isSmall ? (
            <div style={{width: width * 0.675}}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  backgroundColor: '#FFFFFF',
                  paddingBottom: 10,
                  alignItems: 'left',
                  justifyContent: 'center',
                  paddingRight: 20,
                  paddingLeft: 20,
                }}
              >
                {paymentDone ? (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <CheckCircleOutlineIcon
                      color="primary"
                      className={classes.checkIcon}
                    />
                    <FormLabel
                      component="legend"
                      style={{
                        color: '#3d3d3d',
                        fontWeight: 'bold',
                        marginTop: 10,
                        textAlign: 'center',
                      }}
                    >
                      Está fatura já está paga
                    </FormLabel>
                  </div>
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'left',
                    }}
                  >
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <MoneyOffIcon
                        color="secondary"
                        className={classes.noPayIcon}
                      />
                    </div>

                    <FormLabel
                      component="legend"
                      style={{
                        color: '#3d3d3d',
                        fontWeight: 'bold',
                        marginTop: 20,
                        textAlign: 'center',
                      }}
                    >
                      Está fatura ainda está em aberto. Clique no botão abaixo
                      para prosseguir com o pagamento
                    </FormLabel>
                    <Button
                      style={{
                        fontSize: 10,
                        textAlign: 'center',
                        marginTop: 20,
                      }}
                      variant="contained"
                      color="primary"
                      component="span"
                      size="large"
                      onClick={() => handleChangeIndex(1)}
                    >
                      Realizar pagamento
                    </Button>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  backgroundColor: '#FFFFFF',
                  alignItems: 'center',
                  justifyContent: 'center',
                  paddingRight: 20,
                  paddingLeft: 20,
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  {paymentDone ? (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <CheckCircleOutlineIcon
                        color="primary"
                        className={classes.checkIcon}
                      />
                      <FormLabel
                        component="legend"
                        style={{
                          color: '#3d3d3d',
                          fontWeight: 'bold',
                          marginTop: 10,
                          textAlign: 'center',
                        }}
                      >
                        Está fatura já está paga
                      </FormLabel>
                    </div>
                  ) : (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <MoneyOffIcon
                        color="secondary"
                        className={classes.noPayIcon}
                      />
                      <FormLabel
                        component="legend"
                        style={{
                          color: '#3d3d3d',
                          fontWeight: 'bold',
                          marginTop: 20,
                          textAlign: 'center',
                        }}
                      >
                        Está fatura ainda está em aberto. Clique no botão abaixo
                        para prosseguir com o pagamento
                      </FormLabel>
                      <Button
                        style={{
                          fontSize: 10,
                          textAlign: 'center',
                          marginTop: 20,
                        }}
                        variant="contained"
                        color="primary"
                        component="span"
                        size="large"
                        onClick={() => handleChangeIndex(1)}
                      >
                        Realizar pagamento
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CheckPayment;
