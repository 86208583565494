/* eslint-disable no-unused-vars */
import React, {useLayoutEffect, useState, useEffect} from 'react';
import {useMediaQuery} from 'react-responsive';
import {makeStyles} from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import {FaBars} from 'react-icons/fa';
import ContentMap from './ContentMap';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import AlertSending from '../../components/AlertSeding';
import {useSelector} from 'react-redux';
import {CircularProgress} from '@material-ui/core';
import * as ActionStore from '../../store/actions/setRestaurante';
import {useDispatch} from 'react-redux';
import {getAuth} from 'firebase/auth';

const useStyles = makeStyles(theme => ({
  formControl: {
    marginLeft: 10,
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

// eslint-disable-next-line react/prop-types
const Location = ({handleToggleSidebar, handleNotiStack, handleBackDrop}) => {
  const auth = getAuth();
  const isSmall = useMediaQuery({query: '(max-width: 750.98px)'});
  const isVerySmall = useMediaQuery({query: '(max-width:450.00px)'});
  const [isLoading, setLoading] = useState(true);
  const classes = useStyles();
  const dispatch = useDispatch();
  const [marker, setMarker] = useState({lat: 0, lng: 0});

  const [endereco, setEndereco] = useState('');
  const [numero, setNumero] = useState('');
  const [bairro, setBairro] = useState('');
  const [cidade, setCidade] = useState('');
  const handleChange = event => {
    setValue(event.target.value);
  };
  const [value, setValue] = useState();
  const [location, setLocation] = useState({latitude: 0, longitude: 0});
  const estado = useSelector(state => state.setrestaurante);
  const end = useSelector(state => state.setrestaurante.endereco);
  const num = useSelector(state => state.setrestaurante.numero);
  const bai = useSelector(state => state.setrestaurante.bairro);
  const cid = useSelector(state => state.setrestaurante.cidade);
  const est = useSelector(state => state.setrestaurante.estado);
  const loc = useSelector(state => state.setrestaurante.location);
  const docid = useSelector(state => state.setrestaurante.id);

  useEffect(() => {
    setTimeout(() => {
      setEndereco(end);
      setNumero(num);
      setBairro(bai);
      setCidade(cid);
      setValue(est);
      setLocation({
        latitude: loc.coordinates[1],
        longitude: loc.coordinates[0],
      });
      setLoading(false);
      setMarker({lat: loc.coordinates[1], lng: loc.coordinates[0]});
    }, 1000);
  }, [estado]);

  useEffect(() => {
    setLocation({latitude: loc.coordinates[1], longitude: loc.coordinates[0]});
  }, [marker, setMarker]);

  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    // Button is displayed after scrolling for 500 pixels
    const toggleVisibility = () => {
      if (window.pageYOffset > 50) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', toggleVisibility);

    return () => window.removeEventListener('scroll', toggleVisibility);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  }
  const [width, height] = useWindowSize();

  const [estados, setEstados] = useState([
    {
      title: 'AC',
    },
    {
      title: 'AL',
    },
    {
      title: 'AP',
    },
    {
      title: 'AM',
    },
    {
      title: 'BA',
    },
    {
      title: 'CE',
    },
    {
      title: 'DF',
    },
    {
      title: 'ES',
    },
    {
      title: 'GO',
    },
    {
      title: 'MA',
    },
    {
      title: 'MT',
    },
    {
      title: 'MS',
    },
    {
      title: 'MG',
    },
    {
      title: 'PA',
    },
    {
      title: 'PB',
    },
    {
      title: 'PR',
    },
    {
      title: 'PE',
    },
    {
      title: 'PI',
    },
    {
      title: 'RJ',
    },
    {
      title: 'RN',
    },
    {
      title: 'RS',
    },
    {
      title: 'RO',
    },
    {
      title: 'RR',
    },
    {
      title: 'SC',
    },
    {
      title: 'SP',
    },
    {
      title: 'SE',
    },
    {
      title: 'TO',
    },
  ]);

  const [isSending, setIsSending] = useState(false);
  function onSave() {
    setIsSending(true);
    if (endereco === '') {
      setIsSending(false);
      handleNotiStack('Preencha o campo endereço', 'error');
    } else if (numero === '' || numero === 0) {
      setIsSending(false);
      handleNotiStack('Preencha o campo número', 'error');
    } else if (bairro === '') {
      setIsSending(false);
      handleNotiStack('Preencha o campo bairro', 'error');
    } else if (cidade === '') {
      setIsSending(false);
      handleNotiStack('Preencha o campo cidade', 'error');
    } else if (value === '') {
      setIsSending(false);
      handleNotiStack('Selecione um estado', 'error');
    } else if (location.latitude === 0 || location.longitude === 0) {
      setIsSending(false);
      handleNotiStack(
        'Localização inválida. Ajuste a marca em um local válido no mapa',
        'error',
      );
    } else {
      auth.currentUser.getIdToken().then(token => {
        const request = new Request(
          'https://kops.apiireit.com/gateway/store/update/address/' +
            docid +
            '?address=' +
            endereco +
            '&number=' +
            numero +
            '&bairro=' +
            bairro +
            '&cidade=' +
            cidade +
            '&estado=' +
            value +
            '&isdstc=' +
            false,
          {
            method: 'PUT',
            mode: 'cors',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify([[marker.lat, marker.lng]]),
          },
        );

        fetch(request)
          .then(response => {
            if (response.status === 200) {
              handleBackDrop('', '', 200, false);
              response
                .json()
                .then(data => {
                  setIsSending(false);
                  setTimeout(() => {
                    handleNotiStack(
                      'Informações atualizadas com sucesso',
                      'success',
                    );
                  }, 1000);
                  dispatch(
                    ActionStore.setrestaurante(
                      data._id,
                      data.nome,
                      data.dsc,
                      data.nota,
                      data.nNotas,
                      data.cat,
                      data.entrg,
                      data.rtrda,
                      data.agdnt,
                      data.espra,
                      data.dsct,
                      data.frete,
                      data.nAv,
                      data.pdmin,
                      data.entgrts,
                      data.location,
                      data.img,
                      data.stt,
                      data.catprice,
                      data.iient,
                      data.chck,
                      data.merc,
                      data.cpm,
                      data.promoent,
                      data.bapp,
                      data.pix,
                      data.bame,
                      data.picpay,
                      data.bmp,
                      data.bpaypal,
                      data.bpre,
                      data.bvir,
                      data.bvref,
                      data.bd,
                      data.bvd,
                      data.bmcd,
                      data.belod,
                      data.bhipd,
                      data.bdind,
                      data.baled,
                      data.bsood,
                      data.bvrd,
                      data.bbend,
                      data.bdisd,
                      data.btrid,
                      data.bbnsd,
                      data.bbrsd,
                      data.bbrd,
                      data.bvc,
                      data.bmcc,
                      data.beloc,
                      data.bhipc,
                      data.bdinc,
                      data.balec,
                      data.bsodc,
                      data.bvrc,
                      data.bbenc,
                      data.bdisc,
                      data.btric,
                      data.bbnsc,
                      data.bbrsc,
                      data.bbrc,
                      data.end,
                      data.num,
                      data.bairro,
                      data.cid,
                      data.est,
                      data.cnpj,
                      data.bodom,
                      data.hdom,
                      data.boseg,
                      data.hseg,
                      data.boter,
                      data.hter,
                      data.boqua,
                      data.hqua,
                      data.boqui,
                      data.hqui,
                      data.bosex,
                      data.hsex,
                      data.bosab,
                      data.hsab,
                      data.aratdm,
                      data.raio,
                      data.dstc,
                      data.essm,
                      data.esfds,
                      data.inalta,
                      data.npd,
                      data.znid,
                      data.zntx,
                      data.iitx,
                      data.iietx,
                      data.freetx,
                      data.freetill,
                      data.lastSeenAt,
                      data.presence,
                      data.openchat,
                      data.agd,
                      data.fagdays,
                      data.dtsm,
                      data.dtfds,
                      data.ctto,
                      data.aratdmii,
                      data.mxprmetrg,
                      data.bbank,
                      data.btcnta,
                      data.bagcia,
                      data.bcnta,
                      data.bdig,
                      data.cpix,
                      data.npix,
                      data.nimg,
                    ),
                  );
                })
                .catch(error => {
                  console.log(error);
                });
            } else if (response.status === 503) {
              setIsSending(false);
              setTimeout(() => {
                handleNotiStack(
                  'Não foi possível salvar as informações',
                  'error',
                );
              }, 1000);
              handleBackDrop(
                'Serviço indisponível no momento',
                'O serviços para as lojas Irê it não estão disponíveis no momento. Por favor, tente novamente mais tarde',
                503,
                true,
              );
              throw new Error('Something went wrong on API server!');
            } else if (response.status === 404) {
              setIsSending(false);
              setTimeout(() => {
                handleNotiStack(
                  'Não foi possível salvar as informações',
                  'error',
                );
              }, 1000);
              handleBackDrop(
                'Serviço não encontrado.',
                'Por favor, entre em contato com o nosso suporte e relate o problema.',
                404,
                true,
              );
            } else if (response.status === 401) {
              setIsSending(false);
              setTimeout(() => {
                handleNotiStack(
                  'Não foi possível salvar as informações',
                  'error',
                );
              }, 1000);
              handleBackDrop(
                'Você não possui autorização ou suas credenciais expirarão.',
                'Por favor, atualize a baixa ou refaça o processo dee login.',
                401,
                true,
              );
            } else if (response.status === 400) {
              setIsSending(false);
              setTimeout(() => {
                handleNotiStack(
                  'Não foi possível salvar as informações',
                  'error',
                );
              }, 1000);
              handleBackDrop(
                'Requisição inválida.',
                'Confira se todos os campos estão preenchidos corretamente.',
                400,
                true,
              );
            } else if (response.status === 500) {
              setIsSending(false);
              setTimeout(() => {
                handleNotiStack(
                  'Não foi possível salvar as informações',
                  'error',
                );
              }, 1000);
              handleBackDrop(
                'Nosso servidor está passando por irregularidades.',
                'Estamos trabalhado para resolver o mais rápido possível.',
                500,
                true,
              );
            }
          })
          .catch(error => {
            setIsSending(false);
            setTimeout(() => {
              handleNotiStack(
                'Não foi possível salvar as informações',
                'error',
              );
            }, 1000);
            handleBackDrop(
              'Nosso servidor está passando por irregularidades.',
              'Estamos trabalhado para resolver o mais rápido possível.',
              500,
              true,
            );
            console.error(error);
          });
      });
    }
  }

  return (
    <div>
      {isLoading ? (
        <div
          style={{
            display: 'flex',
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            height: height,
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <div>
          {isVisible ? (
            <Fab
              onClick={() => scrollToTop()}
              style={{position: 'fixed', bottom: 20, right: 20, zIndex: 2}}
              size={'small'}
              color="primary"
              aria-label="add"
            >
              <ArrowUpwardIcon />
            </Fab>
          ) : null}
          <div
            style={{marginLeft: 10, marginTop: 10, marginBottom: 10}}
            className="btn-toggle"
            onClick={() => handleToggleSidebar(true)}
          >
            <FaBars />
          </div>
          <div
            style={{
              display: 'flex',
              backgroundColor: '#FFFFFF',
              flex: 1,
              flexDirection: 'column',
              marginBottom: 30,
              width: 'auto',
            }}
          >
            <div>
              <ContentMap
                width={width}
                style={{width: width, height: 300}}
                isVerySmall={isVerySmall}
                isSmall={isSmall}
                marker={marker}
                setMarker={setMarker}
              />
            </div>

            <div style={{marginLeft: 10, marginRight: 10}}>
              <p
                style={{
                  width: '90%',
                  padding: 10,
                  color: '#393939',
                  backgroundColor: '#F4f4f4',
                  borderRadius: 10,
                }}
              >
                * Para ajustar a localização basta clicar em cima da localização
                da sua loja no mapa acima. Um circulo preto irá aparecer
                mostrando o local atual da sua loja.
              </p>
              <p
                style={{
                  width: '90%',
                  padding: 10,
                  color: '#393939',
                  backgroundColor: '#ddffdd',
                  borderRadius: 10,
                }}
              >
                * Ajuste sua localização para facilitar as retiradas e agilizar
                a chegada dos entregadores em seu estabelecimento. Lembre-se a
                Irê it calcula o frete de acordo com a distância entre o ponto
                de entrega e a localização da loja.
              </p>
            </div>
            <div>
              <TextField
                style={{
                  marginTop: 30,
                  width: '90%',
                  marginBottom: 10,
                  paddingLeft: 10,
                  paddingRight: 10,
                }}
                inputProps={{
                  style: {fontSize: 14, padding: 6, color: '#797979'},
                  maxLength: 100,
                }}
                InputLabelProps={{
                  style: {fontSize: 14, marginTop: -8, color: '#797979'},
                }}
                id="endereco"
                variant="outlined"
                label="Endereço"
                defaultValue={endereco}
                value={endereco}
                onChange={event => {
                  setEndereco(event.target.value);
                }}
              />
              <div>
                <TextField
                  style={{
                    marginTop: 30,
                    width: 100,
                    marginBottom: 10,
                    paddingLeft: 10,
                    paddingRight: 10,
                  }}
                  inputProps={{
                    style: {fontSize: 14, padding: 6, color: '#797979'},
                    maxLength: 100,
                  }}
                  InputLabelProps={{
                    style: {fontSize: 14, marginTop: -8, color: '#797979'},
                  }}
                  id="numero"
                  variant="outlined"
                  label="Número"
                  defaultValue={numero}
                  value={numero}
                  type={'number'}
                  onChange={event => {
                    setNumero(event.target.value);
                  }}
                />
                <TextField
                  style={{
                    marginTop: 30,
                    width: 100,
                    marginBottom: 10,
                    paddingLeft: 10,
                    paddingRight: 10,
                  }}
                  inputProps={{
                    style: {fontSize: 14, padding: 6, color: '#797979'},
                    maxLength: 100,
                  }}
                  InputLabelProps={{
                    style: {fontSize: 14, marginTop: -8, color: '#797979'},
                  }}
                  id="Bairro"
                  variant="outlined"
                  label="Bairro"
                  defaultValue={bairro}
                  value={bairro}
                  onChange={event => {
                    setBairro(event.target.value);
                  }}
                />
                <TextField
                  style={{
                    marginTop: 30,
                    width: 100,
                    marginBottom: 10,
                    paddingLeft: 10,
                    paddingRight: 10,
                  }}
                  inputProps={{
                    style: {fontSize: 14, padding: 6, color: '#797979'},
                    maxLength: 100,
                  }}
                  InputLabelProps={{
                    style: {fontSize: 14, marginTop: -8, color: '#797979'},
                  }}
                  id="Cidade"
                  variant="outlined"
                  label="Cidade"
                  defaultValue={cidade}
                  value={cidade}
                  onChange={event => {
                    setCidade(event.target.value);
                  }}
                />
              </div>
              <div style={{marginTop: 15}}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="demo-simple-select-outlined-label">
                    Estado
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={value}
                    onChange={handleChange}
                    label="Estado"
                  >
                    {(Array.isArray(estados)
                      ? estados.map(({title}) => [title])
                      : Object.entries(estados)
                    ).map(([title]) => {
                      return (
                        <MenuItem
                          style={{fontSize: 14}}
                          key={title}
                          value={title}
                        >
                          {title}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
              <Button
                onClick={() => onSave()}
                style={{marginLeft: 10, marginTop: 20}}
                variant="contained"
                size={'medium'}
                color="primary"
              >
                Salvar
              </Button>
            </div>
          </div>
        </div>
      )}
      <AlertSending
        bopen={isSending}
        handleClose={() => setIsSending(false)}
        handleNotiStack
        style={{
          top: 0,
          position: 'absolute',
          zIndex: 3,
        }}
      />
    </div>
  );
};

export default Location;
