/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import {useMediaQuery} from 'react-responsive';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import SyncIcon from '@material-ui/icons/Sync';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import ChoosePayment from './choosePayment';
import CheckPayment from './checkPayment';
import PaymentPage from './paymentPage';

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 500,
  },
  tabs: {
    fontSize: 14,
  },
  labelContainer: {
    padding: 0,
  },
  tab: {
    fontSize: 7,
    minWidth: '33.3%',
    width: '33.3%',
    minHeight: 0,
    height: 70,
    fontWeight: 'bold',
  },
}));

export default function PaymentTabs({
  value,
  handleChange,
  handleChangeIndex,
  paymentDone,
  setPaymentDone,
  frm,
  handleChangeFrm,
  loadingFrmFat,
  bBoleto,
  bPix,
  bCard,
  cdg,
  setCdg,
  exppix,
  setExppix,
  expblt,
  setExpblt,
  urlblt,
  setUrlblt,
  downloadBlt,
  setDownloadBlt,
  fatSelected,
  checkIfPaymentIsDone,
  currentBoleto,
  currentPix,
  pixDone,
  pixExp,
  boletoExp,
  generateBoleto,
  generatePix,
  getBoleto,
  getPix,
  isLoadingPagePayment,
  setLoadingPagePayment,
  handleNotiStack,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const isSmall = useMediaQuery({query: '(max-width: 767.98px)'});
  const isVerySmall = useMediaQuery({query: '(max-width:450.00px)'});

  return (
    <div style={{margintop: 0, paddingTop: 0}} className={classes.root}>
      <AppBar
        position={isVerySmall ? 'fixed' : isSmall ? 'fixed' : 'static'}
        color="default"
      >
        <Tabs
          className={classes.tabs}
          labelContainer={classes.labelContainer}
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="full width tabs example"
        >
          <Tab
            labelContainer={classes.labelContainer}
            className={classes.tab}
            icon={<SyncIcon />}
            label="Verificação"
            {...a11yProps(0)}
          />
          {!paymentDone ? (
            <Tab
              className={classes.tab}
              icon={<AccountBalanceWalletIcon />}
              label="Forma de pagamento"
              {...a11yProps(1)}
            />
          ) : null}

          {!paymentDone && frm != '' ? (
            <Tab
              className={classes.tab}
              icon={<AttachMoneyIcon />}
              label="Pagamento"
              {...a11yProps(2)}
            />
          ) : null}
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel
          style={{overflowX: 'hidden'}}
          value={value}
          index={0}
          dir={theme.direction}
        >
          <CheckPayment
            value={value}
            handleChange={handleChange}
            handleChangeIndex={handleChangeIndex}
            paymentDone={paymentDone}
            setPaymentDone={setPaymentDone}
            loadingFrmFat={loadingFrmFat}
          />
        </TabPanel>
        <TabPanel
          style={{overflowX: 'hidden'}}
          value={value}
          index={1}
          dir={theme.direction}
        >
          <ChoosePayment
            frm={frm}
            handleChangeFrm={handleChangeFrm}
            bBoleto={bBoleto}
            bPix={bPix}
            bCard={bCard}
            loadingFrmFat={loadingFrmFat}
            fatSelected={fatSelected}
          />
        </TabPanel>
        <TabPanel
          style={{overflowX: 'hidden'}}
          value={value}
          index={2}
          dir={theme.direction}
        >
          {frm == '' ? (
            <ChoosePayment
              frm={frm}
              handleChangeFrm={handleChangeFrm}
              bBoleto={bBoleto}
              bPix={bPix}
              bCard={bCard}
              loadingFrmFat={loadingFrmFat}
              fatSelected={fatSelected}
            />
          ) : (
            <PaymentPage
              frm={frm}
              cdg={cdg}
              setCdg={setCdg}
              exppix={exppix}
              setExppix={setExppix}
              expblt={expblt}
              setExpblt={setExpblt}
              urlblt={urlblt}
              setUrlbt={setUrlblt}
              downloadBlt={downloadBlt}
              setDownloadBlt={setDownloadBlt}
              fatSelected={fatSelected}
              checkIfPaymentIsDone={checkIfPaymentIsDone}
              currentBoleto={currentBoleto}
              currentPix={currentPix}
              pixDone={pixDone}
              pixExp={pixExp}
              boletoExp={boletoExp}
              generateBoleto={generateBoleto}
              generatePix={generatePix}
              getBoleto={getBoleto}
              getPix={getPix}
              isLoadingPagePayment={isLoadingPagePayment}
              setLoadingPagePayment={setLoadingPagePayment}
              value={value}
              handleNotiStack={handleNotiStack}
            />
          )}
        </TabPanel>
      </SwipeableViews>
    </div>
  );
}
