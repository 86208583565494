/* eslint-disable react/prop-types */
import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import {useMediaQuery} from 'react-responsive';
import {Button, DialogActions} from '@material-ui/core';

const AvaliationPolicy = ({bopen, handleClose}) => {
  const isVerySmall = useMediaQuery({query: '(max-width:450.00px)'});

  function close() {
    handleClose();
  }

  return (
    <div>
      <Dialog
        onClose={() => close()}
        style={{
          marginLeft: isVerySmall ? -20 : 0,
          marginRight: isVerySmall ? -20 : 0,
          paddingLeft: 0,
        }}
        aria-labelledby="customized-dialog-title"
        open={bopen}
      >
        <div style={{padding: 20}}>
          <h2>Política de Moderação de Avaliações Irê It:</h2>

          <p>
            A moderação de avaliações é uma parte essencial da manutenção de um
            ambiente respeitoso e construtivo para todos os usuários do Irê It.
            Para garantir a qualidade das avaliações, implementamos as seguintes
            diretrizes de moderação baseadas em quatro tópicos principais:
          </p>

          <ul>
            <li>
              <strong>
                1. Contendo Ofensas, Palavrões ou Discurso de Ódio:
              </strong>{' '}
              Avaliações que contenham linguagem ofensiva, palavrões ou discurso
              de ódio serão moderadas. Buscamos criar um ambiente positivo e
              respeitoso, e qualquer conteúdo que viole esses princípios será
              removido.
            </li>

            <li>
              <strong>
                2. Avaliações Relacionadas ao Irê It, Não à Sua Loja:
              </strong>{' '}
              Avaliações que se concentrem exclusivamente no Irê It, e não na
              experiência específica do usuário com a loja Irê It, serão
              moderadas. Encorajamos os usuários a compartilharem feedbacks
              relevantes para aprimorar os serviços da nossa plataforma, mas a
              moderação visa manter o foco nas experiências individuais.
            </li>

            <li>
              <strong>3. Itens Não Relacionados ao Pedido:</strong> Avaliações
              que mencionem itens ou serviços que não fazem parte do pedido
              realizado serão moderadas. Isso garante que as avaliações estejam
              diretamente relacionadas à experiência de entrega de alimentos do
              Irê It.
            </li>

            <li>
              <strong>4. Suspeita de Pedido de Trote:</strong> Avaliações que
              contenham suspeita de pedido de trote ou informações
              inconsistentes serão moderadas. A suspeita de pedidos de trote
              prejudica a integridade do sistema de avaliação e, portanto, essas
              avaliações serão removidas para manter a precisão e utilidade do
              feedback.
            </li>
          </ul>

          <p>
            <strong>Procedimentos de Moderação:</strong> As avaliações que
            violarem essas diretrizes serão sujeitas a moderação. A equipe de
            moderação revisará as avaliações denunciadas e tomará medidas
            apropriadas, que podem incluir edição, remoção ou aplicação de
            advertências.
          </p>

          <p>
            <strong>Transparência e Comunicação:</strong> Nosso processo de
            moderação é transparente, e os usuários serão informados sobre a
            razão da moderação de suas avaliações. Os usuários têm o direito de
            contestar a moderação e apresentar esclarecimentos, se necessário.
          </p>

          <p>
            <strong>Prazo para Publicação de Comentários:</strong> A Irê It tem
            até 7 dias úteis para postar os comentários na página da loja dentro
            do aplicativo da Irê It. Este prazo visa garantir que as avaliações
            sejam processadas e publicadas de maneira adequada, proporcionando
            transparência e informações oportunas aos usuários.
          </p>

          <p>
            <strong>Conformidade com a Política de Privacidade:</strong> Estas
            regras de moderação estão em conformidade com a política de
            privacidade da Irê It, disponível para consulta no link{' '}
            <a
              href="https://www.ireit.com.br/politica_de_privacidade"
              target="_blank"
              rel="noopener noreferrer"
            >
              Política de Privacidade Irê It
            </a>
            . Os usuários são encorajados a revisar a política de privacidade
            para compreender como suas informações pessoais são tratadas pela
            Irê It.
          </p>
        </div>
        <DialogActions style={{position: 'relative', bottom: 0}}>
          <Button onClick={() => close()}>Fechar</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AvaliationPolicy;
