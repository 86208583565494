/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, {useState, useEffect, useRef, useCallback} from 'react';
import {GoogleMap, Polygon, useJsApiLoader} from '@react-google-maps/api';
import {Circle} from '@react-google-maps/api';
import {Marker} from '@react-google-maps/api';
import {useSelector} from 'react-redux';

const containerStyle = {
  width: '100%',
  height: '500px',
};

const ContentMap = ({
  width,
  isDistance,
  setIsDistance,
  raio,
  setRaio,
  handleChange,
  path,
  setPath,
  currentzone,
}) => {
  const {isLoaded} = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyADIHr5UwS75lsAsHpdTHG2u5i9y2nBpO4',
  });

  const [zoom, setZoom] = useState(25.35);

  const clat = useSelector(
    state => state.setrestaurante.location.coordinates[1],
  );

  const clng = useSelector(
    state => state.setrestaurante.location.coordinates[0],
  );
  const cPath = useSelector(state => state.setrestaurante.aratdmii.coordinates);

  useEffect(() => {
    setTimeout(() => {
      setZoom(12.35);
    }, 1000);
  }, []);

  useEffect(() => {
    let newpath = [];
    try {
      currentzone[0].forEach(s => newpath.push({lat: s[1], lng: s[0]}));
      setPath(newpath);
    } catch (err) {
      console.log(err);
    }
  }, [currentzone]);

  // Define refs for Polygon instance and listeners
  const polygonRef = useRef(null);
  const listenersRef = useRef([]);

  // Call setPath with new edited path
  const onEdit = useCallback(() => {
    if (polygonRef.current) {
      const nextPath = polygonRef.current
        .getPath()
        .getArray()
        .map(latLng => {
          return {lat: latLng.lat(), lng: latLng.lng()};
        });
      setPath(nextPath);
    }
  }, [setPath]);

  const [map, setMap] = React.useState(null);
  const mapRef = useRef(GoogleMap);

  const onLoad = React.useCallback(function callback(map) {
    setMap(map);
    setZoom(12.35);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  const onLoad2 = useCallback(
    polygon => {
      polygonRef.current = polygon;
      const path = polygon.getPath();
      listenersRef.current.push(
        path.addListener('set_at', onEdit),
        path.addListener('insert_at', onEdit),
        path.addListener('remove_at', onEdit),
      );
    },
    [onEdit],
  );

  // Clean up refs
  const onUnmount2 = useCallback(() => {
    listenersRef.current.forEach(lis => lis.remove());
    polygonRef.current = null;
  }, []);

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={{lat: clat, lng: clng}}
      zoom={zoom}
      onLoad={onLoad}
      onUnmount={onUnmount}
      ref={mapRef}
    >
      <div
        style={{
          position: 'absolute',
          display: 'flex',
          top: 0,
          left: 0,
          right: 0,
          zIndex: 2,
          marginLeft: '20%',
          marginRight: '20%',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      ></div>

      <Marker
        icon={{
          // eslint-disable-next-line no-undef
          path: google.maps.SymbolPath.CIRCLE,
          scale: 5,
        }}
        position={{lat: clat, lng: clng}}
      />

      <Polygon
        // Make the Polygon editable / draggable
        editable={false}
        draggable={false}
        options={{
          strokeOpacity: 1,
          strokeColor: '#06448e',
          fillColor: '#06447e',
        }}
        path={path}
        onMouseUp={onEdit}
        onDragEnd={onEdit}
        onLoad={onLoad2}
        onUnmount={onUnmount2}
      />

      <></>
    </GoogleMap>
  ) : (
    <div style={{width: '100%', height: '500px'}}></div>
  );
};

export default ContentMap;
