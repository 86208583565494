/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import React, {useState, forwardRef, useCallback} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {useSnackbar, SnackbarContent} from 'notistack';
import Collapse from '@material-ui/core/Collapse';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import {useDispatch} from 'react-redux';

const useStyles = makeStyles(() => ({
  root: {
    '@media (min-width:600px)': {
      minWidth: '344px !important',
    },
  },
  card: {
    backgroundColor: '#39B40D',
    width: '100%',
  },
  typography: {
    fontWeight: 'normal',
    color: '#ffffff',
  },
  actionRoot: {
    padding: '8px 8px 8px 16px',
    justifyContent: 'space-between',
  },
  icons: {
    marginLeft: 'auto',
  },
  expand: {
    padding: '8px 8px',
    transform: 'rotate(0deg)',
    transition: 'all .2s',
  },
  collapse: {
    padding: 16,
  },
  checkIcon: {
    fontSize: 16,
    paddingRight: 4,
    marginLeft: 5,
  },
  button: {
    padding: 0,
    textTransform: 'none',
  },
}));

const ReportComplete = forwardRef((props, ref) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const dispatch = useDispatch();
  const {closeSnackbar} = useSnackbar();
  const handleExpandClick = useCallback(() => {
    setExpanded(oldExpanded => !oldExpanded);
  }, []);

  const handleDismiss = useCallback(() => {
    closeSnackbar(props.id);
  }, [props.id, closeSnackbar]);

  function changeScreen(route) {
    dispatch(props.ActionScreen.setChangeScreen(route));
    handleDismiss();
  }

  return (
    <SnackbarContent ref={ref} className={classes.root}>
      <Card className={classes.card}>
        <CardActions classes={{root: classes.actionRoot}}>
          <Typography variant="subtitle2" className={classes.typography}>
            {props.message}
          </Typography>
          <div className={classes.icons}>
            <IconButton
              aria-label="Show more"
              className={classes.expand}
              style={
                (expanded ? {transform: 'rotate(180deg)'} : null,
                {color: '#FFFFFF'})
              }
              onClick={handleExpandClick}
            >
              <ExpandMoreIcon />
            </IconButton>
            <IconButton
              className={classes.expand}
              onClick={handleDismiss}
              style={{color: '#FFFFFF'}}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Paper className={classes.collapse}>
            <Typography style={{fontSize: 12}} gutterBottom>
              {props.submessage}
            </Typography>
            <Button
              variant="outlined"
              color="primary"
              size="small"
              className={classes.button}
              style={{paddingLeft: 5, alignItems: 'center'}}
              onClick={() => changeScreen(props.route)}
            >
              {props.txtbutton}
              <ChevronRightIcon color="primary" className={classes.checkIcon} />
            </Button>
          </Paper>
        </Collapse>
      </Card>
    </SnackbarContent>
  );
});

export default ReportComplete;
