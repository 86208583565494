export function setnotifications(notifications) {
  return {
    type: 'SET_NOTIFICATIONS',
    notifications,
  };
}

export function setpedidos(pedidos) {
  return {
    type: 'SET_PEDIDOS',
    pedidos,
  };
}
