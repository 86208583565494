/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, {useState, useEffect} from 'react';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import {useMediaQuery} from 'react-responsive';
import DeleteIcon from '@material-ui/icons/Delete';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';
import {useSelector, useDispatch} from 'react-redux';
import * as ActionItem from '../../../store/actions/setItem';
import {Box} from '@material-ui/core';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)(props => {
  const {children, classes, onClose, ...other} = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

// eslint-disable-next-line react/prop-types
const DialogReoderComps = ({
  list,
  currentIndex,
  setList,
  bopen,
  handleClose,
}) => {
  const isSmall = useMediaQuery({query: '(max-width: 750.98px)'});
  const isVerySmall = useMediaQuery({query: '(max-width:450.00px)'});
  const [isLoading, setLoading] = useState(true);
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const handleClose2 = () => {
    setValue(0);
    setOpen(false);
  };

  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChangeIndex = index => {
    setValue(index);
  };

  function close() {
    setValue(0);
    handleClose();
  }

  function onSave() {
    let newListComps = list.map((item, index) => {
      if (index === currentIndex) {
        return {...item, data: newList};
      } else {
        return item;
      }
    });

    dispatch(ActionItem.setItens(newListComps));

    setTimeout(() => {
      setList(newListComps);
      setValue(0);
      handleClose();
    }, 100);
  }

  const handleClickOpen2 = () => {
    setOpen(true);
  };

  const [itens, setItens] = useState(list);

  useEffect(() => {
    setTimeout(() => {
      let newlist = [...list];
      if (newlist[currentIndex] === undefined || newlist[currentIndex] === []) {
        setNewList([]);
      } else {
        setNewList(newlist[currentIndex].data);
      }
    }, 100);
  }, [list, currentIndex]);

  const [newList, setNewList] = useState([]);

  const Listdata = () => {
    let list = newList.map((item, index) => {
      return (
        <div key={item.id}>
          <Box
            style={{
              marginLeft: 10,
              marginRight: 10,
              borderRadius: 5,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginTop: 10,
            }}
            boxShadow={3}
          >
            <p style={{padding: 10}}>{item.nome} </p>
            <div style={{marginRight: 10}}>
              {index === 0 ? null : (
                <IconButton
                  onClick={() => moveUp(newList, index)}
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                  size={'small'}
                  style={{paddingLeft: 0}}
                >
                  <ArrowUpwardIcon />
                </IconButton>
              )}
              {index === itens.length - 1 ? null : (
                <IconButton
                  onClick={() => moveDown(newList, index)}
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                  size={'small'}
                  style={{paddingLeft: 0}}
                >
                  <ArrowDownwardIcon />
                </IconButton>
              )}
            </div>
          </Box>
        </div>
      );
    });
    return list;
  };

  function moveUp(lister, index) {
    let nlist = array_move(lister, index, index - 1);
    let lista = nlist.map((item, index) => {
      return item;
    });
    setNewList(lista);
  }

  function moveDown(lister, index) {
    let nlist = array_move(lister, index, index + 1);
    let lista = nlist.map((item, index) => {
      return item;
    });
    setNewList(lista);
  }

  function array_move(liste, old_index, new_index) {
    let arr = liste.map((item, index) => {
      return item;
    });

    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
  }

  return (
    <div>
      <Dialog
        onClose={() => close()}
        style={{
          marginLeft: isVerySmall ? -20 : 0,
          marginRight: isVerySmall ? -20 : 0,
          paddingLeft: 0,
        }}
        aria-labelledby="customized-dialog-title"
        open={bopen}
      >
        <div
          style={{
            backgroundColor: '#FFffff',
            width: isVerySmall ? 'auto' : 300,
            height: 'auto',
            paddingLeft: 10,
            paddingRight: 10,
          }}
        ></div>
        <Listdata />
        <Divider variant="middle" />
        <DialogActions style={{position: 'relative', bottom: 0}}>
          {isVerySmall ? (
            <Button size={'small'} onClick={() => close()}>
              Cancelar
            </Button>
          ) : (
            <Button onClick={() => close()}>Cancelar</Button>
          )}
          {isSmall ? (
            <Button size={'small'} onClick={() => onSave()} color="primary">
              Salvar
            </Button>
          ) : (
            <Button onClick={() => onSave()} color="primary">
              Salvar
            </Button>
          )}
        </DialogActions>
      </Dialog>

      <Dialog
        open={open}
        onClose={() => close()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Excluir item?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Deseja deletar o item?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose2} color="primary">
            Não
          </Button>
          <Button onClick={handleClose2} color="primary">
            Sim
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DialogReoderComps;
