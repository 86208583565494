/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, {useState, useEffect} from 'react';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import FullWidthTab from './TabsItem';
import Divider from '@material-ui/core/Divider';
import {useMediaQuery} from 'react-responsive';
import DeleteIcon from '@material-ui/icons/Delete';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';
import {useSelector} from 'react-redux';
import {Box} from '@material-ui/core';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import AlertSending from '../../components/AlertSeding';
import {getAuth} from 'firebase/auth';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)(props => {
  const {children, classes, onClose, ...other} = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const DialogEditCat = ({
  bopen,
  handleClose,
  handleNotiStack,
  handleBackDrop,
  updateCatalogo,
  currentList,
}) => {
  const auth = getAuth();
  const isSmall = useMediaQuery({query: '(max-width: 750.98px)'});
  const isVerySmall = useMediaQuery({query: '(max-width:450.00px)'});
  const [isLoading, setLoading] = useState(true);
  const [open, setOpen] = React.useState(false);
  const [Ctitle, setCTitle] = useState('');
  const cat = useSelector(state => state.setcats.cat);
  const itens = useSelector(state => state.setcats.itens);
  const idstore = useSelector(state => state.setrestaurante.idstore);
  const idcat = useSelector(state => state.setcats.id);

  const [newList, setNewList] = useState([]);

  const handleClose2 = () => {
    setOpen(false);
  };

  function close() {
    handleClose();
  }

  const [isSending, setIsSending] = useState(false);
  function onSave() {
    updatecat();
  }

  const handleClickOpen2 = () => {
    setOpen(true);
  };

  useEffect(() => {
    setTimeout(() => {
      setCTitle(cat);
      setNewList(itens);
    }, 100);
  }, [cat]);

  const Listdata = () => {
    let list = newList.map((item, index) => {
      return (
        <div key={item.id}>
          <Box
            style={{
              marginLeft: 10,
              marginRight: 10,
              borderRadius: 5,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginTop: 10,
            }}
            boxShadow={3}
          >
            <p style={{padding: 10}}>{item.nome} </p>
            <div style={{marginRight: 10}}>
              {index === 0 ? null : (
                <IconButton
                  onClick={() => moveUp(item, index)}
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                  size={'small'}
                  style={{paddingLeft: 0}}
                >
                  <ArrowUpwardIcon />
                </IconButton>
              )}
              {index === itens.length - 1 ? null : (
                <IconButton
                  onClick={() => moveDown(item, index)}
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                  size={'small'}
                  style={{paddingLeft: 0}}
                >
                  <ArrowDownwardIcon />
                </IconButton>
              )}
            </div>
          </Box>
        </div>
      );
    });
    return list;
  };

  function moveUp(item, index) {
    let nlist = array_move(newList, index, index - 1);
    let list = nlist.map((item, index) => {
      return item;
    });
    setNewList(list);
  }

  function moveDown(item, index) {
    let nlist = array_move(newList, index, index + 1);
    let list = nlist.map((item, index) => {
      return item;
    });
    setNewList(list);
  }

  function array_move(arr, old_index, new_index) {
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing
  }

  function deletecat() {
    setIsSending(true);
    auth.currentUser.getIdToken().then(token => {
      const request = new Request(
        'https://kops.apiireit.com/gateway/produtos/delete/' +
          idcat +
          '?idstore=' +
          idstore,
        {
          method: 'DELETE',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        },
      );

      fetch(request)
        .then(response => {
          if (response.status === 200) {
            handleBackDrop('', '', 200, false);
            setTimeout(() => {
              handleClose2();
              handleClose();
              setIsSending(false);
              handleNotiStack('Categoria deletada com sucesso', 'success');
              var index = currentList
                .map(x => {
                  return x.id;
                })
                .indexOf(idcat);
              currentList.splice(index, 1);
            }, 1000);
            updateCatalogo();
          } else if (response.status === 503) {
            setIsSending(false);
            setTimeout(() => {
              handleNotiStack(
                'Não foi possível salvar as informações',
                'error',
              );
            }, 1000);
            handleClose2();
            handleClose();
            handleBackDrop(
              'Serviço indisponível no momento',
              'O serviços para as lojas Irê it não estão disponíveis no momento. Por favor, tente novamente mais tarde',
              503,
              true,
            );
            throw new Error('Something went wrong on API server!');
          } else if (response.status === 404) {
            setIsSending(false);
            setTimeout(() => {
              handleNotiStack(
                'Não foi possível salvar as informações',
                'error',
              );
            }, 1000);
            handleClose2();
            handleClose();
            handleBackDrop(
              'Serviço não encontrado.',
              'Por favor, entre em contato com o nosso suporte e relate o problema.',
              404,
              true,
            );
          } else if (response.status === 401) {
            setIsSending(false);
            setTimeout(() => {
              handleNotiStack(
                'Não foi possível salvar as informações',
                'error',
              );
            }, 1000);
            handleClose2();
            handleClose();
            handleBackDrop(
              'Você não possui autorização ou suas credenciais expirarão.',
              'Por favor, atualize a baixa ou refaça o processo dee login.',
              401,
              true,
            );
          } else if (response.status === 400) {
            setIsSending(false);
            setTimeout(() => {
              handleNotiStack(
                'Não foi possível salvar as informações',
                'error',
              );
            }, 1000);
            handleClose2();
            handleClose();
            handleBackDrop(
              'Requisição inválida.',
              'Confira se todos os campos estão preenchidos corretamente.',
              400,
              true,
            );
          } else if (response.status === 500) {
            setIsSending(false);
            setTimeout(() => {
              handleNotiStack(
                'Não foi possível salvar as informações',
                'error',
              );
            }, 1000);
            handleClose2();
            handleClose();
            handleBackDrop(
              'Nosso servidor está passando por irregularidades.',
              'Estamos trabalhado para resolver o mais rápido possível.',
              500,
              true,
            );
          }
        })
        .catch(error => {
          setIsSending(false);
          setTimeout(() => {
            handleNotiStack('Não foi possível salvar as informações', 'error');
          }, 1000);
          handleClose2();
          handleClose();
          handleBackDrop(
            'Nosso servidor está passando por irregularidades.',
            'Estamos trabalhado para resolver o mais rápido possível.',
            500,
            true,
          );
          console.error(error);
        });
    });
  }

  function updatecat() {
    setIsSending(true);
    auth.currentUser.getIdToken().then(token => {
      const request = new Request(
        'https://kops.apiireit.com/gateway/produtos/update/cat/' +
          idcat +
          '?newname=' +
          Ctitle,
        {
          method: 'PUT',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(newList),
        },
      );

      fetch(request)
        .then(response => {
          if (response.status === 200) {
            handleBackDrop('', '', 200, false);
            setTimeout(() => {
              handleClose2();
              handleClose();
              setIsSending(false);
              handleNotiStack('Categorias atualizadas com sucesso', 'success');
            }, 1000);
            updateCatalogo();
          } else if (response.status === 503) {
            setIsSending(false);
            setTimeout(() => {
              handleNotiStack(
                'Não foi possível salvar as informações',
                'error',
              );
            }, 1000);
            handleClose2();
            handleClose();
            handleBackDrop(
              'Serviço indisponível no momento',
              'O serviços para as lojas Irê it não estão disponíveis no momento. Por favor, tente novamente mais tarde',
              503,
              true,
            );
            throw new Error('Something went wrong on API server!');
          } else if (response.status === 404) {
            setIsSending(false);
            setTimeout(() => {
              handleNotiStack(
                'Não foi possível salvar as informações',
                'error',
              );
            }, 1000);
            handleClose2();
            handleClose();
            handleBackDrop(
              'Serviço não encontrado.',
              'Por favor, entre em contato com o nosso suporte e relate o problema.',
              404,
              true,
            );
          } else if (response.status === 401) {
            setIsSending(false);
            setTimeout(() => {
              handleNotiStack(
                'Não foi possível salvar as informações',
                'error',
              );
            }, 1000);
            handleClose2();
            handleClose();
            handleBackDrop(
              'Você não possui autorização ou suas credenciais expirarão.',
              'Por favor, atualize a baixa ou refaça o processo dee login.',
              401,
              true,
            );
          } else if (response.status === 400) {
            setIsSending(false);
            setTimeout(() => {
              handleNotiStack(
                'Não foi possível salvar as informações',
                'error',
              );
            }, 1000);
            handleClose2();
            handleClose();
            handleBackDrop(
              'Requisição inválida.',
              'Confira se todos os campos estão preenchidos corretamente.',
              400,
              true,
            );
          } else if (response.status === 500) {
            setIsSending(false);
            setTimeout(() => {
              handleNotiStack(
                'Não foi possível salvar as informações',
                'error',
              );
            }, 1000);
            handleClose2();
            handleClose();
            handleBackDrop(
              'Nosso servidor está passando por irregularidades.',
              'Estamos trabalhado para resolver o mais rápido possível.',
              500,
              true,
            );
          }
        })
        .catch(error => {
          setIsSending(false);
          setTimeout(() => {
            handleNotiStack('Não foi possível salvar as informações', 'error');
          }, 1000);
          handleClose2();
          handleClose();
          handleBackDrop(
            'Nosso servidor está passando por irregularidades.',
            'Estamos trabalhado para resolver o mais rápido possível.',
            500,
            true,
          );
          console.error(error);
        });
    });
  }

  return (
    <div>
      <Dialog
        onClose={() => close()}
        style={{
          marginLeft: isVerySmall ? -20 : 0,
          marginRight: isVerySmall ? -20 : 0,
          paddingLeft: 0,
        }}
        aria-labelledby="customized-dialog-title"
        open={bopen}
      >
        <div
          style={{
            backgroundColor: '#FFffff',
            width: isVerySmall ? 'auto' : 300,
            height: 'auto',
            paddingLeft: 10,
            paddingRight: 10,
          }}
        >
          <TextField
            style={{width: '100%', marginTop: 10}}
            inputProps={{
              style: {fontSize: 14},
              maxLength: 150,
            }}
            InputLabelProps={{style: {fontSize: 14}}}
            id="cate"
            label="Categoria"
            variant={'outlined'}
            defaultValue={Ctitle}
            value={Ctitle}
            onChange={event => {
              setCTitle(event.target.value);
            }}
          />
        </div>
        <Listdata />
        <Divider variant="middle" />
        <DialogActions style={{position: 'relative', bottom: 0}}>
          {isVerySmall ? (
            <div
              style={{
                alignItems: 'center',
              }}
            >
              <IconButton
                onClick={handleClickOpen2}
                aria-label="delete"
                color="secondary"
              >
                <DeleteIcon />
              </IconButton>
            </div>
          ) : isSmall ? (
            <div
              style={{
                display: 'flex',
              }}
            >
              <Button
                onClick={handleClickOpen2}
                style={{marginTop: 0}}
                size={'small'}
                color="secondary"
                variant="outlined"
              >
                Deletar
              </Button>
            </div>
          ) : (
            <div
              style={{
                position: 'absolute',
                bottom: 0,
                marginBottom: 10,
                left: 15,
              }}
            >
              <Button
                onClick={handleClickOpen2}
                style={{marginTop: 0}}
                size={'small'}
                color="secondary"
                variant="outlined"
              >
                Deletar
              </Button>
            </div>
          )}
          {isVerySmall ? (
            <Button size={'small'} onClick={() => close()}>
              Cancelar
            </Button>
          ) : (
            <Button onClick={() => close()}>Cancelar</Button>
          )}
          {isSmall ? (
            <Button size={'small'} onClick={() => onSave()} color="primary">
              Salvar
            </Button>
          ) : (
            <Button onClick={() => onSave()} color="primary">
              Salvar
            </Button>
          )}
        </DialogActions>
      </Dialog>

      <Dialog
        open={open}
        onClose={() => close()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Deletar categoria?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Deseja deletar a categoria e todos os seus itens?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose2} color="primary">
            Não
          </Button>
          <Button onClick={() => deletecat()} color="primary">
            Sim
          </Button>
        </DialogActions>
      </Dialog>
      <AlertSending
        bopen={isSending}
        handleClose={() => setIsSending(false)}
        handleNotiStack
        style={{
          top: 0,
          position: 'absolute',
          zIndex: 3,
        }}
      />
    </div>
  );
};

export default DialogEditCat;
