import React, {useState} from 'react';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Divider from '@material-ui/core/Divider';
import {useMediaQuery} from 'react-responsive';
import CircularProgress from '@material-ui/core/CircularProgress';

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

// eslint-disable-next-line react/prop-types
const AlertSending = ({bopen, handleClose}) => {
  const isSmall = useMediaQuery({query: '(max-width: 750.98px)'});
  const isVerySmall = useMediaQuery({query: '(max-width:450.00px)'});
  const [isLoading, setLoading] = useState(true);

  function close() {
    handleClose();
  }

  function onSave() {
    setLoading(true);
    handleClose();
  }

  return (
    <div>
      <Dialog
        onClose={() => close()}
        style={{
          marginLeft: isVerySmall ? -20 : 0,
          marginRight: isVerySmall ? -20 : 0,
          paddingLeft: 0,
        }}
        aria-labelledby="customized-dialog-title"
        open={bopen}
      >
        <div>
          {isLoading ? (
            <div
              style={{
                display: 'flex',
                flex: 1,
                alignItems: 'center',
                justifyContent: 'center',
                height: 100,
                marginLeft: 20,
                marginRight: 20,
              }}
            >
              <CircularProgress />
              <p1 style={{marginLeft: 20}}>Salvando...</p1>
            </div>
          ) : null}
        </div>

        {isLoading ? null : (
          <div>
            <DialogActions style={{position: 'relative', bottom: 0}}>
              {isVerySmall ? (
                <Button size={'small'} onClick={() => close()}>
                  Cancelar
                </Button>
              ) : (
                <Button onClick={() => close()}>Cancelar</Button>
              )}
              {isSmall ? (
                <Button size={'small'} onClick={() => onSave()} color="primary">
                  Adicionar
                </Button>
              ) : (
                <Button onClick={() => close()} color="primary">
                  Adicionar
                </Button>
              )}
            </DialogActions>
          </div>
        )}

        <Divider variant="middle" />
      </Dialog>
    </div>
  );
};

export default AlertSending;
