/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, {useState, useLayoutEffect, useEffect} from 'react';
import {useMediaQuery} from 'react-responsive';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  formControl: {
    marginLeft: 10,
    minWidth: 120,
    background: '#FFFFFF',
    backgroundColor: '#FFFFFF',
  },
}));

// eslint-disable-next-line react/prop-types
const ChoosePayment = ({
  frm,
  handleChangeFrm,
  bBoleto,
  bPix,
  bCard,
  loadingFrmFat,
  fatSelected,
}) => {
  const isSmall = useMediaQuery({query: '(max-width: 750.98px)'});
  const isVerySmall = useMediaQuery({query: '(max-width:450.00px)'});
  const [width, height] = useWindowSize();
  const [isLoading, setLoading] = useState(true);
  const classes = useStyles();
  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  }

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  return (
    <div>
      {isLoading || loadingFrmFat ? (
        <div
          style={{
            display: 'flex',
            width: isVerySmall ? '45%' : '100%',
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <div>
          {isVerySmall ? (
            <div style={{}}>
              <FormControl component="fieldset">
                <FormLabel
                  component="legend"
                  style={{
                    color: '#3d3d3d',
                    fontWeight: 'bold',
                    fontSize: 18,
                    marginBottom: 10,
                  }}
                >
                  Escolha a forma de pagamento
                </FormLabel>
                <RadioGroup
                  aria-label="Forma de pagamento"
                  name="frmpayment"
                  value={frm}
                  onChange={handleChangeFrm}
                >
                  {bPix ? (
                    <FormControlLabel
                      value="Pix"
                      control={<Radio color="primary" />}
                      label="Pix"
                    />
                  ) : null}
                  {bBoleto && fatSelected.total <= -5 ? (
                    <FormControlLabel
                      value="Boleto"
                      control={<Radio color="primary" />}
                      label="Boleto"
                    />
                  ) : null}
                  {bCard ? (
                    <FormControlLabel
                      value="Card"
                      control={<Radio color="primary" />}
                      label="Cartão de crédito"
                    />
                  ) : null}
                </RadioGroup>
              </FormControl>
            </div>
          ) : isSmall ? (
            <div style={{width: width * 0.675}}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  backgroundColor: '#FFFFFF',
                  paddingBottom: 10,
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  paddingRight: 20,
                  paddingLeft: 20,
                }}
              >
                <FormControl component="fieldset">
                  <FormLabel
                    component="legend"
                    style={{
                      color: '#3d3d3d',
                      fontWeight: 'bold',
                      fontSize: 18,
                      marginBottom: 10,
                    }}
                  >
                    Escolha a forma de pagamento
                  </FormLabel>
                  <RadioGroup
                    aria-label="Forma de pagamento"
                    name="frmpayment"
                    value={frm}
                    onChange={handleChangeFrm}
                  >
                    {bPix ? (
                      <FormControlLabel
                        value="Pix"
                        control={<Radio color="primary" />}
                        label="Pix"
                      />
                    ) : null}
                    {bBoleto && fatSelected.total <= -5 ? (
                      <FormControlLabel
                        value="Boleto"
                        control={<Radio color="primary" />}
                        label="Boleto"
                      />
                    ) : null}
                    {bCard ? (
                      <FormControlLabel
                        value="Card"
                        control={<Radio color="primary" />}
                        label="Cartão de crédito"
                      />
                    ) : null}
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
          ) : (
            <div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  backgroundColor: '#FFFFFF',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  paddingRight: 20,
                  paddingLeft: 20,
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: '#FFFFFF',
                  }}
                >
                  <FormControl
                    style={{
                      padding: 10,
                      width: '100%',
                      height: '100%',
                    }}
                    className={classes.formControl}
                    variant="filled"
                  >
                    <FormLabel
                      component="legend"
                      style={{
                        color: '#3d3d3d',
                        fontWeight: 'bold',
                        fontSize: 18,
                        marginBottom: 10,
                      }}
                    >
                      Escolha a forma de pagamento
                    </FormLabel>
                    <RadioGroup
                      aria-label="Forma de pagamento"
                      name="frmpayment"
                      value={frm}
                      onChange={handleChangeFrm}
                      color="primary"
                    >
                      {bPix ? (
                        <FormControlLabel
                          value="Pix"
                          control={<Radio color="primary" />}
                          label="Pix"
                        />
                      ) : null}
                      {bBoleto && fatSelected.total <= -5 ? (
                        <FormControlLabel
                          value="Boleto"
                          control={<Radio color="primary" />}
                          label="Boleto"
                        />
                      ) : null}
                      {bCard ? (
                        <FormControlLabel
                          value="Card"
                          control={<Radio color="primary" />}
                          label="Cartão de crédito"
                        />
                      ) : null}
                    </RadioGroup>
                  </FormControl>
                </div>
              </div>
            </div>
          )}
          <div></div>
        </div>
      )}
    </div>
  );
};

export default ChoosePayment;
