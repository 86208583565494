/* eslint-disable react/prop-types */
import React, {useState, useEffect} from 'react';
import {withStyles} from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {useMediaQuery} from 'react-responsive';
import CircularProgress from '@material-ui/core/CircularProgress';

const GreenCheckbox = withStyles({
  root: {
    color: '#C4C4C4',
    '&$checked': {
      color: '#06448E',
    },
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />);

const Disponibilidade = ({
  bdom,
  setbdom,
  bseg,
  setbseg,
  bter,
  setbter,
  bqua,
  setbqua,
  bqui,
  setbqui,
  bsex,
  setbsex,
  bsab,
  setbsab,
  fHorario,
  setFHorario,
}) => {
  const isSmall = useMediaQuery({query: '(max-width: 750.98px)'});
  const isVerySmall = useMediaQuery({query: '(max-width:450.00px)'});
  const [isLoading, setLoading] = useState(true);
  const handleChangeDom = event => {
    setbdom(event.target.checked);
  };

  const handleChangeSeg = event => {
    setbseg(event.target.checked);
  };

  const handleChangeTer = event => {
    setbter(event.target.checked);
  };

  const handleChangeQua = event => {
    setbqua(event.target.checked);
  };

  const handleChangeQui = event => {
    setbqui(event.target.checked);
  };

  const handleChangeSex = event => {
    setbsex(event.target.checked);
  };

  const handleChangeSab = event => {
    setbsab(event.target.checked);
  };

  useEffect(() => {
    setTimeout(() => {
      if (fHorario) {
        setbdom(true);
        setbseg(true);
        setbter(true);
        setbqua(true);
        setbqui(true);
        setbsex(true);
        setbsab(true);
        setFHorario(false);
      }
    }, 1000);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  return (
    <div>
      {isLoading ? (
        <div
          style={{
            display: 'flex',
            width: isVerySmall ? '45%' : '100%',
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            height: isVerySmall ? '100%' : isSmall ? '100%' : 250,
            flexDirection: isVerySmall
              ? 'column'
              : isSmall
              ? 'column'
              : 'column',
          }}
        >
          <p style={{color: '#393939', width: isVerySmall ? 150 : '80%'}}>
            Dias em que o item estará disponível:
          </p>
          <FormControlLabel
            control={
              <GreenCheckbox
                onChange={handleChangeDom}
                checked={bdom}
                name="minPromo"
              />
            }
            style={{color: '#393939'}}
            label={
              <p
                style={{
                  fontSize: 14,
                  color: '#393939',
                }}
              >
                Dom
              </p>
            }
          />
          <FormControlLabel
            control={
              <GreenCheckbox
                onChange={handleChangeSeg}
                checked={bseg}
                name="minPromo"
              />
            }
            style={{color: '#393939'}}
            label={
              <p
                style={{
                  fontSize: 14,
                  color: '#393939',
                }}
              >
                Seg
              </p>
            }
          />
          <FormControlLabel
            control={
              <GreenCheckbox
                onChange={handleChangeTer}
                checked={bter}
                name="minPromo"
              />
            }
            style={{color: '#393939'}}
            label={
              <p
                style={{
                  fontSize: 14,
                  color: '#393939',
                }}
              >
                Ter
              </p>
            }
          />
          <FormControlLabel
            control={
              <GreenCheckbox
                onChange={handleChangeQua}
                checked={bqua}
                name="minPromo"
              />
            }
            style={{color: '#393939'}}
            label={
              <p
                style={{
                  fontSize: 14,
                  color: '#393939',
                }}
              >
                Qua
              </p>
            }
          />
          <FormControlLabel
            control={
              <GreenCheckbox
                onChange={handleChangeQui}
                checked={bqui}
                name="minPromo"
              />
            }
            style={{color: '#393939'}}
            label={
              <p
                style={{
                  fontSize: 14,
                  color: '#393939',
                }}
              >
                Qui
              </p>
            }
          />
          <FormControlLabel
            control={
              <GreenCheckbox
                onChange={handleChangeSex}
                checked={bsex}
                name="minPromo"
              />
            }
            style={{color: '#393939'}}
            label={
              <p
                style={{
                  fontSize: 14,
                  color: '#393939',
                }}
              >
                Sex
              </p>
            }
          />
          <FormControlLabel
            control={
              <GreenCheckbox
                onChange={handleChangeSab}
                checked={bsab}
                name="minPromo"
              />
            }
            style={{color: '#393939'}}
            label={
              <p
                style={{
                  fontSize: 14,
                  color: '#393939',
                }}
              >
                Sab
              </p>
            }
          />
        </div>
      )}
    </div>
  );
};

export default Disponibilidade;
