/* eslint-disable no-unused-vars */
import React, {useState, useEffect} from 'react';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import {useMediaQuery} from 'react-responsive';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';
import {Box} from '@material-ui/core';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const GreenCheckbox = withStyles({
  root: {
    color: '#C4C4C4',
    '&$checked': {
      color: '#06448E',
    },
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />);

const DialogTitle = withStyles(styles)(props => {
  const {children, classes, onClose, ...other} = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

// eslint-disable-next-line react/prop-types
const DialogAddCatComp = ({list, setList, bopen, handleClose}) => {
  const isSmall = useMediaQuery({query: '(max-width: 750.98px)'});
  const isVerySmall = useMediaQuery({query: '(max-width:450.00px)'});
  const [open, setOpen] = React.useState(false);
  const handleClose2 = () => {
    setOpen(false);
  };

  function close() {
    handleClose();
  }

  function onSave() {
    let newItem = {
      id: 'bd7ascbea-c1b1-46c2-aed5-adj00b2zd2sax38sda',
      title: '',
      min: 0,
      max: 1,
      qtd: 0,
      vlr: 0,
      bobg: false,
      bcheck: false,
      data: [],
    };

    // eslint-disable-next-line react/prop-types
    let newlist = list.map(item => {
      return item;
    });

    newlist.splice(0, 0, newItem);
    setList(newlist);

    setTimeout(() => {
      handleClose();
    }, 100);
  }

  const [itens, setItens] = useState(list);

  useEffect(() => {
    setTimeout(() => {
      setNewList(list);
    }, 100);
  }, [list]);

  const [newList, setNewList] = useState([]);

  const Listdata = () => {
    let list = newList.map((item, index) => {
      return (
        <div key={item.id}>
          <Box
            style={{
              marginLeft: 10,
              marginRight: 10,
              borderRadius: 5,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginTop: 10,
            }}
            boxShadow={3}
          >
            <p style={{padding: 10}}>{item.title} </p>
            <div style={{marginRight: 10}}>
              {index === 0 ? null : (
                <IconButton
                  onClick={() => moveUp(item, index)}
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                  size={'small'}
                  style={{paddingLeft: 0}}
                >
                  <ArrowUpwardIcon />
                </IconButton>
              )}
              {index === itens.length - 1 ? null : (
                <IconButton
                  onClick={() => moveDown(item, index)}
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                  size={'small'}
                  style={{paddingLeft: 0}}
                >
                  <ArrowDownwardIcon />
                </IconButton>
              )}
            </div>
          </Box>
        </div>
      );
    });
    return list;
  };

  function moveUp(item, index) {
    let nlist = array_move(newList, index, index - 1);
    let list = nlist.map((item, index) => {
      return item;
    });
    setNewList(list);
  }

  function moveDown(item, index) {
    let nlist = array_move(newList, index, index + 1);
    let list = nlist.map((item, index) => {
      return item;
    });
    setNewList(list);
  }

  function array_move(arr, old_index, new_index) {
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing
  }

  const Formbox = () => {
    return (
      <div>
        <div
          style={{
            width: '100%',
            marginLeft: 0,
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            marginBottom: 0,
            marginTop: 0,
          }}
        >
          <Box boxShadow={3}>
            <div
              style={{
                backgroundColor: '#F0F0F0',
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                borderRadius: 5,
                paddingLeft: 10,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                }}
              >
                <TextField
                  style={{marginTop: 20, width: '95%', marginBottom: 10}}
                  inputProps={{
                    style: {fontSize: 14, padding: 10, color: '#797979'},
                    maxLength: 50,
                  }}
                  InputLabelProps={{
                    style: {
                      fontSize: 14,
                      marginTop: -8,
                      color: '#797979',
                    },
                  }}
                  id="vlr"
                  variant="outlined"
                  label="Categoria"
                  defaultValue={''}
                />
              </div>
              <div style={{display: 'flex'}}>
                <TextField
                  style={{marginTop: 20, width: '20%', marginBottom: 10}}
                  inputProps={{
                    style: {fontSize: 14, padding: 6, color: '#797979'},
                    maxLength: 10,
                  }}
                  InputLabelProps={{
                    style: {
                      fontSize: 14,
                      marginTop: -8,
                      color: '#797979',
                    },
                  }}
                  id="min"
                  variant="outlined"
                  label="Min."
                  defaultValue={0}
                />
                <TextField
                  style={{
                    marginTop: 20,
                    width: '20%',
                    marginBottom: 10,
                    marginLeft: '18%',
                  }}
                  inputProps={{
                    style: {fontSize: 14, padding: 6, color: '#797979'},
                    maxLength: 10,
                  }}
                  InputLabelProps={{
                    style: {
                      fontSize: 14,
                      marginTop: -8,
                      color: '#797979',
                    },
                  }}
                  id="max"
                  variant="outlined"
                  label="Max."
                  defaultValue={0}
                />
              </div>
              <div style={{marginTop: 8}}>
                <FormControlLabel
                  control={<GreenCheckbox checked={false} name="chck" />}
                  style={{color: '#393939'}}
                  label={
                    <p
                      style={{
                        fontSize: 14,
                        color: '#393939',
                      }}
                    >
                      Obrigatório
                    </p>
                  }
                />
              </div>
            </div>
          </Box>
        </div>
      </div>
    );
  };

  return (
    <div>
      <Dialog
        onClose={() => close()}
        style={{
          marginLeft: isVerySmall ? 0 : 0,
          marginRight: isVerySmall ? 0 : 0,
          paddingLeft: 0,
        }}
        aria-labelledby="customized-dialog-title"
        open={bopen}
      >
        <div
          style={{
            backgroundColor: '#FFffff',
            width: isVerySmall ? '90%' : '100%',
            height: 'auto',
            paddingLeft: 10,
            paddingRight: 0,
          }}
        ></div>
        <Formbox />
        <DialogActions style={{position: 'relative', bottom: 0}}>
          {isVerySmall ? (
            <Button size={'small'} onClick={() => close()}>
              Cancelar
            </Button>
          ) : (
            <Button onClick={() => close()}>Cancelar</Button>
          )}
          {isSmall ? (
            <Button size={'small'} onClick={() => onSave()} color="primary">
              Adicionar
            </Button>
          ) : (
            <Button onClick={() => onSave()} color="primary">
              Adicionar
            </Button>
          )}
        </DialogActions>
      </Dialog>

      <Dialog
        open={open}
        onClose={() => close()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Excluir item?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Deseja deletar o item?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose2} color="primary">
            Não
          </Button>
          <Button onClick={handleClose2} color="primary">
            Sim
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DialogAddCatComp;
