/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, {useState, useEffect} from 'react';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import {useMediaQuery} from 'react-responsive';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';
import {Box} from '@material-ui/core';
import MyLocationIcon from '@material-ui/icons/MyLocation';
import {format} from '../../configuration/format';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import AddIcon from '@material-ui/icons/Add';
import Chip from '@material-ui/core/Chip';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import {realFormat} from '../../configuration/realFormat';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import {makeStyles} from '@material-ui/core/styles';
import AlertSending from '../../components/AlertSeding';
import {timeinmillis} from '../../configuration/timeinmillis';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const useStyles = makeStyles(theme => ({
  formControl: {
    marginLeft: 10,
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const DialogTitle = withStyles(styles)(props => {
  const {children, classes, onClose, ...other} = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

// eslint-disable-next-line react/prop-types
const Itens = ({key, nome, cqtd}) => {
  return (
    <div
      key={key}
      style={{
        marginLeft: 10,
        marginRight: 10,
        paddingTop: 0,
        paddingBottom: 3,
      }}
    >
      <div
        style={{
          flexDirection: 'row',
          display: 'flex',
          alignItems: 'center',
          marginTop: 0,
        }}
      >
        <div>
          <p
            style={{
              backgroundColor: '#E9E9E9',
              marginRight: 5,
              borderRadius: 40,
              paddingBottom: 3,
              paddingTop: 3,
              paddingLeft: 5,
              paddingRight: 5,
              fontSize: 14,
              color: '#999999',
            }}
          >
            {cqtd}
          </p>
        </div>
        <div>
          <p style={{color: '#999999', fontSize: 14, marginRight: 25}}>
            {nome}
          </p>
        </div>
      </div>
    </div>
  );
};

const ListItens = ({listitem}) => {
  let currentlist = listitem.itens;
  let listitens = currentlist.map(item2 => {
    let newlist = item2.data.map(item3 => {
      if (item3.qtd >= 1) {
        return {nome: item3.nome, qtd: item3.qtd};
      }
    });

    let list = newlist.filter(function (element) {
      return element !== undefined;
    });
    return list;
  });

  const views = [];

  for (let i = 0; i < listitens.length; i++) {
    for (let j = 0; j < listitens[i].length; j++) {
      views.push(
        <Itens
          key={i + j}
          nome={listitens[i][j].nome}
          cqtd={listitens[i][j].qtd}
        />,
      );
    }
  }
  return views;
};

// eslint-disable-next-line react/prop-types
const DialogPd = ({
  docId,
  bopen,
  handleClose,
  currentitem,
  handleNotiStack,
}) => {
  const isSmall = useMediaQuery({query: '(max-width: 750.98px)'});
  const isVerySmall = useMediaQuery({query: '(max-width:450.00px)'});
  const [isLoading, setLoading] = useState(true);
  const [comment, setComment] = useState('');
  const [open, setOpen] = React.useState(false);
  const [np2, setNp2] = useState('');
  const [cp1, setCp1] = useState('');
  const [cp2, setCp2] = useState('');
  const [isp2, setp2] = useState(false);
  const [notaEnt, setNotaEnt] = useState({nota: 5});
  const [isSending, setIsSending] = useState(false);
  const classes = useStyles();
  const handleClose2 = () => {
    setValue(0);
    setOpen(false);
  };

  const [isEntrega, setIsEntrega] = useState(true);

  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChangeIndex = index => {
    setValue(index);
  };
  const handleNotaChange = event => {
    setNotaEnt(event.target.value);
  };

  function close() {
    setValue(0);
    handleClose();
  }

  function onSave() {
    setTimeout(() => {
      handleClose();
    }, 100);
  }

  const handleClickOpen2 = () => {
    setOpen(true);
  };

  const [DATA, setDATA] = useState([]);

  const [entreganotas, setEntreganotas] = useState([
    {
      nota: 5,
    },
    {
      nota: 4,
    },
    {
      nota: 3,
    },
    {
      nota: 2,
    },
    {
      nota: 1,
    },
  ]);

  function isEmpty(obj) {
    return Object.keys(obj).length === 0;
  }

  function deletep2() {
    setCp2('');
    setNp2('');
    setp2();
    setp2(false);
  }

  function sendAv(idacpnt) {
    if (notaEnt === undefined) {
      handleNotiStack('Selecione uma nota para a entrega', 'warning');
    } else {
      setIsSending(true);
      setTimeout(() => {
        handleNotiStack('Entrega avaliada', 'success');
        setIsSending(false);
      }, 1000);
    }
  }

  useEffect(() => {
    if (!isEmpty(currentitem)) {
      setDATA(currentitem);
    }
  }, [currentitem]);

  const Lista = ({
    nome,
    cqtd,
    obs,
    vlr,
    itens,
    bpeso,
    intPeso,
    item,
    index,
  }) => {
    if (DATA.itens.length > 0) {
      let list = DATA.itens.map((item, index) => {
        return (
          <div
            key={index}
            style={{
              display: 'flex',
              alignItems: 'center',
              borderTopWidth: 1,
              borderTopColor: '#d9d9d9',
              justifyContent: 'space-between',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                marginTop: 0,
                width: '100%',
              }}
            >
              {item.bpeso ? (
                <div>
                  {item.cqtd * item.intpeso * 1000 >= 1000 ? (
                    <p
                      style={{
                        backgroundColor: '#d9d9d9',
                        marginRight: 5,
                        borderRadius: 40,
                        paddingBottom: 3,
                        paddingTop: 3,
                        paddingLeft: 5,
                        paddingRight: 5,
                        fontSize: 14,
                      }}
                    >
                      {(item.cqtd * item.intpeso * 1000) / 1000} kg
                    </p>
                  ) : (
                    <p
                      style={{
                        backgroundColor: '#d9d9d9',
                        marginRight: 5,
                        borderRadius: 40,
                        paddingBottom: 3,
                        paddingTop: 3,
                        paddingLeft: 5,
                        paddingRight: 5,
                        fontSize: 14,
                      }}
                    >
                      {item.cqtd * item.intpeso * 1000} g
                    </p>
                  )}
                </div>
              ) : (
                <div>
                  <p
                    style={{
                      backgroundColor: '#d9d9d9',
                      marginRight: 5,
                      borderRadius: 40,
                      paddingBottom: 3,
                      paddingTop: 3,
                      paddingLeft: 5,
                      paddingRight: 5,
                      fontSize: 14,
                    }}
                  >
                    {item.cqtd}
                  </p>
                </div>
              )}
              <div style={{flex: 0.75}}>
                <p style={{color: '#000000', fontSize: 14, marginRight: 25}}>
                  {item.nome}
                </p>
                {item.itens.length === 0 ? null : (
                  <div style={{marginTop: 10}}>
                    <ListItens key={index} listitem={item} />
                  </div>
                )}
                {item.obs === '' ? null : (
                  <div style={{display: 'flex', flexDirection: 'row'}}>
                    <p style={{color: '#999999', marginRight: 10}}>
                      Obs: {item.obs}
                    </p>
                  </div>
                )}
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  flex: 0.25,
                }}
              >
                <p style={{color: '#000000', fontSize: 14}}>
                  R$ {format(item.cqtd * item.vlr)}
                </p>
              </div>
            </div>
          </div>
        );
      });

      return list;
    } else {
      return null;
    }
  };

  return (
    <div>
      <AlertSending
        bopen={isSending}
        handleClose={() => setIsSending(false)}
        handleNotiStack
        style={{
          top: 0,
          position: 'absolute',
          zIndex: 3,
        }}
      />
      <Dialog
        onClose={() => close()}
        style={{
          marginLeft: isVerySmall ? -20 : 0,
          marginRight: isVerySmall ? -20 : 0,
          paddingLeft: 0,
        }}
        aria-labelledby="customized-dialog-title"
        open={bopen}
      >
        <div
          style={{
            backgroundColor: '#FFffff',
            width: isVerySmall ? 'auto' : 300,
            height: 'auto',
            paddingLeft: 10,
            paddingRight: 10,
          }}
        ></div>
        <div
          style={{
            paddingLeft: 0,
            paddingRight: 0,
            marginLeft: 10,
            marginRight: 10,
            maxWidth: 300,
          }}
        >
          <p
            style={{
              fontWeight: 'bold',
              color: '#595959',
              marginLeft: 0,
              marginRight: 10,
              fontSize: 20,
            }}
          >
            Detalhes do pedido:
          </p>
          {DATA.status === 0 ? (
            <Box
              boxShadow={3}
              style={{
                paddingLeft: 10,
                paddingRight: 10,
                paddingTop: 5,
                paddingBottom: 5,
                borderRadius: 10,
                marginTop: 0,
              }}
            >
              <p style={{color: '#06448e', fontWeight: 'bold'}}>
                Confirme o pedido para ver os dados do cliente.
              </p>
            </Box>
          ) : DATA.status >= 1 ? (
            <Box
              boxShadow={3}
              style={{
                paddingLeft: 10,
                paddingRight: 10,
                paddingTop: 5,
                paddingBottom: 5,
                borderRadius: 10,
                marginBottom: 10,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  marginTop: 0,
                  marginBottom: 0,
                }}
              >
                <p
                  style={{
                    color: '#999999',
                    marginRight: 10,
                    padding: 0,
                    marginTop: 0,
                  }}
                >
                  Nome:
                </p>
                <p
                  style={{
                    color: '#393939',
                    fontWeight: 'bold',
                    padding: 0,
                    marginTop: 0,
                  }}
                >
                  {DATA.nome}
                </p>
              </div>
              <div style={{display: 'flex', marginTop: 0}}>
                <p
                  style={{
                    color: '#999999',
                    marginRight: 10,
                    padding: 0,
                    marginTop: 0,
                  }}
                >
                  Contato:
                </p>
                <p
                  style={{
                    color: '#393939',
                    fontWeight: 'bold',
                    padding: 0,
                    marginTop: 0,
                  }}
                >
                  {DATA.ctt}
                </p>
              </div>
            </Box>
          ) : null}
          <Box boxShadow={3}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginLeft: 5,
                marginRight: 5,
              }}
            >
              <div style={{display: 'flex', alignItems: 'center'}}>
                <MyLocationIcon style={{color: '#06448e'}} />
              </div>
              <div style={{marginLeft: 3, marginRight: 3}}>
                <p
                  style={{
                    fontSize: 14,
                    fontWeight: 'bold',
                    color: '#393939',
                    marginBottom: 0,
                    marginTop: 10,
                  }}
                >
                  {DATA.end}
                </p>
                <p
                  style={{
                    fontSize: 14,
                    color: '#393939',
                    marginBottom: 0,
                    marginTop: 0,
                  }}
                >
                  {DATA.subend}
                </p>
                <p
                  style={{
                    fontSize: 12,
                    color: '#797979',
                    marginBottom: 0,
                    marginTop: 0,
                  }}
                >
                  {DATA.comp}
                </p>
                <p
                  style={{
                    fontSize: 12,
                    color: '#797979',
                    marginBottom: 10,
                    marginTop: 0,
                  }}
                >
                  {DATA.ref}
                </p>
              </div>
              <div style={{display: 'flex', alignItems: 'flex-end'}}>
                {DATA.bentrega ? (
                  <p
                    style={{
                      color: '#FFFFFF',
                      backgroundColor: '#06448e',
                      paddingTop: 3,
                      paddingBottom: 3,
                      paddingRight: 10,
                      paddingLeft: 10,
                      borderRadius: 30,
                      fontSize: 12,
                    }}
                  >
                    Entrega
                  </p>
                ) : (
                  <p
                    style={{
                      color: '#FFFFFF',
                      backgroundColor: '#06448e',
                      paddingTop: 3,
                      paddingBottom: 3,
                      paddingRight: 10,
                      paddingLeft: 10,
                      borderRadius: 30,
                      fontSize: 12,
                    }}
                  >
                    Retirada
                  </p>
                )}
              </div>
            </div>
          </Box>
          <div>
            <div style={{display: 'flex', marginLeft: 10, marginRight: 10}}>
              <p>N° do pedido: </p>
              <p style={{marginLeft: 3, fontWeight: 'bold', color: '#393939'}}>
                {DATA.npd}
              </p>
            </div>
            <Divider variant="middle" />
          </div>
          <div>
            <div
              style={{
                display: 'flex',
                marginLeft: 10,
                marginRight: 10,
                flexDirection: 'column',
              }}
            >
              <p>Itens: </p>
              <Lista />
            </div>
            <Divider variant="middle" />
          </div>
          <div>
            <div style={{display: 'flex', marginLeft: 10, marginRight: 10}}>
              <p>Taxa de serviço: </p>
              <p style={{marginLeft: 3, fontWeight: 'bold', color: '#393939'}}>
                R$ {format(DATA.txcr)}
              </p>
            </div>
            <Divider variant="middle" />
          </div>
          <div>
            <div style={{display: 'flex', marginLeft: 10, marginRight: 10}}>
              <p>Data: </p>
              <p style={{marginLeft: 3, fontWeight: 'bold', color: '#393939'}}>
                {timeinmillis(DATA.hracpt)}
              </p>
            </div>
            <Divider variant="middle" />
          </div>
          <div>
            <div style={{display: 'flex', marginLeft: 10, marginRight: 10}}>
              <p>Pagamento: </p>
              <p style={{marginLeft: 3, fontWeight: 'bold', color: '#393939'}}>
                {DATA.frm}
              </p>
            </div>
            <Divider variant="middle" />
          </div>

          <div>
            <div style={{display: 'flex', marginLeft: 10, marginRight: 10}}>
              <p>Valor: </p>
              <p style={{marginLeft: 3, fontWeight: 'bold', color: '#393939'}}>
                R$ {format(DATA.total)}
              </p>
            </div>
            <Divider variant="middle" />
          </div>

          <div>
            <div style={{display: 'flex', marginLeft: 10, marginRight: 10}}>
              <p>Entrega: </p>
              <p style={{marginLeft: 3, fontWeight: 'bold', color: '#393939'}}>
                R$ {format(DATA.frete)}
              </p>
            </div>
            <Divider variant="middle" />
          </div>

          <div>
            <div style={{display: 'flex', marginLeft: 10, marginRight: 10}}>
              <p>Status: </p>
              <p style={{marginLeft: 3, fontWeight: 'bold', color: '#393939'}}>
                {DATA.status === 0
                  ? 'Aguardando confirmação'
                  : DATA.status === 1
                  ? 'Em produção'
                  : DATA.status === 2
                  ? 'Pedido pronto'
                  : DATA.status === 3
                  ? 'Em entrega'
                  : DATA.status === 4
                  ? 'Finalizado'
                  : 'Cancelado'}
              </p>
            </div>
            <Divider variant="middle" />
          </div>

          <div>
            <div style={{display: 'flex', marginLeft: 10, marginRight: 10}}>
              <p>Desconto: </p>
              <p style={{marginLeft: 3, fontWeight: 'bold', color: '#393939'}}>
                R$ {format(DATA.dsc)}
              </p>
            </div>
            <Divider variant="middle" />
          </div>
          {DATA.etrii ? (
            <Box
              style={{
                paddingTop: 1,
                paddingBottom: 10,
                marginTop: 10,
                borderRadius: 10,
              }}
              boxShadow={3}
            >
              <div
                style={{
                  paddingLeft: 0,
                  paddingRight: 0,
                  marginLeft: 10,
                  marginRight: 10,
                  maxWidth: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <p
                  style={{
                    fontWeight: 'bold',
                    color: '#595959',
                    marginLeft: 0,
                    marginRight: 10,
                    fontSize: 20,
                  }}
                >
                  Detalhes da entrega:
                </p>
                {DATA.esttii == 0 ? null : (
                  <img
                    src={DATA.dlvrdata.img}
                    style={{
                      width: 40,
                      height: 40,
                      borderRadius: 40 / 2,
                    }}
                  />
                )}
              </div>
              {DATA.esttii == 0 ? null : (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    paddingLeft: 10,
                    paddingRight: 10,
                    marginBottom: 10,
                  }}
                >
                  <p
                    style={{
                      marginTop: 0,
                      marginBottom: 0,
                    }}
                  >
                    Nome:{' '}
                  </p>
                  <p
                    style={{
                      marginTop: 0,
                      marginBottom: 0,
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      marginLeft: 5,
                    }}
                  >
                    {DATA.dlvrdata.nome}
                  </p>
                </div>
              )}
              {DATA.esttii == 0 ? null : (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    paddingLeft: 10,
                    paddingRight: 10,
                    marginBottom: 10,
                  }}
                >
                  <p
                    style={{
                      marginTop: 0,
                      marginBottom: 0,
                    }}
                  >
                    Nota:{' '}
                  </p>
                  <p
                    style={{
                      marginTop: 0,
                      marginBottom: 0,
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      marginLeft: 5,
                    }}
                  >
                    {DATA.dlvrdata.nota}
                  </p>
                </div>
              )}
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <p
                  style={{
                    marginTop: 0,
                    marginBottom: 0,
                  }}
                >
                  Codigo de retirada:{' '}
                </p>
                <p
                  style={{
                    marginTop: 0,
                    marginBottom: 0,
                    fontWeight: 'bold',
                  }}
                >
                  {DATA.cdgstore}
                </p>
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginBottom: 10,
                }}
              >
                <p
                  style={{
                    marginTop: 0,
                    marginBottom: 0,
                  }}
                >
                  Forma de pagamento:{' '}
                </p>
                <p
                  style={{
                    marginTop: 0,
                    marginBottom: 0,
                  }}
                >
                  Dinheiro
                </p>
              </div>
              <Divider />
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginTop: 10,
                  marginBottom: 10,
                }}
              >
                <p
                  style={{
                    marginTop: 0,
                    marginBottom: 0,
                  }}
                >
                  Valor:{' '}
                </p>
                <p
                  style={{
                    fontWeight: 'bold',
                    marginTop: 0,
                    marginBottom: 0,
                  }}
                >
                  {realFormat(DATA.frete)}
                </p>
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  paddingLeft: 10,
                  paddingRight: 10,
                }}
              >
                {DATA.esttii == 0 ? (
                  <Chip
                    variant="outlined"
                    size="small"
                    color={'primary'}
                    icon={<RadioButtonCheckedIcon />}
                    label="Buscando entregador"
                    onClick={() => deletep2()}
                  />
                ) : DATA.esttii == 1 ? (
                  <Chip
                    variant="outlined"
                    size="small"
                    color={'primary'}
                    icon={<RadioButtonCheckedIcon />}
                    label="Entregador a caminho"
                    onClick={() => deletep2()}
                  />
                ) : DATA.esttii == 2 ? (
                  <Chip
                    variant="outlined"
                    size="small"
                    color={'primary'}
                    icon={<RadioButtonCheckedIcon />}
                    label="Entregador chegou"
                    onClick={() => deletep2()}
                  />
                ) : DATA.esttii == 3 ? (
                  <Chip
                    variant="outlined"
                    size="small"
                    color={'primary'}
                    icon={<RadioButtonCheckedIcon />}
                    label="Entregador levando pedido"
                    onClick={() => deletep2()}
                  />
                ) : DATA.esttii == 4 ? (
                  <Chip
                    variant="outlined"
                    size="small"
                    color={'primary'}
                    icon={<RadioButtonCheckedIcon />}
                    label="Entregador retornando"
                    onClick={() => deletep2()}
                  />
                ) : DATA.esttii == 5 ? (
                  <Chip
                    variant="outlined"
                    size="small"
                    color={'primary'}
                    icon={<RadioButtonCheckedIcon />}
                    label="Entrega finalizada"
                    onClick={() => deletep2()}
                  />
                ) : DATA.esttii == 6 ? (
                  <Chip
                    variant="outlined"
                    size="small"
                    color={'secondary'}
                    icon={<RadioButtonCheckedIcon />}
                    label="Entrega cancelada"
                    onClick={() => deletep2()}
                  />
                ) : null}
              </div>
              <div style={{marginLeft: 10, marginRight: 10}}>
                <p
                  style={{
                    fontSize: 12,
                    marginTop: 10,
                    marginBottom: 0,
                  }}
                >
                  {DATA.endereco}
                </p>
                <p
                  style={{
                    fontSize: 12,
                    marginBottom: 0,
                    marginTop: 0,
                  }}
                >
                  {DATA.subendereco}
                </p>
                <p
                  style={{
                    fontSize: 12,
                    marginBottom: 0,
                    marginTop: 0,
                  }}
                >
                  {DATA.referencia}
                </p>
              </div>
              {DATA.esttii == 5 ? (
                <div
                  style={{
                    marginTop: 10,
                    marginRight: 10,
                    marginLeft: 0,
                  }}
                >
                  <Divider />
                  <div>
                    <p style={{marginLeft: 10}}>Avaliação entregador: </p>
                    {DATA.avEntStore ? (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          marginLeft: 10,
                        }}
                      >
                        <p
                          style={{
                            fontWeight: 'bold',
                            marginTop: 0,
                            marginBottom: 0,
                          }}
                        >
                          Entrega avaliada!
                        </p>
                      </div>
                    ) : (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <FormControl
                          style={{
                            marginTop: 0,
                            width: '90%',
                          }}
                          className={classes.formControl}
                        >
                          <InputLabel
                            style={{fontSize: 14}}
                            id="demo-simple-select-label"
                          >
                            Nota para entrega
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={notaEnt}
                            onChange={handleNotaChange}
                            style={{
                              marginTop: 14,
                              width: '50%',
                            }}
                          >
                            {(Array.isArray([entreganotas])
                              ? entreganotas.map(item => [item])
                              : Object.entries(entreganotas)
                            ).map(item => {
                              return (
                                <MenuItem
                                  style={{fontSize: 14}}
                                  key={item[0].nota}
                                  value={item[0]}
                                >
                                  {item[0].nota}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                        <Chip
                          variant="outlined"
                          size="small"
                          color={'primary'}
                          label="Avaliar"
                          onClick={() => sendAv(DATA.idacpnt)}
                        />
                      </div>
                    )}
                  </div>
                </div>
              ) : null}
            </Box>
          ) : null}
        </div>
        <DialogActions style={{position: 'relative', bottom: 0}}>
          {isSmall ? (
            <Button size={'small'} onClick={() => onSave()} color="primary">
              Ok
            </Button>
          ) : (
            <Button onClick={() => onSave()} color="primary">
              Ok
            </Button>
          )}
        </DialogActions>
      </Dialog>

      <Dialog
        open={open}
        onClose={() => close()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Excluir item?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Deseja deletar o item?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose2} color="primary">
            Não
          </Button>
          <Button onClick={handleClose2} color="primary">
            Sim
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DialogPd;
