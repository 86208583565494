import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Divider from '@material-ui/core/Divider';
import {useMediaQuery} from 'react-responsive';
import {useDispatch} from 'react-redux';
import * as ActionScreen from '../../store/actions/setStateScreen';
import ReceiptIcon from '@material-ui/icons/Receipt';
import Tada from 'react-reveal/Tada';

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

// eslint-disable-next-line react/prop-types
const AlertNewOrder = ({bopen, handleClose}) => {
  const isSmall = useMediaQuery({query: '(max-width: 750.98px)'});
  const isVerySmall = useMediaQuery({query: '(max-width:450.00px)'});
  const dispatch = useDispatch();
  function close() {
    handleClose();
  }

  function onSave() {
    dispatch(ActionScreen.setChangeScreen('Gestor'));
    handleClose();
  }

  return (
    <div>
      <Dialog
        onClose={() => close()}
        style={{
          marginLeft: isVerySmall ? -20 : 0,
          marginRight: isVerySmall ? -20 : 0,
          paddingLeft: 0,
        }}
        aria-labelledby="customized-dialog-title"
        open={bopen}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <Tada count={5} duration={2000}>
            <ReceiptIcon
              style={{fontSize: 50, marginTop: 10, color: '#06448E'}}
            />
          </Tada>
          <p style={{fontWeight: 'bold', color: '#393939'}}>Novo pedido</p>
        </div>

        <Divider variant="middle" />

        <DialogActions style={{position: 'relative', bottom: 0}}>
          {isVerySmall ? (
            <Button size={'small'} onClick={() => close()}>
              Recusar
            </Button>
          ) : (
            <Button onClick={() => close()}>Recusar</Button>
          )}
          {isSmall ? (
            <Button size={'small'} onClick={() => onSave()} color="primary">
              Ver pedido
            </Button>
          ) : (
            <Button onClick={() => onSave()} color="primary">
              Ver pedido
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AlertNewOrder;
