export function setuser(uid, email) {
  return {
    type: 'SET_USER',
    uid,
    email,
  };
}

export function setagreement(agreement) {
  return {
    type: 'SET_AGREEMENT',
    agreement,
  };
}
