export function setitem(
  id,
  idstore,
  nome,
  descricao,
  cat,
  idcat,
  pdv,
  bqtd,
  qtd,
  cqtd,
  vlr,
  bimg,
  img,
  vlrpromo,
  bpromo,
  bmonted,
  bpeso,
  intpeso,
  bavailable,
  itens,
  cpt,
  obs,
  bodom,
  boseg,
  boter,
  boqua,
  boqui,
  bosex,
  bosab,
  index,
) {
  return {
    type: 'SET_ITEM',
    id,
    idstore,
    nome,
    descricao,
    cat,
    idcat,
    pdv,
    bqtd,
    qtd,
    cqtd,
    vlr,
    bimg,
    img,
    vlrpromo,
    bpromo,
    bmonted,
    bpeso,
    intpeso,
    bavailable,
    itens,
    cpt,
    obs,
    bodom,
    boseg,
    boter,
    boqua,
    boqui,
    bosex,
    bosab,
    index,
  };
}

export function setCqtd(cqtd) {
  return {
    type: 'SET_CQTD',
    cqtd,
  };
}

export function setIndex(index) {
  return {
    type: 'SET_INDEX',
    index,
  };
}

export function setItens(itens) {
  return {
    type: 'SET_ITENS',
    itens,
  };
}

export function setFDetails(fDetails) {
  return {
    type: 'SET_FDETAILS',
    fDetails,
  };
}

export function setFComp(fComp) {
  return {
    type: 'SET_FCOMP',
    fComp,
  };
}

export function setFHorario(fHorario) {
  return {
    type: 'SET_FHORARIO',
    fHorario,
  };
}

export function setFPromo(fPromo) {
  return {
    type: 'SET_FPROMO',
    fPromo,
  };
}

export function setFClear(fDetails, fComp, fHorario, fPromo) {
  return {
    type: 'SET_CLEAR_F',
    fDetails,
    fComp,
    fHorario,
    fPromo,
  };
}
