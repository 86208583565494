/* eslint-disable no-unused-vars */
import React, {useLayoutEffect, useState, useEffect} from 'react';
import {useMediaQuery} from 'react-responsive';
import {makeStyles} from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import {FaBars} from 'react-icons/fa';
import {Bar} from 'react-chartjs-2';
import {Line} from 'react-chartjs-2';
import {Box} from '@material-ui/core';
import {format} from '../../configuration/format';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import {useSelector, useDispatch} from 'react-redux';
import * as ActionResume from '../../store/actions/setResumeInicio';
import {getAuth} from 'firebase/auth';

const useStyles = makeStyles(theme => ({
  formControl: {
    marginLeft: 10,
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

// eslint-disable-next-line react/prop-types
const Inicio = ({handleToggleSidebar, handleNotiStack, handleBackDrop}) => {
  const auth = getAuth();
  const isSmall = useMediaQuery({query: '(max-width: 750.98px)'});
  const isVerySmall = useMediaQuery({query: '(max-width:450.00px)'});
  const [isLoading, setLoading] = useState(true);
  const classes = useStyles();
  const idstore = useSelector(state => state.setrestaurante.idstore);
  const resume = useSelector(state => state.setresume.resume);
  const [DATA, setDATA] = useState({});
  const dispatch = useDispatch();
  const [data, setData] = useState({
    labels: [],
    datasets: [
      {
        label: 'Vendas',
        data: [],
        backgroundColor: ['rgba(8, 64, 142, 1)'],
        borderColor: ['rgba(8, 64, 153, 1)'],
        borderWidth: 2,
      },
    ],
  });

  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };

  function isEmpty(obj) {
    return Object.keys(obj).length === 0;
  }

  useEffect(() => {
    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();
    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;
    const formattedToday = dd + '/' + mm + '/' + yyyy;
    if (isEmpty(resume)) {
      UpdateResume();
    } else {
      if (resume.lstup != formattedToday) {
        UpdateResume();
      } else {
        UpdateResume();
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }
    }
  }, []);

  useEffect(() => {
    setDATA(resume);
  }, [resume]);

  function UpdateResume() {
    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();
    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;
    const formattedToday = dd + '/' + mm + '/' + yyyy;
    let intm = mm - 1;
    auth.currentUser.getIdToken().then(token => {
      const request = new Request(
        'https://kops.apiireit.com/gateway/pedidos/get/resumeinicio?estid=' +
          idstore +
          '&startdate=' +
          formattedToday +
          '&year=' +
          yyyy +
          '&month=' +
          intm,
        {
          method: 'GET',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        },
      );

      fetch(request)
        .then(response => {
          if (response.status === 200) {
            handleBackDrop('', '', 200, false);
            response
              .json()
              .then(data => {
                setTimeout(() => {
                  dispatch(ActionResume.setresume(data));
                  setLoading(false);
                }, 1000);
              })
              .catch(error => {
                console.log(error);
              });
          } else if (response.status === 503) {
            handleBackDrop(
              'Serviço indisponível no momento',
              'O serviços para as lojas Irê it não estão disponíveis no momento. Por favor, tente novamente mais tarde',
              503,
              true,
            );
            setLoading(false);
            throw new Error('Something went wrong on API server!');
          } else if (response.status === 404) {
            handleBackDrop(
              'Serviço não encontrado.',
              'Por favor, entre em contato com o nosso suporte e relate o problema.',
              404,
              true,
            );
            setLoading(false);
          } else if (response.status === 401) {
            handleBackDrop(
              'Você não possui autorização ou suas credenciais expirarão.',
              'Por favor, atualize a baixa ou refaça o processo dee login.',
              401,
              true,
            );
            setLoading(false);
          } else if (response.status === 400) {
            handleBackDrop(
              'Requisição inválida.',
              'Confira se todos os campos estão preenchidos corretamente.',
              400,
              true,
            );
            setLoading(false);
          } else if (response.status === 500) {
            handleBackDrop(
              'Nosso servidor está passando por irregularidades.',
              'Estamos trabalhado para resolver o mais rápido possível.',
              500,
              true,
            );
            setLoading(false);
          }
        })
        .catch(error => {
          handleBackDrop(
            'Nosso servidor está passando por irregularidades.',
            'Estamos trabalhado para resolver o mais rápido possível.',
            500,
            true,
          );
          setLoading(false);
          console.error(error);
        });
    });
  }

  useEffect(() => {
    setData({
      labels: DATA.vdlb14,
      datasets: [
        {
          label: 'Vendas',
          data: DATA.vd14,
          backgroundColor: ['rgba(8, 64, 142, 1)'],
          borderColor: ['rgba(8, 64, 153, 1)'],
          borderWidth: 2,
        },
      ],
    });
  }, [DATA]);

  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    // Button is displayed after scrolling for 500 pixels
    const toggleVisibility = () => {
      if (window.pageYOffset > 50) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', toggleVisibility);

    return () => window.removeEventListener('scroll', toggleVisibility);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  }
  const [width, height] = useWindowSize();

  const ListWeek = () => {
    if (!isEmpty(DATA)) {
      let list = DATA.dtsm.map((item, index) => {
        return (
          <div
            key={{index}}
            style={{
              width: isVerySmall ? '100%' : 210,
              marginLeft: 10,
              marginRight: 10,
              marginTop: 20,
            }}
          >
            <Box
              boxShadow={3}
              style={{
                paddingLeft: 10,
                paddingRight: 10,
                borderRadius: 10,
                paddingBottom: 5,
                paddingTop: 5,
              }}
            >
              <p style={{fontWeight: 'bold'}}>{item.day}</p>
              <p style={{}}>Vendas</p>
              <p
                style={{
                  fontWeight: 'bold',
                  fontSize: 20,
                  color: '#393939',
                  marginBottom: 0,
                  paddingBottom: 0,
                }}
              >
                {item.vendas}
              </p>
              <p
                style={{
                  fontWeight: 'bold',
                  color: '#393939',
                  marginTop: 0,
                  paddingTop: 0,
                }}
              >
                R$ {format(item.vlrvendas)}
              </p>
              <div
                style={{
                  backgroundColor: '#f4f4f4',
                  marginBottom: 10,
                  borderRadius: 5,
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    paddingLeft: 10,
                    paddingRight: 10,
                    marginBottom: 0,
                  }}
                >
                  <p>Tempo online</p>
                  {item.time == 0 || isNaN(item.time) || item.time == -1 ? (
                    <p>0%</p>
                  ) : (
                    <p>{(item.time * 100).toFixed(2)}%</p>
                  )}
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    paddingLeft: 10,
                    paddingRight: 10,
                  }}
                >
                  <p>Cancelamentos</p>
                  <p>{(item.cncl * 100).toFixed(2)}%</p>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    paddingLeft: 10,
                    paddingRight: 10,
                  }}
                >
                  <p>Atrasos</p>
                  <p>{(item.atrs * 100).toFixed(2)}%</p>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    paddingLeft: 10,
                    paddingRight: 10,
                  }}
                >
                  <p>Nota média</p>
                  <p>{item.ntme.toFixed(2)}</p>
                </div>
              </div>
            </Box>
          </div>
        );
      });
      return list;
    } else {
      return null;
    }
  };

  const ListWeekend = () => {
    if (!isEmpty(DATA)) {
      let list = DATA.dtfds.map((item, index) => {
        return (
          <div
            key={{index}}
            style={{
              width: isVerySmall ? '100%' : 270,
              marginLeft: 15,
              marginRight: 15,
              marginTop: 20,
            }}
          >
            <Box
              boxShadow={3}
              style={{
                paddingLeft: 10,
                paddingRight: 10,
                borderRadius: 10,
                paddingBottom: 5,
                paddingTop: 5,
              }}
            >
              <p style={{fontWeight: 'bold'}}>{item.day}</p>
              <p style={{}}>Vendas</p>
              <p
                style={{
                  fontWeight: 'bold',
                  fontSize: 20,
                  color: '#393939',
                  marginBottom: 0,
                  paddingBottom: 0,
                }}
              >
                {item.vendas}
              </p>
              <p
                style={{
                  fontWeight: 'bold',
                  color: '#393939',
                  marginTop: 0,
                  paddingTop: 0,
                }}
              >
                R$ {format(item.vlrvendas)}
              </p>
              <div
                style={{
                  backgroundColor: '#f4f4f4',
                  marginBottom: 10,
                  borderRadius: 5,
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    paddingLeft: 10,
                    paddingRight: 10,
                    marginBottom: 0,
                  }}
                >
                  <p>Tempo online</p>
                  {item.time == 0 || isNaN(item.time) || item.time == -1 ? (
                    <p>0%</p>
                  ) : (
                    <p>{(item.time * 100).toFixed(2)}%</p>
                  )}
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    paddingLeft: 10,
                    paddingRight: 10,
                  }}
                >
                  <p>Cancelamentos</p>
                  <p>{(item.cncl * 100).toFixed(2)}%</p>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    paddingLeft: 10,
                    paddingRight: 10,
                  }}
                >
                  <p>Atrasos</p>
                  <p>{(item.atrs * 100).toFixed(2)}%</p>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    paddingLeft: 10,
                    paddingRight: 10,
                  }}
                >
                  <p>Nota média</p>
                  <p>{item.ntme.toFixed(2)}</p>
                </div>
              </div>
            </Box>
          </div>
        );
      });
      return list;
    } else {
      return null;
    }
  };

  return (
    <div>
      {isLoading ? (
        <div
          style={{
            display: 'flex',
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            height: height,
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <div>
          {isVisible ? (
            <Fab
              onClick={() => scrollToTop()}
              style={{position: 'fixed', bottom: 20, right: 20, zIndex: 2}}
              size={'small'}
              color="primary"
              aria-label="add"
            >
              <ArrowUpwardIcon />
            </Fab>
          ) : null}
          <div
            style={{marginLeft: 10, marginTop: 10}}
            className="btn-toggle"
            onClick={() => handleToggleSidebar(true)}
          >
            <FaBars />
          </div>
          {isVerySmall || isSmall ? (
            <div style={{flexDirection: 'column'}}>
              <h1 style={{marginLeft: 20, marginBottom: 5, color: '#393939'}}>
                Seja bem vindo
              </h1>
              <div
                style={{
                  width: '100%',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                }}
              >
                <div style={{width: '100%'}}>
                  <p
                    style={{
                      fontSize: 25,
                      color: '#494949',
                      marginBottom: 15,
                      marginLeft: 20,
                    }}
                  >
                    Acompanhamento
                  </p>
                  <p
                    style={{
                      fontSize: 14,
                      color: '#494949',
                      marginBottom: 10,
                      marginLeft: 20,
                      borderWidth: 1,
                      borderColor: '#ff6600',
                      borderRadius: 3,
                    }}
                  >
                    Ultima atualização: {DATA.lstup}
                  </p>
                  <Box
                    style={{
                      marginLeft: 10,
                      marginRight: 10,
                      borderRadius: 3,
                      paddingTop: 1,
                    }}
                    boxShadow={3}
                  >
                    <div
                      style={{
                        paddingLeft: 20,
                        paddingRight: 20,
                        paddingTop: 5,
                        paddingBottom: 5,
                        backgroundColor: '#ff6600',
                        marginTop: 9,
                        marginBottom: 10,
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginLeft: 5,
                        marginRight: 5,
                        borderRadius: 5,
                      }}
                    >
                      <p style={{color: '#FFFFFF', textAlign: 'center'}}>
                        Pedidos de hoje
                      </p>
                      <p
                        style={{
                          fontWeight: 'bold',
                          fontSize: 20,
                          color: '#FFFFFF',
                          marginBottom: 0,
                          paddingBottom: 0,
                          textAlign: 'center',
                        }}
                      >
                        {DATA.npd} pedidos
                      </p>
                      <p
                        style={{
                          fontWeight: 'bold',
                          color: '#FFFFFF',
                          marginTop: 0,
                          paddingTop: 0,
                          textAlign: 'center',
                        }}
                      >
                        R$ {format(DATA.nvlrpd)}
                      </p>
                    </div>
                    <div
                      style={{
                        paddingLeft: 20,
                        paddingRight: 20,
                        paddingTop: 5,
                        paddingBottom: 5,
                        backgroundColor: '#ff6600',
                        marginTop: 9,
                        marginBottom: 10,
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginLeft: 5,
                        marginRight: 5,
                        borderRadius: 5,
                      }}
                    >
                      <p style={{color: '#FFFFFF', textAlign: 'center'}}>
                        Ticket médio hoje
                      </p>
                      <p
                        style={{
                          fontWeight: 'bold',
                          color: '#FFFFFF',
                          marginTop: 0,
                          paddingTop: 0,
                          textAlign: 'center',
                        }}
                      >
                        R$ {format(DATA.tckm)}
                      </p>
                    </div>
                    <div
                      style={{
                        paddingLeft: 20,
                        paddingRight: 20,
                        paddingTop: 5,
                        paddingBottom: 5,
                        backgroundColor: '#ffffff',
                        marginTop: 10,
                        marginBottom: 10,
                        borderRadius: 10,
                      }}
                    >
                      <p style={{color: '#393939'}}>Pedidos de fevereiro</p>
                      <p
                        style={{
                          fontSize: 16,
                          color: '#393939',
                          marginBottom: 0,
                          paddingBottom: 0,
                          fontWeight: 'bold',
                        }}
                      >
                        {DATA.npdmes} pedidos
                      </p>
                      <p
                        style={{
                          color: '#393939',
                          marginTop: 0,
                          paddingTop: 0,
                          fontWeight: 'bold',
                        }}
                      >
                        R$ {format(DATA.nvlrmes)}
                      </p>
                    </div>
                    <Divider variant="middle" />
                    <div
                      style={{
                        paddingLeft: 20,
                        paddingRight: 20,
                        paddingTop: 5,
                        paddingBottom: 5,
                        backgroundColor: '#ffffff',
                        marginTop: 10,
                        marginBottom: 10,
                        borderRadius: 10,
                      }}
                    >
                      <p style={{color: '#393939'}}>Ticket médio fevereiro</p>
                      <p
                        style={{
                          color: '#393939',
                          marginTop: 0,
                          paddingTop: 0,
                          fontWeight: 'bold',
                        }}
                      >
                        R$ {format(DATA.tckmmes)}
                      </p>
                    </div>
                    <Divider variant="middle" />
                    <div
                      style={{
                        paddingLeft: 20,
                        paddingRight: 20,
                        paddingTop: 5,
                        paddingBottom: 5,
                        backgroundColor: '#ffffff',
                        width: 120,
                        marginTop: 10,
                        marginBottom: 10,
                        borderRadius: 10,
                      }}
                    >
                      <p style={{color: '#393939'}}>Avaliações no app</p>
                      <p
                        style={{
                          color: '#393939',
                          marginTop: 0,
                          paddingTop: 0,
                          fontWeight: 'bold',
                        }}
                      >
                        {DATA.navmes}
                      </p>
                    </div>
                  </Box>
                </div>
                <div
                  style={{
                    width: '100%',
                  }}
                >
                  <p
                    style={{
                      fontSize: 25,
                      color: '#494949',
                      marginLeft: 20,
                      marginBottom: -10,
                    }}
                  >
                    Desempenho
                  </p>
                </div>

                <Box
                  boxShadow={3}
                  style={{
                    paddingTop: 5,
                    marginTop: 30,
                    marginLeft: 10,
                    marginRight: 10,
                    paddingBottom: 30,
                    alignItems: 'center',
                    borderRadius: 3,
                  }}
                >
                  <div style={{width: '100%'}}>
                    <p
                      style={{
                        fontSize: 25,
                        color: '#494949',
                        marginBottom: 15,
                        marginLeft: 20,
                      }}
                    >
                      Últimos 3 dias
                    </p>
                    <Box
                      style={{
                        marginLeft: 10,
                        marginRight: 10,
                        borderRadius: 10,
                      }}
                      boxShadow={3}
                    >
                      <div
                        style={{
                          paddingLeft: 20,
                          paddingRight: 20,
                          paddingTop: 5,
                          paddingBottom: 5,
                        }}
                      >
                        <p style={{}}>Vendas</p>
                        <p
                          style={{
                            fontWeight: 'bold',
                            fontSize: 20,
                            color: '#393939',
                            marginBottom: 0,
                            paddingBottom: 0,
                          }}
                        >
                          {DATA.vdd3}
                        </p>
                        <p
                          style={{
                            fontWeight: 'bold',
                            color: '#393939',
                            marginTop: 0,
                            paddingTop: 0,
                          }}
                        >
                          R$ {format(DATA.vlrvd3)}
                        </p>
                      </div>
                      <Divider variant="middle" />
                      <div
                        style={{
                          paddingLeft: 20,
                          paddingRight: 20,
                          paddingTop: 5,
                          paddingBottom: 5,
                        }}
                      >
                        <p style={{}}>Novos clientes</p>
                        <p
                          style={{
                            fontWeight: 'bold',
                            fontSize: 20,
                            color: '#393939',
                            marginBottom: 0,
                            paddingBottom: 0,
                          }}
                        >
                          {DATA.nClient}
                        </p>
                        <p
                          style={{
                            fontWeight: 'bold',
                            color: '#393939',
                            marginTop: 0,
                            paddingTop: 0,
                          }}
                        >
                          R$ {format(DATA.nvlrClient)}
                        </p>
                      </div>
                      <Divider variant="middle" />
                      <div
                        style={{
                          paddingLeft: 20,
                          paddingRight: 20,
                          paddingTop: 5,
                          paddingBottom: 5,
                        }}
                      >
                        <p style={{}}>Produto mais vendido</p>
                        <p
                          style={{
                            fontWeight: 'bold',
                            fontSize: 20,
                            color: '#393939',
                            marginBottom: 10,
                            paddingBottom: 0,
                          }}
                        >
                          - {DATA.pmv}
                        </p>
                      </div>
                    </Box>
                  </div>
                  <div style={{width: '100%'}}>
                    <p
                      style={{
                        fontSize: 25,
                        color: '#494949',
                        marginBottom: 15,
                        marginLeft: 20,
                      }}
                    >
                      Últimos 7 dias
                    </p>
                    <Box
                      style={{
                        marginLeft: 10,
                        marginRight: 10,
                        borderRadius: 10,
                      }}
                      boxShadow={3}
                    >
                      <div
                        style={{
                          paddingLeft: 20,
                          paddingRight: 20,
                          paddingTop: 5,
                          paddingBottom: 5,
                        }}
                      >
                        <p style={{}}>Vendas</p>
                        <p
                          style={{
                            fontWeight: 'bold',
                            fontSize: 20,
                            color: '#393939',
                            marginBottom: 0,
                            paddingBottom: 0,
                          }}
                        >
                          {DATA.vdd7}
                        </p>
                        <p
                          style={{
                            fontWeight: 'bold',
                            color: '#393939',
                            marginTop: 0,
                            paddingTop: 0,
                          }}
                        >
                          R$ {format(DATA.vlrvd7)}
                        </p>
                      </div>
                      <Divider variant="middle" />
                      <div
                        style={{
                          paddingLeft: 20,
                          paddingRight: 20,
                          paddingTop: 5,
                          paddingBottom: 5,
                        }}
                      >
                        <p style={{}}>Média das avaliações</p>
                        <p
                          style={{
                            fontWeight: 'bold',
                            fontSize: 20,
                            color: '#393939',
                            marginBottom: 0,
                            paddingBottom: 0,
                          }}
                        >
                          {DATA.mavd7.toFixed(2)}
                        </p>
                        <p
                          style={{
                            fontWeight: 'bold',
                            color: '#393939',
                            marginTop: 0,
                            paddingTop: 0,
                          }}
                        >
                          {DATA.navd7} pedidos
                        </p>
                      </div>
                      <Divider variant="middle" />
                      <div
                        style={{
                          paddingLeft: 20,
                          paddingRight: 20,
                          paddingTop: 5,
                          paddingBottom: 5,
                        }}
                      >
                        <p style={{}}>Tempo online</p>
                        {DATA.tmd7 == 0 || isNaN(DATA.tmd7) ? (
                          <p
                            style={{
                              fontWeight: 'bold',
                              fontSize: 20,
                              color: '#393939',
                              marginBottom: 0,
                              paddingBottom: 0,
                            }}
                          >
                            0%
                          </p>
                        ) : (
                          <p
                            style={{
                              fontWeight: 'bold',
                              fontSize: 20,
                              color: '#393939',
                              marginBottom: 0,
                              paddingBottom: 0,
                            }}
                          >
                            {(DATA.tmd7 * 100).toFixed(2)}%
                          </p>
                        )}

                        <p
                          style={{
                            width: 150,
                            fontSize: 12,
                            color: '#393939',
                            marginTop: 0,
                            paddingTop: 0,
                          }}
                        >
                          Os melhores restaurantes ficam acima de 75%
                        </p>
                      </div>
                      <Divider variant="middle" />
                      <div
                        style={{
                          paddingLeft: 20,
                          paddingRight: 20,
                          paddingTop: 5,
                          paddingBottom: 5,
                        }}
                      >
                        <p style={{}}>Cancelamentos</p>
                        <p
                          style={{
                            fontWeight: 'bold',
                            fontSize: 20,
                            color: '#393939',
                            marginBottom: 0,
                            paddingBottom: 0,
                          }}
                        >
                          {(DATA.cld7 * 100).toFixed(2)}%
                        </p>
                        <p
                          style={{
                            width: 150,
                            fontSize: 12,
                            color: '#393939',
                            marginTop: 0,
                            paddingTop: 0,
                          }}
                        >
                          Os melhores restaurantes ficam abaixo de 1,8%
                        </p>
                      </div>
                      <Divider variant="middle" />
                      <div
                        style={{
                          paddingLeft: 20,
                          paddingRight: 20,
                          paddingTop: 5,
                          paddingBottom: 5,
                        }}
                      >
                        <p style={{}}>Atrasos</p>
                        <p
                          style={{
                            fontWeight: 'bold',
                            fontSize: 20,
                            color: '#393939',
                            marginBottom: 0,
                            paddingBottom: 0,
                          }}
                        >
                          {(DATA.atrd7 * 100).toFixed(2)}%
                        </p>
                        <p
                          style={{
                            width: 150,
                            fontSize: 12,
                            color: '#393939',
                            marginTop: 0,
                            paddingTop: 0,
                            marginBottom: 10,
                          }}
                        >
                          Os melhores restaurantes ficam abaixo de 10%
                        </p>
                      </div>
                    </Box>
                  </div>
                  <div style={{width: '100%'}}>
                    <p
                      style={{
                        fontSize: 25,
                        color: '#494949',
                        marginBottom: 15,
                        marginLeft: 20,
                      }}
                    >
                      Vendas nos últimos 14 dias
                    </p>
                  </div>
                  <div
                    style={{
                      width: '100%',
                    }}
                  >
                    <Box
                      boxShadow={3}
                      style={{
                        marginLeft: 10,
                        marginRight: 10,
                        borderRadius: 10,
                      }}
                    >
                      <Line
                        height={220}
                        style={{
                          marginLeft: 0,
                          marginRight: 0,
                        }}
                        data={data}
                        options={options}
                      />
                    </Box>
                  </div>
                </Box>
              </div>
              <div style={{marginLeft: 0, marginRight: 0}}>
                <div
                  style={{
                    width: '100%',
                    alignItems: 'flex-end',
                    marginBottom: 10,
                  }}
                >
                  <p
                    style={{
                      fontSize: 25,
                      color: '#494949',
                      marginBottom: -13,
                      marginLeft: 20,
                    }}
                  >
                    Finais de semana
                  </p>
                  <p
                    style={{
                      fontSize: 14,
                      color: '#494949',
                      marginBottom: -10,
                      marginLeft: 20,
                      borderWidth: 1,
                      borderColor: '#ff6600',
                      borderRadius: 3,
                    }}
                  >
                    (Ultimas 4 semanas)
                  </p>
                </div>
                <div
                  style={{flexWrap: 'wrap', display: 'flex', marginBottom: 10}}
                >
                  <ListWeekend />
                </div>
                <div
                  style={{
                    width: '100%',
                    alignItems: 'flex-end',
                    marginBottom: 10,
                  }}
                >
                  <p
                    style={{
                      fontSize: 25,
                      color: '#494949',
                      marginBottom: -13,
                      marginLeft: 20,
                    }}
                  >
                    Dias da semana
                  </p>
                  <p
                    style={{
                      fontSize: 14,
                      color: '#494949',
                      marginBottom: -10,
                      marginLeft: 20,
                      borderWidth: 1,
                      borderColor: '#ff6600',
                      borderRadius: 3,
                    }}
                  >
                    (Ultimas 4 semanas)
                  </p>
                </div>
                <div
                  style={{flexWrap: 'wrap', display: 'flex', marginBottom: 70}}
                >
                  <ListWeek />
                </div>
              </div>
            </div>
          ) : (
            <div style={{display: 'flex', flexDirection: 'column'}}>
              <h1 style={{marginLeft: 20, marginBottom: 5, color: '#393939'}}>
                Seja bem vindo
              </h1>
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                }}
              >
                <div style={{width: '100%'}}>
                  <p
                    style={{
                      fontSize: 25,
                      color: '#494949',
                      marginBottom: 15,
                      marginLeft: 20,
                    }}
                  >
                    Acompanhamento
                  </p>
                  <p
                    style={{
                      fontSize: 14,
                      color: '#494949',
                      marginBottom: 10,
                      borderWidth: 1,
                      borderColor: '#ff6600',
                      borderRadius: 3,
                      marginLeft: 20,
                    }}
                  >
                    Ultima atualização: {DATA.lstup}
                  </p>
                  <Box
                    style={{
                      marginLeft: 10,
                      marginRight: 10,
                      borderRadius: 3,
                      display: 'flex',
                    }}
                    boxShadow={3}
                  >
                    <div
                      style={{
                        paddingLeft: 20,
                        paddingRight: 20,
                        paddingTop: 5,
                        paddingBottom: 5,
                        backgroundColor: '#ff6600',
                        width: 120,
                        marginTop: 10,
                        marginBottom: 10,
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                      }}
                    >
                      <p style={{color: '#FFFFFF'}}>Pedidos de hoje</p>
                      <p
                        style={{
                          fontWeight: 'bold',
                          fontSize: 20,
                          color: '#FFFFFF',
                          marginBottom: 0,
                          paddingBottom: 0,
                        }}
                      >
                        {DATA.npd} pedidos
                      </p>
                      <p
                        style={{
                          fontWeight: 'bold',
                          color: '#FFFFFF',
                          marginTop: 0,
                          paddingTop: 0,
                        }}
                      >
                        R$ {format(DATA.nvlrpd)}
                      </p>
                    </div>
                    <div
                      style={{
                        paddingLeft: 20,
                        paddingRight: 20,
                        paddingTop: 5,
                        paddingBottom: 5,
                        backgroundColor: '#ff6600',
                        width: 120,
                        marginTop: 10,
                        marginBottom: 10,
                        borderRadius: 10,
                        borderBottomLeftRadius: 0,
                        borderTopLeftRadius: 0,
                      }}
                    >
                      <p style={{color: '#FFFFFF'}}>Ticket médio hoje</p>
                      <p
                        style={{
                          fontWeight: 'bold',
                          color: '#FFFFFF',
                          marginTop: 0,
                          paddingTop: 0,
                        }}
                      >
                        R$ {format(DATA.tckm)}
                      </p>
                    </div>
                    <div
                      style={{
                        paddingLeft: 20,
                        paddingRight: 20,
                        paddingTop: 5,
                        paddingBottom: 5,
                        backgroundColor: '#ffffff',
                        width: 120,
                        marginTop: 10,
                        marginBottom: 10,
                        borderRadius: 10,
                      }}
                    >
                      <p style={{color: '#393939'}}>Pedidos de fevereiro</p>
                      <p
                        style={{
                          fontSize: 16,
                          color: '#393939',
                          marginBottom: 0,
                          paddingBottom: 0,
                          fontWeight: 'bold',
                        }}
                      >
                        {DATA.npdmes} pedidos
                      </p>
                      <p
                        style={{
                          color: '#393939',
                          marginTop: 0,
                          paddingTop: 0,
                          fontWeight: 'bold',
                        }}
                      >
                        R$ {format(DATA.nvlrmes)}
                      </p>
                    </div>
                    <div
                      style={{
                        paddingLeft: 20,
                        paddingRight: 20,
                        paddingTop: 5,
                        paddingBottom: 5,
                        backgroundColor: '#ffffff',
                        width: 120,
                        marginTop: 10,
                        marginBottom: 10,
                        borderRadius: 10,
                      }}
                    >
                      <p style={{color: '#393939'}}>Ticket médio fevereiro</p>
                      <p
                        style={{
                          color: '#393939',
                          marginTop: 0,
                          paddingTop: 0,
                          fontWeight: 'bold',
                        }}
                      >
                        R$ {format(DATA.tckmmes)}
                      </p>
                    </div>
                    <div
                      style={{
                        paddingLeft: 20,
                        paddingRight: 20,
                        paddingTop: 5,
                        paddingBottom: 5,
                        backgroundColor: '#ffffff',
                        width: 120,
                        marginTop: 10,
                        marginBottom: 10,
                        borderRadius: 10,
                      }}
                    >
                      <p style={{color: '#393939'}}>Avaliações no app</p>
                      <p
                        style={{
                          color: '#393939',
                          marginTop: 0,
                          paddingTop: 0,
                          fontWeight: 'bold',
                        }}
                      >
                        {DATA.navmes}
                      </p>
                    </div>
                  </Box>
                </div>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'flex-end',
                  }}
                >
                  <p
                    style={{
                      fontSize: 25,
                      color: '#494949',
                      marginBottom: -20,
                      marginLeft: 20,
                    }}
                  >
                    Desempenho
                  </p>
                </div>

                <Box
                  boxShadow={3}
                  style={{
                    width: '98%',
                    marginTop: 30,
                    marginLeft: 10,
                    marginRight: 10,
                    paddingBottom: 30,
                    alignItems: 'center',
                    borderRadius: 3,
                  }}
                >
                  <div style={{width: '100%'}}>
                    <p
                      style={{
                        fontSize: 25,
                        color: '#494949',
                        marginBottom: 15,
                        marginLeft: 20,
                      }}
                    >
                      Últimos 3 dias
                    </p>
                    <Box
                      style={{
                        marginLeft: 10,
                        marginRight: 10,
                        borderRadius: 10,
                        display: 'flex',
                      }}
                      boxShadow={3}
                    >
                      <div
                        style={{
                          paddingLeft: 20,
                          paddingRight: 20,
                          paddingTop: 5,
                          paddingBottom: 5,
                        }}
                      >
                        <p style={{}}>Vendas</p>
                        <p
                          style={{
                            fontWeight: 'bold',
                            fontSize: 20,
                            color: '#393939',
                            marginBottom: 0,
                            paddingBottom: 0,
                          }}
                        >
                          {DATA.vdd3}
                        </p>
                        <p
                          style={{
                            fontWeight: 'bold',
                            color: '#393939',
                            marginTop: 0,
                            paddingTop: 0,
                          }}
                        >
                          R$ {format(DATA.vlrvd3)}
                        </p>
                      </div>
                      <div
                        style={{
                          paddingLeft: 20,
                          paddingRight: 20,
                          paddingTop: 5,
                          paddingBottom: 5,
                        }}
                      >
                        <p style={{}}>Novos clientes</p>
                        <p
                          style={{
                            fontWeight: 'bold',
                            fontSize: 20,
                            color: '#393939',
                            marginBottom: 0,
                            paddingBottom: 0,
                          }}
                        >
                          {DATA.nClient}
                        </p>
                        <p
                          style={{
                            fontWeight: 'bold',
                            color: '#393939',
                            marginTop: 0,
                            paddingTop: 0,
                          }}
                        >
                          R$ {format(DATA.nvlrClient)}
                        </p>
                      </div>
                      <div
                        style={{
                          paddingLeft: 20,
                          paddingRight: 20,
                          paddingTop: 5,
                          paddingBottom: 5,
                        }}
                      >
                        <p style={{}}>Produto mais vendido</p>
                        <p
                          style={{
                            fontWeight: 'bold',
                            fontSize: 20,
                            color: '#393939',
                            marginBottom: 0,
                            paddingBottom: 0,
                          }}
                        >
                          - {DATA.pmv}
                        </p>
                      </div>
                    </Box>
                  </div>
                  <div style={{width: '100%'}}>
                    <p
                      style={{
                        fontSize: 25,
                        color: '#494949',
                        marginBottom: 15,
                        marginLeft: 20,
                      }}
                    >
                      Últimos 7 dias
                    </p>
                    <Box
                      style={{
                        marginLeft: 10,
                        marginRight: 10,
                        borderRadius: 10,
                        display: 'flex',
                      }}
                      boxShadow={3}
                    >
                      <div
                        style={{
                          paddingLeft: 20,
                          paddingRight: 20,
                          paddingTop: 5,
                          paddingBottom: 5,
                        }}
                      >
                        <p style={{}}>Vendas</p>
                        <p
                          style={{
                            fontWeight: 'bold',
                            fontSize: 20,
                            color: '#393939',
                            marginBottom: 0,
                            paddingBottom: 0,
                          }}
                        >
                          {DATA.vdd7}
                        </p>
                        <p
                          style={{
                            fontWeight: 'bold',
                            color: '#393939',
                            marginTop: 0,
                            paddingTop: 0,
                          }}
                        >
                          R$ {format(DATA.vlrvd7)}
                        </p>
                      </div>
                      <div
                        style={{
                          paddingLeft: 20,
                          paddingRight: 20,
                          paddingTop: 5,
                          paddingBottom: 5,
                        }}
                      >
                        <p style={{}}>Média das avaliações</p>
                        <p
                          style={{
                            fontWeight: 'bold',
                            fontSize: 20,
                            color: '#393939',
                            marginBottom: 0,
                            paddingBottom: 0,
                          }}
                        >
                          {DATA.mavd7.toFixed(2)}
                        </p>
                        <p
                          style={{
                            fontWeight: 'bold',
                            color: '#393939',
                            marginTop: 0,
                            paddingTop: 0,
                          }}
                        >
                          {DATA.navd7} pedidos
                        </p>
                      </div>
                      <div
                        style={{
                          paddingLeft: 20,
                          paddingRight: 20,
                          paddingTop: 5,
                          paddingBottom: 5,
                        }}
                      >
                        <p style={{}}>Tempo online</p>
                        {DATA.tmd7 == 0 || isNaN(DATA.tmd7) ? (
                          <p
                            style={{
                              fontWeight: 'bold',
                              fontSize: 20,
                              color: '#393939',
                              marginBottom: 0,
                              paddingBottom: 0,
                            }}
                          >
                            0%
                          </p>
                        ) : (
                          <p
                            style={{
                              fontWeight: 'bold',
                              fontSize: 20,
                              color: '#393939',
                              marginBottom: 0,
                              paddingBottom: 0,
                            }}
                          >
                            {(DATA.tmd7 * 100).toFixed(2)}%
                          </p>
                        )}

                        <p
                          style={{
                            width: 150,
                            fontSize: 12,
                            color: '#393939',
                            marginTop: 0,
                            paddingTop: 0,
                          }}
                        >
                          Os melhores restaurantes ficam acima de 75%
                        </p>
                      </div>
                      <div
                        style={{
                          paddingLeft: 20,
                          paddingRight: 20,
                          paddingTop: 5,
                          paddingBottom: 5,
                        }}
                      >
                        <p style={{}}>Cancelamentos</p>
                        <p
                          style={{
                            fontWeight: 'bold',
                            fontSize: 20,
                            color: '#393939',
                            marginBottom: 0,
                            paddingBottom: 0,
                          }}
                        >
                          {(DATA.cld7 * 100).toFixed(2)}%
                        </p>
                        <p
                          style={{
                            width: 150,
                            fontSize: 12,
                            color: '#393939',
                            marginTop: 0,
                            paddingTop: 0,
                          }}
                        >
                          Os melhores restaurantes ficam abaixo de 1,8%
                        </p>
                      </div>
                      <div
                        style={{
                          paddingLeft: 20,
                          paddingRight: 20,
                          paddingTop: 5,
                          paddingBottom: 5,
                        }}
                      >
                        <p style={{}}>Atrasos</p>
                        <p
                          style={{
                            fontWeight: 'bold',
                            fontSize: 20,
                            color: '#393939',
                            marginBottom: 0,
                            paddingBottom: 0,
                          }}
                        >
                          {(DATA.atrd7 * 100).toFixed(2)}%
                        </p>
                        <p
                          style={{
                            width: 150,
                            fontSize: 12,
                            color: '#393939',
                            marginTop: 0,
                            paddingTop: 0,
                          }}
                        >
                          Os melhores restaurantes ficam abaixo de 10%
                        </p>
                      </div>
                    </Box>
                  </div>
                  <div style={{width: '100%'}}>
                    <p
                      style={{
                        fontSize: 25,
                        color: '#494949',
                        marginBottom: 15,
                        marginLeft: 20,
                      }}
                    >
                      Vendas nos últimos 14 dias
                    </p>
                  </div>
                  <div
                    style={{
                      width: '100%',
                    }}
                  >
                    <Box
                      boxShadow={3}
                      style={{
                        marginLeft: 10,
                        marginRight: 10,
                        borderRadius: 10,
                      }}
                    >
                      <Line
                        height={80}
                        style={{
                          marginLeft: 0,
                          marginRight: 0,
                        }}
                        data={data}
                        options={options}
                      />
                    </Box>
                  </div>
                </Box>
              </div>
              <div style={{marginLeft: 20, marginRight: 20}}>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'flex-end',
                    marginBottom: 10,
                  }}
                >
                  <p
                    style={{
                      fontSize: 25,
                      color: '#494949',
                      marginBottom: -13,
                      marginLeft: 0,
                    }}
                  >
                    Finais de semana
                  </p>
                  <p
                    style={{
                      fontSize: 14,
                      color: '#494949',
                      marginBottom: -10,
                      marginLeft: 7,
                      borderWidth: 1,
                      borderColor: '#ff6600',
                      borderRadius: 3,
                    }}
                  >
                    (Ultimas 4 semanas)
                  </p>
                </div>
                <div
                  style={{flexWrap: 'wrap', display: 'flex', marginBottom: 10}}
                >
                  <ListWeekend />
                </div>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'flex-end',
                    marginBottom: 10,
                  }}
                >
                  <p
                    style={{
                      fontSize: 25,
                      color: '#494949',
                      marginBottom: -13,
                      marginLeft: 0,
                    }}
                  >
                    Dias da semana
                  </p>
                  <p
                    style={{
                      fontSize: 14,
                      color: '#494949',
                      marginBottom: -10,
                      marginLeft: 7,
                      borderWidth: 1,
                      borderColor: '#ff6600',
                      borderRadius: 3,
                    }}
                  >
                    (Ultimas 4 semanas)
                  </p>
                </div>
                <div
                  style={{flexWrap: 'wrap', display: 'flex', marginBottom: 30}}
                >
                  <ListWeek />
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Inicio;
