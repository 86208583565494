/* eslint-disable prettier/prettier */
const INITIAL_STATE = {
  id: '',
  idstore: '',
  nome: '',
  descricao: '',
  contato: '',
  nota: 5,
  nNotas: 0,
  cat: '',
  entrega: false,
  retirada: false,
  agendamento: false,
  espera: '',
  dtsc: 0,
  frete: 0.00,
  nAvaliacao: 0,
  pedidominimo: 0.00,
  entregagratis: 0.00,
  location: {latitude: 0.0, longitude: 0.0},
  img: '',
  status: '',
  catprice: 0,
  ireitentrega: false,
  bCkd: false,
  bMerc: false,
  bCupom: false,
  bpromoEntrega: false,
  bapp: false,
  bpix: false,
  bame: false,
  bpicpay: false,
  bmp: false,
  bpaypal: false,
  bpre: false,
  bvir: false,
  bvref: false,
  bd: false,
  bvd: false,
  bmcd: false,
  belod: false,
  bhipd: false,
  bdind: false,
  baled: false,
  bsodd: false,
  bvrd: false,
  bbend: false,
  bdisd: false,
  btrid: false,
  bbnsd: false,
  bbrsd: false,
  bbrd: false,
  bvc: false,
  bmcc: false,
  beloc: false,
  bhipc: false,
  bdinc: false,
  balec: false,
  bsodc: false,
  bvrc: false,
  bbenc: false,
  bdisc: false,
  btric: false,
  bbnsc: false,
  bbrsc: false,
  bbrc: false,
  bodom: false,
  hdom: '',
  boseg: false,
  hseg: '',
  boter: false,
  hter: '',
  boqua: false,
  hqua: '',
  boqui: false,
  hqui: '',
  bosex: false,
  hsex: '',
  bosab: false,
  hsab: '',
  endereco: '',
  numero: 246,
  bairro: '',
  cidade: '',
  estado: '',
  cnpj: '',
  raio: 0,
  bDstc: false,
  aratdm: [
  ],
  aratdmii: [],
  essm: [
    {
      day: 'Segundas',
      bopen: false,
      hr1a: 0,
      hr1f: 0,
      b2t: false,
      hr2a: 0,
      hr2f: 0,
    },
    {
      day: 'Terças',
      bopen: false,
      hr1a: 0,
      hr1f: 0,
      b2t: false,
      hr2a: 0,
      hr2f: 0,
    },
    {
      day: 'Quintas',
      bopen: false,
      hr1a: 0,
      hr1f: 0,
      b2t: false,
      hr2a: 0,
      hr2f: 0,
    },
  ],
  esfds: [
    {
      day: 'Sextas',
      bopen: false,
      hr1a: 0,
      hr1f: 0,
      b2t: false,
      hr2a: 0,
      hr2f: 0,
    },
    {
      day: 'Sabados',
      bopen: false,
      hr1a: 0,
      hr1f: 0,
      b2t: false,
      hr2a: 0,
      hr2f: 0,
    },
    {
      day: 'Domingos',
      bopen: false,
      hr1a: 0,
      hr1f: 0,
      b2t: false,
      hr2a: 0,
      hr2f: 0,
    },
  ],
  inAlta: false,
  npd: 0,
  znid: '',
  zntx: 0.0,
  iitx: 0.0,
  iietx: 0.0,
  freetx: 0.0,
  freetill: 0.0,
  lastSeenAt: 0.0,
  presence: 0.0,
  openchat: 0.0,
  agd: 0.0,
  fagdays: 0.0,
  dtsm:[{}],
  dtfds:[{}],
  mxprmetrg: 0,
  bbank: '',
  btcnta: '',
  bagcia: '',
  bcnta: 0,
  bdig: '',
  cpix: '',
  npix: '',
  nimg: 0
};

export default function setrestaurante(state = INITIAL_STATE, action) {
if (action.type === 'SET_RESTAURANTE_DETAILS') {
return {
  ...state,
  id: action.id,
  nome: action.nome,
  descricao: action.descricao,
  nota: action.nota,
  nNotas: action.nNotas,
  cat: action.cat,
  entrega: action.entrega,
  retirada: action.retirada,
  agendamento: action.agendamento,
  espera: action.espera,
  dstc: action.dstc,
  frete: action.frete,
  nAvaliacao: action.nAvaliacao,
  pedidominimo: action.pedidominimo,
  entregagratis: action.entregagratis,
  location: action.location,
  img: action.img,
  status: action.status,
  catprice: action.catprice,
  ireitentrega: action.ireitentrega,
  isCkd: action.isCkd,
  isMerc: action.isMerc,
  isCupom: action.isCupom,
  bpromoEntrega: action.bpromoEntrega,
  bapp: action.bapp,
  bpix: action.bpix,
  bame: action.bame,
  bpicpay: action.bpicpay,
  bmp: action.bmp,
  bpaypal: action.bpaypal,
  bpre: action.bpre,
  bvir: action.bvir,
  bvref: action.bvref,
  bd: action.bd,
  bvd: action.bvd,
  bmcd: action.bmcd,
  belod: action.belod,
  bhipd: action.bhipd,
  bdind: action.bdind,
  baled: action.baled,
  bsodd: action.bsodd,
  bvrd:  action.bvrd,
  bbend: action.bbend,
  bdisd: action.bdisd,
  btrid: action.btrid,
  bbnsd: action.bbnsd,
  bbrsd: action.bbrsd,
  bbrd: action.bbrd,
  bvc: action.bvc,
  bmcc: action.bmcc,
  beloc: action.beloc,
  bhipc: action.bhipc,
  bdinc: action.bdinc,
  balec: action.balec,
  bsodc: action.bsodc,
  bvrc:  action.bvrc,
  bbenc: action.bbenc,
  bdisc: action.bdisc,
  btric: action.btric,
  bbnsc: action.bbnsc,
  bbrsc: action.bbrsc,
  bbrc: action.bbrc,
  endereco: action.endereco,
  numero: action.numero,
  bairro: action.bairro,
  cidade: action.cidade,
  estado: action.estado,
  cnpj: action.cnpj,
  bodom: action.bodom,
  hdom: action.hdom,
  boseg: action.boseg,
  hseg: action.hseg,
  boter: action.boter,
  hter: action.hter,
  boqua: action.boqua,
  hqua: action.hqua,
  boqui: action.boqui,
  hqui: action.hqui,
  bosex: action.bosex,
  hsex: action.hsex,
  bosab: action.bosab,
  hsab: action.hsab,
  aratdm: action.aratdm,
  raio: action.raio,
  bDstc: action.bDstc,
  essm: action.essm,
  esfds: action.esfds,
  inAlta: action.inAlta,
  npd: action.npd,
  znid: action.nzid,
  zntx: action.zntx,
  iitx: action.iitx,
  iietx: action.iietx,
  freetx: action.freetx,
  freetill: action.freetill,
  lastSeenAt: action.lastSeenAt,
  presence: action.presence,
  openchat: action.openchat,
  agd: action.agd,
  fagdays: action.fagdays,
  dtsm: action.dtsm,
  dtfds: action.dtfds,
  contato: action.contato,
  aratdmii: action.aratdmii,
  mxprmetrg: action.mxprmetrg,
  bbank: action.bbank,
  btcnta: action.btcnta,
  bagcia: action.bagcia,
  bcnta: action.bcnta,
  bdig: action.bdig,
  cpix: action.cpix,
  npix: action.npix,
  nimg: action.nimg
};
} else if (action.type === 'SET_ISMERC') {
  return {
    ...state,
    isMerc: action.isMerc,
  };
} else if (action.type === 'SET_IDSTORE') {
  return {
    ...state,
    idstore: action.idstore,
  };
} else if (action.type === 'SET_IN_ALTA') {
  return {
    ...state,
    inAlta: action.inAlta,
  };
} else if (action.type === 'SET_IREIT_ENTREGA') {
  return {
    ...state,
    ireitentrega: action.ireitentrega,
  };
} else if (action.type === 'SET_RETIRADA') {
  return {
    ...state,
    retirada: action.retirada,
  };
} else if (action.type === 'SET_PROPRIA') {
  return {
    ...state,
    entrega: action.entrega,
  };
} else if (action.type === 'SET_ARATDMII') {
  return {
    ...state,
    aratdmii: action.aratdmii,
  };
}
return state;
}
