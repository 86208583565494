/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import {format} from '../../configuration/format';
import {Divider} from '@material-ui/core';

const Itens = ({key, name, cqtd}) => {
  return (
    <div
      key={key}
      style={{
        marginLeft: 0,
        marginRight: 0,
        paddingTop: 0,
        paddingBottom: 3,
        marginTop: 0,
      }}
    >
      <div
        style={{
          flexDirection: 'row',
          display: 'flex',
          alignItems: 'center',
          marginTop: 0,
        }}
      >
        <div>
          <p
            style={{
              backgroundColor: '#ffffff',
              marginRight: 5,
              borderRadius: 40,
              paddingBottom: 3,
              paddingTop: 3,
              paddingLeft: 5,
              paddingRight: 5,
              fontSize: 12,
              color: '#000000',
              marginTop: 0,
            }}
          >
            {cqtd}
          </p>
        </div>
        <div>
          <p
            style={{
              color: '#000000',
              marginTop: 0,
              fontSize: 12,
              marginRight: 25,
            }}
          >
            {name}
          </p>
        </div>
      </div>
    </div>
  );
};

const ListItens = ({listitem}) => {
  let currentlist = listitem.itens;
  let listitens = currentlist.map(item2 => {
    let newlist = item2.data.map(item3 => {
      if (item3.qtd >= 1) {
        return {nome: item3.nome, qtd: item3.qtd};
      }
    });

    let list = newlist.filter(function (element) {
      return element !== undefined;
    });
    return list;
  });

  const views = [];

  for (let i = 0; i < listitens.length; i++) {
    for (let j = 0; j < listitens[i].length; j++) {
      views.push(
        <Itens
          key={i + j}
          name={listitens[i][j].nome}
          cqtd={listitens[i][j].qtd}
        />,
      );
    }
  }
  return views;
};

const Lista = ({listaitens}) => {
  let lista = listaitens.map((item, index) => {
    return (
      <div
        key={index}
        style={{
          display: 'flex',
          alignItems: 'center',
          borderTopWidth: 1,
          borderTopColor: '#ffffff',
          justifyContent: 'space-between',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            marginTop: 0,
            width: '100%',
          }}
        >
          {item.bpeso ? (
            <div>
              {item.cqtd * item.intpeso * 1000 >= 1000 ? (
                <p
                  style={{
                    backgroundColor: '#ffffff',
                    marginRight: 5,
                    borderRadius: 40,
                    paddingBottom: 3,
                    paddingTop: 0,
                    paddingLeft: 5,
                    paddingRight: 5,
                    fontSize: 12,
                    marginTop: 0,
                  }}
                >
                  {(item.cqtd * item.intpeso * 1000) / 1000} kg
                </p>
              ) : (
                <p
                  style={{
                    backgroundColor: '#ffffff',
                    marginRight: 5,
                    borderRadius: 40,
                    paddingBottom: 3,
                    paddingTop: 0,
                    paddingLeft: 5,
                    paddingRight: 5,
                    fontSize: 12,
                    marginTop: 0,
                  }}
                >
                  {item.cqtd * item.intpeso * 1000} g
                </p>
              )}
            </div>
          ) : (
            <div>
              <p
                style={{
                  backgroundColor: '#ffffff',
                  marginRight: 5,
                  borderRadius: 40,
                  paddingBottom: 3,
                  paddingTop: 0,
                  paddingLeft: 5,
                  paddingRight: 5,
                  fontSize: 12,
                  marginTop: 0,
                }}
              >
                {item.cqtd}
              </p>
            </div>
          )}
          <div style={{flex: 0.7}}>
            <p
              style={{
                color: '#000000',
                fontSize: 12,
                marginRight: 25,
                marginTop: 0,
              }}
            >
              {item.nome}
            </p>
            {item.itens.length === 0 ? null : (
              <div style={{marginTop: 0}}>
                <ListItens key={index} listitem={item} />
              </div>
            )}
            {item.obs === '' ? null : (
              <div style={{display: 'flex', flexDirection: 'row'}}>
                <p style={{color: '#000000', marginRight: 10, fontSize: 12}}>
                  Obs: {item.obs}
                </p>
              </div>
            )}
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              flex: 0.3,
            }}
          >
            <p style={{color: '#000000', fontSize: 12, marginTop: 0}}>
              R$ {format(item.cqtd * item.vlr)}
            </p>
          </div>
        </div>
      </div>
    );
  });

  return lista;
};

class ComponentToPrint extends React.Component {
  constructor() {
    super();
    this.state = {
      nome: '',
      estabelecimento: '',
      npd: '',
      data: '',
      prev: '',
      cont: '',
      end: '',
      subend: '',
      comp: '',
      refer: '',
      itens: [],
      total: '',
      frete: '',
      ireit: '',
      frm: '',
      obs: '',
      dsc: '',
    };
  }

  render() {
    return (
      <div
        style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}
      >
        <p style={{fontWeight: 'bold', textAlign: 'center'}}>Irê it</p>

        <div
          style={{border: '1px solid #000', paddingLeft: 10, paddingRight: 10}}
        >
          <div
            style={{
              display: 'flex',
              marginBottom: 0,
              paddingBottom: 0,
            }}
          >
            <p style={{padding: 0, color: '#000000', marginBottom: 0}}>
              Pedido:{' '}
            </p>
            <p
              style={{
                marginLeft: 5,
                padding: 0,
                color: '#000000',
                marginBottom: 0,
              }}
            >
              {this.props.npd}
            </p>
          </div>
          <div
            style={{
              display: 'flex',
              marginBottom: 0,
              paddingBottom: 0,
            }}
          >
            <p style={{padding: 0, color: '#000000', marginBottom: 10}}>
              Entrega:{' '}
            </p>
            <p
              style={{
                marginLeft: 5,
                padding: 0,
                marginBottom: 10,
                color: '#000000',
              }}
            >
              Pela Irê it
            </p>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            marginBottom: 0,
            paddingBottom: 0,
          }}
        >
          <p
            style={{
              padding: 0,
              color: '#000000',
              marginBottom: 10,
              fontSize: 12,
            }}
          >
            Estabelecimento:{' '}
          </p>
          <p
            style={{
              marginLeft: 5,
              padding: 0,
              marginBottom: 10,
              fontSize: 12,
              color: '#000000',
              maxWidth: 150,
            }}
          >
            {this.props.estabelecimento}
          </p>
        </div>
        <div
          style={{
            display: 'flex',
            marginBottom: 0,
            paddingBottom: 0,
          }}
        >
          <p
            style={{
              padding: 0,
              color: '#000000',
              marginBottom: 10,
              marginTop: 0,
              fontSize: 12,
            }}
          >
            Data:{' '}
          </p>
          <p
            style={{
              marginLeft: 5,
              padding: 0,
              marginTop: 0,
              marginBottom: 10,
              fontSize: 12,
              color: '#000000',
              maxWidth: 150,
            }}
          >
            {this.props.data}
          </p>
        </div>
        <div
          style={{
            display: 'flex',
            marginBottom: 0,
            paddingBottom: 0,
          }}
        >
          <p
            style={{
              padding: 0,
              color: '#000000',
              marginBottom: 10,
              marginTop: 0,
              fontSize: 12,
            }}
          >
            Previsão:{' '}
          </p>
          <p
            style={{
              marginLeft: 5,
              padding: 0,
              marginTop: 0,
              marginBottom: 10,
              fontSize: 12,
              color: '#000000',
              maxWidth: 150,
            }}
          >
            {this.props.prev}
          </p>
        </div>
        <br />
        <div>
          <p
            style={{
              marginTop: 0,
              padding: 0,
              color: '#000000',
              marginBottom: 10,
              fontSize: 12,
            }}
          >
            Dados do cliente:{' '}
          </p>
          <div
            style={{
              display: 'flex',
              marginBottom: 0,
              paddingBottom: 0,
            }}
          >
            <p
              style={{
                padding: 0,
                marginTop: 0,
                color: '#000000',
                marginBottom: 10,
                fontSize: 12,
              }}
            >
              Nome:{' '}
            </p>
            <p
              style={{
                marginLeft: 5,
                padding: 0,
                marginTop: 0,
                marginBottom: 10,
                fontSize: 12,
                color: '#000000',
                maxWidth: 150,
              }}
            >
              {this.props.nome}
            </p>
          </div>
          <div
            style={{
              display: 'flex',
              marginBottom: 0,
              paddingBottom: 0,
            }}
          >
            <p
              style={{
                padding: 0,
                marginTop: 0,
                color: '#000000',
                marginBottom: 10,
                fontSize: 12,
              }}
            >
              Telefone:{' '}
            </p>
            <p
              style={{
                marginLeft: 5,
                padding: 0,
                marginTop: 0,
                marginBottom: 10,
                fontSize: 12,
                color: '#000000',
                maxWidth: 150,
              }}
            >
              {this.props.cont}
            </p>
          </div>
          <div
            style={{
              display: 'flex',
              marginBottom: 0,
              paddingBottom: 0,
            }}
          >
            <p
              style={{
                padding: 0,
                marginTop: 0,
                color: '#000000',
                marginBottom: 10,
                fontSize: 12,
              }}
            >
              Endereço:{' '}
            </p>
            <p
              style={{
                marginLeft: 5,
                padding: 0,
                marginTop: 0,
                marginBottom: 10,
                fontSize: 12,
                color: '#000000',
                maxWidth: 150,
              }}
            >
              {this.props.end}
            </p>
            <p
              style={{
                marginLeft: 5,
                padding: 0,
                marginTop: 0,
                marginBottom: 10,
                fontSize: 12,
                color: '#000000',
                maxWidth: 150,
              }}
            >
              - {this.props.subend}
            </p>
          </div>
          <div
            style={{
              display: 'flex',
              marginBottom: 0,
              paddingBottom: 0,
            }}
          >
            <p
              style={{
                padding: 0,
                marginTop: 0,
                color: '#000000',
                marginBottom: 10,
                fontSize: 12,
              }}
            >
              Complemento:{' '}
            </p>
            <p
              style={{
                marginLeft: 5,
                padding: 0,
                marginTop: 0,
                marginBottom: 10,
                fontSize: 12,
                color: '#000000',
                maxWidth: 150,
              }}
            >
              {this.props.comp}
            </p>
          </div>
          <div
            style={{
              display: 'flex',
              marginBottom: 0,
              paddingBottom: 0,
            }}
          >
            <p
              style={{
                padding: 0,
                marginTop: 0,
                color: '#000000',
                marginBottom: 10,
                fontSize: 12,
              }}
            >
              Referência:{' '}
            </p>
            <p
              style={{
                marginLeft: 5,
                padding: 0,
                marginTop: 0,
                marginBottom: 10,
                fontSize: 12,
                color: '#000000',
                maxWidth: 150,
              }}
            >
              {this.props.refer}
            </p>
          </div>
          <br />
          <div style={{}}>
            <p
              style={{
                marginLeft: 0,
                padding: 0,
                marginTop: 0,
                marginBottom: 10,
                fontSize: 14,
                color: '#000000',
                maxWidth: 150,
              }}
            >
              Detalhes do pedido:
            </p>
            {this.props.itens === undefined ||
            this.props.itens === [] ? null : (
              <Lista listaitens={this.props.itens} />
            )}
          </div>
          <div style={{border: '1px solid #000', padding: 10}}>
            <div
              style={{
                display: 'flex',
                marginBottom: 0,
                paddingBottom: 0,
                justifyContent: 'space-between',
              }}
            >
              <p
                style={{
                  padding: 0,
                  marginTop: 0,
                  color: '#000000',
                  marginBottom: 10,
                  fontSize: 12,
                }}
              >
                Total do pedido:{' '}
              </p>
              <p
                style={{
                  marginLeft: 5,
                  padding: 0,
                  marginTop: 0,
                  marginBottom: 10,
                  fontSize: 12,
                  color: '#000000',
                  maxWidth: 150,
                }}
              >
                R$ {format(this.props.total)}
              </p>
            </div>
            <div
              style={{
                display: 'flex',
                marginBottom: 0,
                paddingBottom: 0,
                justifyContent: 'space-between',
              }}
            >
              <p
                style={{
                  padding: 0,
                  marginTop: 0,
                  color: '#000000',
                  marginBottom: 10,
                  fontSize: 12,
                }}
              >
                Taxa de entrega:{' '}
              </p>
              <p
                style={{
                  marginLeft: 5,
                  padding: 0,
                  marginTop: 0,
                  marginBottom: 10,
                  fontSize: 12,
                  color: '#000000',
                  maxWidth: 150,
                }}
              >
                R$ {format(this.props.frete)}
              </p>
            </div>
            <div
              style={{
                display: 'flex',
                marginBottom: 0,
                paddingBottom: 0,
                justifyContent: 'space-between',
              }}
            >
              <p
                style={{
                  padding: 0,
                  marginTop: 0,
                  color: '#000000',
                  marginBottom: 10,
                  fontSize: 12,
                }}
              >
                Desconto:{' '}
              </p>
              <p
                style={{
                  marginLeft: 5,
                  padding: 0,
                  marginTop: 0,
                  marginBottom: 10,
                  fontSize: 12,
                  color: '#000000',
                  maxWidth: 150,
                }}
              >
                -R$ {format(this.props.dsc)}
              </p>
            </div>
            <div
              style={{
                display: 'flex',
                marginBottom: 0,
                paddingBottom: 0,
                justifyContent: 'space-between',
              }}
            >
              <p
                style={{
                  padding: 0,
                  marginTop: 0,
                  color: '#000000',
                  marginBottom: 10,
                  fontSize: 12,
                }}
              >
                Pago via Irê it:{' '}
              </p>
              <p
                style={{
                  marginLeft: 5,
                  padding: 0,
                  marginTop: 0,
                  marginBottom: 10,
                  fontSize: 12,
                  color: '#000000',
                  maxWidth: 150,
                }}
              >
                R$ {format(this.props.ireit)}
              </p>
            </div>
            <Divider color={'#000'} style={{color: '#000000'}} />
            <div
              style={{
                display: 'flex',
                marginBottom: 0,
                paddingBottom: 0,
                justifyContent: 'space-between',
                marginTop: 10,
              }}
            >
              <p
                style={{
                  padding: 0,
                  marginTop: 0,
                  color: '#000000',
                  marginBottom: 10,
                  fontSize: 12,
                }}
              >
                Cobrar do cliente:{' '}
              </p>
              <p
                style={{
                  marginLeft: 5,
                  padding: 0,
                  marginTop: 0,
                  marginBottom: 10,
                  fontSize: 12,
                  color: '#000000',
                  maxWidth: 150,
                }}
              >
                R$ {format(this.props.total)}
              </p>
            </div>
          </div>
          <br />
          <div
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingBottom: 0,
            }}
          >
            <p
              style={{
                padding: 0,
                marginTop: 0,
                color: '#000000',
                marginBottom: 10,
                fontSize: 12,
                fontWeight: 'bold',
              }}
            >
              Forma de pagamento:{' '}
            </p>
            {this.props.frm === 'pagopeloapp' ? (
              <p
                style={{
                  marginLeft: 0,
                  padding: 0,
                  marginTop: 0,
                  marginBottom: 10,
                  fontSize: 12,
                  color: '#000000',
                  maxWidth: 150,
                }}
              >
                Pagamento pelo app, não cobrar do cliente.
              </p>
            ) : (
              <p
                style={{
                  marginLeft: 0,
                  padding: 0,
                  marginTop: 0,
                  marginBottom: 10,
                  fontSize: 12,
                  color: '#000000',
                  maxWidth: 150,
                }}
              >
                Pagamento presencial
              </p>
            )}
          </div>
          <div
            style={{
              marginBottom: 0,
              paddingBottom: 0,
            }}
          >
            <p
              style={{
                padding: 0,
                marginTop: 0,
                color: '#000000',
                marginBottom: 10,
                fontSize: 12,
                fontWeight: 'bold',
              }}
            >
              Observações:{' '}
            </p>
            <p
              style={{
                marginLeft: 0,
                padding: 0,
                marginTop: 0,
                marginBottom: 10,
                fontSize: 12,
                color: '#000000',
                maxWidth: 150,
              }}
            >
              Incluir cpf na nota fiscal: {this.props.refer}
            </p>
            <p
              style={{
                marginLeft: 0,
                padding: 0,
                marginTop: 0,
                marginBottom: 10,
                fontSize: 12,
                color: '#000000',
                maxWidth: 150,
              }}
            >
              {this.props.obs}
            </p>
          </div>
          <Divider />
          <p
            style={{
              marginLeft: 0,
              padding: 0,
              marginTop: 10,
              marginBottom: 10,
              fontSize: 12,
              color: '#000000',
              maxWidth: 150,
            }}
          >
            Irê it
          </p>
          <p
            style={{
              marginLeft: 0,
              padding: 0,
              marginTop: 0,
              marginBottom: 10,
              fontSize: 12,
              color: '#000000',
              maxWidth: 150,
            }}
          >
            Versão 1.0.0
          </p>
        </div>
      </div>
    );
  }
}

ComponentToPrint.propTypes = {
  nome: PropTypes.string,
  estabelecimento: PropTypes.string,
  npd: PropTypes.string,
  data: PropTypes.string,
  prev: PropTypes.string,
  cont: PropTypes.string,
  end: PropTypes.string,
  subend: PropTypes.string,
  comp: PropTypes.string,
  refer: PropTypes.string,
  itens: PropTypes.array,
  total: PropTypes.string,
  frete: PropTypes.string,
  ireit: PropTypes.string,
  frm: PropTypes.string,
  obs: PropTypes.string,
  key: PropTypes.string,
  name: PropTypes.string,
  cqtd: PropTypes.string,
};

export default ComponentToPrint;
