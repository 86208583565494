const INITIAL_STATE = {
  catalogo: [],
};

export default function setcatalogo(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'SET_CATALOGO':
      return {
        ...state,
        catalogo: action.catalogo,
      };
    default:
      return state;
  }
}
