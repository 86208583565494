export const timeinmillis = time => {
  const date = new Date(time);

  const seconds = date.getSeconds() < 10 ? 0 : '' + date.getSeconds();
  const hour = (date.getHours() < 10 ? '0' : '') + date.getHours();
  const minutes = (date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();
  return `${hour}:${minutes}:${seconds} - ${day}/${month}/${year}`;
};
